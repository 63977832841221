import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react';
import React, { ReactNode } from 'react';

import IconAddCircle from 'assets/icons/add_circle.svg';

interface IDisclosure {
  title: string;
  // eslint-disable-next-line react/require-default-props
  children: ReactNode;
  // eslint-disable-next-line react/require-default-props
  defaultOpen?: boolean;
  id: string;
}

export default ({ title, children, defaultOpen = true, id }: IDisclosure) => (
  <div className='w-full divide-y divide-orange/5 shadow-lg rounded-xl my-2'>
    <Disclosure as='div' defaultOpen={defaultOpen}>
      <DisclosureButton
        id={id}
        className='group flex w-full items-center justify-between bg-orange/20 px-4 py-3 rounded-xl'
      >
        <span className='text-lg group-data-[hover]:text-white/80 text-orange mx-auto'>
          <IconAddCircle className='w-9 h-9 inline-block mr-2' />
          {title}
        </span>
      </DisclosureButton>
      <DisclosurePanel className='mt-2 text-sm/5 p-4 max-h-[400px] overflow-x-auto'>
        {children}
      </DisclosurePanel>
    </Disclosure>
  </div>
);
