const verifyPassword = async (formData: FormData): Promise<any> => {
  const response = await fetch('/consultant/checkpassword', {
    method: 'POST',
    body: formData,
  });

  const jsonResponse = await response.json();
  jsonResponse.status = response.status;
  return jsonResponse;
};

export default verifyPassword;
