import { ISSFooter } from 'types/interfaces/sulu/ISnippets';

import currentLanguage from './currentLanguage';

export default async (): Promise<ISSFooter> => {
  const response = await fetch(`/${currentLanguage}/api/snippet-areas/footer`);
  if (response.ok) {
    const jsonResponse = await response.json();
    return jsonResponse;
  }
  /**
   * ts-ignore is necessary here because an empty object
   * is not valid for the ISSFooter interface
   * */
  // @ts-ignore
  return {};
};
