import React from 'react';

import BlockContainer from 'components/BlockContainer';

import { DEFAULT_SCHEME_COLOR } from 'utils/constants';

interface ICategoryHeader {
  title: string;
  imageUrl: string;
  backgroundColor: string;
  numberOfArticles?: number;
  numberOfPodcasts?: number;
  numberOfVideos?: number;
}

const CategoryHeader = ({
  title,
  imageUrl,
  backgroundColor,
  numberOfArticles,
  numberOfPodcasts,
  numberOfVideos,
}: ICategoryHeader) => {
  const bgImageStyle = {
    backgroundImage: `url(${imageUrl})`,
  };

  return (
    <div
      className='relative'
      style={{ backgroundColor: backgroundColor || DEFAULT_SCHEME_COLOR }}
    >
      {/* linear gradient */}
      <div className='absolute inset-0 bg-gradient-to-b from-transparent to-[#3B373980]' />
      <BlockContainer>
        <div className='grid grid-cols-1 md:grid-cols-[10rem_minmax(0,_1fr)] items-center gap-x-12'>
          <div
            className='hidden md:block w-40 h-40 p-2.5 rounded-xl shadow-lg'
            style={{ backgroundColor: backgroundColor || DEFAULT_SCHEME_COLOR }}
          >
            <div
              className='w-full h-full bg-center bg-no-repeat bg-cover rounded-md'
              style={bgImageStyle}
            />
          </div>
          <div>
            <h1 className='text-white'>{title}</h1>
            <p className='text-sm md:text-base text-white mt-6'>
              {numberOfArticles}&nbsp;Artikel<span className='mx-2'>|</span>
              {numberOfPodcasts}&nbsp;Podcast
              {numberOfPodcasts && numberOfPodcasts === 1 ? '' : 's'}
              <span className='mx-2'>|</span>
              {numberOfVideos}&nbsp;Video
              {numberOfVideos && numberOfVideos === 1 ? '' : 's'}
            </p>
          </div>
        </div>
      </BlockContainer>
    </div>
  );
};

CategoryHeader.defaultProps = {
  numberOfArticles: 0,
  numberOfPodcasts: 0,
  numberOfVideos: 0,
};

export default CategoryHeader;
