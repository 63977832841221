export const googleMapsApiKey = 'AIzaSyDk72ETfbwNUPjlCH0JXBfDGCBWuJNLAbg';

export const geocodeAddress = async (
  address: string,
): Promise<{ lat: number; lng: number } | null> => {
  const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    address,
  )}&key=${googleMapsApiKey}`;

  try {
    const response = await fetch(geocodeUrl);
    const data = await response.json();

    if (data.status === 'OK' && data.results[0]?.geometry?.location) {
      const { lat, lng } = data.results[0].geometry.location;
      return { lat, lng };
    }

    // If geocoding fails, return default coordinates
    return { lat: 54.0885, lng: 10.1334 }; // Default coordinates for WDE GmbH
  } catch (error) {
    // In case of an error (e.g., network issues), return default coordinates
    return { lat: 54.0885, lng: 10.1334 }; // Fallback coordinates
  }
};
