import { IDeleteAccountResponse } from '../../types/interfaces/sulu/IDeleteAccount';

export default async (formData: FormData): Promise<IDeleteAccountResponse> => {
  const response = await fetch('/consultant-account-termination', {
    method: 'POST',
    body: formData,
  });

  const jsonResponse = await response.json();
  jsonResponse.status = response.status;
  return jsonResponse;
};
