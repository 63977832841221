import { z } from 'zod';

import {
  ESuluDataTypes,
  ESuluSnippetTypes,
} from 'types/enums/sulu/EBackendData';

import ZSCBLink from './contentBlocks/ZSCBLink';
import ZSCBSocialMediaLink from './contentBlocks/ZSCBSocialMediaLink';
import ZSuluMedia from './contentBlocks/ZSuluMedia';

/**
 * MIND: ZSS = Zod Sulu Snippet
 * MIND: Some properties are omitted as they were not used
 * at the time of implementation. If some properties are missing,
 * check the raw data coming from Sulu and extend the according type.
 * MIND: Relevant interfaces / types are exported at the bottom of the file.
 */

const ZSuluSnippetBaseData = z.object({
  id: z.string().uuid(),
  type: z.literal(ESuluDataTypes.SNIPPET),
});

export const ZSSHero = ZSuluSnippetBaseData.extend({
  template: z.literal(ESuluSnippetTypes.HERO),
  content: z.object({
    headline: z.string(),
    textBlock: z.string().or(z.null()),
    subtitle: z.string().or(z.null()),
    scrollDownText: z.string().or(z.null()),
    video: ZSuluMedia,
    poster: ZSuluMedia,
  }),
});

export const ZSSFooter = ZSuluSnippetBaseData.extend({
  template: z.literal(ESuluSnippetTypes.FOOTER),
  content: z.object({
    informationLinks: z.array(ZSCBLink),
    entertainmentLinks: z.array(ZSCBLink),
    consultingLinks: z.array(ZSCBLink),
    socialMediaLinks: z.array(ZSCBSocialMediaLink),
  }),
});

export const ZSuluSnippet = z.union([ZSSHero, ZSSFooter]);

export type ISSHero = z.infer<typeof ZSSHero>;
export type ISSFooter = z.infer<typeof ZSSFooter>;

export type ISuluSnippet = z.infer<typeof ZSuluSnippet>;
