import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';

import currentLanguage from './sulu/currentLanguage';

export default (
  route: string,
  query: { key: EUrlSearchParam; value: string | number }[] = [],
) => {
  const firstQueryElement = query.length ? query[0] : undefined;
  const restQueryElements = firstQueryElement ? query.slice(1) : [];

  window.location.href = `/${currentLanguage}${route ? `/${route}` : ''}${
    firstQueryElement
      ? `?${firstQueryElement.key}=${firstQueryElement.value}`
      : ''
  }${restQueryElements.map(q => `&${q.key}=${q.value}`)}`;
};
