import React, { useState } from 'react';

import ButtonPayment from 'components/static/Button/ButtonPayment';

import { EPaymentUrlQuery } from 'types/enums/mollie/EMollieData';
import {
  IMollieCreatePaymentRequestData,
  IMolliePaymentObject,
} from 'types/interfaces/mollie/IMollieApiData';
import { IUser } from 'types/interfaces/sulu/IUser';

import { createPayment } from 'utils/mollie/api';
import showPaymentErrorNotification from 'utils/mollie/showPaymentErrorNotification';
import notify from 'utils/notify';
import currentLanguage from 'utils/sulu/currentLanguage';

interface IPayment {
  articleId: string;
  price: string;
  user: IUser | undefined;
}

export default ({ articleId, price, user }: IPayment) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const redirectUrl = window.location.href.includes('?')
    ? `${window.location.href}&${EPaymentUrlQuery.REDIRECT}=1`
    : `${window.location.href}?${EPaymentUrlQuery.REDIRECT}=1`;
  const cancelUrl = window.location.href.includes('?')
    ? `${window.location.href}&${EPaymentUrlQuery.CANCEL}=1`
    : `${window.location.href}?${EPaymentUrlQuery.CANCEL}=1`;
  const locale = currentLanguage;

  const handlePayment = () => {
    if (!user) {
      notify('Bitte melden Sie sich an, um diesen Artikel kaufen zu können.');
      return;
    }

    setIsError(false);
    setIsLoading(true);

    const createPaymentRequestData: IMollieCreatePaymentRequestData = {
      articleId,
      redirectUrl,
      cancelUrl,
      locale,
    };

    createPayment(createPaymentRequestData)
      .then((response: IMolliePaymentObject) => {
        if (!Object.keys(response).length) {
          setIsError(true);
          showPaymentErrorNotification();
          return;
        }

        const { _links: links } = response;
        const { checkout } = links;

        if (!checkout) {
          setIsError(true);
          showPaymentErrorNotification({
            message:
              'Der Bezahlvorgang konnte nicht gestartet werden. Bitte versuchen Sie es erneut.',
          });
          return;
        }

        // redirect customer to mollie checkout
        window.location.href = checkout.href;
      })
      .catch(error => {
        setIsError(true);
        showPaymentErrorNotification(error);
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <ButtonPayment
      onClick={handlePayment}
      price={price}
      isLoading={isLoading}
      isError={isError}
    />
  );
};
