import React, { useEffect, useState } from 'react';

import { ISSFooter } from 'types/interfaces/sulu/ISnippets';

import getFooter from 'utils/sulu/getFooter';
import suluSnippetHandler from 'utils/sulu/snippetHandler';

export default () => {
  const [footerData, setFooterData] = useState<ISSFooter>({} as ISSFooter);

  useEffect(() => {
    getFooter().then(res => setFooterData(res));
  }, []);

  return (
    <>
      {/* render footer */}
      {suluSnippetHandler(footerData)}
    </>
  );
};
