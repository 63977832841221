import React from 'react';

import { ESuluLayoutBlockVariant } from 'types/enums/sulu/EBackendData';
import { ISLBTwoColumns } from 'types/interfaces/sulu/ILayoutBlocks';

import suluBlockHandler from 'utils/sulu/blockHandler';

const TwoColumns = ({ variant, left, right }: ISLBTwoColumns) => {
  const getColSpanClass = (side: 'left' | 'right') => {
    if (variant === ESuluLayoutBlockVariant.FIFTY_FIFTY) {
      return '';
    }

    return variant === ESuluLayoutBlockVariant.ONE_THIRD_TWO_THIRD
      ? `${side === 'left' ? 'lg:col-span-1' : 'lg:col-span-2'}`
      : `${side === 'left' ? 'lg:col-span-2' : 'lg:col-span-1'}`;
  };

  return (
    <div
      className={`grid grid-cols-1 ${
        variant === ESuluLayoutBlockVariant.FIFTY_FIFTY
          ? 'lg:grid-cols-2'
          : 'lg:grid-cols-3'
      }`}
    >
      <div className={getColSpanClass('left')}>
        {left.map((block, i) => suluBlockHandler(block, i))}
      </div>
      <div className={getColSpanClass('right')}>
        {right.map((block, i) => suluBlockHandler(block, i))}
      </div>
    </div>
  );
};

export default TwoColumns;
