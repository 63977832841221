import React, { useState } from 'react';

import BlockContainer from 'components/BlockContainer';
import CloseButton from 'components/static/CloseButton';

import { IMenuBar } from 'types/interfaces/IMenu';

import './Menu.css';
import createLinks from './utils/createLinks';

export default ({ menuItems }: IMenuBar) => {
  const { information, entertainment, consulting } = menuItems;

  const [showInformationNavigation, setShowInformationNavigation] =
    useState(false);
  const [showEntertainmentNavigation, setShowEntertainmentNavigation] =
    useState(false);
  const [showConsultingNavigation, setShowConsultingNavigation] =
    useState(false);

  return (
    <BlockContainer outerClasses='bg-grey py-4'>
      <div className='grid grid-cols-1'>
        <div>
          <button
            type='button'
            onClick={() => setShowInformationNavigation(true)}
            className='w-full py-2 text-center hover:font-bold'
          >
            Information
          </button>
          <div
            className={`menu-bar-mobile-container ${
              showInformationNavigation ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <div className='relative'>
              <h3 className='w-full text-center px-10'>Information</h3>
              <CloseButton
                className='absolute -top-6 -right-1'
                onClick={() => setShowInformationNavigation(false)}
              />
            </div>
            <div className='mt-5'>{createLinks(information)}</div>
          </div>
        </div>
        <div>
          <button
            type='button'
            onClick={() => setShowEntertainmentNavigation(true)}
            className='w-full py-2 text-center hover:font-bold'
          >
            Unterhaltung
          </button>
          <div
            className={`menu-bar-mobile-container ${
              showEntertainmentNavigation ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <div className='relative'>
              <h3 className='w-full text-center px-10'>Unterhaltung</h3>
              <CloseButton
                className='absolute -top-6 -right-1'
                onClick={() => setShowEntertainmentNavigation(false)}
              />
            </div>
            <div className='mt-5'>{createLinks(entertainment)}</div>
          </div>
        </div>
        <div>
          <button
            type='button'
            onClick={() => setShowConsultingNavigation(true)}
            className='w-full py-2 text-center hover:font-bold'
          >
            Beratung
          </button>
          <div
            className={`menu-bar-mobile-container ${
              showConsultingNavigation ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <div className='relative'>
              <h3 className='w-full text-center px-10'>Beratung</h3>
              <CloseButton
                className='absolute -top-6 -right-1'
                onClick={() => setShowConsultingNavigation(false)}
              />
            </div>
            <div className='mt-5'>{createLinks(consulting)}</div>
          </div>
        </div>
      </div>
    </BlockContainer>
  );
};
