import {
  IMollieCreatePaymentRequestData,
  IMolliePaymentObject,
  IMollieUserPaymentsResponseData,
} from 'types/interfaces/mollie/IMollieApiData';

export const createPayment = async (
  body: IMollieCreatePaymentRequestData,
): Promise<IMolliePaymentObject> => {
  const response = await fetch('/api/create-payment', {
    method: 'POST',
    body: JSON.stringify(body),
  });

  if (response.ok) {
    const jsonResponse = await response.json();
    if (jsonResponse.success) {
      return jsonResponse.payment;
    }
  }

  return {} as IMolliePaymentObject;
};

export const getPaymentStatus = async (
  paymentId: string,
): Promise<IMolliePaymentObject> => {
  const response = await fetch(`/api/status_payment/${paymentId}`);

  if (response.ok) {
    const jsonResponse = await response.json();
    return jsonResponse.payment;
  }

  return {} as IMolliePaymentObject;
};

export const getUserPayments =
  async (): Promise<IMollieUserPaymentsResponseData> => {
    const response = await fetch('/api/user_payments');

    if (response.ok) {
      const jsonResponse = await response.json();
      return jsonResponse;
    }

    return {} as IMollieUserPaymentsResponseData;
  };
