import React from 'react';

import notify from 'utils/notify';

export default (error?: { message: string }) => {
  notify(
    () => (
      <>
        <p className='text-lg'>Fehler</p>
        <p className='mt-1'>
          {error ? error.message : 'Es ist ein unbekannter Fehler aufgetreten.'}
        </p>
      </>
    ),
    'error',
  );
};
