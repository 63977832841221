const colors = {
  black: {
    DEFAULT: '#242423',
  },
  grey: {
    dark: '#e0e0e1',
    DEFAULT: '#f2f1f3',
    light: '#f8f8f8',
  },
  blue: {
    light: '#c0e9fe',
    DEFAULT: '#066EBA',
    gradient: {
      from: '#066EBA',
      to: '#03375D',
    },
  },
  orange: {
    DEFAULT: '#ff9821',
    gradient: {
      from: '#fcca46',
      to: '#fe7f2d',
    },
  },
  'consultant-tile': {
    article: '#1AD7CB',
    podcast: '#63C33C',
    video: '#0715E8',
  },
};

/** @type {import('tailwindcss').TailwindConfig} */
module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: {
      colors,
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
    },
    fontFamily: {
      sans: ['Rubik', 'Helvetica Neue', 'Arial', 'sans-serif'],
    },
  },
  plugins: [require('@tailwindcss/line-clamp')],
};
