import React, { useEffect, useState } from 'react';

import { ESuluTOCItemLevel } from 'types/enums/sulu/EBackendData';
import {
  ISCBTableOfContents,
  ISCBTOCItem,
} from 'types/interfaces/sulu/IContentBlocks';

type TTocItem = {
  name: string;
  id: string;
  items: TTocItem[];
};

const TableOfContents = ({ headline, items }: ISCBTableOfContents) => {
  const [tocItems, setTocItems] = useState<TTocItem[]>([]);

  const createTocItems = () => {
    const newItems: TTocItem[] = [];
    let newItem: TTocItem = {} as TTocItem;
    let indexOfLastAddedItem = 0;
    let lastAddedItem: TTocItem = {} as TTocItem;

    items.forEach((item: ISCBTOCItem) => {
      newItem = {
        name: item.name,
        id: item.id,
        items: [],
      };

      if (item.level === ESuluTOCItemLevel.LEVEL_0) {
        newItems.push(newItem);
      } else {
        indexOfLastAddedItem = newItems.length ? newItems.length - 1 : 0;
        lastAddedItem = newItems.length
          ? newItems[indexOfLastAddedItem]
          : ({} as TTocItem);

        if (Object.keys(lastAddedItem).length) {
          if (
            item.level === ESuluTOCItemLevel.LEVEL_2 &&
            lastAddedItem.items.length
          ) {
            lastAddedItem.items[lastAddedItem.items.length - 1].items.push(
              newItem,
            );
          } else {
            lastAddedItem.items.push(newItem);
          }
        }

        newItems[indexOfLastAddedItem] = Object.keys(lastAddedItem).length
          ? lastAddedItem
          : newItem;
      }
    });

    setTocItems(newItems);
  };

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const y = element.getBoundingClientRect().top + window.scrollY - 60;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (window.location.hash) {
      scrollToElement(window.location.hash.substring(1));
    }
  });

  useEffect(() => {
    createTocItems();
  }, [items]);

  const createItems = (itemsToWorkWith: TTocItem[]) =>
    itemsToWorkWith.map((item: TTocItem) => (
      <li key={item.id}>
        <a href={`#${item.id}`} onClick={() => scrollToElement(item.id)}>
          {item.name}
        </a>
        {item.items.length > 0 && <ol>{createItems(item.items)}</ol>}
      </li>
    ));

  return (
    <div className='table-of-contents'>
      {headline && <h3>{headline}</h3>}
      {tocItems.length > 0 && <ol className='ml-0'>{createItems(tocItems)}</ol>}
    </div>
  );
};

export default TableOfContents;
