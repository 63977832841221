import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-use';

import { ERoute } from '../types/enums/ERoute';
import { EUrlSearchParam } from '../types/enums/EUrlSearchParam';
import navigateTo from './navigateTo';

const GlossaryLinks = () => {
  const location = useLocation();
  const [showGlossaryNavigation, setShowGlossaryNavigation] =
    useState<boolean>(false);

  useEffect(() => {
    // Check if location is defined and contains "GLOSSARY" in the URL (path or query params)
    if (location && location.pathname) {
      setShowGlossaryNavigation(
        location.pathname.includes(EUrlSearchParam.GLOSSARY),
      );
    }
  }, [location]);

  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  return showGlossaryNavigation ? (
    <div className='flex flex-wrap gap-2 mt-4'>
      <button
        className='hover:text-orange w-full sm:w-auto'
        type='button'
        onClick={() => navigateTo(`${ERoute.GLOSSARY}`)}
      >
        Übersicht
      </button>
      {alphabet.map(letter => (
        <button
          className='hover:text-orange w-8 h-8 sm:w-auto sm:h-auto'
          key={letter}
          type='button'
          onClick={() =>
            navigateTo(`${ERoute.GLOSSARY}/${letter.toLowerCase()}`)
          }
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              navigateTo(`${ERoute.GLOSSARY}/${letter.toLowerCase()}`);
            }
          }}
        >
          {letter}
        </button>
      ))}
    </div>
  ) : null;
};

export default GlossaryLinks;
