import React, { useState } from 'react';

import { ETab } from 'types/enums/ETab';

import Login from './Login';
import Registration from './Registration';

export default () => {
  const [activeTab, setActiveTab] = useState<ETab>(ETab.LOGIN);

  const createTabNavButton = (label: string, tab: ETab) => (
    <button
      type='button'
      className={`p-3 text-center font-bold ${
        activeTab === tab ? 'text-orange' : 'text-black'
      } hover:text-orange border-r border-grey-dark last:border-r-0`}
      onClick={() => {
        setActiveTab(tab);
      }}
    >
      {label}
    </button>
  );

  return (
    <div>
      {/* Tab nav */}
      <div className='grid grid-cols-2 items-center border-t md:border-t-0 border-b border-grey-dark'>
        {createTabNavButton('Anmelden', ETab.LOGIN)}
        {createTabNavButton('Registrieren', ETab.REGISTRATION)}
      </div>
      {/* Tab content */}
      <div className='pt-6 px-5 pb-8 h-full max-h-full md:max-h-[500px] overflow-auto'>
        {activeTab === ETab.LOGIN ? <Login /> : <Registration />}
      </div>
    </div>
  );
};
