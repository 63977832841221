// @ts-ignore
import { startApp } from 'sulu-headless-bundle';
// @ts-ignore
import viewRegistry from 'sulu-headless-bundle/src/registries/viewRegistry';

import HeadlessTemplatePage from 'views/HeadlessTemplatePage';

import './index.css';

// register views for rendering page templates
viewRegistry.add('page', 'all_contents', HeadlessTemplatePage);
viewRegistry.add('page', 'category', HeadlessTemplatePage);
viewRegistry.add('page', 'consultant_registration', HeadlessTemplatePage);
viewRegistry.add('page', 'default', HeadlessTemplatePage);
viewRegistry.add('page', 'delete_account', HeadlessTemplatePage);
viewRegistry.add('page', 'homepage', HeadlessTemplatePage);
viewRegistry.add('page', 'my_library', HeadlessTemplatePage);
viewRegistry.add('page', 'my_world', HeadlessTemplatePage);
viewRegistry.add('page', 'password_reset', HeadlessTemplatePage);
viewRegistry.add('page', 'search', HeadlessTemplatePage);
viewRegistry.add('page', 'settings', HeadlessTemplatePage);
viewRegistry.add('page', 'tile', HeadlessTemplatePage);
viewRegistry.add('page', 'consultant_profile', HeadlessTemplatePage);
viewRegistry.add('page', 'consultant_overview', HeadlessTemplatePage);
viewRegistry.add('page', 'consultant_articles_overview', HeadlessTemplatePage);
viewRegistry.add('page', 'consultant_welcome', HeadlessTemplatePage);
viewRegistry.add('page', 'consultant_create_article', HeadlessTemplatePage);
viewRegistry.add('page', 'consultant_new_article', HeadlessTemplatePage);
viewRegistry.add('page', 'consultant_article', HeadlessTemplatePage);
viewRegistry.add('page', 'consultant_delete_account', HeadlessTemplatePage);

// start react application in specific DOM element
startApp(document.getElementById('sulu-headless-container'));
