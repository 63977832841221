// eslint-disable-next-line import/prefer-default-export
export enum EPaymentStatus {
  PAID = 'paid',
  EXPIRED = 'expired',
  FAILED = 'failed',
  CANCELED = 'canceled',
  OPEN = 'open',
}

export enum EPaymentUrlQuery {
  REDIRECT = 'redirectedFromCheckout',
  CANCEL = 'checkoutCanceled',
}
