import React, { useEffect, useId, useState, FormEvent } from 'react';
import { useLocation } from 'react-use';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import { Button, ButtonLink } from 'components/static/Button';
import Layout from 'components/static/Layout';

import { EButtonType } from 'types/enums/EButton';
import { ERoute } from 'types/enums/ERoute';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { EProfileFormData } from 'types/enums/sulu/EFormData';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { ISPSettings } from 'types/interfaces/sulu/IPages';

import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import currentLanguage from 'utils/sulu/currentLanguage';
import getProfile from 'utils/sulu/getProfile';
import updateProfile from 'utils/sulu/updateProfile';

export default ({ content }: ISPSettings) => {
  const { title } = content;

  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const consultantId = searchParams.get(EUrlSearchParam.CONSULTANT_ID) ?? '';

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);

  const firstNameInputId = useId();
  const lastNameInputId = useId();
  const emailInputId = useId();

  const setProfileData = () => {
    getProfile().then(profile => {
      if (profile) {
        setFirstName(profile.firstName);
        setLastName(profile.lastName);
        setEmail(profile.email);
        // eslint-disable-next-line no-underscore-dangle
        setCsrfToken(profile._token);
      }
    });

    // TODO: what to do otherwise? redirect to homepage and open login form?
  };

  useEffect(() => {
    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      consultantId ? ESidebarType.CONSULTANT : ESidebarType.PROFILE,
      [],
      consultantId,
    );
    setMenuItems(sidebarMenuItems);
    setProfileData();
  }, []);

  // TODO: implement profile load + profile edit/update
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setIsError(false);
    setIsLoading(true);
    setErrorMessage('');
    // Prevent the browser from reloading the page
    e.preventDefault();

    const formData = new FormData();
    formData.append(EProfileFormData.EMAIL, email);
    formData.append(EProfileFormData.FIRST_NAME, firstName);
    formData.append(EProfileFormData.LAST_NAME, lastName);
    formData.append(EProfileFormData.TOKEN, csrfToken);

    updateProfile(formData).then(res => {
      const { success, _token: token, status } = res;

      setIsLoading(false);
      if (token) {
        setCsrfToken(token);
      }

      // TODO: check if we have some success: true
      if (success) {
        setIsSuccessful(true);
        setSuccessMessage('Das Aktualisieren Ihres Profils war erfolgreich!');
        setTimeout(() => {
          setIsSuccessful(false);
          setSuccessMessage('');
        }, 3000);
        return;
      }

      setIsError(true);

      if (status === 401 || !success) {
        setErrorMessage(
          'Beim Aktualisieren Ihres Profils ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
        );
      }
    });
  };

  return (
    <Layout menuItems={menuItems} showLogoutButton>
      <BlockContainer>
        <>
          <h3>{title}</h3>
          <h3 className='mt-8'>Ihre Daten</h3>

          <form className='form mt-5' onSubmit={handleSubmit}>
            <div className='flex flex-col gap-6 md:max-w-xs'>
              <label htmlFor={firstNameInputId}>
                Vorname
                <input
                  id={firstNameInputId}
                  type='text'
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required
                />
              </label>
              <label htmlFor={lastNameInputId}>
                Nachname
                <input
                  id={lastNameInputId}
                  type='text'
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  required
                />
              </label>
              <label htmlFor={emailInputId}>
                E-Mail
                <input
                  id={emailInputId}
                  type='email'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                />
              </label>
            </div>
            <div className='flex items-center justify-between mt-12'>
              <Button
                type='submit'
                label='Speichern'
                isLoading={isLoading}
                isSuccessful={isSuccessful}
                isError={isError}
              />
              <ButtonLink
                buttonType={EButtonType.SECONDARY}
                label='Account löschen'
                url={`/${currentLanguage}/${ERoute.DELETE_ACCOUNT}`}
              />
            </div>

            {(errorMessage || successMessage) && (
              <p
                className={`text-sm mt-2 ${
                  errorMessage ? 'text-red-500' : 'text-green-500'
                }`}
              >
                {errorMessage || successMessage}
              </p>
            )}
          </form>
        </>
      </BlockContainer>
      <Footer />
    </Layout>
  );
};
