export default ([...arr]) => {
  let { length } = arr;
  while (length) {
    // eslint-disable-next-line no-plusplus
    const index = Math.floor(Math.random() * length--);
    // eslint-disable-next-line no-param-reassign
    [arr[length], arr[index]] = [arr[index], arr[length]];
  }
  return arr;
};
