import React from 'react';

import Logo from 'assets/images/logo.svg';

import BlockContainer from 'components/BlockContainer';

import { ISCBLink } from 'types/interfaces/sulu/IContentBlocks';
import { ISSFooter } from 'types/interfaces/sulu/ISnippets';

import getSocialMediaIcon from 'utils/getSocialMediaIcon';

const FooterSnippet = ({ content }: ISSFooter) => {
  const {
    informationLinks,
    entertainmentLinks,
    consultingLinks,
    socialMediaLinks,
  } = content;
  const createLinks = (links: ISCBLink[]) =>
    links.map(link => (
      <a
        key={link.name}
        href={link.url}
        target={link.target || '_blank'}
        rel='noreferrer noopener'
        className='py-2 md:px-2'
      >
        {link.name}
      </a>
    ));

  const createSocialMediaLinks = () =>
    socialMediaLinks.map(socialMediaLink => (
      <a
        key={socialMediaLink.social_media_type}
        href={socialMediaLink.url}
        target='_blank'
        rel='noreferrer noopener'
        title={socialMediaLink.title || ''}
        className='flex-grow-0 flex-shrink-0 flex items-center justify-center'
      >
        {getSocialMediaIcon(socialMediaLink.social_media_type)}
      </a>
    ));

  return (
    <BlockContainer outerClasses='border-t-4 border-grey-light'>
      <div className='grid grid-cols-1 md:grid-cols-4 gap-10'>
        <div className='flex flex-col items-start justify-between md:py-2'>
          <Logo className='w-24' />
          <div className='flex flex-wrap items-center gap-4 mt-5'>
            {createSocialMediaLinks()}
          </div>
        </div>
        <div className='flex flex-col items-start'>
          {createLinks(informationLinks)}
        </div>
        <div className='flex flex-col items-start'>
          {createLinks(entertainmentLinks)}
        </div>
        <div className='flex flex-col items-start'>
          {createLinks(consultingLinks)}
        </div>
      </div>
    </BlockContainer>
  );
};

export default FooterSnippet;
