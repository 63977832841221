import React, { FormEvent, useEffect, useId, useState } from 'react';

import { Button } from 'components/static/Button';

import { ERoute } from 'types/enums/ERoute';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import {
  ELoginFormData,
  EPasswordForgetFormData,
} from 'types/enums/sulu/EFormData';

import navigateTo from 'utils/navigateTo';
import getCsrfToken from 'utils/sulu/getCsrfToken';
import getProfile from 'utils/sulu/getProfile';
import login from 'utils/sulu/login';
import passwordForget from 'utils/sulu/passwordForget';

export default () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isPasswordForget, setIsPasswordForget] = useState<boolean>(false);
  const [passwordForgetCsrfToken, setPasswordForgetCsrfToken] = useState('');

  const usernameInputId = useId();
  const passwordInputId = useId();

  const getNewToken = () => {
    getCsrfToken('/password-forget').then(token =>
      setPasswordForgetCsrfToken(token),
    );
  };

  useEffect(() => {
    setErrorMessage('');
    setIsSuccessful(false);
    setIsError(false);
  }, [username, password]);

  useEffect(() => {
    if (!isLoading && isPasswordForget) {
      getNewToken();
    }
  }, [isLoading, isPasswordForget]);

  useEffect(() => {
    document.addEventListener('login:success', getNewToken);
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setIsError(false);
    setIsLoading(true);
    setErrorMessage('');
    // Prevent the browser from reloading the page
    e.preventDefault();

    if (isPasswordForget) {
      const formData = new FormData();
      formData.append(EPasswordForgetFormData.USERNAME, username);
      formData.append(EPasswordForgetFormData.TOKEN, passwordForgetCsrfToken);

      passwordForget(formData).then(res => {
        const { success, errors } = res;

        setIsLoading(false);

        if (success) {
          setIsSuccessful(true);
          return;
        }

        // unfortunately the response form the backend leaks the info if an account exists with this email or not
        // normally it would be better to not leak that fact but to just say "if an account exists you will get an email"
        // the general success message is still missing in the frontend so there might be room for improvement

        setIsError(true);

        if (
          errors &&
          errors?.email_username === `The value "${username}" was not found.`
        ) {
          setErrorMessage(
            'Es existiert kein Benutzer mit dieser Email-Adresse.',
          );
          return;
        }

        setErrorMessage('Es ist ein Fehler aufgetreten.');
      });
      return;
    }

    const formData = new FormData();
    formData.append(ELoginFormData.USERNAME, username);
    formData.append(ELoginFormData.PASSWORD, password);

    login(formData).then(res => {
      const { success, error } = res;

      if (success) {
        getProfile().then(user => {
          setIsLoading(false);
          setIsSuccessful(true);

          // dispatch event to update the profile button
          document.dispatchEvent(new Event('login:success'));

          if (user && user.consultantDetails !== undefined) {
            navigateTo(ERoute.CONSULTANT_WELCOME, [
              { key: EUrlSearchParam.CONSULTANT_ID, value: user.id },
            ]);
          }
        });
        return;
      }

      setIsLoading(false);
      setIsError(true);

      if (!error) {
        setErrorMessage(
          'Es ist ein Fehler bei der Authentifizierung aufgetreten.',
        );
        return;
      }

      if (error.includes('not enabled yet')) {
        setErrorMessage(
          'Sie müssen erst Ihren Account aktivieren, bevor Sie sich anmelden können.',
        );
      }

      if (error.includes('Bad credentials')) {
        setErrorMessage(
          'Die Anmeldedaten sind falsch. Bitte überprüfen Sie diese und versuchen es erneut.',
        );
      }
    });
  };

  return (
    <form className='form flex flex-col gap-6' onSubmit={handleSubmit}>
      <label htmlFor={usernameInputId}>
        E-Mail
        <input
          id={usernameInputId}
          type='email'
          value={username}
          onChange={e => setUsername(e.target.value)}
          required
        />
      </label>
      {!isPasswordForget && (
        <>
          <label htmlFor={passwordInputId}>
            Passwort
            <input
              id={passwordInputId}
              type='password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
          </label>

          <button
            type='button'
            onClick={() => {
              setIsError(false);
              setErrorMessage('');
              setIsPasswordForget(true);
            }}
            className='place-self-start text-sm cursor-pointer'
          >
            Passwort vergessen?
          </button>
          <div className='flex flex-col items-center justify-center'>
            <Button
              type='submit'
              label='Anmelden'
              isLoading={isLoading}
              isSuccessful={isSuccessful}
              isError={isError}
              disabled={!username || !password}
            />
            {errorMessage && (
              <p className='text-sm mt-2 text-red-500 text-center'>
                {errorMessage}
              </p>
            )}
          </div>
        </>
      )}
      {isPasswordForget && (
        <div className='flex flex-col items-center justify-center'>
          <Button
            type='submit'
            label='Absenden'
            isLoading={isLoading}
            isSuccessful={isSuccessful}
            isError={isError}
            disabled={!username}
          />
          {errorMessage && (
            <p className='text-sm mt-2 text-red-500 text-center'>
              {errorMessage}
            </p>
          )}
        </div>
      )}
    </form>
  );
};
