import React, { FormEvent, useEffect, useId, useState } from 'react';
import { useWindowSize } from 'react-use';

import Logo from 'assets/images/logo.svg';

import BlockContainer from 'components/BlockContainer';
import Search from 'components/primitives/Search';
import { Button } from 'components/static/Button';
import ProfileButton from 'components/static/ProfileButton';

import { EButtonSize } from 'types/enums/EButton';
import { ESearchfieldSize, ESearchfieldVariant } from 'types/enums/ESearch';
import { EPasswordResetFormData } from 'types/enums/sulu/EFormData';
import { ISPPasswordReset } from 'types/interfaces/sulu/IPages';

import navigateTo from 'utils/navigateTo';
import getCsrfToken from 'utils/sulu/getCsrfToken';
import passwordReset from 'utils/sulu/passwordReset';

export default ({ content }: ISPPasswordReset) => {
  const { title } = content;
  const { width } = useWindowSize();

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [passwordToken, setPasswordToken] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const [searchfieldIsOpen, setSearchfieldIsOpen] = useState<boolean>(false);

  const passwordInputId = useId();
  const passwordRepeatInputId = useId();

  const getNewToken = () => {
    getCsrfToken(`/password-reset/${passwordToken}`).then(token =>
      setCsrfToken(token),
    );
  };

  useEffect(() => {
    setErrorMessage('');
    setIsSuccessful(false);
    setIsError(false);
  }, [password, passwordRepeat]);

  useEffect(() => {
    const urlParams = new URLSearchParams(document.location.search);
    const urlToken = urlParams.get('token') || '';
    setPasswordToken(urlToken);
    document.addEventListener('login:success', getNewToken);
  }, []);

  useEffect(() => {
    if (!isLoading && passwordToken) {
      getNewToken();
    }
  }, [isLoading, passwordToken]);

  // TODO: check for "Invalid reset token" message

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setIsError(false);
    setIsLoading(true);
    setErrorMessage('');
    // Prevent the browser from reloading the page
    e.preventDefault();

    const formData = new FormData();
    formData.append(EPasswordResetFormData.PASSWORD, password);
    formData.append(EPasswordResetFormData.TOKEN, csrfToken);

    passwordReset(formData, passwordToken).then(res => {
      const { success } = res;

      setIsLoading(false);

      if (success) {
        setIsSuccessful(true);
        // upon successful password reset, sulu sends a new user session as cookie
        // so the user is automatically logged in
        // dispatch event to update the profile button
        document.dispatchEvent(new Event('login:success'));
        setTimeout(() => {
          navigateTo('');
        }, 1000);
        return;
      }

      setIsError(true);
      setErrorMessage('Es ist ein Fehler aufgetreten.');
    });
  };

  const handleToggleSearchfield = (isOpen: boolean) => {
    setSearchfieldIsOpen(isOpen);
  };

  return (
    <div className='h-screen'>
      {/* top navbar */}
      <div className='fixed top-0 z-50 flex items-center justify-between w-full h-14 px-5 py-2 bg-white shadow-md'>
        <Logo
          className={`h-full cursor-pointer transition-opacity ease-in-out duration-300 ${
            !searchfieldIsOpen || width >= 450
              ? 'opacity-100'
              : 'opacity-0 pointer-events-none'
          }`}
          onClick={() => navigateTo('')}
        />
        <div className='flex items-center gap-5'>
          <Search
            variant={ESearchfieldVariant.DARK}
            size={ESearchfieldSize.SMALL}
            onToggleSearchfield={handleToggleSearchfield}
          />
          <ProfileButton buttonSize={EButtonSize.SMALL} />
        </div>
      </div>
      {/* render content */}
      <div className='flex items-center justify-center h-full pt-14'>
        <BlockContainer outerClasses='flex-1'>
          <div className='max-w-screen-md lg:max-w-screen-sm mx-auto'>
            <h1 className='text-center'>{title}</h1>
            <form
              className='form flex flex-col gap-6 mt-10 md:mt-20'
              onSubmit={handleSubmit}
            >
              <label htmlFor={passwordInputId}>
                Neues Passwort
                <input
                  id={passwordInputId}
                  type='password'
                  minLength={8}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                />
              </label>
              <label htmlFor={passwordRepeatInputId}>
                Neues Passwort wiederholen
                <input
                  id={passwordRepeatInputId}
                  type='password'
                  minLength={8}
                  className={`${
                    passwordRepeat && passwordRepeat !== password ? 'error' : ''
                  }`}
                  value={passwordRepeat}
                  onChange={e => {
                    const currentPasswordRepeat = e.target.value;

                    if (currentPasswordRepeat !== password) {
                      e.target.setCustomValidity(
                        'Die beiden Passwörter stimmen nicht überein',
                      );
                    } else {
                      e.target.setCustomValidity('');
                    }

                    setPasswordRepeat(currentPasswordRepeat);
                  }}
                  required
                />
              </label>

              <div className='flex flex-col items-center justify-center'>
                <Button
                  type='submit'
                  label='Absenden'
                  isLoading={isLoading}
                  isSuccessful={isSuccessful}
                  isError={isError}
                  disabled={
                    !password || !passwordRepeat || password !== passwordRepeat
                  }
                />
                {errorMessage && (
                  <p className='text-sm mt-2 text-red-500 text-center'>
                    {errorMessage}
                  </p>
                )}
              </div>
            </form>
          </div>
        </BlockContainer>
      </div>
    </div>
  );
};
