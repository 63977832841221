import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSCBTOCItem from './ZSCBTOCItem';

const ZSCBTableOfContents = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.TABLE_OF_CONTENTS),
  headline: z.string(),
  items: z.array(ZSCBTOCItem),
});

export default ZSCBTableOfContents;
