import React, { useEffect, useState } from 'react';

import CheckIcon from 'assets/icons/check.svg';
import ErrorIcon from 'assets/icons/error.svg';

import { EButtonSize, EButtonType } from 'types/enums/EButton';
import { IButton } from 'types/interfaces/IButton';

import {
  getButtonSizeStyles,
  getButtonTypeStyles,
} from 'utils/getButtonStyles';

import './Button.css';

interface IButtonProps
  extends IButton,
    React.ComponentPropsWithoutRef<'button'> {
  // eslint-disable-next-line react/require-default-props
  onClick?: () => void;
  // eslint-disable-next-line react/require-default-props
  isLoading?: boolean;
  // eslint-disable-next-line react/require-default-props
  isSuccessful?: boolean;
  // eslint-disable-next-line react/require-default-props
  isError?: boolean;
}

export default ({
  onClick = () => {},
  isLoading = false,
  isSuccessful = false,
  isError = false,
  icon = undefined,
  label = undefined,
  showIconOnDesktop = false,
  buttonType = EButtonType.PRIMARY,
  buttonSize = EButtonSize.REGULAR,
  disabled = false,
  addClass = '',
  ...rest
}: IButtonProps) => {
  const [showIcon, setShowIcon] = useState<boolean>(showIconOnDesktop);

  useEffect(() => {
    if (!label) {
      setShowIcon(true);
    }
  }, []);

  return (
    <button
      type='button'
      className={`${getButtonTypeStyles({ buttonType })} ${getButtonSizeStyles({
        icon,
        label,
        buttonSize,
      })} button ${
        buttonSize === EButtonSize.SMALL ? 'button-small' : ''
      } flex items-center justify-center text-center rounded-full transition-all ease-in-out duration-300 ${
        isSuccessful ? 'text-white bg-green-500 pointer-events-none' : ''
      } ${
        isError ? 'text-white bg-red-500 hover:bg-none hover:bg-red-400' : ''
      } ${disabled ? 'pointer-events-none opacity-50' : ''} ${addClass}`}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {icon && (
        <span
          className={`${!showIcon ? 'lg:hidden' : ''} ${
            label ? 'lg:mr-2' : ''
          } ${
            buttonType === EButtonType.SECONDARY ? 'fill-orange' : 'fill-white'
          }`}
        >
          {icon}
        </span>
      )}
      {label && (
        <span className={`${icon ? 'hidden lg:inline' : ''}`}>{label}</span>
      )}
      {rest.type === 'submit' && (isLoading || isSuccessful || isError) && (
        <span className='inline-block ml-2'>
          {isLoading && (
            <span className='spinner-wrapper flex items-center justify-center'>
              <span
                className={`spinner ${
                  buttonType === EButtonType.PRIMARY
                    ? 'border-white'
                    : 'border-orange'
                }`}
              />
            </span>
          )}
          {isSuccessful && <CheckIcon className='icon fill-white' />}
          {isError && <ErrorIcon className='icon fill-white' />}
        </span>
      )}
    </button>
  );
};
