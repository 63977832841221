import React from 'react';

import notify from 'utils/notify';

export default (message?: string) => {
  notify(
    () => (
      <>
        <p className='text-lg'>Erfolgreich</p>
        <p className='mt-1'>
          {message ||
            'Der Bezahlvorgang war erfolgreich. Sie können den Artikel nun herunterladen.'}
        </p>
      </>
    ),
    'success',
  );
};
