import React from 'react';

import ChevronLeftIcon from 'assets/icons/chevron-left.svg';
import ChevronRightIcon from 'assets/icons/chevron-right.svg';

export interface IPagination {
  postsPerPage: number;
  totalPosts: number;
  currentPage: number;
  previousPage: () => void;
  nextPage: () => void;
  className?: string;
}

const Pagination = ({
  postsPerPage,
  totalPosts,
  currentPage,
  previousPage,
  nextPage,
  className,
}: IPagination) => {
  const totalNumberOfPages = Math.ceil(totalPosts / postsPerPage);

  return (
    <div className={`flex items-center justify-center ${className}`}>
      <button
        type='button'
        className={`flex items-center justify-center w-8 h-8 rounded-full bg-transparent hover:bg-grey focus:outline-none ${
          currentPage === 1 ? 'pointer-events-none' : ''
        }`}
        onClick={previousPage}
      >
        <ChevronLeftIcon
          className={`icon ${
            currentPage === 1 ? 'fill-black/50' : 'fill-black'
          }`}
        />
      </button>
      <p className='text-sm mx-5'>
        Seite {currentPage} von {totalNumberOfPages}
      </p>
      <button
        type='button'
        className={`flex items-center justify-center w-8 h-8 rounded-full bg-transparent hover:bg-grey focus:outline-none ${
          currentPage === totalNumberOfPages ? 'pointer-events-none' : ''
        }`}
        onClick={nextPage}
      >
        <ChevronRightIcon
          className={`icon ${
            currentPage === totalNumberOfPages ? 'fill-black/50' : 'fill-black'
          }`}
        />
      </button>
    </div>
  );
};

Pagination.defaultProps = {
  className: '',
};

export default Pagination;
