import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-use';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import { IConsultantTile } from 'components/static/ConsultantTile';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';
import NoDataText from 'components/static/NoDataText';
import Pagination from 'components/static/Pagination';

import useIsInViewport from 'hooks/useIsInViewport';

import { ERoute } from 'types/enums/ERoute';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { ISPConsultantOverview } from 'types/interfaces/sulu/IPages';
import { IConsultantResponse, IUser } from 'types/interfaces/sulu/IUser';

import { TILES_PER_PAGE } from 'utils/constants';
import createConsultantTiles from 'utils/createConsultantTiles';
import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import scrollToElement from 'utils/scrollToElement';
import currentLanguage from 'utils/sulu/currentLanguage';
import getAllConsultants from 'utils/sulu/getAllConsultants';
import getProfile from 'utils/sulu/getProfile';

export default ({ content }: ISPConsultantOverview) => {
  const { title, page_title: pageTitle } = content;

  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const consultantId = searchParams.get(EUrlSearchParam.CONSULTANT_ID) ?? '';

  const scrollToElementRef = useRef<HTMLHeadingElement>(null);
  const isScrollToElementRefInViewport = useIsInViewport(scrollToElementRef);
  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [tiles, setTiles] = useState<IConsultantTile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoggedInConsultant, setIsLoggedInConsultant] = useState<
    boolean | undefined
  >(undefined);
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const tilesPerPage = TILES_PER_PAGE;
  const indexOfLastTile = currentPage * tilesPerPage;
  const indexOfFirstTile = indexOfLastTile - tilesPerPage;
  const currentTiles = tiles.slice(indexOfFirstTile, indexOfLastTile);

  const checkAuth = () => {
    getProfile().then(profile => {
      setUser(profile);
      setAuthChecked(true);
    });
  };

  const updateMenuItems = () => {
    const sidebarType = isLoggedInConsultant
      ? ESidebarType.CONSULTANT
      : ESidebarType.DEFAULT;
    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      sidebarType,
      [],
      consultantId,
    );
    setMenuItems(sidebarMenuItems);
  };

  useEffect(() => {
    setLoading(true);

    checkAuth();
    updateMenuItems();
    getAllConsultants().then((consultants: IConsultantResponse[]) => {
      const consultantTiles = consultants.map(consultant => ({
        tag: 'Berater',
        name: consultant.name || '',
        myOffer: consultant.myOffer || 'Keine weiteren Angaben',
        url: `/${currentLanguage}/${ERoute.CONSULTANT_PROFILE}?${EUrlSearchParam.CONSULTANT_ID}=${consultant.id}`,
        profileImageUrl: consultant.avatar || '',
      }));
      setTiles(consultantTiles);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (authChecked) {
      if (
        user &&
        user.consultantDetails !== undefined &&
        user.id.toString() === consultantId
      ) {
        setIsLoggedInConsultant(true);
      } else {
        setIsLoggedInConsultant(false);
      }
    }
  }, [user, authChecked]);

  useEffect(() => {
    if (isLoggedInConsultant) {
      updateMenuItems();
    }
  }, [isLoggedInConsultant]);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      scrollToElement(scrollToElementRef, isScrollToElementRefInViewport);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(tiles.length / tilesPerPage)) {
      setCurrentPage(currentPage + 1);
      scrollToElement(scrollToElementRef, isScrollToElementRefInViewport);
    }
  };

  return (
    <Layout menuItems={menuItems}>
      {loading ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <BlockContainer>
          <>
            <h3 ref={scrollToElementRef} className='scroll-mt-16'>
              {pageTitle || title}
            </h3>
            <div className='flex items-center justify-center flex-wrap gap-5 mt-5'>
              {tiles.length === 0 && (
                <NoDataText>Keine Berater verfügbar</NoDataText>
              )}
              {tiles.length > 0 && createConsultantTiles(currentTiles)}
            </div>
            {tiles.length > tilesPerPage && (
              <Pagination
                postsPerPage={tilesPerPage}
                totalPosts={tiles.length}
                currentPage={currentPage}
                previousPage={previousPage}
                nextPage={nextPage}
                className='mt-12'
              />
            )}
          </>
        </BlockContainer>
      )}
      <Footer />
    </Layout>
  );
};
