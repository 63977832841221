import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';
import ZSuluMedia from './ZSuluMedia';

const ZSCBMedia = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.MEDIA),
  content: ZSuluMedia,
});

export default ZSCBMedia;
