import { z } from 'zod';

import { ESuluDataTypes, ESuluPageTypes } from 'types/enums/sulu/EBackendData';

import { ZSuluContentBlock } from './IContentBlocks';
import { ZSuluLayoutBlock } from './ILayoutBlocks';
import ZSCBTile from './contentBlocks/ZSCBTile';
import ZSuluMedia from './contentBlocks/ZSuluMedia';

/**
 * MIND: ZSP = Zod Sulu Page
 * MIND: Some properties are omitted as they were not used
 * at the time of implementation. If some properties are missing,
 * check the raw data coming from Sulu and extend the according type.
 * MIND: In some cases we have an additional zod type "PageOnly".
 * This is necessary as both the blocks and the snippets are located
 * in a nested key of the pages' template.
 * The omit method of zod does not allow omitting nested keys.
 * MIND: Relevant interfaces / types are exported at the bottom of the file.
 */

const ZSuluPageBaseData = z.object({
  id: z.string().uuid(),
  type: z.literal(ESuluDataTypes.PAGE),
});

const ZSPContentBase = z.object({
  title: z.string(),
  url: z.string().or(z.null()),
});

export const ZSPTileContentType = z.object({
  isAudio: z.boolean(),
  isDocument: z.boolean(),
  isVideo: z.boolean(),
});

export const ZSPTileContentData = ZSPTileContentType.extend({
  mimeType: z.string(),
  title: z.string(),
  url: z.string(),
});

const ZSPContentTile = z.object({
  id: z.string().uuid(),
  content: z.object({
    title: z.string(),
    url: z.string().or(z.null()),
    // @todo: (backend task) some / rest of data has to be removed from the JSON to prevent direct access
    content: ZSPTileContentData.or(z.null()),
    content_blocks: z
      .array(z.union([ZSuluContentBlock, ZSuluLayoutBlock]))
      .optional(),
    photo: ZSuluMedia,
    text: z.string(),
    text_hover: z.string(),
    main_category: z
      .object({
        content: z.object({
          title: z.string(),
          page_color: z.string(),
        }),
      })
      .or(z.null()),
    color: z.string(),
    duration: z.string(),
    price: z.string(),
    // @todo: (backend task) not needed in frontend, has to be removed from the JSON due to privacy issues
    // author_contact: z.object({
    //   test: z.string(),
    // }),
  }),
});

const ZSPCategoryBlock = z.object({
  title: z.string(),
  focalpoints: z.string(),
});

export const ZSPTileContent = ZSPContentTile.shape.content;

const ZSPContentTileContent = z.object({
  content: ZSPTileContent,
});

const ZSPConsultantOverviewContent = ZSPContentBase.extend({
  page_title: z.string(),
});

const ZSPCategoryContent = ZSPContentBase.extend({
  page_color: z.string(),
  tile_background_image: ZSuluMedia,
  tiles: z.array(ZSPContentTile),
  suitable_consultants: z.string().optional(),
  headline_suitable_consultants: z.string().optional(),
  suitable_consultants_articles: z.string().optional(),
  headline_suitable_articles: z.string().optional(),
});

const ZSPConsultantRegistrationContent = ZSPContentBase.extend({
  introduction: z.string(),
  checkbox_1_label: z.string(),
  checkbox_2_label: z.string(),
  countries: z.string(),
  languages: z.string(),
  categories_blocks: z.array(ZSPCategoryBlock),
});

const ZSPAllContentsContent = ZSPContentBase.extend({
  page_color: z.string(),
  tiles: z.array(ZSPContentTile),
});

const ZSPDefaultContent = ZSPContentBase.extend({
  article: z.string(),
});

const ZSPHomepageContent = ZSPContentBase.extend({
  categories: z.array(ZSCBTile),
});

const ZSPConsultantWelcomeContent = ZSPContentBase.extend({
  page_headline_top: z.string(),
  page_headline_bottom: z.string(),
  intro_text: z.string(),
});

const ZSPConsultantCreateArticleContent = ZSPContentBase.extend({
  page_headline_top: z.string(),
  page_headline_bottom: z.string(),
  intro_text: z.string(),
  description: z.string(),
});

const ZSPConsultantNewArticleContent = ZSPContentBase.extend({
  page_headline_top: z.string(),
  page_headline_bottom: z.string(),
  checkbox_1_label: z.string(),
  checkbox_2_label: z.string(),
});

export const ZSPAllContents = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.ALL_CONTENTS),
  content: ZSPAllContentsContent,
});

export const ZSPCategory = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.CATEGORY),
  content: ZSPCategoryContent,
});

export const ZSPConsultantRegistration = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.CONSULTANT_REGISTRATION),
  content: ZSPConsultantRegistrationContent,
});

export const ZSPDefault = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.DEFAULT),
  content: ZSPDefaultContent,
});

export const ZSPDeleteAccount = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.DELETE_ACCOUNT),
  content: ZSPDefaultContent,
});

export const ZSPHomepage = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.HOMEPAGE),
  content: ZSPHomepageContent,
});

export const ZSPMyLibrary = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.MY_LIBRARY),
  content: ZSPContentBase,
});
export const ZSPConsultantArticlesOverview = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.CONSULTANT_ARTICLES_OVERVIEW),
  content: ZSPContentBase.extend({
    page_title: z.string(),
  }),
});

export const ZSPMyWorld = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.MY_WORLD),
  content: ZSPContentBase,
});

export const ZSPPasswordReset = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.PASSWORD_RESET),
  content: ZSPContentBase,
});

export const ZSPSearch = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.SEARCH),
  content: ZSPContentBase,
});

export const ZSPSettings = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.SETTINGS),
  content: ZSPContentBase,
});

export const ZSPTile = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.TILE),
}).merge(ZSPContentTileContent);

export const ZSPConsultantWelcome = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.CONSULTANT_WELCOME),
  content: ZSPConsultantWelcomeContent,
});

export const ZSPConsultantCreateArticle = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.CONSULTANT_CREATE_ARTICLE),
  content: ZSPConsultantCreateArticleContent,
});

export const ZSPConsultantNewArticle = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.CONSULTANT_NEW_ARTICLE),
  content: ZSPConsultantNewArticleContent,
});

export const ZSPConsultantArticle = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.CONSULTANT_ARTICLE),
  content: ZSPContentBase,
});

export const ZSPConsultantProfile = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.CONSULTANT_PROFILE),
  content: ZSPContentBase,
});

export const ZSPConsultantOverview = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.CONSULTANT_OVERVIEW),
  content: ZSPConsultantOverviewContent,
});

export const ZSPConsultantDeleteAccount = ZSuluPageBaseData.extend({
  template: z.literal(ESuluPageTypes.CONSULTANT_DELETE_ACCOUNT),
  content: ZSPDefaultContent,
});

export const ZSuluPage = z.union([
  ZSPAllContents,
  ZSPCategory,
  ZSPConsultantRegistration,
  ZSPDefault,
  ZSPDeleteAccount,
  ZSPHomepage,
  ZSPMyLibrary,
  ZSPConsultantArticlesOverview,
  ZSPMyWorld,
  ZSPPasswordReset,
  ZSPSearch,
  ZSPSettings,
  ZSPTile,
  ZSPConsultantWelcome,
  ZSPConsultantCreateArticle,
  ZSPConsultantNewArticle,
  ZSPConsultantProfile,
  ZSPConsultantOverview,
  ZSPConsultantArticle,
  ZSPConsultantDeleteAccount,
]);

export type ISPTileContentType = z.infer<typeof ZSPTileContentType>;
export type ISPContentTile = z.infer<typeof ZSPContentTile>;
export type ISPTileContent = z.infer<typeof ZSPTileContent>;
export type ISPAllContents = z.infer<typeof ZSPAllContents>;
export type ISPCategory = z.infer<typeof ZSPCategory>;
export type ISPDefault = z.infer<typeof ZSPDefault>;
export type ISPDeleteAccount = z.infer<typeof ZSPDeleteAccount>;
export type ISPHomepage = z.infer<typeof ZSPHomepage>;
export type ISPMyLibrary = z.infer<typeof ZSPMyLibrary>;
export type ISPMyWorld = z.infer<typeof ZSPMyWorld>;
export type ISPPasswordReset = z.infer<typeof ZSPPasswordReset>;
export type ISPSearch = z.infer<typeof ZSPSearch>;
export type ISPSettings = z.infer<typeof ZSPSettings>;
export type ISPTile = z.infer<typeof ZSPTile>;
// consultant
export type ISPConsultantRegistration = z.infer<
  typeof ZSPConsultantRegistration
>;
export type ISPConsultantCreateArticle = z.infer<
  typeof ZSPConsultantCreateArticle
>;
export type ISPConsultantNewArticle = z.infer<typeof ZSPConsultantNewArticle>;
export type ISPConsultantArticleOverview = z.infer<
  typeof ZSPConsultantArticlesOverview
>;
export type ISPConsultantArticle = z.infer<typeof ZSPConsultantArticle>;
export type ISPConsultantProfile = z.infer<typeof ZSPConsultantProfile>;
export type ISPConsultantOverview = z.infer<typeof ZSPConsultantOverview>;
export type ISPConsultantWelcome = z.infer<typeof ZSPConsultantWelcome>;
export type ISPConsultantDeleteAccount = z.infer<
  typeof ZSPConsultantDeleteAccount
>;
export type ISuluPage = z.infer<typeof ZSuluPage>;
