import React from 'react';

import AllContentsIcon from 'assets/icons/all-contents.svg';
import CategoriesIcon from 'assets/icons/categories.svg';
import HomeIcon from 'assets/icons/home.svg';
import LibraryIcon from 'assets/icons/library-books.svg';
// import Search from 'assets/icons/search.svg';
import SettingsIcon from 'assets/icons/settings.svg';

import { ERoute } from 'types/enums/ERoute';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { INavigation } from 'types/interfaces/sulu/INavigation';

import currentLanguage from './sulu/currentLanguage';

export default (
  sidebarType: ESidebarType,
  subMenuItems: INavigation[] = [],
  consultantId: string = '',
): ISidebarMenuItem[] => {
  // sort sub menu items by their sulu order
  subMenuItems.sort((a, b) => a.order - b.order);

  const sidebarMenuItems: ISidebarMenuItem[] = [
    {
      url:
        sidebarType === ESidebarType.CONSULTANT
          ? `/${currentLanguage}/${ERoute.CONSULTANT_WELCOME}${
              consultantId
                ? `?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}`
                : ''
            }`
          : `/${currentLanguage}`,
      icon: <HomeIcon className='icon' />,
      label: 'Startseite',
    },
  ];

  switch (sidebarType) {
    case ESidebarType.TOPICS:
      sidebarMenuItems.push(
        {
          url: `/${currentLanguage}/${ERoute.ALL_CONTENTS}`,
          icon: <AllContentsIcon className='icon' />,
          label: 'Alle Inhalte',
        },
        // TODO: production
        /*
          {
            url: `/${currentLanguage}/${ERoute.CONSULTANT_OVERVIEW}`,
            icon: <Search className='icon' />,
            label: 'Berater Suche',
          },
          */
        {
          icon: <CategoriesIcon className='icon' />,
          label: 'Themen',
          subMenuItems,
        },
      );
      break;
    case ESidebarType.PROFILE:
      sidebarMenuItems.push(
        {
          url: `/${currentLanguage}/${ERoute.ALL_CONTENTS}`,
          icon: <AllContentsIcon className='icon' />,
          label: 'Alle Inhalte',
        },
        // TODO: production
        /*
        {
          url: `/${currentLanguage}/${ERoute.CONSULTANT_OVERVIEW}`,
          icon: <Search className='icon' />,
          label: 'Berater Suche',
        }, */
        {
          url: `/${currentLanguage}/${ERoute.SETTINGS}`,
          icon: <SettingsIcon className='icon' />,
          label: 'Einstellungen',
        },
        {
          url: `/${currentLanguage}/${ERoute.MY_LIBRARY}`,
          icon: <LibraryIcon className='icon' />,
          label: 'Meine Bibliothek',
        },
      );
      break;
    case ESidebarType.CONSULTANT:
      sidebarMenuItems.push(
        {
          url: `/${currentLanguage}`,
          icon: <AllContentsIcon className='icon' />,
          label: 'Alle Inhalte',
        },
        // TODO: production
        /*
        {
          url: `/${currentLanguage}/${ERoute.CONSULTANT_OVERVIEW}`,
          icon: <Search className='icon' />,
          label: 'Berater Suche',
        },
           */
        {
          url: `/${currentLanguage}/${ERoute.CONSULTANT_PROFILE}${
            consultantId
              ? `?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}`
              : ''
          }`,
          icon: <SettingsIcon className='icon' />,
          label: 'Mein Profil',
        },
        {
          url: `/${currentLanguage}/${ERoute.SETTINGS}${
            consultantId
              ? `?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}`
              : ''
          }`,
          icon: <SettingsIcon className='icon' />,
          label: 'Einstellungen',
        },
        {
          url: `/${currentLanguage}/${ERoute.CONSULTANT_ARTICLES_OVERVIEW}${
            consultantId
              ? `?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}`
              : ''
          }`,
          icon: <LibraryIcon className='icon' />,
          label: 'Meine Beiträge',
        },
      );
      break;
    case ESidebarType.DEFAULT:
    default:
      break;
  }

  return sidebarMenuItems;
};
