import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-use';

import BlockContainer from 'components/BlockContainer';
import Tile from 'components/blocks/Tile';
import TileDetails from 'components/blocks/TileDetails';
import Footer from 'components/snippets/Footer';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';
import NoDataText from 'components/static/NoDataText';
import Pagination from 'components/static/Pagination';

import useIsInViewport from 'hooks/useIsInViewport';

import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { ESuluNavigationContexts } from 'types/enums/sulu/EBackendData';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { ISCBTile } from 'types/interfaces/sulu/IContentBlocks';
import { ISPContentTile, ISPSearch } from 'types/interfaces/sulu/IPages';
import { ISearch } from 'types/interfaces/sulu/ISearch';

import { DEFAULT_SCHEME_COLOR, TILES_PER_PAGE } from 'utils/constants';
import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import scrollToElement from 'utils/scrollToElement';
import getNavigation from 'utils/sulu/getNavigation';
import search from 'utils/sulu/search';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default ({ content }: ISPSearch) => {
  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const query = searchParams.get(EUrlSearchParam.QUERY) ?? '';
  const scrollToElementRef = useRef<HTMLHeadingElement>(null);
  const isScrollToElementRefInViewport = useIsInViewport(scrollToElementRef);
  const [loading, setLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [results, setResults] = useState<ISearch[]>([]);
  const [categoryResults, setCategoryResults] = useState<ISCBTile[]>([]);
  const [tileResults, setTileResults] = useState<ISPContentTile[]>([]);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const tilesPerPage = TILES_PER_PAGE;
  const indexOfLastTile = currentPage * tilesPerPage;
  const indexOfFirstTile = indexOfLastTile - tilesPerPage;
  const currentResults = results.slice(indexOfFirstTile, indexOfLastTile);

  const categorizeCurrentResults = () => {
    const currentCategoryResults: ISCBTile[] = [];
    const currentTileResults: ISPContentTile[] = [];

    currentResults.forEach(currentResult => {
      const { id, document } = currentResult;
      const { url, properties } = document;
      const {
        title: resultTitle,
        page_color: bgColor,
        tile_background_image_json: bgImageJson,
        'main_category#page_color': mainCategoryBgColor,
        color,
        content_json: contentJson,
        photo_json: photoJson,
        text,
        text_hover: description,
      } = properties;

      // category result
      if (bgImageJson) {
        const bgImage = JSON.parse(bgImageJson);
        currentCategoryResults.push({
          id,
          content: {
            title: resultTitle,
            url,
            page_color: bgColor || DEFAULT_SCHEME_COLOR,
            tile_background_image: bgImage,
          },
        });
      } else {
        const photo = JSON.parse(photoJson || '{}');
        const contentType = JSON.parse(contentJson || '{}');
        currentTileResults.push({
          id,
          content: {
            title: resultTitle,
            url,
            main_category: {
              content: {
                // @ts-ignore
                page_color: mainCategoryBgColor,
              },
            },
            // @ts-ignore
            color,
            content: contentType,
            photo,
            text: text || '',
            text_hover: description || '',
          },
        });
      }
    });

    setCategoryResults(currentCategoryResults);
    setTileResults(currentTileResults);
  };

  useEffect(() => {
    // get menu items
    Promise.all([
      getNavigation(ESuluNavigationContexts.INFORMATION),
      getNavigation(ESuluNavigationContexts.ENTERTAINMENT),
      getNavigation(ESuluNavigationContexts.CONSULTING),
    ]).then(subMenuItems => {
      const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
        ESidebarType.TOPICS,
        subMenuItems.flat(),
      );
      setMenuItems(sidebarMenuItems);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    // get search results
    search(query).then(searchResults => {
      setTitle(
        `${searchResults.length} Suchergebnis${
          searchResults.length !== 1 ? 'se' : ''
        } für "${query}"`,
      );
      setResults(searchResults);
      setLoading(false);
    });
  }, [query]);

  useEffect(() => {
    categorizeCurrentResults();
  }, [results, currentPage]);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      scrollToElement(scrollToElementRef, isScrollToElementRefInViewport);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(results.length / tilesPerPage)) {
      setCurrentPage(currentPage + 1);
      scrollToElement(scrollToElementRef, isScrollToElementRefInViewport);
    }
  };

  return (
    <Layout menuItems={menuItems}>
      {/* render content */}
      <BlockContainer>
        {loading ? (
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        ) : (
          <>
            <h3 ref={scrollToElementRef} className='scroll-mt-16'>
              {title}
            </h3>
            <div className='mt-5'>
              {results.length === 0 && (
                <div className='flex justify-center'>
                  <NoDataText>Keine Suchergebnisse</NoDataText>
                </div>
              )}
              {results.length > 0 && (
                <>
                  {categoryResults.length > 0 && (
                    <>
                      <h4 className='border-b border-grey-dark'>Themen</h4>
                      <div className='flex items-center flex-wrap gap-5 mt-5'>
                        {categoryResults.map(categoryResult => (
                          <Tile key={categoryResult.id} {...categoryResult} />
                        ))}
                      </div>
                    </>
                  )}
                  {tileResults.length > 0 && (
                    <>
                      <h4 className='mt-5 border-b border-grey-dark'>
                        Artikel, Podcasts und Videos
                      </h4>
                      <div className='flex items-center flex-wrap gap-5 mt-5'>
                        {tileResults.map(tileResult => (
                          <TileDetails key={tileResult.id} {...tileResult} />
                        ))}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            {results.length > tilesPerPage && (
              <Pagination
                postsPerPage={tilesPerPage}
                totalPosts={results.length}
                currentPage={currentPage}
                previousPage={previousPage}
                nextPage={nextPage}
                className='mt-12'
              />
            )}
          </>
        )}
      </BlockContainer>
      <Footer />
    </Layout>
  );
};
