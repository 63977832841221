import React from 'react';

import { ISLBThreeColumns } from 'types/interfaces/sulu/ILayoutBlocks';

import suluBlockHandler from 'utils/sulu/blockHandler';

const ThreeColumns = ({ left, middle, right }: ISLBThreeColumns) => (
  <div className='grid grid-cols-1 lg:grid-cols-3'>
    <div>{left.map((block, i) => suluBlockHandler(block, i))}</div>
    <div>{middle.map((block, i) => suluBlockHandler(block, i))}</div>
    <div>{right.map((block, i) => suluBlockHandler(block, i))}</div>
  </div>
);

export default ThreeColumns;
