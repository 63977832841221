import { z } from 'zod';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';

const ZSCBLink = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.LINK),
  name: z.string(),
  url: z.string(),
  target: z.string().or(z.null()),
});

export default ZSCBLink;
