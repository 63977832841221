import React from 'react';

import { ESuluMediaTypes } from 'types/enums/sulu/EBackendData';

interface IMedia {
  type: ESuluMediaTypes.VIDEO | ESuluMediaTypes.AUDIO;
  url: string;
  mimeType: string;
}

const Media = ({ type, url, mimeType }: IMedia) => (
  <>
    {type === ESuluMediaTypes.VIDEO && (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video width='100%' controls>
        <source src={url} type={mimeType} />
        Your browser does not support the video tag.
      </video>
    )}
    {type === ESuluMediaTypes.AUDIO && (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <audio controls>
        <source src={url} type={mimeType} />
        Your browser does not support the audio tag.
      </audio>
    )}
  </>
);

export default Media;
