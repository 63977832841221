// eslint-disable-next-line import/prefer-default-export
export enum EUrlSearchParam {
  QUERY = 'query',
  TYPE = 'type',
  CATEGORY = 'category',
  CONSULTANT_ID = 'cid',
  ARTICLE_ID = 'aid',
  CONSULTANT_ARTICLE_CREATED = 'articleSuccessfullyCreated',
  REGISTRATION_SUCCESSFUL = 'registrationSuccessful',
  EMAIL_CONFIRMATION = 'emailConfirmation',
  NO_CONSULTANT = 'noConsultant',
}
