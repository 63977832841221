import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import React from 'react';

import CloseIcon from 'assets/icons/close.svg';

import { Button } from 'components/static/Button';

import { EButtonSize, EButtonType } from 'types/enums/EButton';

interface IDialog {
  isOpen: boolean;
  handleToggle: (value: boolean) => void;
  headline?: string;
  title?: string;
  titleClassName?: string;
  content: JSX.Element;
  confirmButton?: string;
  cancelButton?: string;
  handleCancelButton?: () => void;
  dialogPanelClassName?: string;
}

const DialogElement = ({
  isOpen,
  handleToggle,
  headline,
  title,
  titleClassName,
  content,
  confirmButton,
  cancelButton,
  handleCancelButton,
  dialogPanelClassName,
}: IDialog) => (
  <Dialog
    open={isOpen}
    onClose={() => handleToggle(false)}
    className='relative z-50'
  >
    <div className='fixed inset-0 bg-black/30' aria-hidden='true' />
    <div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
      <DialogPanel
        className={`max-w-[540px] space-y-4 border bg-white py-5 px-4 rounded-xl relative ${dialogPanelClassName}`}
      >
        <Button
          onClick={() => handleToggle(false)}
          icon={<CloseIcon className='icon fill-black' />}
          buttonType={EButtonType.SECONDARY}
          className='absolute right-3 top-3'
        />
        <DialogTitle className='text-center'>
          {headline && (
            <span className='block uppercase text-2xl'>{headline}</span>
          )}
          {title && (
            <span className={`font-bold text-5xl ${titleClassName}`}>
              {title}
            </span>
          )}
        </DialogTitle>
        <div className='max-h-[538px] overflow-x-auto'>{content}</div>
        <div className='flex justify-between'>
          {cancelButton && handleCancelButton && (
            <Button
              type='button'
              label={cancelButton}
              onClick={() => {
                if (handleCancelButton !== undefined) {
                  handleCancelButton();
                }
                handleToggle(false);
              }}
              buttonSize={EButtonSize.SMALL}
              buttonType={EButtonType.TERTIARY}
            />
          )}
          {confirmButton && (
            <Button
              type='button'
              label={confirmButton}
              onClick={() => handleToggle(false)}
              buttonSize={EButtonSize.SMALL}
            />
          )}
        </div>
      </DialogPanel>
    </div>
  </Dialog>
);

DialogElement.defaultProps = {
  headline: '',
  title: '',
  titleClassName: '',
  handleCancelButton: undefined,
  confirmButton: undefined,
  cancelButton: undefined,
  dialogPanelClassName: '',
};

export default DialogElement;
