import React, { useState } from 'react';
import { useWindowSize } from 'react-use';

import ErrorIcon from 'assets/icons/error.svg';
import Logo from 'assets/images/logo.svg';

import BlockContainer from 'components/BlockContainer';
import Search from 'components/primitives/Search';
import ProfileButton from 'components/static/ProfileButton';

import { EButtonSize } from 'types/enums/EButton';
import { ESearchfieldSize, ESearchfieldVariant } from 'types/enums/ESearch';

import navigateTo from 'utils/navigateTo';

export default () => {
  const { width } = useWindowSize();
  const [searchfieldIsOpen, setSearchfieldIsOpen] = useState<boolean>(false);

  const handleToggleSearchfield = (isOpen: boolean) => {
    setSearchfieldIsOpen(isOpen);
  };

  return (
    <div className='md:h-screen'>
      {/* top navbar */}
      <div className='fixed top-0 z-50 flex items-center justify-between w-full h-14 px-5 py-2 bg-white shadow-md'>
        <Logo
          className={`h-full cursor-pointer transition-opacity ease-in-out duration-300 ${
            !searchfieldIsOpen || width >= 450
              ? 'opacity-100'
              : 'opacity-0 pointer-events-none'
          }`}
          onClick={() => navigateTo('')}
        />
        <div className='flex items-center gap-5'>
          <Search
            variant={ESearchfieldVariant.DARK}
            size={ESearchfieldSize.SMALL}
            onToggleSearchfield={handleToggleSearchfield}
          />
          <ProfileButton buttonSize={EButtonSize.SMALL} />
        </div>
      </div>
      {/* render content */}
      <div className='flex items-center justify-center h-full pt-14'>
        <BlockContainer outerClasses='flex-1'>
          <div className='max-w-screen-md lg:max-w-screen-sm mx-auto'>
            <div className='flex justify-center'>
              <ErrorIcon className='w-12 h-12 md:w-16 md:h-16 fill-red-500' />
            </div>
            <h3 className='text-center mt-4'>
              Beim Aufbauen der Seite ist ein Fehler aufgetreten!
            </h3>
            <p className='font-bold mt-8'>Dies kann folgende Ursachen haben:</p>
            <ul>
              <li>
                Auf der Startseite wurde eine falsche Kategorie ausgewählt
              </li>
              <li>
                Auf der Kategorieseite wurde eine falsche Kachel (Artikel, Video
                oder Podcast) ausgewählt
              </li>
              <li>
                Auf der Seite &quot;Alle Inhalte&quot; wurde eine falsche Kachel
                (Artikel, Video oder Podcast) ausgewählt
              </li>
            </ul>
            <p className='font-bold'>
              Bitte beachten Sie, welche Seite welche Daten/Templates anzeigen
              kann:
            </p>
            <ul>
              <li>Startseite = Kategorien</li>
              <li>Kategorieseite = Kacheln</li>
              <li>Alle Inhalte = Kacheln</li>
            </ul>
          </div>
        </BlockContainer>
      </div>
    </div>
  );
};
