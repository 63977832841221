import { IConsultantAllArticlesResponse } from 'types/interfaces/sulu/IConsultantApi';

import { TILES_PER_PAGE } from 'utils/constants';

export default async (
  consultantId: number,
  limit: number = TILES_PER_PAGE,
): Promise<IConsultantAllArticlesResponse[]> => {
  const response = await fetch(
    `/consultant/${consultantId}/articles?limit=${limit}`,
  );

  if (response.ok) {
    try {
      const jsonResponse = await response.json();
      return jsonResponse.data;
    } catch (e) {
      return [];
    }
  }

  return [];
};
