// TODO: update url and response type
export default async (formData: FormData): Promise<any> => {
  const response = await fetch('/registration', {
    method: 'POST',
    body: formData,
  });

  if (response.ok) {
    return response.json();
  }

  return {};
};
