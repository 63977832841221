import { z } from 'zod';

import {
  ESuluSettingsScheduleTypes,
  ESuluSettingsScheduleWeekDays,
} from 'types/enums/sulu/EBackendData';

const ZSCBSettings = z
  .object({
    hidden: z.boolean(),
    schedules_enabled: z.boolean(),
    schedules: z.array(
      z.union([
        z.object({
          type: z.literal(ESuluSettingsScheduleTypes.FIXED),
          start: z.string(),
          end: z.string(),
        }),
        z.object({
          type: z.literal(ESuluSettingsScheduleTypes.WEEKLY),
          start: z.string(),
          end: z.string(),
          days: z.array(z.nativeEnum(ESuluSettingsScheduleWeekDays)),
        }),
      ]),
    ),
  })
  .or(z.object({}))
  .or(z.array(z.string()).default([])); // represents an empty array []

const ZSCBBase = z.object({
  settings: ZSCBSettings,
});

export default ZSCBBase;
