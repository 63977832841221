import React, { FormEvent, useEffect, useId, useState } from 'react';

import ChevronDownIcon from 'assets/icons/chevron-down.svg';

import { Button } from 'components/static/Button';

import { EConsultantImpressumFormData } from 'types/enums/sulu/EFormData';
import { IImpressumForm } from 'types/interfaces/IImpressumForm';
import { IImpressumResponse } from 'types/interfaces/sulu/IImpressum';
import { IImpressumFields } from 'types/interfaces/sulu/IImpressumFields';

import createOrUpdateConsultantImpressum from 'utils/sulu/createOrUpdateConsultantImpressum';
import getCsrfToken from 'utils/sulu/getCsrfToken';

const ImpressumForm = ({
  impressumFields,
  isInEditMode = true,
  onSubmitDone,
}: IImpressumForm) => {
  const [formFields, setFormFields] = useState<IImpressumFields>(
    impressumFields || {
      name: '',
      legalForm: '',
      street: '',
      zipCode: '',
      city: '',
      email: '',
      phoneNumber: '',
      vatId: '',
      register: '',
      registerNumber: '',
    },
  );
  const [csrfToken, setCsrfToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const nameInputId = useId();
  const legalFormInputId = useId();
  const streetInputId = useId();
  const zipCodeInputId = useId();
  const cityInputId = useId();
  const emailInputId = useId();
  const phoneNumberInputId = useId();
  const vatIdInputId = useId();
  const registerInputId = useId();
  const registerNumberInputId = useId();

  const getNewToken = () => {
    getCsrfToken('/consultant/impressum').then(token => setCsrfToken(token));
  };

  useEffect(() => {
    getNewToken();
    document.addEventListener('login:success', getNewToken);
  }, []);

  useEffect(() => {
    if (impressumFields) {
      setFormFields(impressumFields);
    }
  }, [impressumFields]);

  const updateFormField = (field: keyof IImpressumFields, value: string) => {
    setFormFields({
      ...formFields,
      [field]: value,
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setIsSuccessful(false);
    setIsError(false);
    setIsLoading(true);
    setSuccessMessage('');
    setErrorMessage('');
    // Prevent the browser from reloading the page
    e.preventDefault();

    const formData = new FormData();
    formData.append(EConsultantImpressumFormData.NAME, formFields.name);
    formData.append(
      EConsultantImpressumFormData.LEGAL_FORM,
      formFields.legalForm,
    );
    formData.append(EConsultantImpressumFormData.STREET, formFields.street);
    formData.append(EConsultantImpressumFormData.ZIP_CODE, formFields.zipCode);
    formData.append(EConsultantImpressumFormData.CITY, formFields.city);
    formData.append(EConsultantImpressumFormData.EMAIL, formFields.email);
    formData.append(
      EConsultantImpressumFormData.PHONE_NUMBER,
      formFields.phoneNumber,
    );
    formData.append(EConsultantImpressumFormData.VAT_ID, formFields.vatId);
    formData.append(EConsultantImpressumFormData.REGISTER, formFields.register);
    formData.append(
      EConsultantImpressumFormData.REGISTER_NUMBER,
      formFields.registerNumber,
    );
    formData.append(EConsultantImpressumFormData.TOKEN, csrfToken);

    createOrUpdateConsultantImpressum(
      formData,
      isInEditMode ? formFields.id : undefined,
    ).then((res: IImpressumResponse) => {
      const { success } = res;

      setIsLoading(false);

      if (success) {
        setIsSuccessful(true);
        setSuccessMessage('Das Impressum wurde erfolgreich gespeichert.');
        if (onSubmitDone) {
          onSubmitDone();
        }
      } else {
        setIsError(true);
        setErrorMessage('Das Impressum konnte nicht gespeichert werden.');
      }
    });
  };

  return (
    <form
      className='form flex flex-col md:grid md:grid-cols-3 gap-4'
      onSubmit={handleSubmit}
    >
      <label htmlFor={nameInputId}>
        Name
        {isInEditMode ? (
          <input
            id={nameInputId}
            type='text'
            value={formFields.name}
            onChange={e => updateFormField('name', e.target.value)}
          />
        ) : (
          <p className='font-normal'>{formFields.name || '-'}</p>
        )}
      </label>
      <label htmlFor={legalFormInputId}>
        Rechtsform
        {isInEditMode ? (
          <input
            id={legalFormInputId}
            type='text'
            value={formFields.legalForm}
            onChange={e => updateFormField('legalForm', e.target.value)}
          />
        ) : (
          <p className='font-normal'>{formFields.legalForm || '-'}</p>
        )}
      </label>
      <label htmlFor={emailInputId}>
        E-Mail Adresse
        {isInEditMode ? (
          <input
            id={emailInputId}
            type='email'
            value={formFields.email}
            onChange={e => updateFormField('email', e.target.value)}
          />
        ) : (
          <p className='font-normal'>{formFields.email || '-'}</p>
        )}
      </label>
      <label htmlFor={streetInputId}>
        Straße und Hausnummer
        {isInEditMode ? (
          <input
            id={streetInputId}
            type='text'
            value={formFields.street}
            onChange={e => updateFormField('street', e.target.value)}
          />
        ) : (
          <p className='font-normal'>{formFields.street || '-'}</p>
        )}
      </label>
      <label htmlFor={zipCodeInputId}>
        PLZ
        {isInEditMode ? (
          <input
            id={zipCodeInputId}
            type='number'
            value={formFields.zipCode}
            onChange={e => updateFormField('zipCode', e.target.value)}
          />
        ) : (
          <p className='font-normal'>{formFields.zipCode || '-'}</p>
        )}
      </label>
      <label htmlFor={cityInputId}>
        Stadt
        {isInEditMode ? (
          <input
            id={cityInputId}
            type='text'
            value={formFields.city}
            onChange={e => updateFormField('city', e.target.value)}
          />
        ) : (
          <p className='font-normal'>{formFields.city || '-'}</p>
        )}
      </label>
      <label htmlFor={phoneNumberInputId}>
        Telefon
        {isInEditMode ? (
          <input
            id={phoneNumberInputId}
            type='tel'
            value={formFields.phoneNumber}
            onChange={e => updateFormField('phoneNumber', e.target.value)}
          />
        ) : (
          <p className='font-normal'>{formFields.phoneNumber || '-'}</p>
        )}
      </label>
      <label htmlFor={registerInputId}>
        Register
        {isInEditMode ? (
          <div className='relative'>
            <select
              id={registerInputId}
              value={formFields.register}
              onChange={e => updateFormField('register', e.target.value)}
            >
              <option value=''>Register auswählen</option>
              <option value='Handelsregister'>Handelsregister</option>
              <option value='Vereinsregister'>Vereinsregister</option>
              <option value='Partnerschaftsregister'>
                Partnerschaftsregister
              </option>
              <option value='Gesellschaftsregister'>
                Gesellschaftsregister
              </option>
              <option value='Genossenschaftsregister'>
                Genossenschaftsregister
              </option>
            </select>
            <ChevronDownIcon className='absolute top-1/2 right-4 -translate-y-1/2 mt-1' />
          </div>
        ) : (
          <p className='font-normal'>{formFields.register || '-'}</p>
        )}
      </label>
      <label htmlFor={registerNumberInputId}>
        Registernummer
        {isInEditMode ? (
          <input
            id={registerNumberInputId}
            type='text'
            value={formFields.registerNumber}
            onChange={e => updateFormField('registerNumber', e.target.value)}
          />
        ) : (
          <p className='font-normal'>{formFields.registerNumber || '-'}</p>
        )}
      </label>
      <label htmlFor={vatIdInputId} className='col-span-2'>
        Umsatzsteuer- oder Wirtschaftssteuer-Identifikationsnummer
        {isInEditMode ? (
          <input
            id={vatIdInputId}
            type='text'
            value={formFields.vatId}
            onChange={e => updateFormField('vatId', e.target.value)}
          />
        ) : (
          <p className='font-normal'>{formFields.vatId || '-'}</p>
        )}
      </label>
      {isInEditMode && (
        <div className='mt-6'>
          <Button
            type='submit'
            label='Speichern'
            isLoading={isLoading}
            isSuccessful={isSuccessful}
            isError={isError}
          />
          {(errorMessage || successMessage) && (
            <p
              className={`text-sm mt-2 ${
                errorMessage ? 'text-red-500' : 'text-green-500'
              }`}
            >
              {errorMessage || successMessage}
            </p>
          )}
        </div>
      )}
    </form>
  );
};

export default ImpressumForm;
