import React from 'react';

import { ISCBMedia } from 'types/interfaces/sulu/IContentBlocks';

const SuluMedia = ({ content }: ISCBMedia) => {
  const { isVideo, isAudio, url, mimeType } = content;

  return (
    <>
      {isVideo && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <video width='100%' controls>
          <source src={url} type={mimeType} />
          Your browser does not support the video tag.
        </video>
      )}
      {isAudio && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls>
          <source src={url} type={mimeType} />
          Your browser does not support the audio tag.
        </audio>
      )}
    </>
  );
};

export default SuluMedia;
