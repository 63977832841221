import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-use';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import AddTile from 'components/static/AddTile';
import { Button, ButtonFilter } from 'components/static/Button';
import { IConsultantArticleTile } from 'components/static/ConsultantArticleTile';
import Dialog from 'components/static/Dialog';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';
import NoDataText from 'components/static/NoDataText';
import Pagination from 'components/static/Pagination';

import useIsInViewport from 'hooks/useIsInViewport';

import { EConsultantNewArticleType } from 'types/enums/EConsultant';
import { ERoute } from 'types/enums/ERoute';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { ESuluContentTypeText } from 'types/enums/sulu/EBackendData';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { IConsultantAllArticlesResponse } from 'types/interfaces/sulu/IConsultantApi';
import { ISPConsultantArticleOverview } from 'types/interfaces/sulu/IPages';
import { IUser } from 'types/interfaces/sulu/IUser';

import { TILES_PER_PAGE } from 'utils/constants';
import createConsultantArticleTiles from 'utils/createConsultantArticleTiles';
import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import scrollToElement from 'utils/scrollToElement';
import currentLanguage from 'utils/sulu/currentLanguage';
import getAllConsultantArticles from 'utils/sulu/getAllConsultantArticles';
import getProfile from 'utils/sulu/getProfile';

export default ({ content }: ISPConsultantArticleOverview) => {
  const { title, page_title: pageTitle } = content;

  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const articleSuccessfullyCreated =
    searchParams.get(EUrlSearchParam.CONSULTANT_ARTICLE_CREATED) ?? '';
  const articleSuccessfullyDeleted =
    searchParams.get(EUrlSearchParam.CONSULTANT_ARTICLE_DELETED) ?? '';
  const consultantId = searchParams.get(EUrlSearchParam.CONSULTANT_ID) ?? '';

  const scrollToElementRef = useRef<HTMLHeadingElement>(null);
  const isScrollToElementRefInViewport = useIsInViewport(scrollToElementRef);
  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [tiles, setTiles] = useState<IConsultantArticleTile[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<
    EConsultantNewArticleType[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showSuccessArticleDeleted, setShowSuccessArticleDeleted] =
    useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoggedInConsultant, setIsLoggedInConsultant] = useState<
    boolean | undefined
  >(undefined);
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const tilesPerPage = TILES_PER_PAGE;
  const indexOfLastTile = currentPage * tilesPerPage;
  const indexOfFirstTile = indexOfLastTile - tilesPerPage;
  const currentTiles = tiles
    .filter(tile => {
      if (!selectedFilters.length) {
        return tile;
      }
      return selectedFilters.includes(tile.contentType);
    })
    .slice(indexOfFirstTile, indexOfLastTile);

  const checkAuth = () => {
    getProfile().then(profile => {
      setUser(profile);
      setAuthChecked(true);
    });
  };

  const updateMenuItems = () => {
    const sidebarType = isLoggedInConsultant
      ? ESidebarType.CONSULTANT
      : ESidebarType.DEFAULT;
    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      sidebarType,
      [],
      consultantId,
    );
    setMenuItems(sidebarMenuItems);
  };

  useEffect(() => {
    setLoading(true);

    checkAuth();
    updateMenuItems();
    getAllConsultantArticles(parseInt(consultantId, 10)).then(
      (articles: IConsultantAllArticlesResponse[]) => {
        const articleTiles = articles.map(article => ({
          id: article.id || '',
          title: article.title || '',
          description: article.description || 'Keine Beschreibung',
          imageUrl: article.photo || '',
          contentType: (article.category as EConsultantNewArticleType) || '',
          url: `/${currentLanguage}/${ERoute.CONSULTANT_ARTICLE}?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}&${EUrlSearchParam.ARTICLE_ID}=${article.id}`,
        }));
        setTiles(articleTiles);
        setLoading(false);
      },
    );
  }, []);

  useEffect(() => {
    if (authChecked) {
      if (
        user &&
        user.consultantDetails !== undefined &&
        user.id.toString() === consultantId
      ) {
        setIsLoggedInConsultant(true);
      } else {
        setIsLoggedInConsultant(false);
      }
    }
  }, [user, authChecked]);

  useEffect(() => {
    if (isLoggedInConsultant) {
      updateMenuItems();
    }
  }, [isLoggedInConsultant]);

  useEffect(() => {
    setShowSuccessDialog(!!articleSuccessfullyCreated);
    setShowSuccessArticleDeleted(!!articleSuccessfullyDeleted);
  }, []);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      scrollToElement(scrollToElementRef, isScrollToElementRefInViewport);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(tiles.length / tilesPerPage)) {
      setCurrentPage(currentPage + 1);
      scrollToElement(scrollToElementRef, isScrollToElementRefInViewport);
    }
  };

  const handleFilterClick = (filter: EConsultantNewArticleType) => {
    let newSelectedFilters: EConsultantNewArticleType[];
    if (selectedFilters.includes(filter)) {
      newSelectedFilters = selectedFilters.filter(f => f !== filter);
    } else {
      newSelectedFilters = [...selectedFilters, filter];
    }

    setSelectedFilters(newSelectedFilters);
  };

  const handleCloseDialog = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete(EUrlSearchParam.CONSULTANT_ARTICLE_CREATED);
    window.history.replaceState({}, '', url.toString());
    setShowSuccessDialog(false);
  };

  const handleCloseSuccessArticleDeletedDialog = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete(EUrlSearchParam.CONSULTANT_ARTICLE_DELETED);
    window.history.replaceState({}, '', url.toString());
    setShowSuccessArticleDeleted(false);
  };

  return (
    <Layout menuItems={menuItems}>
      {loading ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <>
          <div className='px-5 lg:px-12 py-5 shadow-md'>
            <h3 ref={scrollToElementRef} className='scroll-mt-16'>
              {pageTitle || title}
            </h3>
            <div className='flex flex-col md:flex-row md:items-center gap-1 md:gap-2 mt-4'>
              <span>Filtern nach:</span>
              <div className='flex flex-wrap items-center gap-2 md:gap-1'>
                <ButtonFilter
                  label={ESuluContentTypeText.ARTICLE}
                  isSelected={selectedFilters.includes(
                    EConsultantNewArticleType.ARTICLE,
                  )}
                  onClick={() =>
                    handleFilterClick(EConsultantNewArticleType.ARTICLE)
                  }
                />
                <ButtonFilter
                  label={ESuluContentTypeText.PODCAST}
                  isSelected={selectedFilters.includes(
                    EConsultantNewArticleType.PODCAST,
                  )}
                  onClick={() =>
                    handleFilterClick(EConsultantNewArticleType.PODCAST)
                  }
                />
                <ButtonFilter
                  label={ESuluContentTypeText.VIDEO}
                  isSelected={selectedFilters.includes(
                    EConsultantNewArticleType.VIDEO,
                  )}
                  onClick={() =>
                    handleFilterClick(EConsultantNewArticleType.VIDEO)
                  }
                />
              </div>
            </div>
          </div>
          <BlockContainer>
            <>
              {tiles.length === 0 && isLoggedInConsultant && (
                <NoDataText>
                  Sie haben noch keinen Artikel hinzugefügt
                </NoDataText>
              )}
              <div className='flex justify-center flex-wrap gap-5 mt-5'>
                {tiles.length === 0 && !isLoggedInConsultant && (
                  <NoDataText>Keine Artikel verfügbar</NoDataText>
                )}
                {isLoggedInConsultant && (
                  <AddTile
                    url={`/${currentLanguage}/${ERoute.CONSULTANT_CREATE_ARTICLE}?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}`}
                    text='Neuen Beitrag Erstellen'
                  />
                )}
                {tiles.length > 0 &&
                  createConsultantArticleTiles(
                    isLoggedInConsultant === undefined
                      ? false
                      : isLoggedInConsultant,
                    currentTiles,
                  )}
              </div>
              {tiles.length > tilesPerPage && (
                <Pagination
                  postsPerPage={tilesPerPage}
                  totalPosts={tiles.length}
                  currentPage={currentPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  className='mt-12'
                />
              )}
            </>
          </BlockContainer>
        </>
      )}
      <Footer />
      <Dialog
        isOpen={showSuccessDialog}
        handleToggle={setShowSuccessDialog}
        title='Bestätigung'
        headline='Beitrag'
        dialogPanelClassName='lg:min-w-[800px] min-w-[80%]'
        titleClassName='md:text-5xl text-3xl'
        content={
          <div className='flex flex-col justify-center items-center text-center'>
            <h3 className='text-orange mt-3'>Vielen Dank!</h3>
            <p className='mb-8 lg:px-24'>
              Ihr Beitrag wurde gepostet. Sie finden alle Beiträge in der
              Navigation unter “Meine Beiträge“.
            </p>
            <Button label='Schließen' onClick={handleCloseDialog} />
          </div>
        }
      />
      <Dialog
        isOpen={showSuccessArticleDeleted}
        handleToggle={setShowSuccessArticleDeleted}
        title={
          articleSuccessfullyDeleted === 'true' ? 'Bestätigung' : 'Hinweis!'
        }
        headline={
          articleSuccessfullyDeleted === 'true'
            ? 'Beitrag gelöscht'
            : 'Beitrag wurde  nicht gelöscht'
        }
        dialogPanelClassName='lg:min-w-[800px] min-w-[80%]'
        titleClassName='md:text-5xl text-3xl'
        content={
          <div className='flex flex-col justify-center items-center text-center'>
            {articleSuccessfullyDeleted === 'true' ? (
              <h3 className='text-orange mt-3'>Vielen Dank!</h3>
            ) : (
              <h3 className='text-orange mt-3'>Achtung!</h3>
            )}
            <p className='mb-8 lg:px-24'>
              {articleSuccessfullyDeleted === 'true'
                ? 'Ihr Beitrag wurde gelöscht.'
                : 'Ihr Beitrag konnte nicht gelöscht werden.'}
            </p>
            <Button
              label='Schließen'
              onClick={handleCloseSuccessArticleDeletedDialog}
            />
          </div>
        }
      />
    </Layout>
  );
};
