// eslint-disable-next-line import/no-extraneous-dependencies
import { toJS } from 'mobx';
import React from 'react';
import { ZodFirstPartySchemaTypes } from 'zod/lib/types';

import {
  ISPTile,
  ISPTileContent,
  ZSPTileContent,
} from 'types/interfaces/sulu/IPages';

import Article from './content/Article';

const parseContentData = (
  contentData: ISPTileContent,
  zodType: ZodFirstPartySchemaTypes,
): boolean => {
  // MIND: contentData may have special types ("observableArray") from MobX.
  // The toJS method parses special MobX types to regular JS types.
  const contentDataJS = toJS(contentData);
  const zodParsed = zodType.safeParse(contentDataJS);
  if (!zodParsed.success) {
    localStorage.setItem(
      `page-content-${contentData.title}-${Date.now()}`,
      JSON.stringify(zodParsed.error.issues),
    );
  }
  return zodParsed.success;
};

export default ({ id, content }: ISPTile) => {
  const error = <p>There was an error displaying the content page.</p>;

  const renderPage = () =>
    parseContentData(content, ZSPTileContent) ? (
      <Article id={id} content={content} />
    ) : (
      error
    );

  return <>{renderPage()}</>;
};
