import { IUser } from 'types/interfaces/sulu/IUser';

export default async (consultantId: number): Promise<IUser | null> => {
  const response = await fetch(`/profile/${consultantId}`);

  if (response.ok) {
    const jsonResponse = await response.json();
    const user = jsonResponse.data;
    // eslint-disable-next-line no-underscore-dangle
    user._token = jsonResponse._token;
    return user;
  }

  return null;
};
