/* eslint-disable import/prefer-default-export */
import React from 'react';

const createFocalPoints = (focalPoints: string[] | undefined) => {
  if (!focalPoints) return null;

  const groupedFocalPoints = focalPoints.reduce(
    (acc: { [key: string]: string[] }, focalPoint: string) => {
      const [category, subcategory] = focalPoint.split('_');
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(subcategory);
      return acc;
    },
    {},
  );

  return Object.keys(groupedFocalPoints).map(category => (
    <ul key={category}>
      <li>
        {category} - (Schwerpunkte: {groupedFocalPoints[category].join('; ')})
      </li>
    </ul>
  ));
};

export default createFocalPoints;
