import React, { useState } from 'react';

import { ISCBTile } from 'types/interfaces/sulu/IContentBlocks';

import { DEFAULT_SCHEME_COLOR } from 'utils/constants';
import currentLanguage from 'utils/sulu/currentLanguage';

export default ({ content }: ISCBTile) => {
  const {
    title,
    url,
    page_color: pageColor,
    tile_background_image: image,
  } = content;
  const urlWithoutLang = url.startsWith(`/${currentLanguage}/`)
    ? url.replace(`/${currentLanguage}/`, '/')
    : url;
  const [hover, setHover] = useState<boolean>(false);

  const backgroundColor = pageColor || DEFAULT_SCHEME_COLOR;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className='w-52 h-52 pt-4 px-4 pb-2.5 text-white rounded-xl cursor-pointer'
      style={{
        backgroundColor,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => {
        window.location.href = `/${currentLanguage}${urlWithoutLang}`;
      }}
    >
      <div
        className={`w-32 h-32 translate-x-0 translate-y-0 scale-1 ${
          hover ? 'translate-x-6 translate-y-6 scale-[1.625]' : ''
        } bg-center bg-no-repeat bg-cover transition-all rounded-md ${
          hover ? 'brightness-50' : 'brightness-100'
        }`}
        style={{
          backgroundImage: `url(${image.url})`,
        }}
      />
      <p className='relative font-bold mt-1.5 line-clamp-2 z-10'>{title}</p>
    </div>
  );
};
