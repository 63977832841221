import React, { useEffect, useState } from 'react';

import Logo from 'assets/images/logo.svg';

import Search from 'components/primitives/Search';
import ProfileButton from 'components/static/ProfileButton';

import { EButtonSize } from 'types/enums/EButton';
import { ESearchfieldSize, ESearchfieldVariant } from 'types/enums/ESearch';
import { ISidebar } from 'types/interfaces/ISidebar';

import { DEFAULT_SCHEME_COLOR } from 'utils/constants';
import navigateTo from 'utils/navigateTo';
import getProfile from 'utils/sulu/getProfile';

import LogoutButton from './LogoutButton';
import './Navigation.css';
import createMenuItems from './utils/createMenuItems';

export default ({
  menuItems,
  schemeColor = DEFAULT_SCHEME_COLOR,
  activeLinkUrl = '',
  showLogoutButton = false,
}: ISidebar) => {
  const [hoverMenuItemId, setHoverMenuItemId] = useState<string>('');
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const checkAuth = () => {
    getProfile().then(profile => setIsAuthenticated(!!profile));
  };

  useEffect(() => {
    checkAuth();
    document.addEventListener('login:success', checkAuth);
  }, []);

  return (
    <>
      <div className='fixed top-0 z-50 flex items-center justify-between w-full h-14 px-5 py-2 bg-white shadow-md'>
        <Logo
          className='h-full cursor-pointer'
          onClick={() => navigateTo('')}
        />
        <div className='flex items-center gap-5'>
          <Search
            variant={ESearchfieldVariant.DARK}
            size={ESearchfieldSize.SMALL}
          />
          <ProfileButton buttonSize={EButtonSize.SMALL} />
        </div>
      </div>
      <div
        className={`fixed top-14 bottom-0 left-0 z-50 w-64 bg-grey ${
          showLogoutButton && isAuthenticated ? 'pt-5' : 'py-5'
        }`}
      >
        <div className='flex flex-col h-full'>
          <div className='flex-1 flex flex-col px-5 overflow-y-auto'>
            {createMenuItems(
              menuItems,
              schemeColor,
              activeLinkUrl,
              hoverMenuItemId,
              setHoverMenuItemId,
            )}
          </div>
          {showLogoutButton && isAuthenticated && <LogoutButton />}
        </div>
      </div>
    </>
  );
};
