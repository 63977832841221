import { ETailwindBreakpoint } from 'types/enums/ETailwindBreakpoint';

import tailwindConfig from '../../tailwind.config';

// check tailwind.config.js for values
const fallbackBreakpoints = {
  [ETailwindBreakpoint.sm]: 640,
  [ETailwindBreakpoint.md]: 768,
  [ETailwindBreakpoint.lg]: 1024,
  [ETailwindBreakpoint.xl]: 1280,
  [ETailwindBreakpoint['2xl']]: 1536,
};

export default (breakpoint: ETailwindBreakpoint): number =>
  tailwindConfig?.theme?.screens?.[breakpoint]
    ? Number(tailwindConfig?.theme?.screens?.[breakpoint].replace('px', ''))
    : fallbackBreakpoints[breakpoint];
