export enum EButtonSize {
  SMALL = 'small',
  REGULAR = 'regular',
}

export enum EButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}
