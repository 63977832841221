import { z } from 'zod';

import ZSLBThreeColumns from './layoutBlocks/ZSLBThreeColumns';
import ZSLBTwoColumns from './layoutBlocks/ZSLBTwoColumns';

/**
 * MIND: ZSLB = Zod Sulu Layout Block
 * MIND: Some properties are omitted as they were not used
 * at the time of implementation. If some properties are missing,
 * check the raw data coming from Sulu and extend the according type.
 * MIND: Relevant interfaces / types are exported at the bottom of the file.
 */

export const ZSuluLayoutBlock = z.union([ZSLBTwoColumns, ZSLBThreeColumns]);

export type ISLBTwoColumns = z.infer<typeof ZSLBTwoColumns>;
export type ISLBThreeColumns = z.infer<typeof ZSLBThreeColumns>;

export type ISuluLayoutBlock = z.infer<typeof ZSuluLayoutBlock>;
