import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import { ETailwindBreakpoint } from 'types/enums/ETailwindBreakpoint';
import { ESuluNavigationContexts } from 'types/enums/sulu/EBackendData';
import { IMenuItems } from 'types/interfaces/IMenu';

import getTailwindBreakpointAsNumber from 'utils/getTailwindBreakpointAsNumber';
import getNavigation from 'utils/sulu/getNavigation';

import MenuBar from './MenuBar';
import MenuBarMobile from './MenuBarMobile';

export default () => {
  const { width } = useWindowSize();
  const [menuItems, setMenuItems] = useState<IMenuItems>({
    information: [],
    entertainment: [],
    consulting: [],
  });

  useEffect(() => {
    Promise.all([
      getNavigation(ESuluNavigationContexts.INFORMATION),
      getNavigation(ESuluNavigationContexts.ENTERTAINMENT),
      getNavigation(ESuluNavigationContexts.CONSULTING),
    ]).then(res =>
      setMenuItems({
        information: res[0],
        entertainment: res[1],
        consulting: res[2],
      }),
    );
  }, []);

  return width < getTailwindBreakpointAsNumber(ETailwindBreakpoint.md) ? (
    <MenuBarMobile menuItems={menuItems} />
  ) : (
    <MenuBar menuItems={menuItems} />
  );
};
