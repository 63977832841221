import { IConsultantArticleResponse } from 'types/interfaces/sulu/IConsultantApi';

export default async (
  consultantId: number,
  articleId: string,
): Promise<IConsultantArticleResponse> => {
  const response = await fetch(
    `/consultant/${consultantId}/articles/${articleId}`,
  );

  if (response.ok) {
    try {
      const jsonResponse = await response.json();
      return jsonResponse.length
        ? jsonResponse[0]
        : ({} as IConsultantArticleResponse);
    } catch (e) {
      return {} as IConsultantArticleResponse;
    }
  }

  return {} as IConsultantArticleResponse;
};
