import React, { useEffect, useState } from 'react';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import Layout from 'components/static/Layout';
import NoDataText from 'components/static/NoDataText';

import { ESidebarType } from 'types/enums/ESidebarType';
import { ESuluNavigationContexts } from 'types/enums/sulu/EBackendData';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { ISPAllContents, ISPContentTile } from 'types/interfaces/sulu/IPages';

import createTiles from 'utils/createTiles';
import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import shuffle from 'utils/shuffle';
import getNavigation from 'utils/sulu/getNavigation';

export default ({ content }: ISPAllContents) => {
  const { title, page_color: schemeColor, tiles } = content;
  const [shuffledTiles, setShuffledTiles] = useState<ISPContentTile[]>([]);
  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);

  useEffect(() => {
    setShuffledTiles(shuffle(tiles));
    // Filter out tiles containing "Rechtsbegriffe"
    const filteredTiles = tiles.filter(
      tile => !tile.content.text.includes('Rechtsbegriffe mit'),
    );

    // Shuffle the filtered tiles
    setShuffledTiles(shuffle(filteredTiles));

    // Get menu items
    Promise.all([
      getNavigation(ESuluNavigationContexts.INFORMATION),
      getNavigation(ESuluNavigationContexts.ENTERTAINMENT),
      getNavigation(ESuluNavigationContexts.CONSULTING),
    ]).then(subMenuItems => {
      const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
        ESidebarType.TOPICS,
        subMenuItems.flat(),
      );
      setMenuItems(sidebarMenuItems);
    });
  }, [tiles]);

  return (
    <Layout menuItems={menuItems} schemeColor={schemeColor}>
      <BlockContainer>
        <>
          <h3>{title}</h3>
          <div className='flex items-center justify-center flex-wrap gap-5 mt-5'>
            {tiles.length === 0 && (
              <NoDataText>Keine Inhalte verfügbar</NoDataText>
            )}
            {tiles.length > 0 && createTiles(shuffledTiles, true)}
          </div>
        </>
      </BlockContainer>
      <Footer />
    </Layout>
  );
};
