import React from 'react';

import ConsultantTile, {
  IConsultantTile,
} from 'components/static/ConsultantTile';

export default (tiles: IConsultantTile[], filterIds: number[]) =>
  tiles
    .filter(tile => filterIds.length === 0 || filterIds.includes(tile.id))
    .map(tile => <ConsultantTile key={tile.id} {...tile} />);
