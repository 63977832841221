/* eslint-disable no-plusplus */
/* eslint-disable prefer-const */
const getRandomSample = (values: any[] = []) => {
  let remainingItems = [...values];
  let returnValues = [];
  for (let i = 0; i < values.length; i++) {
    const len = remainingItems.length;
    const index = Math.floor(Math.random() * len);
    returnValues.push(remainingItems[index]);
    remainingItems.splice(index, 1);
  }
  return returnValues;
};
/* eslint-enable prefer-const */
/* eslint-enable no-plusplus */

export default getRandomSample;
