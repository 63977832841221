import React from 'react';

interface ISimpleWysiwyg {
  content: string;
}

const SimpleWysiwyg = ({ content }: ISimpleWysiwyg) => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={{ __html: content }} />
);

export default SimpleWysiwyg;
