import { z } from 'zod';

import { ESuluLayoutBlockTypes } from 'types/enums/sulu/EBackendData';
import { ZSuluContentBlock } from 'types/interfaces/sulu/IContentBlocks';
import ZSCBBase from 'types/interfaces/sulu/contentBlocks/ZSCBBase';

const ZSLBThreeColumns = ZSCBBase.extend({
  type: z.literal(ESuluLayoutBlockTypes.THREE_COLUMNS),
  left: z.array(ZSuluContentBlock),
  middle: z.array(ZSuluContentBlock),
  right: z.array(ZSuluContentBlock),
});

export default ZSLBThreeColumns;
