import {
  ICustomPasswordResetResponse,
  IPasswordResetResponse,
} from 'types/interfaces/sulu/IAuth';

export default async (
  formData: FormData,
  token: string,
): Promise<ICustomPasswordResetResponse> => {
  try {
    const response = await fetch(`/password-reset/${token}`, {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      const jsonResponse: IPasswordResetResponse = await response.json();
      return jsonResponse;
    }

    return {
      success: false,
      error: undefined,
    };
  } catch (error) {
    /**
     * WORKAROUND
     * There will be a redirect afterwards
     * A redirect is not a json, so the try block throws an error.
     * Because of that, we know that the login was successful.
     * */
    return {
      success: true, // TODO: check if false is more correct
      error: undefined,
    };
  }
};
