import { ISPContentTile } from 'types/interfaces/sulu/IPages';

export default async (): Promise<ISPContentTile[]> => {
  const response = await fetch('/user/articles');

  if (response.ok) {
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  return [];
};
