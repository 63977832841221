import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-use';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';
import TileLink from 'components/static/TileLink';

import { ERoute } from 'types/enums/ERoute';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { ISPConsultantWelcome } from 'types/interfaces/sulu/IPages';
import { IUser } from 'types/interfaces/sulu/IUser';

import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import navigateTo from 'utils/navigateTo';
import getProfile from 'utils/sulu/getProfile';

export default ({ content }: ISPConsultantWelcome) => {
  const {
    page_headline_top: headline,
    page_headline_bottom: subline,
    intro_text: introText,
  } = content;

  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const consultantId = searchParams.get(EUrlSearchParam.CONSULTANT_ID) ?? '';

  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoggedInConsultant, setIsLoggedInConsultant] = useState<
    boolean | undefined
  >(undefined);
  const [authChecked, setAuthChecked] = useState<boolean>(false);

  const checkAuth = () => {
    getProfile().then(profile => {
      setUser(profile);
      setAuthChecked(true);
    });
  };

  useEffect(() => {
    checkAuth();
    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      ESidebarType.CONSULTANT,
      [],
      consultantId,
    );
    setMenuItems(sidebarMenuItems);
  }, []);

  useEffect(() => {
    if (authChecked) {
      if (
        user &&
        user.consultantDetails !== undefined &&
        user.id.toString() === consultantId
      ) {
        setIsLoggedInConsultant(true);
      } else {
        setIsLoggedInConsultant(false);
      }
    }
  }, [user, authChecked]);

  useEffect(() => {
    if (isLoggedInConsultant !== undefined) {
      if (!isLoggedInConsultant) {
        navigateTo('', [{ key: EUrlSearchParam.NO_CONSULTANT, value: 'true' }]);
      }
    }
  }, [isLoggedInConsultant]);

  const addConsultantId = (route: ERoute) =>
    `${route}?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}`;

  return (
    <Layout menuItems={menuItems} showLogoutButton={isLoggedInConsultant}>
      {!isLoggedInConsultant ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <BlockContainer>
          <>
            <h1>
              <span className='block text-3xl font-normal uppercase'>
                {headline || 'Herzlich'}
              </span>
              <span className='block'>{subline || 'Willkommen'}</span>
            </h1>
            <div className='mt-12 2xl:max-w-[75%]'>
              {introText && <p className='whitespace-pre-line'>{introText}</p>}
              <div
                className={`flex items-center justify-center flex-wrap gap-4 ${
                  introText ? 'mt-8' : ''
                }`}
              >
                <TileLink
                  title='Mein Profil'
                  url={addConsultantId(ERoute.CONSULTANT_PROFILE)}
                  backgroundColor='#CF0101'
                  imageUrl='https://www.weltdeserbens.de/media/1086/download/volker%20looman%202.jpg?v=1'
                />
                <TileLink
                  title='Meine Beiträge'
                  url={addConsultantId(ERoute.CONSULTANT_ARTICLES_OVERVIEW)}
                  backgroundColor='#63C33C'
                  imageUrl='https://www.weltdeserbens.de/media/1545/download/AdobeStock_86529465_klein.jpg?v=1'
                />
                <TileLink
                  title='Alle Inhalte'
                  url=''
                  backgroundColor='#0715E8'
                  imageUrl='https://www.weltdeserbens.de/media/1552/download/AdobeStock_99115140_klein.jpg?v=1'
                />
                {/* <TileLink */}
                {/*  title='Gekaufte Inhalte' */}
                {/*  url={addConsultantId(ERoute.CONSULTANT_BUYED_ARTICLES)} */}
                {/*  backgroundColor='#EF08C0' */}
                {/*  imageUrl='https://www.weltdeserbens.de/media/1550/download/AdobeStock_452473363_klein.jpg?v=1' */}
                {/* /> */}
              </div>
            </div>
          </>
        </BlockContainer>
      )}
      <Footer />
    </Layout>
  );
};
