import { ICsrfToken } from 'types/interfaces/sulu/ICsrfToken';

export default async (route: string): Promise<string> => {
  const response = await fetch(route);
  if (response.ok) {
    const jsonResponse: ICsrfToken = await response.json();
    // eslint-disable-next-line no-underscore-dangle
    return jsonResponse._token;
  }

  return '';
};
