import { googleMapsApiKey } from './sulu/geocoding';

const searchRadius = async (
  address: string,
  consultantAddress: string,
): Promise<number> => {
  const haversineDistance = (
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
  ): number => {
    const toRad = (value: number) => (value * Math.PI) / 180;
    const R = 6371; // Earth radius in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
        Math.cos(toRad(lat2)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  const geocodeAddress = async (addressInput: string) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      addressInput,
    )}&key=${googleMapsApiKey}`;
    const response = await fetch(url);
    const data = await response.json();

    if (data.status !== 'OK' || !data.results.length) {
      return null; // Return null if geocoding fails
    }

    const { lat, lng } = data.results[0].geometry.location;
    return { lat, lon: lng };
  };

  try {
    // Retrieve geolocation for both the user's and consultant's address
    const [addressData, consultantData] = await Promise.all([
      geocodeAddress(address),
      geocodeAddress(consultantAddress),
    ]);

    if (!addressData || !consultantData) {
      return -1; // Return -1 to indicate failure if either geocode is missing
    }

    // Calculate distance if both addresses were geocoded
    return haversineDistance(
      addressData.lat,
      addressData.lon,
      consultantData.lat,
      consultantData.lon,
    );
  } catch (error) {
    return -1; // Return -1 in case of any other errors
  }
};

export default searchRadius;
