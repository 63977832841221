import React, { FormEvent, useEffect, useId, useState } from 'react';
import { useLocation } from 'react-use';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import { Button } from 'components/static/Button';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';

import { EButtonType } from 'types/enums/EButton';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { EDeleteAccountFormData } from 'types/enums/sulu/EFormData';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { ISPConsultantDeleteAccount } from 'types/interfaces/sulu/IPages';
import { IUser } from 'types/interfaces/sulu/IUser';

import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import navigateTo from 'utils/navigateTo';
import getProfile from 'utils/sulu/getProfile';

import './DeleteAccount.css';

export default ({ content }: ISPConsultantDeleteAccount) => {
  const { title, article } = content;

  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const consultantId = searchParams.get(EUrlSearchParam.CONSULTANT_ID) ?? '';

  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [deleteAccountCheckbox, setDeleteAccountCheckbox] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessful] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoggedInConsultant, setIsLoggedInConsultant] = useState<
    boolean | undefined
  >(undefined);
  const [authChecked, setAuthChecked] = useState<boolean>(false);

  const deleteAccountCheckboxInputId = useId();
  const passwordConfirmInputId = useId();

  const checkAuth = () => {
    getProfile().then(profile => {
      setUser(profile);
      setAuthChecked(true);
    });
  };

  useEffect(() => {
    checkAuth();
    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      ESidebarType.CONSULTANT,
    );
    setMenuItems(sidebarMenuItems);
  }, []);

  useEffect(() => {
    if (authChecked) {
      if (
        user &&
        user.consultantDetails !== undefined &&
        user.id.toString() === consultantId
      ) {
        setIsLoggedInConsultant(true);
      } else {
        setIsLoggedInConsultant(false);
      }
    }
  }, [user, authChecked]);

  useEffect(() => {
    if (isLoggedInConsultant !== undefined) {
      if (!isLoggedInConsultant) {
        navigateTo('', [{ key: EUrlSearchParam.NO_CONSULTANT, value: 'true' }]);
      }
    }
  }, [isLoggedInConsultant]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setIsError(false);
    setIsLoading(true);
    setErrorMessage('');
    // Prevent the browser from reloading the page
    e.preventDefault();

    const formData = new FormData();
    formData.append(EDeleteAccountFormData.PASSWORD, passwordConfirmation);

    // TODO implement service
    // deleteAccount(formData).then(res => {
    //   const { status, success } = res;
    //
    //   setIsLoading(false);
    //
    //   if (success) {
    //     setIsSuccessful(true);
    //     // TODO update success message
    //     setSuccessMessage('Das Löschen Ihres Accounts war erfolgreich.');
    //     logout().then(successful => {
    //       if (successful) {
    //         navigateTo('');
    //       }
    //     });
    //     return;
    //   }
    //
    //   setIsError(true);
    //
    //   if (status === 401) {
    //     setErrorMessage('Das eingegebene Passwort ist falsch.');
    //   }
    //
    //   if (status === 404) {
    //     setErrorMessage(
    //       'Ihr Benutzer wurde nicht gefunden oder Sie sind nicht angemeldet.',
    //     );
    //   }
    //
    //   if (status === 500) {
    //     setErrorMessage(
    //       'Ihr Account konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.',
    //     );
    //   }
    // });
  };

  return (
    <Layout menuItems={menuItems} showLogoutButton>
      {!isLoggedInConsultant ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <BlockContainer>
          <>
            <h3>{title}</h3>
            <div
              className='delete-account-content mt-8'
              dangerouslySetInnerHTML={{ __html: article }}
            />
            <form className='form flex flex-col mt-9' onSubmit={handleSubmit}>
              <label
                htmlFor={deleteAccountCheckboxInputId}
                className='checkbox place-self-start'
              >
                <input
                  id={deleteAccountCheckboxInputId}
                  type='checkbox'
                  checked={deleteAccountCheckbox}
                  onChange={e => setDeleteAccountCheckbox(e.target.checked)}
                  required
                />
                Ich möchte meinen Account und alle damit verbundenen Daten
                löschen.
              </label>
              <label
                htmlFor={passwordConfirmInputId}
                className='place-self-start mt-5'
              >
                Bestätigen Sie bitte mit Ihrem Passwort
                <input
                  id={passwordConfirmInputId}
                  type='password'
                  value={passwordConfirmation}
                  onChange={e => setPasswordConfirmation(e.target.value)}
                  required
                />
              </label>
              <div className='flex items-center justify-between mt-12'>
                <Button
                  type='submit'
                  label='Account löschen'
                  isLoading={isLoading}
                  isSuccessful={isSuccessful}
                  isError={isError}
                  disabled={!deleteAccountCheckbox || !passwordConfirmation}
                />
                {!isSuccessful && (
                  <Button
                    label='Abbrechen'
                    onClick={() => {
                      // eslint-disable-next-line no-restricted-globals
                      history.back();
                    }}
                    buttonType={EButtonType.SECONDARY}
                  />
                )}
              </div>
              {(errorMessage || successMessage) && (
                <p
                  className={`text-sm mt-2 ${
                    errorMessage ? 'text-red-500' : 'text-green-500'
                  }`}
                >
                  {errorMessage || successMessage}
                </p>
              )}
            </form>
          </>
        </BlockContainer>
      )}
      <Footer />
    </Layout>
  );
};
