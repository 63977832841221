export interface IUserArticle {
  articleIdentifier: string | null;
  paymentId: string | null;
  paymentDate: string | null;
  paymentStatus: string | null;
}

const getUserArticles = async (): Promise<IUserArticle[]> => {
  const response = await fetch('/user/article/payments');

  if (response.ok) {
    try {
      const jsonResponse = await response.json();
      return jsonResponse.map((article: IUserArticle) => ({
        articleIdentifier: article.articleIdentifier || 'N/A',
        paymentId: article.paymentId || 'N/A',
        paymentDate:
          article.paymentDate || new Date().toLocaleDateString('de-DE'),
        paymentStatus: article.paymentStatus || 'N/A',
      }));
    } catch (e) {
      return [];
    }
  }

  return [];
};

export default getUserArticles;
