import React, { useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import ArrowDropdownIcon from 'assets/icons/arrow-dropdown.svg';
import PauseIcon from 'assets/icons/pause.svg';
import Logo from 'assets/images/logo.svg';

import BlockContainer from 'components/BlockContainer';
import Search from 'components/primitives/Search';
import ProfileButton from 'components/static/ProfileButton';

import { ISSHero } from 'types/interfaces/sulu/ISnippets';

import navigateTo from 'utils/navigateTo';

import './Hero.css';

export default ({ content }: ISSHero) => {
  const { headline, textBlock, subtitle, scrollDownText, video, poster } =
    content;
  const videoRef = useRef(null);
  const { width } = useWindowSize();
  const [searchfieldIsOpen, setSearchfieldIsOpen] = useState<boolean>(false);

  const handlePauseVideo = () => {
    if (videoRef && videoRef.current) {
      // @ts-ignore
      videoRef.current.pause();
    }
  };

  const handleToggleSearchfield = (isOpen: boolean) => {
    setSearchfieldIsOpen(isOpen);
  };

  return (
    <div className='hero relative bg-black'>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        ref={videoRef}
        playsInline
        autoPlay
        muted
        loop
        poster={poster.url}
        className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 object-cover w-full h-full'
      >
        <source src={video.url} type={video.mimeType} />
        Your browser does not support the video tag.
      </video>
      {/* top navbar */}
      <div className='absolute top-1 lg:top-3 inset-x-0 grid grid-cols-[5rem_minmax(0,_1fr)] lg:grid-cols-[7rem_minmax(0,_1fr)] items-center h-14 lg:h-16 px-5 lg:px-12 py-2 z-30'>
        <Logo
          className={`fill-white h-full cursor-pointer transition-opacity ease-in-out duration-300 ${
            !searchfieldIsOpen || width >= 425
              ? 'opacity-100'
              : 'opacity-0 pointer-events-none'
          }`}
          onClick={() => navigateTo('')}
        />
        <div className='flex items-center justify-end gap-4 lg:gap-5'>
          <Search onToggleSearchfield={handleToggleSearchfield} />
          <ProfileButton />
        </div>
      </div>
      <BlockContainer outerClasses='py-28 lg:py-40 z-10'>
        <div className='w-full md:w-1/2 lg:w-1/3 mx-auto text-white'>
          <div dangerouslySetInnerHTML={{ __html: headline }} />
          {subtitle && (
            <p className='text-center font-light mt-5'>{subtitle}</p>
          )}
        </div>
      </BlockContainer>
      {textBlock && (
        <div
          className='text-white bg-blue md:w-auto w-full rounded-xl absolute z-10 px-4 md:bottom-10 bottom-0 left-1/2 transform -translate-x-1/2
                    bg-gradient-to-b from-blue-gradient-from to-blue-gradient-to opacity-90'
        >
          <div dangerouslySetInnerHTML={{ __html: textBlock }} />
        </div>
      )}

      {scrollDownText && (
        <div className='absolute bottom-4 left-1/2 -translate-x-1/2 flex flex-col items-center'>
          <p className='text-white text-center font-light'>{scrollDownText}</p>
          <ArrowDropdownIcon className='w-7 h-7 fill-orange' />
        </div>
      )}
      <PauseIcon
        className='absolute bottom-5 right-5 lg:right-12 fill-orange/30 hover:fill-orange transition ease-in-out duration-300 cursor-pointer z-20'
        onClick={handlePauseVideo}
      />
    </div>
  );
};
