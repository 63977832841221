import { z } from 'zod';

import {
  ESuluContentBlockTypes,
  ESuluTOCItemLevel,
} from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';

const ZSCBTOCItem = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.TOC_ITEM),
  level: z.nativeEnum(ESuluTOCItemLevel),
  name: z.string(),
  id: z.string(),
});

export default ZSCBTOCItem;
