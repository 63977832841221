import React from 'react';

import RightArrowIcon from 'assets/icons/arrow-right.svg';

interface IShowMoreTile {
  url: string;
  text: string;
}

export default ({ url, text }: IShowMoreTile) => {
  const handleClick = () => {
    window.open(url, '_self');
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role='button'
      tabIndex={0}
      className='w-52 p-2.5 flex flex-col items-center justify-center gap-1 rounded-xl cursor-pointer overflow-hidden bg-orange text-white hover:bg-gradient-to-t hover:from-orange-gradient-from to-orange-gradient-to hover:text-white'
    >
      <RightArrowIcon className='w-10 h-10 fill-white' />
      <p className='px-2 text-center text-white text-xl'>{text}</p>
    </div>
  );
};
