import React, { useEffect, useState } from 'react';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';

import { ESidebarType } from 'types/enums/ESidebarType';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { ISPDefault } from 'types/interfaces/sulu/IPages';

import getSidebarMenuItems from 'utils/getSidebarMenuItems';

export default ({ content }: ISPDefault) => {
  const { title, article } = content;
  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      ESidebarType.DEFAULT,
    );
    setMenuItems(sidebarMenuItems);

    setLoading(false);
  }, []);

  return (
    <Layout menuItems={menuItems}>
      {loading ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <BlockContainer>
          <>
            <h3>{title}</h3>
            <div
              className='article-content mt-8'
              dangerouslySetInnerHTML={{ __html: article }}
            />
          </>
        </BlockContainer>
      )}
      <Footer />
    </Layout>
  );
};
