import React from 'react';

import ConsultantArticleTile, {
  IConsultantArticleTile,
} from 'components/static/ConsultantArticleTile';

export default (
  isLoggedInConsultant: boolean,
  tiles: IConsultantArticleTile[],
) =>
  tiles.map(tile => (
    <ConsultantArticleTile
      key={tile.id}
      isLoggedInConsultant={isLoggedInConsultant}
      tile={tile}
    />
  ));
