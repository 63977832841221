import React from 'react';

import LogoutIcon from 'assets/icons/logout.svg';

import navigateTo from 'utils/navigateTo';
import logout from 'utils/sulu/logout';

import './LogoutButton.css';

export default () => {
  const handleLogout = () => {
    logout().then(successful => {
      if (successful) {
        navigateTo('');
      } else {
        // TODO show error message
      }
    });
  };

  return (
    <button
      type='button'
      className='logout-button flex items-center font-bold px-5 py-8 text-black hover:text-orange transition-colors ease-in-out duration-300'
      onClick={handleLogout}
    >
      <LogoutIcon className='icon' />
      <span>Ausloggen</span>
    </button>
  );
};
