import {
  ICustomLoginResponse,
  ILoginResponse,
} from 'types/interfaces/sulu/IAuth';

export default async (formData: FormData): Promise<ICustomLoginResponse> => {
  try {
    const response = await fetch('/login', {
      method: 'POST',
      body: formData,
    });

    if (response.ok) {
      const textResponse = await response.text();
      const jsonResponse: ILoginResponse = JSON.parse(textResponse);
      const returnResponse = {
        success: !!jsonResponse.data,
        error: jsonResponse.error ? jsonResponse.error.message : undefined,
      };
      return returnResponse;
    }

    return {
      success: false,
      error: undefined,
    };
  } catch (error) {
    /**
     * WORKAROUND
     * There will be a redirect after successful login.
     * A redirect is not a json, so the try block throws an error.
     * Because of that, we know that the login was successful.
     * */
    return {
      success: true,
      error: undefined,
    };
  }
};
