import React, { useState } from 'react';

import CloseIcon from 'assets/icons/close.svg';

interface ICloseButton {
  onClick: Function;
  // eslint-disable-next-line react/require-default-props
  className?: string;
  // eslint-disable-next-line react/require-default-props
  iconClassName?: string;
}

export default ({
  onClick,
  className = '',
  iconClassName = '',
}: ICloseButton) => {
  const [hover, setHover] = useState<boolean>(false);

  return (
    <div className={className}>
      <button
        type='button'
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onBlur={() => setHover(false)}
        onClick={() => onClick()}
      >
        <CloseIcon
          className={`w-8 h-8 transition ease-in-out duration-300 ${
            hover ? 'fill-orange' : 'fill-black'
          } ${iconClassName}`}
        />
      </button>
    </div>
  );
};
