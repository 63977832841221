import currentLanguage from './currentLanguage';

export default async (pageUrl: string): Promise<any> => {
  if (!pageUrl) {
    return {};
  }

  const response = await fetch(`/${currentLanguage}${pageUrl}.json`);
  if (response.ok) {
    const jsonResponse = await response.json();
    return jsonResponse.content;
  }
  return {};
};
