import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-use';

import EditIcon from 'assets/icons/editPen.svg';
import LocationDotIcon from 'assets/icons/locationDot.svg';
import SpeechBubbleIcon from 'assets/icons/speechBubble.svg';
import TrashIcon from 'assets/icons/trashCan.svg';
import Logo from 'assets/images/logo.svg';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import { Button, ButtonLink } from 'components/static/Button';
import { IConsultantArticleTile } from 'components/static/ConsultantArticleTile';
import ImpressumForm from 'components/static/ImpressumForm';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';
import NoDataText from 'components/static/NoDataText';
import ShowMoreTile from 'components/static/ShowMoreTile';
import SimpleWysiwyg from 'components/static/SimpleWysiwyg';

import { EConsultantNewArticleType } from 'types/enums/EConsultant';
import { ERoute } from 'types/enums/ERoute';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { IConsultantAllArticlesResponse } from 'types/interfaces/sulu/IConsultantApi';
import {
  IConsultantCompany,
  IConsultantDetails,
  IConsultantResponse,
  IUser,
} from 'types/interfaces/sulu/IUser';

import createConsultantArticleTiles from 'utils/createConsultantArticleTiles';
import createFocalPoints from 'utils/createFocalPoints';
import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import currentLanguage from 'utils/sulu/currentLanguage';
import getAllConsultantArticles from 'utils/sulu/getAllConsultantArticles';
import getAllConsultants from 'utils/sulu/getAllConsultants';
import getConsultant from 'utils/sulu/getConsultant';
import getProfile from 'utils/sulu/getProfile';

import GoogleMap from '../utils/GoogleMap';

export default () => {
  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const consultantId = searchParams.get(EUrlSearchParam.CONSULTANT_ID) ?? '';

  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [consultantDetails, setConsultantDetails] = useState<
    IConsultantDetails | undefined
  >(undefined);
  const [company, setCompany] = useState<IConsultantCompany | undefined>(
    undefined,
  );
  const [articleTiles, setArticleTiles] = useState<IConsultantArticleTile[]>(
    [],
  );
  const [isLoggedInConsultant, setIsLoggedInConsultant] =
    useState<boolean>(false);
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  const [impressumIsInEditMode, setImpressumIsInEditMode] =
    useState<boolean>(false);
  const [isThisMyProfile, setIsThisMyProfile] = useState<boolean>(false);
  const [mapCenter, setMapCenter] = useState<string>();

  const checkAuth = () => {
    if (consultantId) {
      getConsultant(parseInt(consultantId, 10)).then(profile => {
        setUser(profile);
        setAuthChecked(true);
        getProfile().then(myProfile => {
          if (myProfile?.id === profile?.id) setIsThisMyProfile(true);
        });
      });
    } else {
      setAuthChecked(true);
    }
  };

  const updateMenuItems = () => {
    const sidebarType = isLoggedInConsultant
      ? ESidebarType.CONSULTANT
      : ESidebarType.DEFAULT;
    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      sidebarType,
      [],
      consultantId,
    );
    setMenuItems(sidebarMenuItems);
  };

  useEffect(() => {
    if (company) {
      const address = `${company.street}, ${company.plz} ${company.ort}, ${company.staat}`;
      setMapCenter(address);
    }
  }, [company]);

  useEffect(() => {
    setLoading(true);
    updateMenuItems();
    checkAuth();
    document.addEventListener('login:success', checkAuth);
    if (consultantId) {
      getAllConsultantArticles(parseInt(consultantId, 10), 3).then(
        (articles: IConsultantAllArticlesResponse[]) => {
          const tiles = articles.map(article => ({
            id: article.id || '',
            title: article.title || '',
            description: article.description || 'Keine Beschreibung',
            imageUrl: article.photo || '',
            contentType:
              article.category as unknown as EConsultantNewArticleType, // Type assertion
            url: `/${currentLanguage}/${ERoute.CONSULTANT_ARTICLE}?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}&${EUrlSearchParam.ARTICLE_ID}=${article.id}`,
          }));
          setArticleTiles(tiles);
        },
      );
    }
  }, []);

  useEffect(() => {
    if (authChecked) {
      if (user) {
        if (
          user.consultantDetails !== undefined &&
          user.id.toString() === consultantId
        ) {
          setIsLoggedInConsultant(true);
        } else {
          setIsLoggedInConsultant(false);
        }
        setConsultantDetails(
          'consultantDetails' in user ? user.consultantDetails : undefined,
        );
        setCompany(
          'consultantDetails' in user && user.consultantDetails
            ? user.consultantDetails.company
            : undefined,
        );
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [user, authChecked]);

  useEffect(() => {
    updateMenuItems();
  }, [isLoggedInConsultant]);

  const handleImpressumSubmit = () => {
    setImpressumIsInEditMode(false);
    checkAuth();
  };

  useEffect(() => {
    if (!loading && window.location.hash === '#myArctiles') {
      const element = document.getElementById('myArctiles');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [loading]);

  useEffect(() => {
    getAllConsultants().then((consultants: IConsultantResponse[]) => {
      const foundConsultant = consultants?.find(
        consultant => consultant.id.toString() === consultantId,
      );
      if (foundConsultant) {
        const updatedAvatar = foundConsultant.avatar.replace(
          '/1200x627/',
          '/sulu-400x400/',
        );
        setAvatar(updatedAvatar);
      }
    });
  }, [consultantId]);

  return (
    <Layout menuItems={menuItems} showLogoutButton={isLoggedInConsultant}>
      {loading ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <div className='max-w-7xl  bg-grey-background'>
          <BlockContainer>
            {user && consultantDetails ? (
              <>
                <div className='relative grid grid-cols-1 lg:grid-cols-4 xl:grid-cols-5 gap-x-10 gap-y-6'>
                  <div className='col-span-1 flex flex-col'>
                    <div className='grid grid-col-2 lg:grid-cols-5 gap-x-10 gap-y-6'>
                      <div className='col-span-1 flex-col'>
                        {avatar ? (
                          <div
                            className='h-52 w-40 bg-center bg-no-repeat bg-cover rounded-xl'
                            style={{
                              backgroundImage: avatar ? `url(${avatar})` : '',
                            }}
                          />
                        ) : (
                          <div className='h-52 w-40 bg-center bg-no-repeat bg-cover rounded-xl bg-gradient-to-t from-[#B5B5B5] to-[#E4E3E5]'>
                            <Logo className='w-[75%] h-full m-auto fill-white' />
                          </div>
                        )}
                        <h4 className='text-xl font-bold mt-5'>Sprachen</h4>
                        <p className='text-sm mt-2'>
                          {consultantDetails.languages.map(language => (
                            <span key={language} className='block'>
                              {language}
                            </span>
                          ))}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-span-1 lg:col-span-3 flex flex-col${
                      isLoggedInConsultant ? ' pr-20' : ''
                    }`}
                  >
                    {consultantDetails.focalPoint && (
                      <p className='text-sm font-semibold text-orange'>
                        {consultantDetails.focalPoint}
                      </p>
                    )}
                    <h2 className='font-bold mb-5'>
                      {user.firstName || ''} {user.lastName || ''}
                    </h2>
                    {consultantDetails.myOffer && (
                      <SimpleWysiwyg
                        content={consultantDetails.myOffer || ''}
                      />
                    )}
                    {consultantDetails.focalPoints &&
                      consultantDetails.focalPoints.length > 0 && (
                        <>
                          <h4 className='text-xl font-semibold mt-5'>
                            Schwerpunkte
                          </h4>
                          <div className='mt-5 mb-0'>
                            {createFocalPoints(consultantDetails.focalPoints)}
                          </div>
                          {!isThisMyProfile && (
                            <div className='mt-8'>
                              <ButtonLink
                                label='Kontakt aufnehmen'
                                url={`mailto:${user.email}`}
                              />
                            </div>
                          )}
                        </>
                      )}

                    <div className='col-span-1 flex justify-end' />
                  </div>
                  {isThisMyProfile && (
                    <div className='flex flex-col ml-auto gap-4'>
                      <ButtonLink
                        icon={<EditIcon className='icon' />}
                        url={`/${currentLanguage}/${ERoute.CONSULTANT_REGISTRATION}?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}`}
                      />
                      <ButtonLink
                        icon={<TrashIcon className='icon' />}
                        url={`/${currentLanguage}/${ERoute.CONSULTANT_DELETE_ACCOUNT}?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}`}
                      />
                    </div>
                  )}
                </div>

                {/* Company details */}
                {company && (
                  <div className='bg-blue-light px-9 py-5 mt-10 rounded-xl'>
                    <h2 className='font-bold'>{company.companyName}</h2>
                    <div className='grid grid-cols-2 lg:grid-cols-5  gap-x-10 gap-y-6 mt-7 truncate'>
                      <div className='col-span-2 lg:col-span-2'>
                        <div className='flex gap-4'>
                          <div>
                            <LocationDotIcon className='mt-1' />
                          </div>
                          <div className='flex flex-col'>
                            <h4 className='text-xl font-semibold'>Adresse</h4>
                            <div className='block text-sm'>
                              {company.companyName}
                              <br />
                              {company.street}
                              <br />
                              {company.plz}, {company.ort}
                              <br />
                              {company.bundesland}
                              <br />
                              {company.staat}
                            </div>
                          </div>
                        </div>
                        <div className='flex gap-4 mt-7'>
                          <div>
                            <SpeechBubbleIcon className='mt-1' />
                          </div>
                          <div className='flex flex-col'>
                            <h4 className='text-xl font-semibold'>Kontakt</h4>
                            {company.telephone && (
                              <a
                                href={`tel:${company.telephone}`}
                                className='block text-sm mt-2'
                              >
                                {company.telephone}
                              </a>
                            )}
                            {company.email && (
                              <a
                                href={`mailto:${company.email}`}
                                className='block text-sm'
                              >
                                {company.email}
                              </a>
                            )}
                            {company.website && (
                              <a
                                href={company.website}
                                target='_blank'
                                rel='noreferrer'
                                className='block text-sm'
                              >
                                {company.website}
                              </a>
                            )}
                          </div>
                        </div>
                        {company.barrierFree !== undefined && (
                          <div className='mt-7 ml-10'>
                            <h4 className='text-xl font-semibold'>
                              Barrierefrei
                            </h4>
                            <p className='text-sm mt-1'>
                              {company.barrierFree ? 'Ja' : 'Nein'}
                            </p>
                          </div>
                        )}
                      </div>

                      {/* Google maps */}
                      <div className='col-span-5 lg:col-span-3 lg:col-start-3'>
                        {mapCenter && (
                          <GoogleMap
                            key='reload'
                            addresses={[]}
                            defaultCenter={mapCenter}
                          />
                        )}
                      </div>
                      <div className='col-span-5 lg:col-span-3 lg:col-start-3'>
                        {consultantDetails.categories.length > 0 && (
                          <div className=''>
                            <h4 className='text-xl font-semibold'>
                              Wir beraten zu
                            </h4>
                            <div className='flex flex-wrap gap-2 mt-1'>
                              {consultantDetails.categories.map(category => (
                                <span
                                  className='bg-white rounded-full px-5'
                                  key={category}
                                >
                                  {category}
                                </span>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Articles */}
                <div id='myArctiles'>
                  <h2 className='font-bold mt-10'>
                    Artikel von {user.firstName} {user.lastName}
                  </h2>
                  <div className='flex flex-wrap gap-5 mt-5'>
                    <ShowMoreTile
                      text='Zeige alle Artikel'
                      url={`/${currentLanguage}/${ERoute.CONSULTANT_ARTICLES_OVERVIEW}?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}`}
                    />
                    {createConsultantArticleTiles(
                      isThisMyProfile,
                      articleTiles,
                    )}
                  </div>
                </div>

                {/* Impressum */}
                <div className='mt-10 p-4 bg-grey-light rounded-xl'>
                  <div className='flex items-center justify-between mb-5'>
                    <h2 className='font-bold'>Impressum</h2>
                    {isThisMyProfile && (
                      <Button
                        icon={<EditIcon />}
                        onClick={() => setImpressumIsInEditMode(true)}
                      />
                    )}
                  </div>
                  <ImpressumForm
                    isInEditMode={impressumIsInEditMode}
                    impressumFields={user.impressum}
                    onSubmitDone={handleImpressumSubmit}
                  />
                </div>
              </>
            ) : (
              <NoDataText>Beraterprofil konnte nicht geladen werden</NoDataText>
            )}
          </BlockContainer>
        </div>
      )}
      <Footer />
    </Layout>
  );
};
