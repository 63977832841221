import { IProfileUpdateResponse } from 'types/interfaces/sulu/IProfile';

export default async (formData: FormData): Promise<IProfileUpdateResponse> => {
  const response = await fetch('/profile', {
    method: 'POST',
    body: formData,
  });

  const jsonResponse = await response.json();
  jsonResponse.status = response.status;
  return jsonResponse;
};
