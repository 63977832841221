import {
  ICustomPasswordForgetResponse,
  IPasswordForgetResponse,
} from 'types/interfaces/sulu/IAuth';

export default async (
  formData: FormData,
): Promise<ICustomPasswordForgetResponse> => {
  try {
    const response = await fetch('/password-forget', {
      method: 'POST',
      body: formData,
    });

    const jsonResponse: IPasswordForgetResponse = await response.json();
    return jsonResponse;
  } catch (error) {
    return {
      success: false,
      errors: undefined,
    };
  }
};
