// eslint-disable-next-line import/no-extraneous-dependencies
import { toJS } from 'mobx';
import React, { Key } from 'react';
import { ZodFirstPartySchemaTypes } from 'zod/lib/types';

import BlockContainer from 'components/BlockContainer';
import { IFrame, Image, SuluMedia, Wysiwyg } from 'components/blocks';
import TableOfContents from 'components/blocks/TableOfContents';

import { ESuluContentBlockTypes } from 'types/enums/sulu/EBackendData';
import { ISuluContentBlock } from 'types/interfaces/sulu/IContentBlocks';
import ZSCBIFrame from 'types/interfaces/sulu/contentBlocks/ZSCBIFrame';
import ZSCBImage from 'types/interfaces/sulu/contentBlocks/ZSCBImage';
import ZSCBMedia from 'types/interfaces/sulu/contentBlocks/ZSCBMedia';
import ZSCBTableOfContents from 'types/interfaces/sulu/contentBlocks/ZSCBTableOfContents';
import ZSCBWysiwyg from 'types/interfaces/sulu/contentBlocks/ZSCBWysiwyg';

const parseBlockData = (
  blockData: ISuluContentBlock,
  zodType: ZodFirstPartySchemaTypes,
): boolean => {
  // MIND: blockData may have special types ("observableArray") from MobX.
  // The toJS method parses special MobX types to regular JS types.
  const blockDataJS = toJS(blockData);
  const zodParsed = zodType.safeParse(blockDataJS);
  if (!zodParsed.success) {
    localStorage.setItem(
      `block-${blockData.type}-${Date.now()}`,
      JSON.stringify(zodParsed.error.issues),
    );
  }
  return zodParsed.success;
};

export default (blockData: ISuluContentBlock, key: Key): JSX.Element => {
  const { type } = blockData;
  let blockToRender = <h2>No component found!</h2>;
  const outerClass = '';
  const innerClass = '';

  switch (type) {
    case ESuluContentBlockTypes.IMAGE:
      if (parseBlockData(blockData, ZSCBImage)) {
        blockToRender = <Image {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.MEDIA:
      if (parseBlockData(blockData, ZSCBMedia)) {
        blockToRender = <SuluMedia {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.WYSIWYG:
      if (parseBlockData(blockData, ZSCBWysiwyg)) {
        blockToRender = <Wysiwyg {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.IFRAME:
      if (parseBlockData(blockData, ZSCBIFrame)) {
        blockToRender = <IFrame {...blockData} key={key} />;
      }
      break;
    case ESuluContentBlockTypes.TABLE_OF_CONTENTS:
      if (parseBlockData(blockData, ZSCBTableOfContents)) {
        blockToRender = <TableOfContents {...blockData} key={key} />;
      }
      break;
    default: {
      return blockToRender;
    }
  }

  return (
    <BlockContainer
      key={key}
      outerClasses={`${type} ${outerClass}`}
      innerClasses={`${innerClass}`}
    >
      {blockToRender}
    </BlockContainer>
  );
};
