import React, { ReactNode } from 'react';

import Navigation from 'components/primitives/Navigation';

import { ISidebar } from 'types/interfaces/ISidebar';

import { DEFAULT_SCHEME_COLOR } from 'utils/constants';

interface ILayout extends ISidebar {
  children: ReactNode;
}

export default ({
  menuItems,
  schemeColor = DEFAULT_SCHEME_COLOR,
  activeLinkUrl = '',
  showLogoutButton = false,
  children,
}: ILayout) => (
  <>
    <Navigation
      menuItems={menuItems}
      schemeColor={schemeColor || DEFAULT_SCHEME_COLOR}
      activeLinkUrl={activeLinkUrl}
      showLogoutButton={showLogoutButton}
    />
    <div className='lg:pl-64 lg:pt-14'>{children}</div>
  </>
);
