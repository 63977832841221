import React from 'react';

import ConsultantArticleTile, {
  IConsultantArticleTile,
} from 'components/static/ConsultantArticleTile';

export default (isThisMyProfile: boolean, tiles: IConsultantArticleTile[]) =>
  tiles.map(tile => (
    <ConsultantArticleTile
      key={tile.id}
      isThisMyProfile={isThisMyProfile}
      tile={tile}
    />
  ));
