import React, { useState, useEffect } from 'react';

import { ButtonLink } from '../static/Button';

interface ISCBIFrame {
  url: string;
}

const IFrame: React.FC<ISCBIFrame> = ({ url }) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    const checkIframe = () => {
      const iframe = document.createElement('iframe');
      iframe.src = url;
      iframe.style.display = 'none';
      document.body.appendChild(iframe);

      iframe.onload = () => {
        try {
          const sameOrigin =
            iframe.contentWindow?.location.origin === window.location.origin;
          if (!sameOrigin) {
            setError(true);
          }
        } catch (e) {
          setError(true);
        }
        document.body.removeChild(iframe);
      };

      iframe.onerror = () => {
        setError(true);
        document.body.removeChild(iframe);
      };
    };

    checkIframe();
  }, [url]);

  return (
    <div>
      {error ? (
        <div>
          <p>
            Um das Video abzuspielen benutzen Sie bitte den unteren Link. Sie
            werden dann auf eine externe Seite umgeleitet.
          </p>
          <div className='flex justify-center mt-6'>
            <ButtonLink
              url={url}
              label='Video Ansehen'
              target='_blank'
              rel='noreferrer noopener'
              download
            />
          </div>
        </div>
      ) : (
        <iframe
          src={url}
          title={`iframe for url ${url}`}
          width='100%'
          height='350px'
          allowFullScreen
        />
      )}
    </div>
  );
};

export default IFrame;
