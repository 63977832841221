/* eslint-disable new-cap */
import { jsPDF } from 'jspdf';
import React from 'react';

import { EButtonSize, EButtonType } from 'types/enums/EButton';
import { IButton } from 'types/interfaces/IButton';

import {
  getButtonSizeStyles,
  getButtonTypeStyles,
} from 'utils/getButtonStyles';

import './Button.css';

interface IButtonInvoice
  extends IButton,
    React.ComponentPropsWithoutRef<'div'> {
  title: string;
  price: string;
  quantity: number;
  paymentDate: string;
  firstName: string;
  lastName: string;
  email: string;
}

const ButtonInvoice = ({
  title,
  price,
  quantity,
  paymentDate,
  firstName,
  lastName,
  email,
  label = undefined,
  buttonType = EButtonType.PRIMARY,
  buttonSize = EButtonSize.REGULAR,
  disabled = false,
  ...rest
}: IButtonInvoice) => {
  const handleClick = () => {
    const name = `${firstName} ${lastName}`;

    const doc = new jsPDF();
    doc.setFontSize(18);
    doc.setLineWidth(0.5);
    doc.text('Rechnung', 10, 15);

    // Invoice header
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.setFont('times-roman');

    doc.text('Datum:', 160, 15);
    doc.text(paymentDate || '', 180, 15);

    doc.text('Welt des Erbens WDE GmbH', 10, 35);
    doc.text('Moorweg 2', 10, 40);
    doc.text('24582 Bordesholm', 10, 45);
    doc.text('Deutschland', 10, 50);
    doc.line(10, 55, 200, 55);

    // Invoice recipient
    doc.text('Rechnung an:', 10, 65);
    doc.text(name || '', 10, 70);
    doc.text(email || '', 10, 75);
    doc.line(10, 80, 200, 80);

    // Table for product information
    doc.text('Produkt', 10, 90);
    doc.text('Menge', 90, 90);
    doc.text('Preis', 145, 90);
    doc.line(10, 95, 200, 95);
    doc.text(title, 10, 100);
    doc.text(String(quantity), 90, 100);
    doc.text(`${price} EUR`, 145, 100);

    // Closing words
    doc.text('Vielen Dank für Ihren Einkauf bei Welt des Erbens.', 10, 250);
    doc.text(
      'Bei Fragen oder Anmerkungen stehen wir Ihnen gerne zur Verfügung.',
      10,
      255,
    );
    doc.text('Mit freundlichen Grüßen,', 10, 275);
    doc.text('Welt des Erbens WDE GmbH', 10, 280);

    doc.save(`Rechnung - ${title}.pdf`);
  };

  return (
    <button
      type='button'
      className={`button ${
        buttonSize === EButtonSize.SMALL ? 'button-small' : ''
      }`}
      disabled={disabled}
      onClick={handleClick}
    >
      <div
        className={`${getButtonTypeStyles({
          buttonType,
        })} ${getButtonSizeStyles({
          label,
          buttonSize,
        })} flex items-center justify-center text-center no-underline rounded-full transition-all ease-in-out duration-300 ${
          disabled && 'pointer-events-none opacity-50'
        }`}
        {...rest}
      >
        {label && <span className={`${label ? 'inline' : ''}`}>{label}</span>}
      </div>
    </button>
  );
};

export default ButtonInvoice;
