import React from 'react';

import AddCircleIcon from 'assets/icons/add_circle_white.svg';

interface IAddTile {
  url: string;
  text: string;
}

export default ({ url, text }: IAddTile) => {
  const handleClick = () => {
    window.open(url, '_self');
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleClick();
    }
  };

  return (
    <div
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role='button'
      tabIndex={0}
      className='w-52 px-2.5 py-10 flex flex-col items-center justify-center gap-1 rounded-xl cursor-pointer overflow-hidden bg-orange text-white hover:bg-gradient-to-t hover:from-orange-gradient-from to-orange-gradient-to hover:text-white'
    >
      <AddCircleIcon className='w-10 h-10' />
      <p className='mt-2 px-2 text-center text-white text-xl'>{text}</p>
    </div>
  );
};
