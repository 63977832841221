import { IConsultantNewArticleResponse } from 'types/interfaces/sulu/IConsultantApi';

export default async (
  formData: FormData,
): Promise<IConsultantNewArticleResponse> => {
  const response = await fetch('/consultant/articles', {
    method: 'POST',
    body: formData,
  });

  try {
    const jsonResponse = await response.json();
    return jsonResponse;
  } catch (e) {
    return { status: 'Es ist ein unerwarteter Fehler aufgetreten' };
  }
};
