import React from 'react';

import { ISPTileContentType } from 'types/interfaces/sulu/IPages';

import getContentTypeText from 'utils/getContentTypeText';

interface IContentTypeText {
  contentType: ISPTileContentType;
  className?: string;
}

const ContentTypeText = ({ contentType, className }: IContentTypeText) => (
  <div
    className={`flex text-black bg-white/90 px-2.5 py-0.5 rounded-full ${className}`}
  >
    <span className='text-xs'>{getContentTypeText(contentType)}</span>
  </div>
);

ContentTypeText.defaultProps = {
  className: '',
};

export default ContentTypeText;
