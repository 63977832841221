import { ESuluContentTypeText } from 'types/enums/sulu/EBackendData';
import { ISPTileContentType } from 'types/interfaces/sulu/IPages';

export default (contentType: ISPTileContentType): string => {
  if (contentType.isAudio) {
    return ESuluContentTypeText.PODCAST;
  }
  if (contentType.isDocument) {
    return ESuluContentTypeText.ARTICLE;
  }
  if (contentType.isVideo) {
    return ESuluContentTypeText.VIDEO;
  }
  return '';
};
