import { z } from 'zod';

import ZSCBIFrame from './contentBlocks/ZSCBIFrame';
import ZSCBImage from './contentBlocks/ZSCBImage';
import ZSCBLink from './contentBlocks/ZSCBLink';
import ZSCBMedia from './contentBlocks/ZSCBMedia';
import ZSCBSocialMediaLink from './contentBlocks/ZSCBSocialMediaLink';
import ZSCBTOCItem from './contentBlocks/ZSCBTOCItem';
import ZSCBTableOfContents from './contentBlocks/ZSCBTableOfContents';
import ZSCBTile from './contentBlocks/ZSCBTile';
import ZSCBWysiwyg from './contentBlocks/ZSCBWysiwyg';

/**
 * MIND: ZSCB = Zod Sulu Content Block
 * MIND: Some properties are omitted as they were not used
 * at the time of implementation. If some properties are missing,
 * check the raw data coming from Sulu and extend the according type.
 * MIND: Relevant interfaces / types are exported at the bottom of the file.
 */

export const ZSuluContentBlock = z.union([
  ZSCBImage,
  ZSCBMedia,
  ZSCBWysiwyg,
  ZSCBIFrame,
  ZSCBTableOfContents,
]);

export type ISCBTile = z.infer<typeof ZSCBTile>;
export type ISCBLink = z.infer<typeof ZSCBLink>;
export type ISCBSocialMediaLink = z.infer<typeof ZSCBSocialMediaLink>;
export type ISCBWysiwyg = z.infer<typeof ZSCBWysiwyg>;
export type ISCBIFrame = z.infer<typeof ZSCBIFrame>;
export type ISCBImage = z.infer<typeof ZSCBImage>;
export type ISCBMedia = z.infer<typeof ZSCBMedia>;
export type ISCBTableOfContents = z.infer<typeof ZSCBTableOfContents>;
export type ISCBTOCItem = z.infer<typeof ZSCBTOCItem>;

export type ISuluContentBlock = z.infer<typeof ZSuluContentBlock>;
