import React, { FormEvent, useEffect, useId, useState } from 'react';

import { Button } from 'components/static/Button';

import { ERoute } from 'types/enums/ERoute';
import { ERegistrationFormData } from 'types/enums/sulu/EFormData';

import currentLanguage from 'utils/sulu/currentLanguage';
import getCsrfToken from 'utils/sulu/getCsrfToken';
import register from 'utils/sulu/register';

export default () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [terms, setTerms] = useState(false);
  const [csrfToken, setCsrfToken] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const firstNameInputId = useId();
  const lastNameInputId = useId();
  const emailInputId = useId();
  const passwordInputId = useId();
  const passwordConfirmInputId = useId();
  const termsInputId = useId();

  const getNewToken = () => {
    getCsrfToken('/registration').then(token => setCsrfToken(token));
  };

  useEffect(() => {
    getNewToken();
    document.addEventListener('login:success', getNewToken);
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setIsError(false);
    setIsLoading(true);
    setErrorMessage('');
    // Prevent the browser from reloading the page
    e.preventDefault();

    const formData = new FormData();
    formData.append(ERegistrationFormData.USERNAME, email);
    formData.append(ERegistrationFormData.EMAIL, email);
    formData.append(ERegistrationFormData.PASSWORD, password);
    formData.append(ERegistrationFormData.FIRST_NAME, firstName);
    formData.append(ERegistrationFormData.LAST_NAME, lastName);
    formData.append(ERegistrationFormData.TERMS, terms ? 'true' : 'false');
    formData.append(ERegistrationFormData.IS_CONSULTANT, 'false');
    formData.append(ERegistrationFormData.TOKEN, csrfToken);

    register(formData).then(res => {
      const { form_errors: errors, success } = res;

      setIsLoading(false);

      if (errors && Object.keys(errors).length) {
        let errMessage =
          'Beim Registrieren ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.';

        if (
          errors['registration[email]'] &&
          errors['registration[email]']?.includes('Wert wird bereits verwendet')
        ) {
          errMessage =
            'Diese E-Mail Adresse ist bereits registriert. Bitte melden Sie sich an.';
        }

        setIsError(true);
        setErrorMessage(errMessage);
        return;
      }

      if (success) {
        setIsSuccessful(true);
        setSuccessMessage('Die Registrierung war erfolgreich!');
        // TODO container should close after a few seconds
      }
    });
  };

  const isButtonDisabled = () =>
    !(firstName && lastName && email && password && passwordConfirm && terms);

  return (
    <form className='form flex flex-col gap-6' onSubmit={handleSubmit}>
      {/* <div> */}
      {/*  <h4 className='text-center font-bold mb-1'>Berater Registrierung</h4> */}
      {/*  <div className='flex justify-center items-center'> */}
      {/*    <ButtonLink */}
      {/*      type='button' */}
      {/*      label='Sie sind Berater' */}
      {/*      url={`/${currentLanguage}/${ERoute.CONSULTANT_REGISTRATION}`} */}
      {/*      buttonType={EButtonType.SECONDARY} */}
      {/*    /> */}
      {/*    <RightArrowIcon className='icon fill-orange -ml-4' /> */}
      {/*  </div> */}
      {/* </div> */}
      <label htmlFor={firstNameInputId}>
        Vorname
        <input
          id={firstNameInputId}
          type='text'
          value={firstName}
          onChange={e => setFirstName(e.target.value)}
          required
        />
      </label>
      <label htmlFor={lastNameInputId}>
        Nachname
        <input
          id={lastNameInputId}
          type='text'
          value={lastName}
          onChange={e => setLastName(e.target.value)}
          required
        />
      </label>
      <label htmlFor={emailInputId}>
        E-Mail
        <input
          id={emailInputId}
          type='email'
          value={email}
          onChange={e => setEmail(e.target.value)}
          required
        />
      </label>
      <label htmlFor={passwordInputId}>
        Passwort
        <input
          id={passwordInputId}
          type='password'
          value={password}
          onChange={e => setPassword(e.target.value)}
          required
        />
      </label>
      <label htmlFor={passwordConfirmInputId}>
        Passwort wiederholen
        <input
          id={passwordConfirmInputId}
          type='password'
          className={`${
            passwordConfirm && passwordConfirm !== password ? 'error' : ''
          }`}
          value={passwordConfirm}
          onChange={e => {
            const currentPasswordConfirm = e.target.value;

            if (currentPasswordConfirm !== password) {
              e.target.setCustomValidity(
                'Die beiden Passwörter stimmen nicht überein',
              );
            } else {
              e.target.setCustomValidity('');
            }

            setPasswordConfirm(currentPasswordConfirm);
          }}
          required
        />
      </label>
      <label htmlFor={termsInputId} className='checkbox'>
        <input
          id={termsInputId}
          type='checkbox'
          checked={terms}
          onChange={e => setTerms(e.target.checked)}
          required
        />
        Ich habe die{' '}
        <a
          href={`/${currentLanguage}/${ERoute.DATA_PRIVACY}`}
          target='_blank'
          rel='noreferrer noopener'
        >
          Datenschutzerklärung
        </a>{' '}
        gelesen und bin einverstanden.
      </label>
      <div className='flex flex-col items-center justify-center'>
        <Button
          type='submit'
          label='Registrieren'
          isLoading={isLoading}
          isSuccessful={isSuccessful}
          isError={isError}
          disabled={isButtonDisabled()}
        />
        {(errorMessage || successMessage) && (
          <p
            className={`text-sm mt-2 text-center ${
              errorMessage ? 'text-red-500' : 'text-green-500'
            }`}
          >
            {errorMessage || successMessage}
          </p>
        )}
      </div>
    </form>
  );
};
