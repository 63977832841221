import React from 'react';

import './BlockContainer.css';

interface IBlockContainer {
  children: JSX.Element;
  outerClasses?: string;
  innerClasses?: string;
}

const BlockContainer = ({
  children,
  outerClasses,
  innerClasses,
}: IBlockContainer) => (
  <div className={`block-outer ${outerClasses}`}>
    <div className={`block-inner ${innerClasses}`}>{children}</div>
  </div>
);

BlockContainer.defaultProps = {
  outerClasses: '',
  innerClasses: '',
};

export default BlockContainer;
