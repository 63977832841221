export enum ESearchfieldVariant {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum ESearchfieldSize {
  SMALL = 'small',
  REGULAR = 'regular',
}

export enum ESearchfieldDirection {
  TO_LEFT = 'toLeft',
  TO_RIGHT = 'toRight',
}
