import { z } from 'zod';

import ZSuluMedia from './ZSuluMedia';

const ZSCBTile = z.object({
  id: z.string().uuid(),
  content: z.object({
    title: z.string(),
    url: z.string(),
    page_color: z.string(),
    tile_background_image: ZSuluMedia,
  }),
});

export default ZSCBTile;
