import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import Logo from 'assets/images/logo.svg';

import Search from 'components/primitives/Search';
import ProfileButton from 'components/static/ProfileButton';

import { ESearchfieldVariant } from 'types/enums/ESearch';
import { ISidebar } from 'types/interfaces/ISidebar';

import { DEFAULT_SCHEME_COLOR } from 'utils/constants';
import navigateTo from 'utils/navigateTo';
import getProfile from 'utils/sulu/getProfile';

import LogoutButton from './LogoutButton';
import './Navigation.css';
import createMenuItems from './utils/createMenuItems';

export default ({
  menuItems,
  schemeColor = DEFAULT_SCHEME_COLOR,
  activeLinkUrl = '',
  showLogoutButton = false,
}: ISidebar) => {
  const { width } = useWindowSize();
  const [showSidebar, setShowSidebar] = useState(false);
  const [searchfieldIsOpen, setSearchfieldIsOpen] = useState<boolean>(false);
  const [hoverMenuItemId, setHoverMenuItemId] = useState<string>('');
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  const checkAuth = () => {
    getProfile().then(profile => setIsAuthenticated(!!profile));
  };

  useEffect(() => {
    checkAuth();
    document.addEventListener('login:success', checkAuth);
  }, []);

  const handleToggleSearchfield = (isOpen: boolean) => {
    setSearchfieldIsOpen(isOpen);
  };

  return (
    <>
      <div className='sticky top-0 z-50 grid grid-cols-2 md:grid-cols-3 items-center w-full h-14 px-5 py-2 bg-white shadow-md'>
        <button
          type='button'
          onClick={() => setShowSidebar(!showSidebar)}
          className={`relative text-black w-8 h-8 focus:outline-none transition-opacity ease-in-out duration-300 ${
            !searchfieldIsOpen || width >= 375
              ? 'opacity-100'
              : 'opacity-0 pointer-events-none'
          }`}
        >
          <span className='sr-only'>Open sidebar</span>
          <span
            aria-hidden='true'
            className={`sidebar-menu-button ${
              showSidebar ? 'rotate-45 translate-y-1' : '-translate-y-1.5'
            }`}
          />
          <span
            aria-hidden='true'
            className={`sidebar-menu-button ${showSidebar ? 'opacity-0' : ''}`}
          />
          <span
            aria-hidden='true'
            className={`sidebar-menu-button ${
              showSidebar ? '-rotate-45 -translate-y-1' : 'translate-y-1.5'
            }`}
          />
        </button>
        <Logo
          className={`hidden md:block place-self-center h-full cursor-pointer transition-opacity ease-in-out duration-300 ${
            !searchfieldIsOpen || width >= 950
              ? 'opacity-100'
              : 'opacity-0 pointer-events-none'
          }`}
          onClick={() => navigateTo('')}
        />
        <div className='place-self-end flex items-center gap-4'>
          <Search
            variant={ESearchfieldVariant.DARK}
            onToggleSearchfield={handleToggleSearchfield}
          />
          <ProfileButton />
        </div>
      </div>

      {/* backdrop */}
      <div
        className={`fixed z-30 top-14 left-0 w-full h-full bg-black/75 ease-in-out duration-300 ${
          showSidebar ? 'opacity-100 visible' : 'opacity-0 invisible'
        }`}
      />
      <div
        className={`sidebar-container ${
          !showLogoutButton || !isAuthenticated ? 'pb-8' : ''
        } ${showSidebar ? 'translate-x-0 ' : '-translate-x-full'}`}
      >
        <div className='flex flex-col h-full'>
          <div className='flex-1 flex flex-col px-5 overflow-y-auto'>
            {createMenuItems(
              menuItems,
              schemeColor,
              activeLinkUrl,
              hoverMenuItemId,
              setHoverMenuItemId,
              () => setShowSidebar(false),
            )}
          </div>
          {showLogoutButton && isAuthenticated && <LogoutButton />}
        </div>
      </div>
    </>
  );
};
