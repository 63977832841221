import React from 'react';

interface ILoadingSpinner {
  // eslint-disable-next-line react/require-default-props
  size?: string;
  // eslint-disable-next-line react/require-default-props
  color?: string;
  // eslint-disable-next-line react/require-default-props
  addClass?: string;
}

export default ({
  size = 'w-6 h-6',
  color = 'border-black/50',
  addClass = '',
}: ILoadingSpinner) => (
  <div
    className={`${size} animate-spin flex border-2 ${color} border-l-transparent rounded-full ${addClass}`}
  />
);
