// eslint-disable-next-line import/prefer-default-export
export enum ERoute {
  SETTINGS = 'einstellungen',
  ALL_CONTENTS = 'alle-inhalte',
  MY_LIBRARY = 'meine-bibliothek',
  DELETE_ACCOUNT = 'account-loeschen',
  SEARCH_RESULTS = 'suchergebnisse',
  DATA_PRIVACY = 'datenschutz',
  CONSULTANT_REGISTRATION = 'berater/registrierung',
  CONSULTANT_WELCOME = 'berater/willkommen',
  CONSULTANT_PROFILE = 'berater/profil',
  CONSULTANT_ARTICLES_OVERVIEW = 'berater/artikel-uebersicht',
  CONSULTANT_BUYED_ARTICLES = 'berater/gekaufte-inhalte',
  CONSULTANT_CREATE_ARTICLE = 'berater/beitrag-erstellen',
  CONSULTANT_NEW_ARTICLE = 'berater/beitrag-erstellen/neuer-beitrag',
  CONSULTANT_OVERVIEW = 'berater/uebersicht',
  CONSULTANT_ARTICLE = 'berater/artikel',
  CONSULTANT_DELETE_ACCOUNT = 'berater/account-loeschen',
}
