import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-use';

import EditIcon from 'assets/icons/editPen.svg';
import LocationDotIcon from 'assets/icons/locationDot.svg';
import SpeechBubbleIcon from 'assets/icons/speechBubble.svg';
import TrashIcon from 'assets/icons/trashCan.svg';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import { Button, ButtonLink } from 'components/static/Button';
import { IConsultantArticleTile } from 'components/static/ConsultantArticleTile';
import ImpressumForm from 'components/static/ImpressumForm';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';
import NoDataText from 'components/static/NoDataText';
import ShowMoreTile from 'components/static/ShowMoreTile';
import SimpleWysiwyg from 'components/static/SimpleWysiwyg';

import { ERoute } from 'types/enums/ERoute';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { IConsultantAllArticlesResponse } from 'types/interfaces/sulu/IConsultantApi';
import {
  IConsultantCompany,
  IConsultantDetails,
  IUser,
} from 'types/interfaces/sulu/IUser';

import createConsultantArticleTiles from 'utils/createConsultantArticleTiles';
import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import currentLanguage from 'utils/sulu/currentLanguage';
import getAllConsultantArticles from 'utils/sulu/getAllConsultantArticles';
import getConsultant from 'utils/sulu/getConsultant';

import { EConsultantNewArticleType } from '../types/enums/EConsultant';
import getProfile from '../utils/sulu/getProfile';

export default () => {
  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const consultantId = searchParams.get(EUrlSearchParam.CONSULTANT_ID) ?? '';

  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [consultantDetails, setConsultantDetails] = useState<
    IConsultantDetails | undefined
  >(undefined);
  const [company, setCompany] = useState<IConsultantCompany | undefined>(
    undefined,
  );
  const [articleTiles, setArticleTiles] = useState<IConsultantArticleTile[]>(
    [],
  );
  const [isLoggedInConsultant, setIsLoggedInConsultant] =
    useState<boolean>(false);
  const [authChecked, setAuthChecked] = useState<boolean>(false);
  const [impressumIsInEditMode, setImpressumIsInEditMode] =
    useState<boolean>(false);
  const [googleMapsSrc, setGoogleMapsSrc] = useState<string>('');
  const [isThisMyProfile, setIsThisMyProfile] = useState<boolean>(false);
  const checkAuth = () => {
    if (consultantId) {
      getConsultant(parseInt(consultantId, 10)).then(profile => {
        setUser(profile);
        setAuthChecked(true);
        getProfile().then(myProfile => {
          if (myProfile?.id === profile?.id) setIsThisMyProfile(true);
        });
      });
    } else {
      setAuthChecked(true);
    }
  };

  useEffect(() => {
    console.log('isLoggedInConsultant', isLoggedInConsultant);
    console.log('isThisMyProfile', isThisMyProfile);
  }, [isLoggedInConsultant, isThisMyProfile]);

  const updateMenuItems = () => {
    const sidebarType = isLoggedInConsultant
      ? ESidebarType.CONSULTANT
      : ESidebarType.DEFAULT;
    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      sidebarType,
      [],
      consultantId,
    );
    setMenuItems(sidebarMenuItems);
  };

  useEffect(() => {
    setLoading(true);
    updateMenuItems();
    checkAuth();
    document.addEventListener('login:success', checkAuth);
    if (consultantId) {
      getAllConsultantArticles(parseInt(consultantId, 10), 3).then(
        (articles: IConsultantAllArticlesResponse[]) => {
          const tiles = articles.map(article => ({
            id: article.id || '',
            title: article.title || '',
            description: article.description || 'Keine Beschreibung',
            imageUrl: article.photo || '',
            contentType:
              article.category as unknown as EConsultantNewArticleType, // Type assertion
            url: `/${currentLanguage}/${ERoute.CONSULTANT_ARTICLE}?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}&${EUrlSearchParam.ARTICLE_ID}=${article.id}`,
          }));
          setArticleTiles(tiles);
        },
      );
    }
  }, []);

  useEffect(() => {
    if (authChecked) {
      if (user) {
        if (
          user.consultantDetails !== undefined &&
          user.id.toString() === consultantId
        ) {
          setIsLoggedInConsultant(true);
        } else {
          setIsLoggedInConsultant(false);
        }
        setConsultantDetails(
          'consultantDetails' in user ? user.consultantDetails : undefined,
        );
        setCompany(
          'consultantDetails' in user && user.consultantDetails
            ? user.consultantDetails.company
            : undefined,
        );
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  }, [user, authChecked]);

  useEffect(() => {
    updateMenuItems();
  }, [isLoggedInConsultant]);

  useEffect(() => {
    if (company) {
      const address = `${company.street}, ${company.plz} ${company.ort}, ${company.bundesland}, ${company.staat}`;
      setGoogleMapsSrc(
        `https://maps.google.com/maps?width=520&height=400&hl=en&q=${encodeURIComponent(
          address,
        )}&t=&z=12&ie=UTF8&iwloc=B&output=embed`,
      );
    }
  }, [company]);

  const handleImpressumSubmit = () => {
    setImpressumIsInEditMode(false);
    checkAuth();
  };

  return (
    <Layout menuItems={menuItems} showLogoutButton={isLoggedInConsultant}>
      {loading ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <div className='max-w-5xl mx-auto'>
          <BlockContainer>
            {user && consultantDetails ? (
              <>
                {/* TODO check mobile order */}
                <div className='relative grid grid-cols-1 md:grid-cols-4 xl:grid-cols-5 gap-x-10 gap-y-6'>
                  <div className='col-span-1 flex flex-col'>
                    <div className='grid grid-col-2 md:grid-cols-5 gap-x-10 gap-y-6'>
                      <div className='col-span-2 md:col-span-1 flex flex-col'>
                        {/* TODO update src and add condition for profile image */}
                        <div
                          className={`bg-center bg-no-repeat bg-cover pt-[133%] rounded-xl${
                            false
                              ? ' !bg-gradient-to-t !from-[#B5B5B5] !to-[#E4E3E5]'
                              : ''
                          }`}
                          style={{
                            backgroundImage: `url(https://loremflickr.com/320/240)`,
                          }}
                        />
                        <h4 className='text-xl font-bold mt-5'>Sprachen</h4>
                        <p className='text-sm mt-2'>
                          {consultantDetails.languages.map(language => (
                            <span key={language} className='block'>
                              {language}
                            </span>
                          ))}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`col-span-1 md:col-span-3 flex flex-col${
                      isLoggedInConsultant ? ' pr-20' : ''
                    }`}
                  >
                    {consultantDetails.focalPoint && (
                      <p className='text-sm font-semibold text-orange'>
                        {consultantDetails.focalPoint}
                      </p>
                    )}
                    <h2 className='font-bold mb-5'>
                      {user.firstName || ''} {user.lastName || ''}
                    </h2>
                    {consultantDetails.myOffer && (
                      <SimpleWysiwyg
                        content={consultantDetails.myOffer || ''}
                      />
                    )}
                    {consultantDetails.focalPoints &&
                      consultantDetails.focalPoints.length > 0 && (
                        <>
                          <h4 className='text-xl font-semibold mt-5'>
                            Schwerpunkte
                          </h4>
                          <ul className='mt-5 mb-0 ml-3'>
                            {consultantDetails.focalPoints.map(focalPoint => (
                              <li
                                className='text-sm mt-2 first:mt-0'
                                key={focalPoint}
                              >
                                {focalPoint}
                              </li>
                            ))}
                          </ul>
                          {!isThisMyProfile && (
                            <div className='mt-8'>
                              <ButtonLink
                                label='Kontakt aufnehmen'
                                url={`mailto:${user.email}`}
                              />
                            </div>
                          )}
                        </>
                      )}

                    <div className='col-span-1 flex justify-end' />
                  </div>
                  {isThisMyProfile && (
                    <div>
                      <Button icon={<EditIcon className='icon' />} />
                      <br />
                      <ButtonLink
                        icon={<TrashIcon className='icon' />}
                        url={`/${currentLanguage}/${ERoute.CONSULTANT_DELETE_ACCOUNT}?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}`}
                      />
                    </div>
                  )}
                </div>

                {/* Company details */}
                {company && (
                  <div className='bg-blue-light px-9 py-5 mt-10 rounded-xl'>
                    <h2 className='font-bold'>{company.companyName}</h2>
                    <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-10 gap-y-6 mt-7'>
                      <div className='col-span-1'>
                        <div className='flex gap-4'>
                          <LocationDotIcon className='mt-1' />
                          <div className='flex flex-col'>
                            <h4 className='text-xl font-semibold'>Adresse</h4>
                            <div className='block text-sm'>
                              {company.companyName}
                              <br />
                              {company.street}
                              <br />
                              {company.plz}, {company.ort}
                              <br />
                              {company.bundesland}
                              <br />
                              {company.staat}
                            </div>
                          </div>
                        </div>
                        <div className='flex gap-4 mt-7'>
                          <SpeechBubbleIcon className='mt-1' />
                          <div className='flex flex-col'>
                            <h4 className='text-xl font-semibold'>Kontakt</h4>
                            {company.telephone && (
                              <a
                                href={`tel:${company.telephone}`}
                                className='block text-sm mt-2'
                              >
                                {company.telephone}
                              </a>
                            )}
                            {company.email && (
                              <a
                                href={`mailto:${company.email}`}
                                className='block text-sm'
                              >
                                {company.email}
                              </a>
                            )}
                            {company.website && (
                              <a
                                href={company.website}
                                target='_blank'
                                rel='noreferrer'
                                className='block text-sm'
                              >
                                {company.website}
                              </a>
                            )}
                          </div>
                        </div>
                        {company.barrierFree !== undefined && (
                          <div className='mt-7 ml-10'>
                            <h4 className='text-xl font-semibold'>
                              Barrierefrei
                            </h4>
                            <p className='text-sm mt-1'>
                              {company.barrierFree ? 'Ja' : 'Nein'}
                            </p>
                          </div>
                        )}
                      </div>

                      {/* Google maps */}
                      <div className='col-span-1 xl:col-span-2 p-4'>
                        {googleMapsSrc && (
                          <iframe
                            title='Google Maps'
                            width='100%'
                            height='100%'
                            id='gmap_canvas'
                            src={googleMapsSrc}
                            className='rounded-xl ml-6 md:ml-0'
                          />
                        )}
                      </div>
                    </div>
                    {consultantDetails.categories.length > 0 && (
                      <div className='mt-7 ml-10'>
                        <h4 className='text-xl font-semibold'>
                          Wir beraten zu
                        </h4>
                        <div className='flex flex-wrap gap-2 mt-1'>
                          {consultantDetails.categories.map(category => (
                            <span
                              className='bg-white rounded-full px-5'
                              key={category}
                            >
                              {category}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {/* Articles */}
                <div>
                  <h2 className='font-bold mt-10'>
                    Artikel von {user.firstName} {user.lastName}
                  </h2>
                  <div className='flex flex-wrap gap-5 mt-5'>
                    <ShowMoreTile
                      text='Zeige alle Artikel'
                      url={`/${currentLanguage}/${ERoute.CONSULTANT_ARTICLES_OVERVIEW}?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}`}
                    />
                    {createConsultantArticleTiles(
                      isLoggedInConsultant,
                      articleTiles,
                    )}
                  </div>
                </div>

                {/* Impressum */}
                <div className='mt-10 p-4 bg-grey-light rounded-xl'>
                  <div className='flex items-center justify-between mb-5'>
                    <h2 className='font-bold'>Impressum</h2>
                    {isThisMyProfile && (
                      <Button
                        icon={<EditIcon />}
                        onClick={() => setImpressumIsInEditMode(true)}
                      />
                    )}
                  </div>
                  <ImpressumForm
                    isInEditMode={impressumIsInEditMode}
                    impressumFields={user.impressum}
                    onSubmitDone={handleImpressumSubmit}
                  />
                </div>
              </>
            ) : (
              <NoDataText>Beraterprofil konnte nicht geladen werden</NoDataText>
            )}
          </BlockContainer>
        </div>
      )}
      <Footer />
    </Layout>
  );
};
