import { z } from 'zod';

import {
  ESuluLayoutBlockTypes,
  ESuluLayoutBlockVariant,
} from 'types/enums/sulu/EBackendData';
import { ZSuluContentBlock } from 'types/interfaces/sulu/IContentBlocks';
import ZSCBBase from 'types/interfaces/sulu/contentBlocks/ZSCBBase';

const ZSLBTwoColumns = ZSCBBase.extend({
  type: z.literal(ESuluLayoutBlockTypes.TWO_COLUMNS),
  variant: z.nativeEnum(ESuluLayoutBlockVariant),
  left: z.array(ZSuluContentBlock),
  right: z.array(ZSuluContentBlock),
});

export default ZSLBTwoColumns;
