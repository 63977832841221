import {
  AdvancedMarker,
  APIProvider,
  Map,
  Pin,
  InfoWindow,
} from '@vis.gl/react-google-maps';
import React, { useState, useEffect } from 'react';

import { ERoute } from '../types/enums/ERoute';
import { EUrlSearchParam } from '../types/enums/EUrlSearchParam';
import mapOptions from './mapOptions';
import currentLanguage from './sulu/currentLanguage';
import { geocodeAddress, googleMapsApiKey } from './sulu/geocoding';

interface IGoogleMapProps {
  addresses: string[];
  defaultCenter: string;
}

// Define the shape of a Point of Interest (POI)
interface Poi {
  key: string;
  location: google.maps.LatLngLiteral;
  address: string;
}

const GoogleMap = ({ addresses, defaultCenter }: IGoogleMapProps) => {
  const [geoLocations, setGeoLocation] = useState<Poi[]>([]);
  const [selectedPoi, setSelectedPoi] = useState<Poi | null>(null);
  const [centerLocation, setCenterLocation] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [isBrowserLocation, setIsBrowserLocation] = useState<boolean>(false);

  // The fallback address when neither defaultCenter nor browser location is available
  const fallbackAddress = 'Welt des Erbens WDE GmbH Moorweg 24582 Bordesholm';

  // Function to set the default center to browser's location
  const setBrowserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords;
        setCenterLocation({ lat: latitude, lng: longitude });
        setIsBrowserLocation(true); // Mark that browser location is being used
      });
    }
  };

  // Geocoding for the defaultCenter or use the browser's location if empty
  useEffect(() => {
    if (defaultCenter) {
      geocodeAddress(defaultCenter).then(location => {
        if (location) setCenterLocation(location);
      });
    } else {
      setBrowserLocation(); // Use browser location if defaultCenter is empty
    }
  }, [defaultCenter]);

  // If no defaultCenter or browser location, use fallback address
  useEffect(() => {
    if (!centerLocation && !isBrowserLocation) {
      geocodeAddress(fallbackAddress).then(location => {
        if (location) setCenterLocation(location);
      });
    }
  }, [centerLocation, isBrowserLocation]);

  // Geocoding for all addresses
  useEffect(() => {
    addresses.forEach(async address => {
      const location = await geocodeAddress(address);
      if (location) {
        setGeoLocation(prevGeoLocations => [
          ...prevGeoLocations,
          { key: address, location, address },
        ]);
      }
    });
  }, [addresses]);

  if (!centerLocation) return <p>Lade Karte...</p>;

  return (
    <div
      style={{
        height: '100%',
        minHeight: '200px',
        width: '100%',
        borderRadius: '8px',
        overflow: 'hidden',
      }}
    >
      <APIProvider apiKey={googleMapsApiKey}>
        <Map
          defaultZoom={12}
          center={centerLocation || defaultCenter}
          mapId='7cfbc4bf44e5f81e'
          {...mapOptions}
          // gestureHandling='none'
        >
          <AdvancedMarker
            key='defaultCenter'
            position={centerLocation}
            onClick={() =>
              setSelectedPoi({
                key: 'defaultCenter',
                location: centerLocation,
                address: isBrowserLocation
                  ? 'Ihr Standort'
                  : defaultCenter || fallbackAddress,
              })
            }
          >
            <Pin background='#066EBA' glyphColor='white' borderColor='white' />
          </AdvancedMarker>

          {geoLocations.map(poi => (
            <AdvancedMarker
              key={poi.key}
              position={poi.location}
              onClick={() => setSelectedPoi(poi)}
            >
              <Pin
                background='#ff9821'
                glyphColor='white'
                borderColor='white'
              />
            </AdvancedMarker>
          ))}

          {selectedPoi && (
            <InfoWindow
              position={selectedPoi.location}
              onCloseClick={() => setSelectedPoi(null)}
            >
              <div className='h-full p-3 flex flex-col justify-between'>
                {selectedPoi.key === 'defaultCenter' ? (
                  <p className='whitespace-pre-line'>{selectedPoi.address}</p>
                ) : (
                  <div>
                    <p className='mb-2 whitespace-pre-line'>
                      {selectedPoi.address
                        .replace(/Id=\d+/, '')
                        .replace(/_/g, ' - ')
                        .split(', ')
                        .filter(part => !part.includes('Id='))
                        .map((part, index) => {
                          let content;
                          if (index === 0) {
                            content = (
                              <div>
                                <p style={{ fontWeight: 'bolder' }}>{part}</p>
                              </div>
                            );
                          } else if (index <= 2) {
                            content = (
                              <div>
                                {part}
                                <br />
                              </div>
                            );
                          } else if (index === 3) {
                            content = (
                              <div>
                                <p
                                  style={{
                                    marginTop: '1rem',
                                    fontWeight: 'bolder',
                                  }}
                                >
                                  Tätigkeitsfelder:
                                </p>
                                <ul
                                  style={{ marginTop: '0', marginLeft: '1rem' }}
                                >
                                  <li>{part}</li>
                                </ul>
                              </div>
                            );
                          } else {
                            content = null;
                          }
                          return <div key={part}>{content}</div>;
                        })}
                    </p>
                    {selectedPoi.address.match(/Id=(\d+)/) && (
                      <a
                        href={`/${currentLanguage}/${
                          ERoute.CONSULTANT_PROFILE
                        }?${EUrlSearchParam.CONSULTANT_ID}=${
                          selectedPoi.address.match(/Id=(\d+)/)?.[1]
                        }`}
                        className='text-orange-500 underline cursor-pointer self-end'
                      >
                        Profil anzeigen
                      </a>
                    )}
                  </div>
                )}
              </div>
            </InfoWindow>
          )}
        </Map>
      </APIProvider>
    </div>
  );
};

export default GoogleMap;
