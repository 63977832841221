import React from 'react';

import { IButton } from 'types/interfaces/IButton';

import './Button.css';

interface IButtonFilter
  extends IButton,
    React.ComponentPropsWithoutRef<'button'> {
  isSelected: boolean;
}

export default ({
  onClick = () => {},
  label = '',
  isSelected,
  ...rest
}: IButtonFilter) => (
  <button
    type='button'
    onClick={onClick}
    className={`px-2 rounded-md ${
      isSelected
        ? 'text-white bg-orange hover:bg-gradient-to-r hover:from-orange-gradient-from to-orange-gradient-to'
        : 'text-black hover:text-orange'
    }`}
    {...rest}
  >
    {label}
  </button>
);
