import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-use';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import { ButtonLink, ButtonInvoice } from 'components/static/Button';
import CheckPaymentLoading from 'components/static/CheckPaymentLoading';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';
import Payment from 'components/static/Payment';

import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import {
  EPaymentStatus,
  EPaymentUrlQuery,
} from 'types/enums/mollie/EMollieData';
import {
  ESuluLayoutBlockTypes,
  ESuluNavigationContexts,
} from 'types/enums/sulu/EBackendData';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import {
  IMolliePaymentObject,
  IMollieUserPayment,
} from 'types/interfaces/mollie/IMollieApiData';
import { IDetailPage } from 'types/interfaces/sulu/IDetailPage';
import { IUser } from 'types/interfaces/sulu/IUser';

import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import GlossaryLinks from 'utils/glossaryLinks';
import { getPaymentStatus, getUserPayments } from 'utils/mollie/api';
import showPaymentErrorNotification from 'utils/mollie/showPaymentErrorNotification';
import showPaymentSuccessNotification from 'utils/mollie/showPaymentSuccessNotification';
import notify from 'utils/notify';
import suluBlockHandler from 'utils/sulu/blockHandler';
import currentLanguage from 'utils/sulu/currentLanguage';
import getNavigation from 'utils/sulu/getNavigation';
import getPageContent from 'utils/sulu/getPageContent';
import getProfile from 'utils/sulu/getProfile';
import suluLayoutHandler from 'utils/sulu/layoutHandler';

import getUserArticles, {
  IUserArticle,
} from '../../utils/sulu/getUserArticles';

export default ({ id, content }: IDetailPage) => {
  const {
    main_category: mainCategory,
    content: contentData,
    content_blocks: contentBlocks,
    text,
    text_hover: description,
    price,
  } = content;
  const { content: mainCategoryContent } = mainCategory || {};
  const { page_color: pageColor } = mainCategoryContent || {};
  let contentUrl = '';

  if (contentData) {
    contentUrl = contentData.url;
  }

  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const category = searchParams.get(EUrlSearchParam.CATEGORY) ?? '';
  const redirectedFromCheckout =
    searchParams.get(EPaymentUrlQuery.REDIRECT) ?? '';
  const checkoutCanceled = searchParams.get(EPaymentUrlQuery.CANCEL) ?? '';
  const [schemeColor, setSchemeColor] = useState<string>(pageColor || '');
  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IUser>();
  const [userPayment, setUserPayment] = useState<IMollieUserPayment>();
  const [alreadyPaid, setAlreadyPaid] = useState<boolean>(false);
  const [paymentWillBeChecked, setPaymentWillBeChecked] =
    useState<boolean>(false);
  const checkPaymentInterval = useRef<NodeJS.Timer>();
  const [checkPaymentIntervalCounter, setCheckPaymentIntervalCounter] =
    useState<number>(0);
  const [userArticles, setUserArticles] = useState<IUserArticle[]>([]);

  const INTERVAL_TIMEOUT = 5000; // 5 seconds
  const REQUEST_LIMIT = 6; // max. wait of 30 seconds

  const clearCheckPaymentInterval = () => {
    if (checkPaymentInterval.current) {
      clearInterval(checkPaymentInterval.current as unknown as number);
    }
  };

  const checkAuth = () => {
    getProfile().then(profile => setUser(profile || undefined));
  };

  const handlePaymentNotSuccessful = () => {
    clearCheckPaymentInterval();
    setPaymentWillBeChecked(false);
    showPaymentErrorNotification({
      message:
        'Der Bezahlvorgang konnte nicht erfolgreich abgeschlossen werden. Bitte versuchen Sie es erneut.',
    });
  };

  const handlePaymentSuccessful = () => {
    localStorage.setItem(`article-${id}-pac`, '1');
    clearCheckPaymentInterval();
    setPaymentWillBeChecked(false);
    setAlreadyPaid(true);
    showPaymentSuccessNotification();
  };

  const checkPaymentStatus = () => {
    if (!userPayment || !Object.keys(userPayment).length) {
      handlePaymentNotSuccessful();
      return;
    }

    getPaymentStatus(userPayment.id).then((response: IMolliePaymentObject) => {
      const { status } = response;

      if (!Object.keys(response).length || status !== EPaymentStatus.PAID) {
        if (checkPaymentIntervalCounter === REQUEST_LIMIT) {
          handlePaymentNotSuccessful();
        }
        return;
      }

      handlePaymentSuccessful();
    });
  };

  useEffect(() => {
    setLoading(true);

    const promises = [
      getPageContent(category ? `/${category}` : ''),
      getNavigation(ESuluNavigationContexts.INFORMATION),
      getNavigation(ESuluNavigationContexts.ENTERTAINMENT),
      getNavigation(ESuluNavigationContexts.CONSULTING),
      getProfile(),
      getUserPayments(),
      localStorage.getItem(`article-${id}-pac`),
      localStorage.getItem(`article-${id}-ccac`),
    ];

    Promise.all(promises).then(res => {
      const pageContent = res[0];
      const subMenuItems = [res[1], res[2], res[3]];
      const profile = res[4];
      const { payment: payments } = res[5];
      const payment: IMollieUserPayment =
        payments && payments.length
          ? payments.findLast((p: IMollieUserPayment) => p.articleId === id)
          : {};
      const paymentAlreadyChecked = res[6];
      const checkoutCanceledAlreadyChecked = res[7];

      const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
        ESidebarType.TOPICS,
        subMenuItems.flat(),
      );
      setMenuItems(sidebarMenuItems);
      if (pageContent && pageContent.page_color) {
        setSchemeColor(pageContent.page_color);
      }
      setUser(profile || undefined);
      setUserPayment(payment);
      setLoading(false);

      checkPaymentInterval.current = setInterval(() => {
        setCheckPaymentIntervalCounter(prevCounter => prevCounter + 1);
      }, INTERVAL_TIMEOUT);

      if (!paymentAlreadyChecked && redirectedFromCheckout) {
        setPaymentWillBeChecked(true);
        setCheckPaymentIntervalCounter(1);
      } else {
        clearCheckPaymentInterval();
      }

      if (checkoutCanceled && !checkoutCanceledAlreadyChecked) {
        localStorage.setItem(`article-${id}-ccac`, '1');
        notify('Der Bezahlvorgang wurde abgebrochen.');
      }
    });

    document.addEventListener('login:success', checkAuth);

    return () => clearCheckPaymentInterval();
  }, []);

  useEffect(() => {
    if (checkPaymentIntervalCounter > 0) {
      checkPaymentStatus();
    }
  }, [checkPaymentIntervalCounter]);

  useEffect(() => {
    if (user) {
      const userArticle = user.articles.find(
        article => article.articleIdentifier === id,
      );
      const articleAvailable = !!userArticle;
      setAlreadyPaid(articleAvailable);
    }
  }, [user]);

  const renderContent = () => {
    if (!contentBlocks || !contentBlocks.length) {
      return null;
    }

    return contentBlocks.map((block, i) => {
      if (
        block.type === ESuluLayoutBlockTypes.TWO_COLUMNS ||
        block.type === ESuluLayoutBlockTypes.THREE_COLUMNS
      ) {
        return suluLayoutHandler(block, i);
      }

      return suluBlockHandler(block, i);
    });
  };

  useEffect(() => {
    const fetchUserArticles = async () => {
      const fetchedUserArticles = await getUserArticles();
      setUserArticles(fetchedUserArticles);
    };

    fetchUserArticles().then();
  }, [user]);

  return (
    <Layout
      menuItems={menuItems}
      schemeColor={schemeColor}
      activeLinkUrl={category ? `/${currentLanguage}/${category}` : ''}
    >
      {loading ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <div className='2xl:max-w-[75%] mx-auto bg-grey-background'>
          <BlockContainer outerClasses='lg:pb-7'>
            <>
              <h2 className='font-bold' style={{ color: schemeColor }}>
                {text}
              </h2>
              <p style={{ color: schemeColor }}>{description}</p>
              <GlossaryLinks />
            </>
          </BlockContainer>

          {renderContent()}

          <BlockContainer
            outerClasses={`last-block${
              contentBlocks && contentBlocks.length ? ' lg:pt-7' : ''
            }`}
          >
            <>
              <div className='flex justify-center'>
                {!alreadyPaid &&
                  !paymentWillBeChecked &&
                  price &&
                  price !== '0' && (
                    <div className='flex flex-col items-center'>
                      <Payment articleId={id} price={price} user={user} />
                      {!user && contentUrl && (
                        <p className='text-sm text-black/50 text-center mt-2'>
                          Wenn Sie diesen Artikel bereits gekauft haben,
                          <br />
                          müssen Sie sich anmelden, um ihn herunterzuladen
                        </p>
                      )}
                    </div>
                  )}
                {paymentWillBeChecked && <CheckPaymentLoading />}
                {contentUrl && (alreadyPaid || !price || price === '0') && (
                  <ButtonLink
                    url={contentUrl}
                    label='Herunterladen'
                    target='_blank'
                    rel='noreferrer noopener'
                    download
                  />
                )}
              </div>
              <div className='flex justify-center mt-4'>
                {alreadyPaid && (
                  <ButtonInvoice
                    title={text || ''}
                    price={price || ''}
                    firstName={user?.firstName || ''}
                    lastName={user?.lastName || ''}
                    email={user?.email || ''}
                    paymentDate={
                      userArticles.find(
                        article => article.articleIdentifier === id,
                      )?.paymentDate || new Date().toLocaleDateString('de-DE')
                    }
                    quantity={1}
                    label='Rechnung Herunterladen'
                  />
                )}
              </div>
            </>
          </BlockContainer>
        </div>
      )}
      <Footer />
    </Layout>
  );
};
