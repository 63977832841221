import React, { useEffect, useState } from 'react';

import { ISSHero } from 'types/interfaces/sulu/ISnippets';

import getHero from 'utils/sulu/getHero';
import suluSnippetHandler from 'utils/sulu/snippetHandler';

export default () => {
  const [heroData, setHeroData] = useState<ISSHero>({} as ISSHero);

  useEffect(() => {
    getHero().then(res => setHeroData(res));
  }, []);

  return (
    <>
      {/* render hero */}
      {suluSnippetHandler(heroData)}
    </>
  );
};
