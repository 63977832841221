import React, { useEffect, useState } from 'react';

import { EButtonSize, EButtonType } from 'types/enums/EButton';
import { IButton } from 'types/interfaces/IButton';

import {
  getButtonSizeStyles,
  getButtonTypeStyles,
} from 'utils/getButtonStyles';

import './Button.css';

interface IButtonLink extends IButton, React.ComponentPropsWithoutRef<'a'> {
  url: string;
}

export default ({
  url,
  icon = undefined,
  label = undefined,
  showIconOnDesktop = false,
  buttonType = EButtonType.PRIMARY,
  buttonSize = EButtonSize.REGULAR,
  disabled = false,
  ...rest
}: IButtonLink) => {
  const [showIcon, setShowIcon] = useState<boolean>(showIconOnDesktop);

  useEffect(() => {
    if (!label) {
      setShowIcon(true);
    }
  }, []);

  return (
    <button
      type='button'
      className={`button${
        buttonSize === EButtonSize.SMALL ? ' button-small' : ''
      }`}
      disabled={disabled}
    >
      <a
        href={url || '#'}
        className={`${getButtonTypeStyles({
          buttonType,
        })} ${getButtonSizeStyles({
          icon,
          label,
          buttonSize,
        })} flex items-center justify-center text-center no-underline rounded-full transition-all ease-in-out duration-300 ${
          disabled && 'pointer-events-none opacity-50'
        }`}
        {...rest}
      >
        {icon && (
          <span
            className={`${!showIcon ? 'lg:hidden' : ''} ${
              label ? 'lg:mr-2' : ''
            } ${
              buttonType === EButtonType.SECONDARY ? 'fill-black' : 'fill-white'
            }`}
          >
            {icon}
          </span>
        )}
        {label && (
          <span className={`${icon ? 'hidden lg:inline' : ''}`}>{label}</span>
        )}
      </a>
    </button>
  );
};
