import React from 'react';

import BlockContainer from 'components/BlockContainer';
import Menu from 'components/primitives/Menu';
import Footer from 'components/snippets/Footer';
import Hero from 'components/snippets/Hero';

import { ISPMyWorld } from 'types/interfaces/sulu/IPages';

export default ({ content }: ISPMyWorld) => {
  const { title } = content;

  return (
    <>
      <Hero />
      {/* <SearchConsultant /> */}
      <Menu />
      {/* render content */}
      <BlockContainer>
        <div className='flex items-center justify-center flex-wrap gap-5'>
          my world here
          <br />
          {title}
        </div>
      </BlockContainer>
      <Footer />
    </>
  );
};
