import { EButtonSize, EButtonType } from 'types/enums/EButton';
import { IButton } from 'types/interfaces/IButton';

const getButtonTypeStyles = ({ buttonType }: IButton): string => {
  if (buttonType === EButtonType.PRIMARY) {
    return 'bg-orange text-white hover:bg-gradient-to-r hover:from-orange-gradient-from to-orange-gradient-to hover:text-white';
  }

  if (buttonType === EButtonType.SECONDARY) {
    return 'bg-transparent text-orange hover:bg-white';
  }

  if (buttonType === EButtonType.TERTIARY) {
    return 'bg-grey-dark text-black hover:bg-gradient-to-r hover:from-grey-light to-grey-dark hover:text-black';
  }

  return '';
};

const getButtonSizeStyles = ({ icon, label, buttonSize }: IButton): string => {
  let styles: string = '';

  if (label) {
    styles = icon
      ? 'w-10 lg:w-auto h-10 lg:h-auto lg:px-6'
      : 'w-auto h-auto px-6';

    if (buttonSize === EButtonSize.SMALL) {
      styles += ' text-sm';
      styles += ' lg:py-1.5';
      if (!icon) {
        styles += ' py-2.5';
      }
    } else {
      styles += ' lg:py-3';
      if (!icon) {
        styles += ' py-2';
      }
    }
  } else {
    styles = 'w-10 h-10';
    styles +=
      buttonSize === EButtonSize.SMALL ? ' lg:w-8 lg:h-8' : ' lg:w-12 lg:h-12';
  }

  return styles;
};

export { getButtonTypeStyles, getButtonSizeStyles };
