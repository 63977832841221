import React, { Fragment } from 'react';

import { ISidebarMenuItem } from 'types/interfaces/ISidebar';

import createSubMenuItems from './createSubMenuItems';

const isActive = (url: string): boolean =>
  `${window.location.pathname}${window.location.search}` === url;

export default (
  menuItems: ISidebarMenuItem[],
  schemeColor: string,
  activeLinkUrl: string,
  hoverMenuItemId: string,
  setHoverMenuItemId: Function,
  handleClick?: Function,
) =>
  menuItems.map(menuItem => {
    const { url, icon, label, subMenuItems } = menuItem;

    return subMenuItems && subMenuItems.length > 0 ? (
      <Fragment key={label}>
        <div className='flex items-center text-black font-bold py-3'>
          <span className='sub-menu-items-icon mr-4'>{icon}</span>
          <span>{label}</span>
        </div>
        {createSubMenuItems(
          subMenuItems,
          schemeColor,
          activeLinkUrl,
          hoverMenuItemId,
          setHoverMenuItemId,
          handleClick,
        )}
      </Fragment>
    ) : (
      <a
        key={label}
        href={url}
        className={`sidebar-parent-link flex items-center hover:text-black font-bold py-3 ${
          isActive(url || '') ? 'is-active text-black' : 'text-black/50'
        }`}
        onClick={() => {
          if (handleClick) {
            handleClick();
          }
        }}
      >
        {icon}
        <span>{label}</span>
      </a>
    );
  });
