// eslint-disable-next-line import/no-extraneous-dependencies
import { toJS } from 'mobx';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-use';
import { ZodFirstPartySchemaTypes } from 'zod/lib/types';

import BlockContainer from 'components/BlockContainer';
import Tile from 'components/blocks/Tile';
import Menu from 'components/primitives/Menu';
// import SearchConsultant from 'components/primitives/Search/SearchConsultant';
import Footer from 'components/snippets/Footer';
import Hero from 'components/snippets/Hero';
import { Button } from 'components/static/Button';
import Dialog from 'components/static/Dialog';

import { CorruptBlock } from 'pages';

// import { ERoute } from 'types/enums/ERoute';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { ISCBTile } from 'types/interfaces/sulu/IContentBlocks';
import { ISPHomepage } from 'types/interfaces/sulu/IPages';
import ZSCBTile from 'types/interfaces/sulu/contentBlocks/ZSCBTile';

// import navigateTo from 'utils/navigateTo';
import getProfile from 'utils/sulu/getProfile';

export default ({ content }: ISPHomepage) => {
  const { categories } = content;

  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const registrationSuccessful =
    searchParams.get(EUrlSearchParam.REGISTRATION_SUCCESSFUL) ?? '';
  const consultantRegistrationSuccessful =
    searchParams.get(EUrlSearchParam.CONSULTANT_REGISTRATION_SUCCESSFUL) ?? '';
  const emailConfirmation =
    searchParams.get(EUrlSearchParam.EMAIL_CONFIRMATION) ?? '';
  const noConsultantNotification =
    searchParams.get(EUrlSearchParam.NO_CONSULTANT) ?? '';

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [showRegistrationSuccessDialog, setShowRegistrationSuccessDialog] =
    useState(false);
  const [
    showConsultantRegistrationSuccessDialog,
    setShowConsultantRegistrationSuccessDialog,
  ] = useState(false);
  const [showEmailConfirmationDialog, setShowEmailConfirmationDialog] =
    useState(false);
  const [
    showNoConsultantNotificationDialog,
    setShowNoConsultantNotificationDialog,
  ] = useState(false);
  // const [filterId, setFilterId] = useState<number[]>([]);
  // const [searchTriggered, setSearchTriggered] = useState(false);

  // const navigate = navigateTo;

  useEffect(() => {
    getProfile().then(profile => setIsLoggedIn(!!profile));
    setShowRegistrationSuccessDialog(!!registrationSuccessful);
    setShowConsultantRegistrationSuccessDialog(
      !!consultantRegistrationSuccessful,
    );
    setShowEmailConfirmationDialog(!!emailConfirmation);
    setShowNoConsultantNotificationDialog(!!noConsultantNotification);
  }, []);

  const parseCategoryData = (
    categoryData: ISCBTile,
    zodType: ZodFirstPartySchemaTypes,
  ): boolean => {
    const categoryDataJS = toJS(categoryData);
    const zodParsed = zodType.safeParse(categoryDataJS);
    if (!zodParsed.success) {
      localStorage.setItem(
        `homepage-createCategories-${categoryData.content.title}-${Date.now()}`,
        JSON.stringify(zodParsed.error.issues),
      );
    }
    return zodParsed.success;
  };

  const createCategories = () =>
    categories.map(category =>
      parseCategoryData(category, ZSCBTile) ? (
        <Tile key={category.id} {...category} />
      ) : (
        <CorruptBlock />
      ),
    );

  /*  TODO: production
    useEffect(() => {
    if (searchTriggered && filterId.length >= 0) {
      navigate(`${ERoute.CONSULTANT_OVERVIEW}`);
    }
  }, [searchTriggered]);
    */

  return (
    <>
      <Hero />
      {/* TODO: production
      <div className='xl:grid xl:grid-cols-12 gap-4 bg-blue-light p-4 items-center'>
        <div className='xl:col-span-3'>
          <div className='xl:text-right text-center'>
            <p className='text-xl'>Sie suchen einen Berater?</p>
          </div>
        </div>
        <div className='xl:col-span-9'>
          <SearchConsultant
            setFilterId={setFilterId}
            setSearchTriggered={setSearchTriggered}
            hideAdvancedSearch
          />
        </div>
      </div>
      */}
      <Menu />
      {/* render content */}
      <div className='bg-grey-background'>
        <BlockContainer>
          <div className='flex items-center justify-center flex-wrap gap-5'>
            {createCategories()}
          </div>
        </BlockContainer>
      </div>
      <Footer />
      <Dialog
        isOpen={showRegistrationSuccessDialog}
        handleToggle={setShowRegistrationSuccessDialog}
        title='Bestätigung'
        headline='Registrierung'
        dialogPanelClassName='lg:min-w-[800px] min-w-[80%]'
        titleClassName='md:text-5xl text-3xl'
        content={
          <div className='flex flex-col justify-center items-center text-center'>
            <h3 className='text-orange mt-3'>Vielen Dank!</h3>
            <p className='mb-8 lg:px-24'>
              Wir haben Ihr Profil erhalten und werden Ihre Eingaben prüfen! Sie
              erhalten eine E-Mail, sobald Ihr Profil auf Welt des Erbens
              freigegeben wurde.
            </p>
            <Button
              type='button'
              onClick={() => setShowRegistrationSuccessDialog(false)}
              label='Schließen'
            />
          </div>
        }
      />
      <Dialog
        isOpen={showConsultantRegistrationSuccessDialog}
        handleToggle={setShowConsultantRegistrationSuccessDialog}
        title='Bestätigung'
        headline='Registrierung'
        dialogPanelClassName='lg:min-w-[800px] min-w-[80%]'
        titleClassName='md:text-5xl text-3xl'
        content={
          <div className='flex flex-col justify-center items-center text-center'>
            <h3 className='text-orange mt-3'>Vielen Dank!</h3>
            <p className='mb-8 lg:px-24'>
              Ihr Profil als Berater wurde erfolgreich aktiviert.
            </p>
            <Button
              type='button'
              onClick={() => setShowConsultantRegistrationSuccessDialog(false)}
              label='Schließen'
            />
          </div>
        }
      />
      <Dialog
        isOpen={showEmailConfirmationDialog}
        handleToggle={setShowEmailConfirmationDialog}
        title='Bestätigt'
        headline='E-Mail Adresse'
        dialogPanelClassName='lg:min-w-[800px] min-w-[80%]'
        titleClassName='md:text-5xl text-3xl'
        content={
          <div className='flex flex-col justify-center items-center text-center'>
            <h3 className='text-orange mt-3'>Vielen Dank!</h3>
            <p className='mb-8 lg:px-24'>
              Sie haben Ihre E-Mail Adresse bei Welt des Erbens erfolgreich
              bestätigt.
            </p>
            <Button
              type='button'
              onClick={() => setShowEmailConfirmationDialog(false)}
              label='Schließen'
            />
          </div>
        }
      />
      <Dialog
        isOpen={showNoConsultantNotificationDialog}
        handleToggle={setShowNoConsultantNotificationDialog}
        headline={isLoggedIn ? 'Hinweis' : 'Anmeldung'}
        title={isLoggedIn ? 'Zugriff verweigert' : 'Erforderlich'}
        dialogPanelClassName='lg:min-w-[800px] min-w-[80%]'
        titleClassName='md:text-5xl text-3xl'
        content={
          <div className='flex flex-col justify-center items-center text-center'>
            <h3 className='text-orange mt-3'>Information</h3>
            <p className='mb-8 lg:px-24'>
              {isLoggedIn ? (
                <>
                  Sie wurden umgeleitet, da Sie entweder kein Berater sind oder
                  dieses Beraterprofil nicht Ihnen gehört.
                  <br />
                  Bitte überprüfen Sie Ihre Zugangsdaten und versuchen Sie es
                  erneut.
                </>
              ) : (
                <>
                  Sie wurden umgeleitet, da Sie nicht angemeldet waren.
                  <br />
                  Bitte melden Sie sich an, um fortzufahren.
                </>
              )}
            </p>
            <Button
              type='button'
              onClick={() => setShowNoConsultantNotificationDialog(false)}
              label='Schließen'
            />
          </div>
        }
      />
    </>
  );
};
