import React from 'react';
import { useWindowSize } from 'react-use';

import { ETailwindBreakpoint } from 'types/enums/ETailwindBreakpoint';
import { ISearchComponent } from 'types/interfaces/ISearchComponent';

import getTailwindBreakpointAsNumber from 'utils/getTailwindBreakpointAsNumber';

import SearchDesktop from './SearchDesktop';
import SearchMobile from './SearchMobile';

export default ({
  variant,
  size,
  direction,
  onToggleSearchfield,
}: ISearchComponent) => {
  const { width } = useWindowSize();

  return width < getTailwindBreakpointAsNumber(ETailwindBreakpoint.lg) ? (
    <SearchMobile
      variant={variant}
      direction={direction}
      onToggleSearchfield={onToggleSearchfield}
    />
  ) : (
    <SearchDesktop variant={variant} size={size} />
  );
};
