import React from 'react';

import LoadingSpinner from 'components/static/LoadingSpinner';

export default () => (
  <div className='fixed z-[60] top-0 left-0 flex items-center justify-center w-full h-full bg-black/75'>
    <div className='flex flex-col items-center justify-center bg-white rounded-xl p-5'>
      <LoadingSpinner size='w-10 h-10' />
      <p className='mt-5 text-center'>Überprüfe Bezahlvorgang</p>
    </div>
  </div>
);
