import { ISearch } from 'types/interfaces/sulu/ISearch';

import currentLanguage from './currentLanguage';

export default async (
  query: string,
  indices: string = 'page_juramuseum_published',
): Promise<ISearch[]> => {
  const response = await fetch(
    `/${currentLanguage}/api/search?q=${query}&indices=${indices}`,
  );
  if (response.ok) {
    const jsonResponse = await response.json();
    // eslint-disable-next-line no-underscore-dangle
    return jsonResponse._embedded.hits;
  }
  return [];
};
