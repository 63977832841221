import React from 'react';

import ErrorIcon from 'assets/icons/error.svg';

import LoadingSpinner from 'components/static/LoadingSpinner';

interface IButtonPayment extends React.ComponentPropsWithoutRef<'button'> {
  onClick: () => void;
  price: string;
  // eslint-disable-next-line react/require-default-props
  label?: string;
  // eslint-disable-next-line react/require-default-props
  isLoading?: boolean;
  // eslint-disable-next-line react/require-default-props
  isError?: boolean;
}

export default ({
  onClick,
  price,
  label = 'Jetzt kaufen',
  isLoading = false,
  isError = false,
  ...rest
}: IButtonPayment) => (
  <button
    type='button'
    className={`flex items-center ${
      rest.disabled ? 'pointer-events-none opacity-50' : ''
    }`}
    onClick={onClick}
    {...rest}
  >
    <div
      className={`flex items-center justify-center text-center text-white px-6 lg:px-10 py-3 rounded-full transition-all ease-in-out duration-300 z-10 ${
        isError
          ? 'bg-red-500 hover:bg-red-400'
          : 'bg-orange hover:bg-gradient-to-r hover:from-orange-gradient-from to-orange-gradient-to'
      }`}
    >
      <span>{label}</span>
      <span className='inline-block ml-2'>
        {isLoading && <LoadingSpinner color='border-white' />}
        {isError && <ErrorIcon className='icon fill-white' />}
      </span>
    </div>
    <div
      className={`flex flex-col rounded-full py-0.5 pr-6 pl-12 -ml-10 ${
        isError ? 'bg-red-400' : 'bg-orange/80'
      }`}
    >
      <span className='text-xs text-white'>Nur</span>
      <span className='text-sm leading-4 font-semibold text-white'>
        {price}&nbsp;€
      </span>
      <span className='text-[0.5rem] leading-[0.75rem] text-white/75'>
        inkl. MwSt.
      </span>
    </div>
  </button>
);
