export enum ESearchfieldVariant {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum ESearchfieldSize {
  SMALL = 'small',
  REGULAR = 'regular',
}

export enum ESearchfieldDirection {
  TO_LEFT = 'toLeft',
  TO_RIGHT = 'toRight',
}

export const CATEGORY_OPTIONS = [
  'Rechtsanwalt',
  'Steuerberater',
  'Estate Planner',
  'Notar',
  'Coach',
  'Testamentsvollstrecker',
  'Immobilien-Sachverständige',
  'Immobilien-Makler',
  'Versicherungs-Makler',
  'Sterbebegleitung',
  'Bestatter',
  'Steinmetz',
];

export const LANGUAGE_OPTIONS = [
  'Deutsch',
  'Englisch',
  'Französisch',
  'Spanisch',
  'Niederländisch',
  'Afrikaans',
  'Albanisch',
  'Amharisch',
  'Arabisch',
  'Armenisch',
  'Aserbaidschanisch',
  'Baskisch',
  'Bengalisch',
  'Birmanisch',
  'Bosnisch',
  'Bulgarisch',
  'Cebuano',
  'Chichewa',
  'Chinesisch',
  'Dänisch',
  'Esperanto',
  'Estnisch',
  'Filipino',
  'Finnisch',
  'Friesisch',
  'Galizisch',
  'Georgisch',
  'Griechisch',
  'Gujarati',
  'Haitianisch',
  'Hausa',
  'Hawaiisch',
  'Hebräisch',
  'Hindi',
  'Hmong',
  'Igbo',
  'Indonesisch',
  'Irisch',
  'Isländisch',
  'Italienisch',
  'Japanisch',
  'Javanisch',
  'Jiddisch',
  'Kannada',
  'Kasachisch',
  'Katalanisch',
  'Khmer',
  'Kinyarwanda',
  'Kirgisisch',
  'Koreanisch',
  'Kroatisch',
  'Kurdisch',
  'Laotisch',
  'Latein',
  'Lettisch',
  'Litauisch',
  'Luxemburgisch',
  'Madagassisch',
  'Malaiisch',
  'Malayalam',
  'Maltesisch',
  'Maori',
  'Marathi',
  'Mongolisch',
  'Nepalesisch',
  'Norwegisch',
  'Odia (Oriya)',
  'Paschtu',
  'Persisch',
  'Polnisch',
  'Portugiesisch',
  'Punjabi',
  'Rumänisch',
  'Russisch',
  'Samoanisch',
  'Schottisches Gälisch',
  'Schwedisch',
  'Serbisch',
  'Sesotho',
  'Shona',
  'Sindhi',
  'Singhalesisch',
  'Slowakisch',
  'Slowenisch',
  'Somali',
  'Suaheli',
  'Sundanesisch',
  'Tadschikisch',
  'Tamil',
  'Telugu',
  'Thailändisch',
  'Tschechisch',
  'Türkisch',
  'Ukrainisch',
  'Ungarisch',
  'Urdu',
  'Usbekisch',
  'Vietnamesisch',
  'Walisisch',
  'Weißrussisch',
  'Xhosa',
  'Yoruba',
  'Zulu',
];

export const FOCAL_POINTS_OPTION = [
  'Abwicklungstestamentsvollstreckung',
  'Auslandsvermögen',
  'Betriebswirtschaftliche Beratung',
  'Eheerrecht',
  'Einkommensteuer',
  'Erbschaftsteuer',
  'Erbrecht',
  'Familienstrategie',
  'Family Office',
  'Gesellschaftsgründungen',
  'Gesellschaftsrecht',
  'Gesundheits-Coaching',
  'Immobilien',
  'Immobilienbesteuerung',
  'Immobilienrecht',
  'Insolvenzberatung',
  'Internationales Privatrecht',
  'Internationales Steuerrecht',
  'Kommunikations-Coaching',
  'Land-/Forstwirtschaft',
  'Liquiditätsberechnungen',
  'Mediation',
  'Mental-Coaching',
  'Nachfolge-Coaching',
  'Paar-Coaching',
  'Pflichtteile',
  'Private Nachfolgeplanung',
  'Projekt-Coaching',
  'Schenkungen',
  'Stiftungen',
  'Stiftungsrecht',
  'Streitvermeidung',
  'Testamente',
  'Testamentsvollstrecker eines Behindertentestaments',
  'Testamentsvollstrecker eines Bedürftigentestamentes',
  'Testamentsvollstreckung bei Pflichtteilsbeschränkung',
  'Testamentsvollstreckung bzgl. Land-/Forstwirtschaft',
  'Testamentsvollstreckung bzgl. Unternehmen',
  'Testamentsvollstreckung mit Auslandsbezug',
  'Testamentsvollstreckung mit Schwerpunkt Steuern',
  'Unternehmensbesteuerung',
  'Unternehmer-Coaching',
  'Unternehmerische Nachfolgeplanung',
  'Vorsorgedokumente',
  'Werte-Coaching',
  'Ziele-Coaching',
];

export const COUNTRY_OPTIONS = [
  'Deutschland',
  'Österreich',
  'Schweiz',
  'Spanien',
  'Frankreich',
  'Niederlande',
  'Afghanistan',
  'Albanien',
  'Algerien',
  'Andorra',
  'Angola',
  'Antigua und Barbuda',
  'Argentinien',
  'Armenien',
  'Aserbaidschan',
  'Australien',
  'Ägypten',
  'Äquatorialguinea',
  'Äthiopien',
  'Bahamas',
  'Bahrain',
  'Bangladesch',
  'Barbados',
  'Belgien',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivien',
  'Bosnien und Herzegowina',
  'Botswana',
  'Brasilien',
  'Brunei',
  'Bulgarien',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Chile',
  'China',
  'Costa Rica',
  'Côte d’Ivoire',
  'Dominica',
  'Dominikanische Republik',
  'Dschibuti',
  'Dänemark',
  'Ecuador',
  'El Salvador',
  'Eritrea',
  'Estland',
  'Eswatini',
  'Fidschi',
  'Finnland',
  'Gabun',
  'Gambia',
  'Georgien',
  'Ghana',
  'Grenada',
  'Griechenland',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heiliger Stuhl',
  'Honduras',
  'Indien',
  'Indonesien',
  'Irak',
  'Iran',
  'Irland',
  'Island',
  'Israel',
  'Italien',
  'Jamaika',
  'Japan',
  'Jemen',
  'Jordanien',
  'Kambodscha',
  'Kamerun',
  'Kanada',
  'Kasachstan',
  'Kenia',
  'Kirgisistan',
  'Kiribati',
  'Kolumbien',
  'Komoren',
  'Kongo, Demokratische Republik',
  'Kongo, Republik',
  'Korea, Nord',
  'Korea, Süd',
  'Kosovo',
  'Kroatien',
  'Kuba',
  'Kuwait',
  'Laos',
  'Lesotho',
  'Lettland',
  'Liberia',
  'Libyen',
  'Liechtenstein',
  'Litauen',
  'Luxemburg',
  'Madagaskar',
  'Malawi',
  'Malaysia',
  'Malediven',
  'Mali',
  'Malta',
  'Marokko',
  'Marshallinseln',
  'Mauretanien',
  'Mauritius',
  'Mexiko',
  'Mikronesien, Föderierte Staaten von',
  'Moldawien',
  'Monaco',
  'Mongolei',
  'Montenegro',
  'Mosambik',
  'Myanmar (Burma)',
  'Namibia',
  'Nauru',
  'Nepal',
  'Neuseeland',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Nordmazedonien',
  'Norwegen',
  'Oman',
  'Pakistan',
  'Palau',
  'Palästina',
  'Panama',
  'Papua-Neuguinea',
  'Paraguay',
  'Peru',
  'Philippinen',
  'Polen',
  'Portugal',
  'Rumänien',
  'Russland',
  'Ruanda',
  'Saint Kitts und Nevis',
  'Saint Lucia',
  'Saint Vincent und die Grenadinen',
  'Salomonen',
  'Sambia',
  'Samoa',
  'San Marino',
  'Saudi-Arabien',
  'Schweden',
  'Senegal',
  'Serbien',
  'Seychellen',
  'Sierra Leone',
  'Simbabwe',
  'Singapur',
  'Slowakei',
  'Slowenien',
  'Somalia',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Syrien',
  'São Tomé und Príncipe',
  'Südafrika',
  'Südsudan',
  'Tadschikistan',
  'Taiwan',
  'Tansania',
  'Thailand',
  'Togo',
  'Tonga',
  'Trinidad und Tobago',
  'Tschad',
  'Tschechische Republik',
  'Tunesien',
  'Turkmenistan',
  'Tuvalu',
  'Türkei',
  'Uganda',
  'Ukraine',
  'Ungarn',
  'Uruguay',
  'Usbekistan',
  'Vanuatu',
  'Venezuela',
  'Vereinigte Arabische Emirate',
  'Vereinigte Staaten von Amerika',
  'Vereinigtes Königreich',
  'Vietnam',
  'Weißrussland',
  'Zentralafrikanische Republik',
  'Zypern',
];
