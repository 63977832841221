import { IUser } from 'types/interfaces/sulu/IUser';

export default async (): Promise<IUser[] | null> => {
  const response = await fetch('/consultants');

  if (response.ok) {
    try {
      const jsonResponse = await response.json();
      return jsonResponse.data;
    } catch (e) {
      return [];
    }
  }

  return [];
};
