import React, { FormEvent, useEffect, useId, useState } from 'react';
import { useLocation } from 'react-use';

import ChevronDownIcon from 'assets/icons/chevron-down.svg';
import Location from 'assets/icons/location.svg';
import UserIcon from 'assets/icons/user.svg';

import { Button } from 'components/static/Button';

import { IUser } from 'types/interfaces/sulu/IUser';

import GoogleMap from 'utils/GoogleMap';
import searchRadius from 'utils/searchRadius';
import getConsultant from 'utils/sulu/getConsultant';
import getConsultantList from 'utils/sulu/getConsultantList';

interface Props {
  setFilterId: (ids: number[]) => void;
  setSearchTriggered: (triggered: boolean) => void;
  hideAdvancedSearch: boolean;
}

const saveToSessionStorage = (key: string, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

const getFromSessionStorage = (key: string, defaultValue: any) => {
  const stored = sessionStorage.getItem(key);
  return stored ? JSON.parse(stored) : defaultValue;
};

export default ({
  setFilterId,
  setSearchTriggered,
  hideAdvancedSearch,
}: Props) => {
  // Initialization of state with sessionStorage values
  const [consultantIds, setConsultantIds] = useState<number[]>(
    getFromSessionStorage('consultantIds', []),
  );
  const [consultantsList, setConsultantsList] = useState<IUser[] | null>(
    getFromSessionStorage('consultantsList', null),
  );
  const [searchActive, setSearchActive] = useState<boolean>(
    getFromSessionStorage('searchActive', false),
  );
  const [category, setCategory] = useState(
    getFromSessionStorage('category', ''),
  );
  const [address, setAddress] = useState(getFromSessionStorage('address', ''));
  const [radius, setRadius] = useState<number>(
    getFromSessionStorage('radius', 2),
  );
  const [companyOrName, setCompanyOrName] = useState(
    getFromSessionStorage('companyOrName', ''),
  );
  const [languages, setLanguages] = useState(
    getFromSessionStorage('languages', ''),
  );
  const [focalPoints, setFocalPoints] = useState(
    getFromSessionStorage('focalPoints', ''),
  );
  const [country, setCountry] = useState(getFromSessionStorage('country', ''));
  const [advancedSearch, setAdvancedSearch] = useState(
    getFromSessionStorage('advancedSearch', false),
  );
  const [hasArticle, setHasArticle] = useState(
    getFromSessionStorage('hasArticle', false),
  );
  const [barrierFree, setBarrierFree] = useState(
    getFromSessionStorage('barrierFree', false),
  );
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState<string[]>([]);
  const [openGoogleMaps, setOpenMapIfram] = useState(false);
  const [hideFromHomepage, setHideFromHomepage] = useState(false);
  const [consultantsFound, setConsultantsFound] = useState<number[]>([]);
  const [optionsCategories, setOptionsCategories] = useState<string[]>([]);
  const [optionsLanguages, setOptionsLanguages] = useState<string[]>([]);
  const [optionsFocalPoints, setOptionsFocalPoints] = useState<string[]>([]);
  const [optionsCountries, setOptionsCountries] = useState<string[]>([]);

  const categorySelectId = useId();
  const cityInputId = useId();
  const radiusSelectId = useId();
  const companyOrNameId = useId();
  const languagesId = useId();
  const focalPointsId = useId();
  const countryId = useId();
  const hasArticleId = useId();
  const barrierFreeId = useId();

  // Save the values in sessionStorage every time they change
  useEffect(() => {
    saveToSessionStorage('consultantIds', consultantIds);
    saveToSessionStorage('consultantsList', consultantsList);
    saveToSessionStorage('searchActive', searchActive);
    saveToSessionStorage('category', category);
    saveToSessionStorage('address', address);
    saveToSessionStorage('radius', radius);
    saveToSessionStorage('companyOrName', companyOrName);
    saveToSessionStorage('languages', languages);
    saveToSessionStorage('focalPoints', focalPoints);
    saveToSessionStorage('country', country);
    saveToSessionStorage('advancedSearch', advancedSearch);
    saveToSessionStorage('hasArticle', hasArticle);
    saveToSessionStorage('barrierFree', barrierFree);
  }, [
    consultantIds,
    consultantsList,
    searchActive,
    category,
    address,
    radius,
    companyOrName,
    languages,
    focalPoints,
    country,
    advancedSearch,
    hasArticle,
    barrierFree,
  ]);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname?.toLowerCase().includes('uebersicht'.toLowerCase())) {
      setHideFromHomepage(true);
    } else {
      setHideFromHomepage(false);
    }
  }, [location]);

  const advancedSearchHandler = () => {
    if (!hideAdvancedSearch) {
      setAdvancedSearch(!advancedSearch);
    }
  };

  const filterConsultantsByRadius = async (
    consultant: IUser,
  ): Promise<boolean> => {
    const consultantAddress =
      consultant?.consultantDetails?.company?.ort ??
      consultant?.consultantDetails?.company?.plz ??
      '';
    if (!consultantAddress) {
      return false;
    }

    const distance = await searchRadius(address, consultantAddress);

    return distance <= radius;
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true); // Set loading to true when submitting the form

    try {
      if (!consultantsList) return;

      // Filter consultants by radius asynchronously
      const consultantsWithinRadius = await Promise.all(
        consultantsList.map(async consultant => {
          const isWithinRadius = await filterConsultantsByRadius(consultant);
          return isWithinRadius ? consultant : null;
        }),
      );

      const filteredConsultants = consultantsWithinRadius
        .filter(consultant => !!consultant)
        .filter(consultant => {
          if (!category) {
            return true;
          }

          return consultant?.consultantDetails?.categories?.includes(category);
        })
        .filter(consultant => {
          if (!companyOrName) {
            return true;
          }

          return (
            consultant?.consultantDetails?.company?.companyName
              ?.toLowerCase()
              .includes(companyOrName.toLowerCase()) ||
            consultant?.firstName
              ?.toLowerCase()
              .includes(companyOrName.toLowerCase()) ||
            consultant?.lastName
              ?.toLowerCase()
              .includes(companyOrName.toLowerCase())
          );
        })
        .filter(consultant => {
          if (!languages) {
            return true;
          }

          return consultant?.consultantDetails?.languages?.includes(languages);
        })
        .filter(consultant => {
          if (!focalPoints) {
            return true;
          }

          return consultant?.consultantDetails?.focalPoints?.some(
            fp =>
              fp?.split('_').pop()?.toLowerCase() === focalPoints.toLowerCase(),
          );
        })
        .filter(consultant => {
          if (!country) {
            return true;
          }

          return consultant?.consultantDetails?.countries?.some(
            c => c.toLowerCase() === country.toLowerCase(),
          );
        })
        .filter(consultant => {
          if (!barrierFree) {
            return true;
          }

          return (
            consultant?.consultantDetails?.company?.barrierFree === barrierFree
          );
        });

      // Get the filtered IDs
      const filteredIds = filteredConsultants.map(
        consultant => consultant?.id || 0,
      );

      // Pass the filtered IDs to the parent component
      setSearchTriggered(true);
      setSearchActive(true);
      setFilterId(filteredIds);
      setConsultantsFound(filteredIds);
      setLoading(false);
      sessionStorage.clear();
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hideFromHomepage && consultantsList) {
      handleSubmit(
        new Event('submit') as unknown as FormEvent<HTMLFormElement>,
      ).then();
    }
  }, [hideFromHomepage]);

  useEffect(() => {
    getConsultantList().then(consultants => {
      if (consultants) {
        const ids = consultants.map(consultant => consultant.id);
        setConsultantIds(ids);

        Promise.all(ids.map(id => getConsultant(id))).then(profiles => {
          setConsultantsList(profiles as []);
        });
      }
    });
  }, []);

  useEffect(() => {
    const geoLocations =
      consultantsList
        ?.filter(consultant => !!consultant?.consultantDetails?.company)
        .map(consultant => {
          const { firstName, lastName, consultantDetails, id } = consultant;
          const { company } = consultantDetails || {};
          const { street, plz, ort } = company || {};
          return `${firstName} ${lastName}, ${street}, ${plz} ${ort}, ${consultantDetails?.focalPoints}, Id=${id}`;
        }) ?? [];
    setAddresses(geoLocations);

    const optionCategories =
      consultantsList
        ?.filter(consultant => !!consultant?.consultantDetails?.categories)
        .flatMap(consultant => {
          const { consultantDetails } = consultant;
          return consultantDetails?.categories || [];
        })
        .filter(optionCategory => optionCategory.length > 1) // Filtert Wörter mit weniger als 2 Zeichen aus
        .filter(
          (optionCategory, index, self) =>
            self.indexOf(optionCategory) === index,
        ) ?? // Entfernt doppelte Einträge
      [];

    const optionLanguages =
      consultantsList
        ?.filter(consultant => !!consultant?.consultantDetails?.languages)
        .flatMap(consultant => {
          const { consultantDetails } = consultant;
          return consultantDetails?.languages || [];
        })
        .filter(optionLanguage => optionLanguage.length > 1) // Filtert Wörter mit weniger als 2 Zeichen aus
        .filter(
          (optionLanguage, index, self) =>
            self.indexOf(optionLanguage) === index,
        ) ?? // Entfernt doppelte Einträge
      [];

    const optionFocalPoints =
      consultantsList
        ?.filter(consultant => !!consultant?.consultantDetails?.focalPoints)
        .flatMap(consultant => {
          const { consultantDetails } = consultant;
          return consultantDetails?.focalPoints || [];
        })
        .filter(optionFocalPoint => optionFocalPoint.length > 1) // Filtert Wörter mit weniger als 2 Zeichen aus
        .filter(
          (optionFocalPoint, index, self) =>
            self.indexOf(optionFocalPoint) === index,
        ) ?? // Entfernt doppelte Einträge
      [];

    const optionCountries =
      consultantsList
        ?.filter(consultant => !!consultant?.consultantDetails?.countries)
        .flatMap(consultant => {
          const { consultantDetails } = consultant;
          return consultantDetails?.countries || [];
        })
        .filter(optioCountry => optioCountry.length > 1) // Filtert Wörter mit weniger als 2 Zeichen aus
        .filter(
          (optioCountry, index, self) => self.indexOf(optioCountry) === index,
        ) ?? // Entfernt doppelte Einträge
      [];

    setOptionsCategories(optionCategories);
    setOptionsLanguages(optionLanguages);
    setOptionsFocalPoints(optionFocalPoints);
    setOptionsCountries(optionCountries);
  }, [consultantsList]);

  return (
    <div className='w-full flex-warp gap-4 lg:gap-6'>
      {/* Loading Indicator */}
      {loading && (
        <div
          className='absolute inset-0 bg-white bg-opacity-75 flex justify-center items-start'
          style={{ zIndex: 10 }}
        >
          <h2 className='text-orange-500 mt-32'>Suche Berater ...</h2>
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className='2xl:grid 2xl:grid-cols-6 2xl:w-3/4 2xl:justify-between'>
          <div className='xl:col-span-5 xl:border form grid xl:grid-cols-9 md:grid-cols-2 grid-cols-1 gap-2 xl:rounded-full rounded-xl xl:bg-white'>
            <label
              htmlFor={categorySelectId}
              className='relative xl:col-span-3 xl:ml-2'
            >
              <UserIcon className='absolute top-1/2 left-4 -translate-y-1/2 w-4 h-4' />
              <select
                id={categorySelectId}
                className='xl:border-none border rounded-xl !mt-1 !pl-9'
                placeholder='Wen suchen Sie?'
                aria-label='Wen suchen Sie?'
                value={category}
                onChange={e => setCategory(e.target.value)}
              >
                <option value=''>Wen suchen Sie?</option>
                {optionsCategories.map(option => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <ChevronDownIcon className='absolute top-1/2 right-4 -translate-y-1/2' />
            </label>
            <label htmlFor={cityInputId} className='relative xl:col-span-2'>
              <Location className='absolute top-1/2 left-4 -translate-y-1/2' />
              <input
                id={cityInputId}
                placeholder='Ort oder PLZ'
                aria-label='Ort oder PLZ'
                className='xl:border-none border rounded-xl  !mt-1 !pl-9'
                value={address}
                onChange={e => setAddress(e.target.value)}
              />
            </label>

            <label htmlFor={radiusSelectId} className='relative xl:col-span-2'>
              <select
                id={radiusSelectId}
                aria-label='Umkreissuche'
                className='xl:border-none border rounded-xl  !mt-1 !pl-9'
                value={radius}
                onChange={e => setRadius(Number(e.target.value))}
              >
                <option value='2'>Ganzer Ort</option>
                <option value='5'>5 km</option>
                <option value='10'>10 km</option>
                <option value='25'>25 km</option>
                <option value='50'>50 km</option>
                <option value='100'>100 km</option>
                <option value='200'>200 km</option>
                <option value='500'>500 km</option>
              </select>
              <ChevronDownIcon className='absolute top-1/2 right-4 -translate-y-1/2' />
            </label>

            <Button
              type='submit'
              label='Jetzt Finden'
              addClass='xl:mt-0 xl:rounded-l-none xl:rounded-full rounded-xl flex-1 h-[40px] xl:h-full mt-1 xl:col-span-2'
            />
          </div>
          {hideFromHomepage && (
            <button
              type='button'
              onClick={advancedSearchHandler}
              className='text-orange underline cursor-pointer ml-2 xl:mt-0 mt-2'
            >
              Erweitere Suche {'>'}
            </button>
          )}
        </div>

        {!hideAdvancedSearch && advancedSearch && (
          <div>
            <div className='gap-4 mt-6 grid grid-cols-1 md:grid-cols-2 lg:gap-6 xl:w-3/4'>
              <label htmlFor={companyOrNameId}>
                <div>
                  <input
                    id={companyOrNameId}
                    placeholder='Unternehmen/Name'
                    aria-label='Unternehmen/Name'
                    className='pl-2 p-2 border rounded-xl w-full'
                    value={companyOrName}
                    onChange={e => setCompanyOrName(e.target.value)}
                  />
                </div>
              </label>
              <label htmlFor={languagesId}>
                <div className='relative'>
                  <select
                    id={languagesId}
                    className='py-2 p-2 border rounded-xl w-full appearance-none'
                    placeholder='Sprachen'
                    aria-label='Sprachen'
                    value={languages}
                    onChange={e => setLanguages(e.target.value)}
                  >
                    <option value=''>Sprachen</option>
                    {optionsLanguages.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <ChevronDownIcon className='absolute top-1/2 right-4 -translate-y-1/2' />
                </div>
              </label>

              <label htmlFor={focalPointsId}>
                <div className='relative'>
                  <select
                    id={focalPointsId}
                    className='pl-2 p-2 border rounded-xl w-full appearance-none'
                    placeholder='Schwerpunkte'
                    aria-label='Schwerpunkte'
                    value={focalPoints.replace('_', ' - ')}
                    onChange={e => setFocalPoints(e.target.value)}
                  >
                    <option value=''>Schwerpunkte</option>
                    {optionsFocalPoints.map(option => (
                      <option key={option} value={option.replace('_', ' - ')}>
                        {option.replace('_', ' - ')}
                      </option>
                    ))}
                  </select>
                  <ChevronDownIcon className='absolute top-1/2 right-4 -translate-y-1/2' />
                </div>
              </label>

              <label htmlFor={countryId}>
                <div className='relative'>
                  <select
                    id={countryId}
                    className='pl-2 p-2 border rounded-xl w-full appearance-none'
                    placeholder='Tätigkeitsländer'
                    aria-label='Tätigkeitsländer'
                    value={country}
                    onChange={e => setCountry(e.target.value)}
                  >
                    <option value=''>Tätigkeitsländer</option>
                    {optionsCountries.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  <ChevronDownIcon className='absolute top-1/2 right-4 -translate-y-1/2' />
                </div>
              </label>

              <label htmlFor={hasArticleId}>
                <div className='flex items-center mb-2'>
                  <input
                    id={hasArticleId}
                    type='checkbox'
                    aria-label='Artikel vorhanden'
                    checked={hasArticle}
                    onChange={() => setHasArticle(!hasArticle)}
                    className='mr-2 grow-0 shrink-0'
                  />
                  <span className='font-normal'>Artikel vorhanden</span>
                </div>
              </label>

              <label htmlFor={barrierFreeId}>
                <div className='flex items-center mb-2'>
                  <input
                    id={barrierFreeId}
                    type='checkbox'
                    aria-label='Barrierefrei'
                    checked={barrierFree}
                    onChange={() => setBarrierFree(!barrierFree)}
                    className='mr-2 grow-0 shrink-0'
                  />
                  <span className='font-normal'>Barrierefrei</span>
                </div>
              </label>
            </div>
          </div>
        )}
        {hideFromHomepage && (
          <>
            <div className='flex flex-col md:flex-row md:justify-between mb-2'>
              <div className='mt-6 md:block hidden'>
                {consultantsFound.length > 0 && (
                  <p>
                    {consultantsFound.length}{' '}
                    {consultantsFound.length === 1
                      ? 'passenden Anbieter gefunden'
                      : 'passende Anbieter gefunden'}
                  </p>
                )}
              </div>
              <div>
                <button
                  type='button'
                  onClick={() => setOpenMapIfram(!openGoogleMaps)}
                  className='text-orange underline cursor-pointer mt-2 lg:mt-4 lg:ml-4 flex items-center'
                >
                  <Location className='icon fill-orange text-orange mr-2' />
                  {openGoogleMaps
                    ? 'Karte schließen'
                    : 'Ergebnisse in Karte anzeigen'}
                </button>
              </div>
              <div className='mt-6 md:hidden sm:block'>
                {consultantsFound.length > 0 && (
                  <p>
                    {consultantsFound.length}{' '}
                    {consultantsFound.length === 1
                      ? 'passenden Anbieter gefunden'
                      : 'passende Anbieter gefunden'}
                  </p>
                )}
              </div>
            </div>
            {openGoogleMaps && (
              <div className='h-[552px]'>
                <GoogleMap
                  key='reload' // Toggle key to force re-render
                  addresses={addresses}
                  defaultCenter={address}
                />
              </div>
            )}
          </>
        )}
      </form>
    </div>
  );
};
