// eslint-disable-next-line import/no-extraneous-dependencies
import { toJS } from 'mobx';
import React from 'react';
import { ZodFirstPartySchemaTypes } from 'zod/lib/types';

import FooterSnippet from 'components/snippets/Footer/FooterSnippet';
import HeroSnippet from 'components/snippets/Hero/HeroSnippet';
import LoadingSpinner from 'components/static/LoadingSpinner';

import { CorruptSnippet } from 'pages';

import { ESuluSnippetTypes } from 'types/enums/sulu/EBackendData';
import {
  ISuluSnippet,
  ZSSFooter,
  ZSSHero,
} from 'types/interfaces/sulu/ISnippets';

const parseSnippetData = (
  snippetData: ISuluSnippet,
  zodType: ZodFirstPartySchemaTypes,
): boolean => {
  // MIND: snippetData may have special types ("observableArray") from MobX.
  // The toJS method parses special MobX types to regular JS types.
  const snippetDataJS = toJS(snippetData);
  const zodParsed = zodType.safeParse(snippetDataJS);
  if (!zodParsed.success) {
    localStorage.setItem(
      `snippet-${snippetData.template}-${Date.now()}`,
      JSON.stringify(zodParsed.error.issues),
    );
  }
  return zodParsed.success;
};

export default (snippetData: ISuluSnippet): JSX.Element => {
  const { template } = snippetData;
  switch (template) {
    case ESuluSnippetTypes.HERO:
      return parseSnippetData(snippetData, ZSSHero) ? (
        <HeroSnippet {...snippetData} />
      ) : (
        <CorruptSnippet />
      );
    case ESuluSnippetTypes.FOOTER:
      return parseSnippetData(snippetData, ZSSFooter) ? (
        <FooterSnippet {...snippetData} />
      ) : (
        <CorruptSnippet />
      );
    default:
      return (
        <div className='flex justify-center p-6'>
          <LoadingSpinner size='w-10 h-10' />
        </div>
      );
  }
};
