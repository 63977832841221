import React, { useEffect, useState } from 'react';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';

import { ESidebarType } from 'types/enums/ESidebarType';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { ISPDefault } from 'types/interfaces/sulu/IPages';

import getSidebarMenuItems from 'utils/getSidebarMenuItems';

import { ESuluNavigationContexts } from '../types/enums/sulu/EBackendData';
import GlossaryLinks from '../utils/glossaryLinks';
import getNavigation from '../utils/sulu/getNavigation';

export default ({ content }: ISPDefault) => {
  const { title, article } = content;
  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      getNavigation(ESuluNavigationContexts.INFORMATION),
      getNavigation(ESuluNavigationContexts.ENTERTAINMENT),
      getNavigation(ESuluNavigationContexts.CONSULTING),
    ]).then(subMenuItems => {
      const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
        ESidebarType.TOPICS,
        subMenuItems.flat(),
      );
      setMenuItems(sidebarMenuItems);
    });

    setLoading(false);
  }, []);

  return (
    <Layout menuItems={menuItems}>
      {loading ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <div className='bg-grey-background'>
          <BlockContainer>
            <>
              <h3>{title}</h3>
              <GlossaryLinks />
              <div
                className='article-content mt-8'
                dangerouslySetInnerHTML={{ __html: article }}
              />
            </>
          </BlockContainer>
        </div>
      )}
      <Footer />
    </Layout>
  );
};
