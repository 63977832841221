import React, { useEffect, useId, useState } from 'react';
import { useLocation } from 'react-use';

import TrashIcon from 'assets/icons/trashCan.svg';

import { EButtonType } from 'types/enums/EButton';
import { ERoute } from 'types/enums/ERoute';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { IConsultantArticleResponse } from 'types/interfaces/sulu/IConsultantApi';

import navigateTo from 'utils/navigateTo';
import deleteConsultantArticle from 'utils/sulu/deleteConsultantArticle';

import verifyPassword from '../../../utils/sulu/verifyPassword';
import Dialog from '../Dialog';
import Button from './Button';
import ButtonLink from './ButtonLink';

interface ButtonDeleteArticleProps {
  article: IConsultantArticleResponse;
  consultantId: string;
  articleId: string;
}

const ButtonDeleteArticle: React.FC<ButtonDeleteArticleProps> = ({
  article,
  consultantId,
  articleId,
}) => {
  const [showDeleteArticleDialog, setShowDeleteArticleDialog] =
    useState<boolean>(false);
  const [showPasswortConfirmation, setShowPasswortConfirmation] =
    useState<boolean>(false);
  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const shouldBeDeleted =
    searchParams.get(EUrlSearchParam.CONSULTANT_ARTICLE_SHOULD_BE_DELETED) ??
    '';
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const passwordConfirmInputId = useId();

  const handleTrashClick = () => {
    setShowDeleteArticleDialog(true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('password', passwordConfirmation);

    setIsLoading(true);
    setErrorMessage('');

    try {
      const response = await verifyPassword(formData);

      if (response.status === 200) {
        await deleteConsultantArticle(articleId);
        setIsLoading(false);
        setIsSuccessful(true);
        setSuccessMessage('Der Artikel wurde erfolgreich gelöscht.');
        navigateTo(ERoute.CONSULTANT_ARTICLES_OVERVIEW, [
          { key: EUrlSearchParam.CONSULTANT_ID, value: consultantId },
          { key: EUrlSearchParam.CONSULTANT_ARTICLE_DELETED, value: 'true' },
        ]);
      } else {
        setIsLoading(false);
        setIsError(true);
        setErrorMessage('Das Passwort ist falsch.');
      }
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(
        'Ein Fehler ist beim Überprüfen des Passworts aufgetreten.',
      );
    }
  };

  useEffect(() => {
    setShowDeleteArticleDialog(!!shouldBeDeleted);
  }, []);

  return (
    <>
      <Button
        icon={<TrashIcon className='icon' />}
        onClick={handleTrashClick}
      />

      <Dialog
        isOpen={showDeleteArticleDialog}
        handleToggle={setShowDeleteArticleDialog}
        title='Hinweis!'
        headline='Artikel löschen'
        dialogPanelClassName='lg:min-w-[800px] w-full min-w-[80%]'
        titleClassName='md:text-5xl text-3xl'
        content={
          <div>
            <div className='flex flex-col justify-center items-center text-center'>
              <p className='mb-8 lg:px-24'>
                Bitte laden Sie Ihren Artikel herunter, bevor Sie ihn löschen.
                Sobald der Artikel gelöscht ist, kann er nicht wiederhergestellt
                werden und ist unwiderruflich verloren.
              </p>
            </div>
            <div className='flex flex-col md:flex-row gap-4 justify-between'>
              <Button
                label='Abbrechen'
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  history.back();
                }}
                buttonType={EButtonType.SECONDARY}
              />
              {article.category === 'text' && (
                <ButtonLink
                  label='Artikel herunterladen'
                  target='_blank'
                  rel='noreferrer noopener'
                  download={`${article.title}.txt`}
                  url={`data:text/plain;charset=utf-8,${encodeURIComponent(
                    article.content,
                  )}`}
                  buttonType={EButtonType.SECONDARY}
                />
              )}
              {article.category === 'text' && article.file.url && (
                <ButtonLink
                  label='PDF herunterladen'
                  target='_blank'
                  rel='noreferrer noopener'
                  download={`${article.title}.txt`}
                  url={`data:text/plain;charset=utf-8,${encodeURIComponent(
                    article.content,
                  )}`}
                  buttonType={EButtonType.SECONDARY}
                />
              )}
              {article.file.url && article.category !== 'text' && (
                <ButtonLink
                  label='Artikel herunterladen'
                  target='_blank'
                  rel='noreferrer noopener'
                  download={`${article.title}.txt`}
                  url={article.file.url}
                  buttonType={EButtonType.SECONDARY}
                />
              )}
              {article.video_link && (
                <ButtonLink
                  url={article.video_link}
                  label='Video Ansehen'
                  target='_blank'
                  rel='noreferrer noopener'
                  download
                />
              )}

              <Button
                type='button'
                onClick={() => {
                  setShowPasswortConfirmation(true);
                }}
                label='Artikel löschen'
              />
            </div>
            {showPasswortConfirmation && (
              <div>
                <form
                  className='form flex flex-col mt-9'
                  onSubmit={handleSubmit}
                >
                  <label
                    htmlFor={passwordConfirmInputId}
                    className='place-self-start mt-5'
                  >
                    Bestätigen Sie bitte mit Ihrem Passwort
                    <input
                      id={passwordConfirmInputId}
                      type='password'
                      value={passwordConfirmation}
                      onChange={e => setPasswordConfirmation(e.target.value)}
                      required
                    />
                  </label>
                  <div className='flex flex-col md:flex-row items-center justify-between mt-4 md:mt-12'>
                    <Button
                      type='submit'
                      label='Account löschen'
                      isLoading={isLoading}
                      isSuccessful={isSuccessful}
                      isError={isError}
                      disabled={!passwordConfirmation}
                    />
                    {!isSuccessful && (
                      <Button
                        label='Abbrechen'
                        onClick={() => {
                          // eslint-disable-next-line no-restricted-globals
                          history.back();
                        }}
                        buttonType={EButtonType.SECONDARY}
                      />
                    )}
                  </div>
                  {(errorMessage || successMessage) && (
                    <p
                      className={`text-sm mt-2 ${
                        errorMessage ? 'text-red-500' : 'text-green-500'
                      }`}
                    >
                      {errorMessage || successMessage}
                    </p>
                  )}
                </form>
              </div>
            )}
          </div>
        }
      />
    </>
  );
};

export default ButtonDeleteArticle;
