import React, { useEffect, useState } from 'react';

import LoginIcon from 'assets/icons/login.svg';
import UserIcon from 'assets/icons/user.svg';

import { ButtonAuth, ButtonLink } from 'components/static/Button';

import { EButtonSize, EButtonType } from 'types/enums/EButton';
import { ERoute } from 'types/enums/ERoute';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { IButton } from 'types/interfaces/IButton';
import { IUser } from 'types/interfaces/sulu/IUser';

import currentLanguage from 'utils/sulu/currentLanguage';
import getProfile from 'utils/sulu/getProfile';

export default ({
  buttonType = EButtonType.PRIMARY,
  buttonSize = EButtonSize.REGULAR,
  disabled = false,
}: IButton) => {
  const [user, setUser] = useState<IUser | null>(null);
  const [url, setUrl] = useState<string>(
    `/${currentLanguage}/${ERoute.SETTINGS}`,
  );

  const checkAuth = () => {
    getProfile().then(profile => {
      setUser(profile);
    });
  };

  useEffect(() => {
    checkAuth();
    document.addEventListener('login:success', checkAuth);
  }, []);

  useEffect(() => {
    if (user && user.consultantDetails !== undefined) {
      setUrl(
        `/${currentLanguage}/${ERoute.CONSULTANT_WELCOME}?${EUrlSearchParam.CONSULTANT_ID}=${user.id}`,
      );
    } else {
      setUrl(`/${currentLanguage}/${ERoute.SETTINGS}`);
    }
  }, [user]);

  return user ? (
    <ButtonLink
      url={url}
      icon={<UserIcon className='icon' />}
      showIconOnDesktop
      label='Meine Welt'
      buttonType={buttonType}
      buttonSize={buttonSize}
      disabled={disabled}
    />
  ) : (
    <ButtonAuth
      icon={<LoginIcon className='icon' />}
      showIconOnDesktop
      label='Anmelden'
      buttonType={buttonType}
      buttonSize={buttonSize}
      disabled={disabled}
    />
  );
};
