// eslint-disable-next-line import/no-extraneous-dependencies
import { toJS } from 'mobx';
import React from 'react';
import { ZodFirstPartySchemaTypes } from 'zod/lib/types';

import {
  AllContents,
  Category,
  ConsultantCreateArticle,
  ConsultantNewArticle,
  ConsultantRegistration,
  CorruptPage,
  Default,
  DeleteAccount,
  ErrorDefault,
  Homepage,
  MyLibrary,
  MyWorld,
  PasswordReset,
  Search,
  Settings,
  Tile,
  ConsultantProfile,
  ConsultantOverview,
  ConsultantArticlesOverview,
  ConsultantWelcome,
  ConsultantArticle,
  ConsultantDeleteAccount,
} from 'pages';

import { ESuluPageTypes } from 'types/enums/sulu/EBackendData';
import {
  ISuluPage,
  ZSPAllContents,
  ZSPCategory,
  ZSPConsultantCreateArticle,
  ZSPConsultantNewArticle,
  ZSPConsultantRegistration,
  ZSPDefault,
  ZSPDeleteAccount,
  ZSPHomepage,
  ZSPMyLibrary,
  ZSPMyWorld,
  ZSPConsultantProfile,
  ZSPPasswordReset,
  ZSPSearch,
  ZSPSettings,
  ZSPTile,
  ZSPConsultantOverview,
  ZSPConsultantArticlesOverview,
  ZSPConsultantWelcome,
  ZSPConsultantArticle,
  ZSPConsultantDeleteAccount,
} from 'types/interfaces/sulu/IPages';

const parsePageData = (
  pageData: ISuluPage,
  zodType: ZodFirstPartySchemaTypes,
): boolean => {
  // MIND: pageData may have special types ("observableArray") from MobX.
  // The toJS method parses special MobX types to regular JS types.
  const pageDataJS = toJS(pageData);
  const zodParsed = zodType.safeParse(pageDataJS);
  if (!zodParsed.success) {
    localStorage.setItem(
      `page-${pageData.template}-${Date.now()}`,
      JSON.stringify(zodParsed.error.issues),
    );
  }
  return zodParsed.success;
};

export default (pageData: ISuluPage): JSX.Element => {
  const { template } = pageData;
  switch (template) {
    case ESuluPageTypes.DEFAULT:
      return parsePageData(pageData, ZSPDefault) ? (
        <Default {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.DELETE_ACCOUNT:
      return parsePageData(pageData, ZSPDeleteAccount) ? (
        <DeleteAccount {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.CATEGORY:
      return parsePageData(pageData, ZSPCategory) ? (
        <Category {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.CONSULTANT_REGISTRATION:
      return parsePageData(pageData, ZSPConsultantRegistration) ? (
        <ConsultantRegistration {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.HOMEPAGE:
      return parsePageData(pageData, ZSPHomepage) ? (
        <Homepage {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.ALL_CONTENTS:
      return parsePageData(pageData, ZSPAllContents) ? (
        <AllContents {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.MY_WORLD:
      return parsePageData(pageData, ZSPMyWorld) ? (
        <MyWorld {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.MY_LIBRARY:
      return parsePageData(pageData, ZSPMyLibrary) ? (
        <MyLibrary {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.CONSULTANT_ARTICLES_OVERVIEW:
      return parsePageData(pageData, ZSPConsultantArticlesOverview) ? (
        <ConsultantArticlesOverview {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.PASSWORD_RESET:
      return parsePageData(pageData, ZSPPasswordReset) ? (
        <PasswordReset {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.SEARCH:
      return parsePageData(pageData, ZSPSearch) ? (
        <Search {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.SETTINGS:
      return parsePageData(pageData, ZSPSettings) ? (
        <Settings {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.TILE:
      return parsePageData(pageData, ZSPTile) ? (
        <Tile {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.CONSULTANT_WELCOME:
      return parsePageData(pageData, ZSPConsultantWelcome) ? (
        <ConsultantWelcome {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.CONSULTANT_CREATE_ARTICLE:
      return parsePageData(pageData, ZSPConsultantCreateArticle) ? (
        <ConsultantCreateArticle {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.CONSULTANT_NEW_ARTICLE:
      return parsePageData(pageData, ZSPConsultantNewArticle) ? (
        <ConsultantNewArticle {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.CONSULTANT_PROFILE:
      return parsePageData(pageData, ZSPConsultantProfile) ? (
        <ConsultantProfile />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.CONSULTANT_OVERVIEW:
      return parsePageData(pageData, ZSPConsultantOverview) ? (
        <ConsultantOverview {...pageData} />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.CONSULTANT_ARTICLE:
      return parsePageData(pageData, ZSPConsultantArticle) ? (
        <ConsultantArticle />
      ) : (
        <CorruptPage />
      );
    case ESuluPageTypes.CONSULTANT_DELETE_ACCOUNT:
      return parsePageData(pageData, ZSPConsultantDeleteAccount) ? (
        <ConsultantDeleteAccount {...pageData} />
      ) : (
        <CorruptPage />
      );
    default:
      return <ErrorDefault />;
  }
};
