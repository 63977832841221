import { IRegistrationResponse } from 'types/interfaces/sulu/IAuth';

export default async (formData: FormData): Promise<IRegistrationResponse> => {
  const response = await fetch('/registration', {
    method: 'POST',
    body: formData,
  });

  if (response.ok) {
    const jsonResponse = await response.json();
    return jsonResponse;
  }

  return {} as IRegistrationResponse;
};
