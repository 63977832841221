import React, { useState } from 'react';

import BlockContainer from 'components/BlockContainer';

import { IMenuBar } from 'types/interfaces/IMenu';

import './Menu.css';
import createLinks from './utils/createLinks';

export default ({ menuItems }: IMenuBar) => {
  const { information, entertainment, consulting } = menuItems;

  const [showInformationNavigation, setShowInformationNavigation] =
    useState(false);
  const [showEntertainmentNavigation, setShowEntertainmentNavigation] =
    useState(false);
  const [showConsultingNavigation, setShowConsultingNavigation] =
    useState(false);

  return (
    <BlockContainer outerClasses='bg-grey !py-0'>
      <div className='grid grid-cols-3 gap-10'>
        <div className='relative'>
          <button
            type='button'
            onMouseOver={() => setShowInformationNavigation(true)}
            onFocus={() => setShowInformationNavigation(true)}
            onMouseOut={() => setShowInformationNavigation(false)}
            onBlur={() => setShowInformationNavigation(false)}
            className={`w-full py-6 text-center ${
              showInformationNavigation ? 'font-bold' : ''
            }`}
          >
            Information
          </button>
          <div
            onMouseOver={() => setShowInformationNavigation(true)}
            onFocus={() => setShowInformationNavigation(true)}
            onMouseOut={() => setShowInformationNavigation(false)}
            onBlur={() => setShowInformationNavigation(false)}
            className={`menu-bar-container ${
              showInformationNavigation
                ? 'visible opacity-100'
                : 'invisible opacity-0'
            }`}
          >
            <div className='menu-bar-container-inner'>
              {createLinks(information)}
            </div>
          </div>
        </div>
        <div className='relative'>
          <button
            type='button'
            onMouseOver={() => setShowEntertainmentNavigation(true)}
            onFocus={() => setShowEntertainmentNavigation(true)}
            onMouseOut={() => setShowEntertainmentNavigation(false)}
            onBlur={() => setShowEntertainmentNavigation(false)}
            className={`w-full py-6 text-center ${
              showEntertainmentNavigation ? 'font-bold' : ''
            }`}
          >
            Unterhaltung
          </button>
          <div
            onMouseOver={() => setShowEntertainmentNavigation(true)}
            onFocus={() => setShowEntertainmentNavigation(true)}
            onMouseOut={() => setShowEntertainmentNavigation(false)}
            onBlur={() => setShowEntertainmentNavigation(false)}
            className={`menu-bar-container ${
              showEntertainmentNavigation
                ? 'visible opacity-100'
                : 'invisible opacity-0'
            }`}
          >
            <div className='menu-bar-container-inner'>
              {createLinks(entertainment)}
            </div>
          </div>
        </div>
        <div className='relative'>
          <button
            type='button'
            onMouseOver={() => setShowConsultingNavigation(true)}
            onFocus={() => setShowConsultingNavigation(true)}
            onMouseOut={() => setShowConsultingNavigation(false)}
            onBlur={() => setShowConsultingNavigation(false)}
            className={`w-full py-6 text-center ${
              showConsultingNavigation ? 'font-bold' : ''
            }`}
          >
            Beratung
          </button>
          <div
            onMouseOver={() => setShowConsultingNavigation(true)}
            onFocus={() => setShowConsultingNavigation(true)}
            onMouseOut={() => setShowConsultingNavigation(false)}
            onBlur={() => setShowConsultingNavigation(false)}
            className={`menu-bar-container ${
              showConsultingNavigation
                ? 'visible opacity-100'
                : 'invisible opacity-0'
            }`}
          >
            <div className='menu-bar-container-inner'>
              {createLinks(consulting)}
            </div>
          </div>
        </div>
      </div>
    </BlockContainer>
  );
};
