import { geocodeAddress } from './sulu/geocoding';

interface ILocation {
  lat: number;
  lng: number;
}

export const haversineDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number => {
  const R = 6371;
  const dLat = Math.abs(lat2 - lat1);
  const dLon = Math.abs(lon2 - lon1);

  // Umrechnung der Graddifferenzen in Bogenmaß
  const x = dLat * (Math.PI / 180) * R;
  const y =
    dLon * (Math.PI / 180) * R * Math.cos((lat1 + lat2) * (Math.PI / 360));

  return Math.sqrt(x * x + y * y); // Distance in km
};

export const getUserLocation = (): Promise<ILocation | null> =>
  new Promise((resolve, reject) => {
    // Check permissions for Geolocation
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: 'geolocation' })
        .then(permissionStatus => {
          if (
            permissionStatus.state === 'granted' ||
            permissionStatus.state === 'prompt'
          ) {
            // User needs to be asked for permission if "prompt"
            navigator.geolocation.getCurrentPosition(
              position => {
                const { latitude, longitude } = position.coords;
                resolve({ lat: latitude, lng: longitude });
              },
              (error: GeolocationPositionError) => {
                switch (error.code) {
                  case error.PERMISSION_DENIED:
                    reject(new Error('Geolocation permission denied'));
                    break;
                  case error.POSITION_UNAVAILABLE:
                    reject(
                      new Error('Position unavailable (network/GPS issue)'),
                    );
                    break;
                  case error.TIMEOUT:
                    reject(new Error('Geolocation request timed out'));
                    break;
                  default:
                    reject(new Error('Unknown geolocation error'));
                }
              },
            );
          } else {
            reject(new Error('Geolocation permission denied'));
          }
        });
    } else {
      reject(new Error('Permissions API not supported'));
    }
  });

export const calculateConsultantDistance = async (
  userLocation: ILocation,
  consultantAddress: string,
): Promise<string | null> => {
  try {
    const location = await geocodeAddress(consultantAddress);

    if (location) {
      const distance = haversineDistance(
        userLocation.lat,
        userLocation.lng,
        location.lat,
        location.lng,
      );
      return distance.toFixed(2); // Returns the distance in km
    }

    return null;
  } catch (error) {
    return null;
  }
};
