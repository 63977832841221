import React, { useState } from 'react';

import ContentTypeText from 'components/static/ContentTypeText';

import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { ISPContentTile } from 'types/interfaces/sulu/IPages';

import { DEFAULT_SCHEME_COLOR } from 'utils/constants';
import currentLanguage from 'utils/sulu/currentLanguage';

interface ITileDetails extends ISPContentTile {
  useVibrantColor?: boolean;
  schemeColor?: string;
  category?: string;
}

const TileDetails = ({
  content,
  useVibrantColor,
  schemeColor,
  category,
}: ITileDetails) => {
  const {
    url: contentUrl,
    photo,
    text,
    text_hover: textHover,
    content: contentType,
    main_category: mainCategory,
    color,
  } = content;
  const mainCategoryContent = mainCategory?.content || null;
  const pageColor = mainCategoryContent?.page_color || '';
  const mainCategoryTitle = mainCategoryContent?.title || '';
  let backgroundColor =
    color || schemeColor || pageColor || DEFAULT_SCHEME_COLOR;
  backgroundColor = useVibrantColor ? backgroundColor : `${backgroundColor}50`;
  const [hover, setHover] = useState<boolean>(false);
  const bgImageStyle = {
    backgroundImage: `url(${photo.url})`,
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`w-52 p-2.5 rounded-xl transition-colors cursor-pointer ${
        useVibrantColor ? 'text-white' : 'text-black'
      }`}
      style={{
        backgroundColor: hover ? `${backgroundColor}50` : backgroundColor,
      }}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onBlur={() => setHover(false)}
      onClick={() => {
        // workaround to handle preview which sets url to null
        const url = contentUrl ?? '';
        const urlWithoutLang = url.startsWith(`/${currentLanguage}/`)
          ? url.replace(`/${currentLanguage}/`, '/')
          : url;
        const mainCategoryIsEqualsCategory =
          mainCategoryTitle.toLowerCase() === category;
        const href = mainCategoryIsEqualsCategory
          ? urlWithoutLang
          : `${urlWithoutLang}${
              category ? `?${EUrlSearchParam.CATEGORY}=${category}` : ''
            }`;
        window.location.href = `/${currentLanguage}${href}`;
      }}
    >
      <div className='relative pt-[100%] rounded-md shadow-md'>
        <div
          className={`absolute inset-0 rounded-md bg-center bg-no-repeat bg-cover transition-all
    ${hover ? 'brightness-50' : 'brightness-100'}`}
          style={bgImageStyle}
        />
        {contentType &&
          typeof contentType === 'object' &&
          'isAudio' in contentType &&
          'isDocument' in contentType &&
          'isVideo' in contentType && (
            <ContentTypeText
              contentType={{
                isAudio: contentType.isAudio,
                isDocument: contentType.isDocument,
                isVideo: contentType.isVideo,
              }}
              className={`absolute top-2 left-2 transition-opacity ${
                hover ? 'opacity-100' : 'opacity-0'
              }`}
            />
          )}
      </div>

      <div className='relative flex items-center h-12 px-2 mt-2'>
        <p
          className={`absolute left-2 right-2 inset-y-0 font-bold line-clamp-2 transition-all ${
            hover ? 'text-white' : ''
          }`}
          style={{
            transform: hover ? 'translateY(calc(-100% - 1rem))' : '',
          }}
        >
          {text}
        </p>
        <p
          className={`text-black text-sm line-clamp-2 transition-opacity ${
            hover ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {textHover}
        </p>
      </div>
    </div>
  );
};

TileDetails.defaultProps = {
  useVibrantColor: false,
  schemeColor: undefined,
  category: undefined,
};

export default TileDetails;
