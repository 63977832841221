export enum ELoginFormData {
  USERNAME = '_username',
  PASSWORD = '_password',
}

export enum EPasswordForgetFormData {
  USERNAME = 'password_forget[email_username]',
  TOKEN = 'password_forget[_token]',
}

export enum EPasswordResetFormData {
  PASSWORD = 'password_reset[plainPassword]',
  TOKEN = 'password_reset[_token]',
}

export enum ERegistrationFormData {
  USERNAME = 'registration[username]',
  EMAIL = 'registration[email]',
  PASSWORD = 'registration[plainPassword]',
  FIRST_NAME = 'registration[firstName]',
  LAST_NAME = 'registration[lastName]',
  TERMS = 'registration[terms]',
  TOKEN = 'registration[_token]',
  IS_CONSULTANT = 'registration[isConsultant]',
}

export enum EProfileFormData {
  EMAIL = 'profile[mainEmail]',
  FIRST_NAME = 'profile[firstName]',
  LAST_NAME = 'profile[lastName]',
  TOKEN = 'profile[_token]',
}

export enum EDeleteAccountFormData {
  PASSWORD = 'password',
}

export enum EConsultantRegistrationFormData {
  USERNAME = 'registration[username]',
  EMAIL = 'registration[email]',
  PASSWORD = 'registration[plainPassword]',
  FIRST_NAME = 'registration[firstName]',
  LAST_NAME = 'registration[lastName]',
  SOCIALMEDIA_LINKEDIN = 'registration[socialMediaProfile][profile_1]',
  SOCIALMEDIA_FB = 'registration[socialMediaProfile][profile_2]',
  SOCIALMEDIA_INSTA = 'registration[socialMediaProfile][profile_3]',
  AVATAR = 'registration[avatar]',
  TITLE = 'registration[consultantDetails][title]',
  CONFIRMATION_TERMS = 'registration[terms]',
  MY_OFFER = 'registration[consultantDetails][myOffer]',
  CONSULTANT_CATEGORIES = 'registration[consultantDetails][categories]',
  CONSULTANT_COUNTRIES = 'registration[consultantDetails][countries]',
  CONSULTANT_LANGUAGES = 'registration[consultantDetails][languages]',
  CONSULTANT_FOCALPOINTS = 'registration[consultantDetails][focalPoints]',
  CONSULTANT_FOCALPOINT_MISCELLANEOUS = 'registration[consultantDetails][focalPoint]',
  CONSULTANT_CONFIRMATION_TERMS = 'registration[consultantDetails][terms]',
  CONSULTANT_CONFIRMATION_POLICY = 'registration[consultantDetails][policy]',
  COMPANY_NAME = 'registration[consultantDetails][consultantCompany][companyName]',
  COMPANY_STREET = 'registration[consultantDetails][consultantCompany][street]',
  COMPANY_ABOUT = 'registration[consultantDetails][consultantCompany][about]',
  COMPANY_PLZ = 'registration[consultantDetails][consultantCompany][plz]',
  COMPANY_CITY = 'registration[consultantDetails][consultantCompany][ort]',
  COMPANY_COUNTRY = 'registration[consultantDetails][consultantCompany][staat]',
  COMPANY_FEDERAL_STATE = 'registration[consultantDetails][consultantCompany][bundesland]',
  COMPANY_TELEPHONE = 'registration[consultantDetails][consultantCompany][telephone]',
  COMPANY_EMAIL = 'registration[consultantDetails][consultantCompany][email]',
  COMPANY_WEBSITE = 'registration[consultantDetails][consultantCompany][website]',
  COMPANY_ACCESSIBILITY = 'registration[consultantDetails][consultantCompany][barrierFree]',
  COMPANY_CONFIRMATION_TERMS = 'registration[consultantDetails][consultantCompany][confirmTerms]',
  TOKEN = 'registration[_token]',
  IS_CONSULTANT = 'registration[isConsultant]',
}

export enum EConsultantNewArticleFormData {
  ARTICLE_TYPE = 'type',
  THUMBNAIL = 'photo',
  TITLE = 'title',
  SUBTITLE = 'subtitle',
  ARTICLE_CONTENT = 'content',
  ARTICLE_FILE = 'file',
  VIDEO_LINK = 'videoLink',
  TOKEN = '_token',
}

export enum EConsultantImpressumFormData {
  NAME = 'name',
  LEGAL_FORM = 'legalForm',
  STREET = 'street',
  ZIP_CODE = 'zipCode',
  CITY = 'city',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  VAT_ID = 'vatId',
  REGISTER = 'register',
  REGISTER_NUMBER = 'registerNumber',
  TOKEN = '_token',
}

export enum EConsultantSearchFormData {
  CATEGORY = 'category',
  CITY = 'city',
  RADIUS = 'radius',
  LANGUAGES = 'languages',
  FOCALPOINTS = 'focalPoints',
  COUNTRY = 'country',
}
