import React, { useState } from 'react';
import { useDebounce } from 'react-use';

import SearchIcon from 'assets/icons/search.svg';

import LoadingSpinner from 'components/static/LoadingSpinner';

import { ERoute } from 'types/enums/ERoute';
import { ESearchfieldSize, ESearchfieldVariant } from 'types/enums/ESearch';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { ISearchComponent } from 'types/interfaces/ISearchComponent';
import { ISearch } from 'types/interfaces/sulu/ISearch';

import currentLanguage from 'utils/sulu/currentLanguage';
import search from 'utils/sulu/search';

import SearchResult from './SearchResult';

const SearchDesktop = ({ variant, size }: ISearchComponent) => {
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<ISearch[]>([]);
  const [hoverSearchField, setHoverSearchField] = useState<boolean>(false);
  const [focusSearchField, setFocusSearchField] = useState<boolean>(false);

  const [isReady] = useDebounce(
    () => {
      search(query).then(searchResults => setResults(searchResults));
    },
    500,
    [query],
  );

  const getVariantStyles = (): string => {
    let styles = 'bg-grey-light placeholder:text-black/50'; // variant === dark

    if (variant === ESearchfieldVariant.LIGHT) {
      styles =
        'bg-white/10 hover:bg-white focus:bg-white placeholder:text-white/70 hover:placeholder:text-black/50 focus:placeholder:text-black/50';
      if (query) {
        styles = 'bg-white';
      }
    }

    return styles;
  };

  const getSearchIconStyles = (): string => {
    let styles = 'fill-black';

    if (variant === ESearchfieldVariant.LIGHT) {
      styles = 'fill-white/70';
      if (hoverSearchField || focusSearchField || query) {
        styles = 'fill-black';
      }
    }

    styles += size === ESearchfieldSize.SMALL ? ' !w-5 !h-5' : '';
    return styles;
  };

  const createSearchResults = () =>
    // show only the first 3 results
    results
      .slice(0, 3)
      .map(result => <SearchResult key={result.document.id} {...result} />);

  return (
    <div className='relative'>
      <div className='relative w-80 z-10'>
        <input
          type='text'
          value={query}
          placeholder='Suche'
          onChange={({ currentTarget }) => {
            setQuery(currentTarget.value);
          }}
          onMouseOver={() => setHoverSearchField(true)}
          onFocus={() => setFocusSearchField(true)}
          onMouseOut={() => setHoverSearchField(false)}
          onBlur={() => setFocusSearchField(false)}
          className={`w-full ${getVariantStyles()} ${
            size === ESearchfieldSize.SMALL
              ? 'py-1.5 pl-11 text-sm'
              : 'py-3 pl-12'
          } pr-4 font-light rounded-full transition ease-in-out duration-300 focus:outline-none`}
        />
        <SearchIcon
          className={`icon ${getSearchIconStyles()} absolute top-1/2 left-4 -translate-y-1/2 transition ease-in-out duration-300`}
        />
      </div>
      {/* results container */}
      <div
        className={`${
          variant === ESearchfieldVariant.LIGHT ? 'bg-white' : 'bg-grey-light'
        } absolute top-1/2 inset-x-0 pt-8 px-4 pb-4 ${
          size === ESearchfieldSize.SMALL ? 'rounded-b-2xl' : 'rounded-b-3xl'
        } shadow-lg transition-all ease-in-out ${
          focusSearchField ? 'visible opacity-100' : 'invisible opacity-0'
        }`}
      >
        {!isReady() && (
          <div className='flex justify-center'>
            <LoadingSpinner
              size={size === ESearchfieldSize.SMALL ? 'w-5 h-5' : 'w-6 h-6'}
            />
          </div>
        )}
        {isReady() && results.length === 0 && (
          <p
            className={`text-black/50 text-center ${
              size === ESearchfieldSize.SMALL ? 'text-sm' : ''
            }`}
          >
            Keine Ergebnisse
          </p>
        )}
        {isReady() && results.length > 0 && (
          <>
            {createSearchResults()}
            {results.length > 3 && (
              <div className='flex items-center justify-end pt-4'>
                <a
                  href={`/${currentLanguage}/${ERoute.SEARCH_RESULTS}?${EUrlSearchParam.QUERY}=${query}`}
                  className='text-sm font-medium'
                >
                  Alle anzeigen
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

SearchDesktop.defaultProps = {
  variant: ESearchfieldVariant.LIGHT,
  size: ESearchfieldSize.REGULAR,
};

export default SearchDesktop;
