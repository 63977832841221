import React, { useState } from 'react';
import { useDebounce } from 'react-use';

import SearchIcon from 'assets/icons/search.svg';

import CloseButton from 'components/static/CloseButton';
import LoadingSpinner from 'components/static/LoadingSpinner';

import { ERoute } from 'types/enums/ERoute';
import {
  ESearchfieldDirection,
  ESearchfieldVariant,
} from 'types/enums/ESearch';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { ISearchComponent } from 'types/interfaces/ISearchComponent';
import { ISearch } from 'types/interfaces/sulu/ISearch';

import currentLanguage from 'utils/sulu/currentLanguage';
import search from 'utils/sulu/search';

import SearchResult from './SearchResult';

const SearchMobile = ({
  variant,
  direction,
  onToggleSearchfield,
}: ISearchComponent) => {
  const [query, setQuery] = useState<string>('');
  const [results, setResults] = useState<ISearch[]>([]);
  const [showSearchfield, setShowSearchfield] = useState<boolean>(false);
  const [hoverSearchField, setHoverSearchField] = useState<boolean>(false);
  const [focusSearchField, setFocusSearchField] = useState<boolean>(false);

  const [isReady] = useDebounce(
    () => {
      search(query).then(searchResults => setResults(searchResults));
    },
    500,
    [query],
  );

  const getVariantStyles = (): string => {
    let styles = 'bg-grey-light placeholder:text-black/50'; // variant === dark

    if (variant === ESearchfieldVariant.LIGHT) {
      styles =
        'bg-white/10 hover:bg-white focus:bg-white placeholder:text-white/70 hover:placeholder:text-black/50 focus:placeholder:text-black/50';
      if (query) {
        styles = 'bg-white';
      }
    }

    return styles;
  };

  const getSearchIconContainerStyles = (): string => {
    let styles = 'translate-x-0';
    styles +=
      variant === ESearchfieldVariant.LIGHT ? ' bg-white/10' : ' bg-grey-light';

    if (showSearchfield) {
      styles =
        direction === ESearchfieldDirection.TO_LEFT
          ? 'translate-x-1'
          : '-translate-x-1';
    }

    return styles;
  };

  const getIconStyles = (): string => {
    let styles = 'fill-black';

    if (variant === ESearchfieldVariant.LIGHT) {
      styles = 'fill-white/70';
      if (showSearchfield && (hoverSearchField || focusSearchField || query)) {
        styles = 'fill-black';
      }
    }

    return styles;
  };

  const handleToggleSearchfield = () => {
    if (onToggleSearchfield) {
      onToggleSearchfield(!showSearchfield);
    }
    setShowSearchfield(!showSearchfield);
  };

  const createSearchResults = () =>
    // show only the first 3 results
    results
      .slice(0, 3)
      .map(result => <SearchResult key={result.document.id} {...result} />);

  return (
    <div className='relative'>
      <div
        className={`relative ${
          showSearchfield ? 'w-56 md:w-80' : 'w-10'
        } h-10 transition-[width] ease-in-out duration-300 z-10`}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={`${getSearchIconContainerStyles()} absolute top-0 ${
            direction === ESearchfieldDirection.TO_LEFT ? 'left-0' : 'right-0'
          } flex items-center justify-center w-10 h-10 rounded-full cursor-pointer transition ease-in-out delay-150 z-20`}
          onClick={handleToggleSearchfield}
        >
          <SearchIcon
            className={`icon ${getIconStyles()} transition ease-in-out duration-300`}
          />
        </div>
        <input
          type='text'
          value={query}
          placeholder='Suche'
          onChange={({ currentTarget }) => {
            setQuery(currentTarget.value);
          }}
          onMouseOver={() => setHoverSearchField(true)}
          onFocus={() => setFocusSearchField(true)}
          onMouseOut={() => setHoverSearchField(false)}
          onBlur={() => setFocusSearchField(false)}
          className={`${getVariantStyles()} w-full px-12 py-2 font-light rounded-full transition ease-in-out duration-300 focus:outline-none ${
            showSearchfield ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
        />
        <CloseButton
          className={`absolute inset-y-0 ${
            direction === ESearchfieldDirection.TO_LEFT ? 'right-0' : 'left-0'
          } flex items-center justify-center w-10 transition-opacity ease-in-out duration-300 ${
            showSearchfield ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
          iconClassName={getIconStyles()}
          onClick={handleToggleSearchfield}
        />
      </div>
      {/* results container */}
      <div
        className={`${
          variant === ESearchfieldVariant.LIGHT ? 'bg-white' : 'bg-grey-light'
        } absolute top-1/2 inset-x-0 pt-8 px-4 pb-4 rounded-b-[20px] shadow-lg transition-all ease-in-out ${
          showSearchfield && focusSearchField
            ? 'visible opacity-100'
            : 'invisible opacity-0'
        }`}
      >
        {!isReady() && (
          <div className='flex justify-center'>
            <LoadingSpinner />
          </div>
        )}
        {isReady() && results.length === 0 && (
          <p className='text-black/50 text-center'>Keine Ergebnisse</p>
        )}
        {isReady() && results.length > 0 && (
          <>
            {createSearchResults()}
            {results.length > 3 && (
              <div className='flex items-center justify-end pt-4'>
                <a
                  href={`/${currentLanguage}/${ERoute.SEARCH_RESULTS}?${EUrlSearchParam.QUERY}=${query}`}
                  className='text-sm font-medium'
                >
                  Alle anzeigen
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

SearchMobile.defaultProps = {
  variant: ESearchfieldVariant.LIGHT,
  direction: ESearchfieldDirection.TO_LEFT,
  onToggleSearchfield: undefined,
};

export default SearchMobile;
