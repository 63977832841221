import React from 'react';

import Facebook from 'assets/icons/facebook.svg';
import Instagram from 'assets/icons/instagram.svg';
import LinkedIn from 'assets/icons/linkedin.svg';
import Twitter from 'assets/icons/twitter.svg';
import YouTube from 'assets/icons/youtube.svg';

import { ESuluContentBlockSocialMediaType } from 'types/enums/sulu/EBackendData';

export default (
  socialMediaType: ESuluContentBlockSocialMediaType,
): JSX.Element => {
  switch (socialMediaType) {
    case ESuluContentBlockSocialMediaType.FACEBOOK:
      return <Facebook className='w-4 fill-black hover:fill-[#4267B2]' />;
    case ESuluContentBlockSocialMediaType.INSTAGRAM:
      return <Instagram className='w-4 fill-black hover:fill-[#E1306C]' />;
    case ESuluContentBlockSocialMediaType.LINKEDIN:
      return <LinkedIn className='w-4 fill-black hover:fill-[#0077b5]' />;
    case ESuluContentBlockSocialMediaType.TWITTER:
      return <Twitter className='w-4 fill-black hover:fill-[#1DA1F2]' />;
    case ESuluContentBlockSocialMediaType.YOUTUBE:
      return <YouTube className='w-5 fill-black hover:fill-[#F00]' />;
    default:
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
  }
};
