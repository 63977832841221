import React, { useState } from 'react';

export interface IConsultantTile {
  tag: string;
  name: string;
  myOffer: string;
  url: string;
  profileImageUrl: string;
}

const ConsultantTile = ({
  tag,
  name,
  profileImageUrl,
  myOffer,
  url,
}: IConsultantTile) => {
  const [hover, setHover] = useState(false);

  const bgImageStyle = {
    backgroundImage: `url(${profileImageUrl})`,
  };

  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <div
      className='w-52 p-2.5 rounded-xl cursor-pointer overflow-hidden bg-blue-light'
      onClick={handleClick}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onBlur={() => setHover(false)}
      onKeyDown={e => e.key === 'Enter' && handleClick()}
      role='button'
      tabIndex={0}
    >
      <div className='relative pt-[100%] rounded-md shadow-md'>
        <div
          className={`absolute inset-0 rounded-md bg-center bg-no-repeat bg-cover transition-all
    ${hover ? 'brightness-50' : 'brightness-100'}`}
          style={bgImageStyle}
        />
        <div
          className={`flex text-black bg-white/90 px-2.5 py-0.5 rounded-full absolute top-2 left-2 transition-opacity ${
            hover ? 'opacity-100' : 'opacity-0'
          }`}
        >
          <span className='text-xs'>{tag}</span>
        </div>
      </div>

      <div className='relative flex items-center h-12 px-2 mt-2'>
        <p
          className={`absolute left-2 right-2 inset-y-0 font-bold line-clamp-2 transition-all ${
            hover ? 'text-white' : ''
          }`}
          style={{
            transform: hover ? 'translateY(calc(-100% - 1rem))' : '',
          }}
        >
          {name}
        </p>
        <p
          className={`text-black text-sm line-clamp-2 transition-opacity ${
            hover ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {myOffer}
        </p>
      </div>
    </div>
  );
};

export default ConsultantTile;
