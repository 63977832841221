import React, { useEffect, useState } from 'react';

import GoogleMapsDotIcon from 'assets/icons/googleMapsDot.svg';
import LibaryBooksIcon from 'assets/icons/library-books.svg';
import Logo from 'assets/images/logo.svg';

import { ERoute } from 'types/enums/ERoute';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import {
  IConsultantCompany,
  IConsultantDetails,
  IConsultantResponse,
  IUser,
} from 'types/interfaces/sulu/IUser';

import {
  calculateConsultantDistance,
  getUserLocation,
} from 'utils/calculateDistance';
import createFocalPoints from 'utils/createFocalPoints';
import currentLanguage from 'utils/sulu/currentLanguage';
import getAllConsultantArticles from 'utils/sulu/getAllConsultantArticles';
import getAllConsultants from 'utils/sulu/getAllConsultants';
import getConsultant from 'utils/sulu/getConsultant';

export interface IConsultantTile {
  id: number;
  name: string;
  url: string;
  profileImageUrl: string;
}

const ConsultantTile = ({
  id,
  name,
  url,
  profileImageUrl,
}: IConsultantTile) => {
  const [consultantProfile, setConsultantProfile] = useState<IUser | null>(
    null,
  );
  const [consultantDistance, setConsultantDistance] = useState<string>('');
  const [company, setCompany] = useState<IConsultantCompany | undefined>(
    undefined,
  );
  const [hasArticle, setHasArticle] = useState<boolean>(false);
  const [consultantDetails, setConsultantDetails] = useState<
    IConsultantDetails | undefined
  >(undefined);
  const [avatar, setAvatar] = useState<string | null>(null);

  getAllConsultantArticles(id, 1).then(articles => {
    if (articles && articles.length > 0) {
      setHasArticle(true);
    }
  });

  useEffect(() => {
    getConsultant(id).then(profile => {
      setConsultantProfile(profile);
    });
  }, [id]);

  useEffect(() => {
    const cDetails: IConsultantDetails | undefined = consultantProfile
      ? consultantProfile.consultantDetails
      : undefined;
    setConsultantDetails(cDetails);
    setCompany(cDetails ? cDetails.company : undefined);
  }, [consultantProfile, company]);

  useEffect(() => {
    const cDetails: IConsultantDetails | undefined = consultantProfile
      ? consultantProfile.consultantDetails
      : undefined;
    setConsultantDetails(cDetails);
    setCompany(cDetails ? cDetails.company : undefined);

    if (!consultantProfile || !company) {
      return;
    }

    getUserLocation().then(userLocation => {
      if (!userLocation) {
        return;
      }
      const consultantAddress = `${company.ort}, ${company.staat}`;
      calculateConsultantDistance(userLocation, consultantAddress).then(
        distance => {
          setConsultantDistance(distance || '');
        },
      );
    });
  }, [consultantProfile, company]);

  useEffect(() => {
    getAllConsultants().then((consultants: IConsultantResponse[]) => {
      const foundConsultant = consultants?.find(
        consultant => consultant.id === id,
      );
      if (foundConsultant) {
        const updatedAvatar = foundConsultant.avatar.replace(
          '/1200x627/',
          '/sulu-400x400/',
        );
        setAvatar(updatedAvatar);
      }
    });
  }, []);

  return (
    <a
      className='md:flex gap-4 rounded-xl border-2 hover:text-inherit'
      href={url}
    >
      <div className='min-h-[20rem] bg-blue-light p-4 rounded-lg'>
        {profileImageUrl.length >= 6 ? (
          <div
            className='md:h-full h-72 md:w-60 w-full bg-center bg-no-repeat bg-cover rounded-xl'
            style={{
              backgroundImage: avatar ? `url(${avatar})` : '',
            }}
          />
        ) : (
          <div className='md:h-full h-72 md:w-60 w-full bg-center bg-no-repeat bg-cover rounded-xl bg-gradient-to-t from-[#B5B5B5] to-[#E4E3E5]'>
            <Logo className='w-[75%] h-full m-auto fill-white' />
          </div>
        )}
      </div>

      <div className='md:basis-3/4 p-4 flex flex-col'>
        <div className='h-8'>
          {consultantDistance && (
            <div className='flex flex-wrap md:justify-end'>
              <GoogleMapsDotIcon className='icon mt-1' />
              <span>{consultantDistance} km von mir entfernt</span>
            </div>
          )}
        </div>

        <div className='md:flex md:justify-between items-center'>
          <h2 className='cursor-pointer'>{name}</h2>
        </div>
        {company && (
          <span className='mt-2'>
            {`${company.plz} ${company.ort}; Tel: ${company.telephone}${
              consultantProfile ? `; E-Mail: ${consultantProfile.email}` : ''
            }`}
          </span>
        )}

        <div className='flex flex-col md:flex-row'>
          <div className='md:basis-1/2 mt-4 mr-8 max-h-52 overflow-hidden'>
            {company && <h3>{company.companyName}</h3>}
            {consultantDetails && (
              <p className='mt-4 text-justify'>
                {consultantDetails.myOffer || ''}
              </p>
            )}
          </div>
          <div className='md:basis-1/2 my-4'>
            <h3>Tätigkeit</h3>
            {consultantDetails ? (
              <div className='mt-5 mb-0'>
                {createFocalPoints(consultantDetails.focalPoints)}
              </div>
            ) : (
              <p className='text-black/50 text-center'>
                Keine Tätigkeiten & Schwerpunkte angegeben
              </p>
            )}
          </div>
        </div>
        {hasArticle ? (
          <div className='flex flex-row mt-auto'>
            <LibaryBooksIcon className='icon fill-orange mr-2' />
            <a
              href={`/${currentLanguage}/${ERoute.CONSULTANT_PROFILE}?${EUrlSearchParam.CONSULTANT_ID}=${id}#myArctiles`}
            >
              Zu meinen Artikeln
            </a>
          </div>
        ) : (
          <div className='flex flex-wrap mt-auto' />
        )}
      </div>
    </a>
  );
};

export default ConsultantTile;
