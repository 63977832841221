// @ts-ignore
import * as Klaro from 'klaro';
import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import klaroConfig from 'components/config.klaro';

import { ESuluPageTypes } from 'types/enums/sulu/EBackendData';
import { ISuluPage } from 'types/interfaces/sulu/IPages';

import suluPageHandler from 'utils/sulu/pageHandler';

export default ({ data }: { data: ISuluPage }) => {
  const resetScrollbarToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (data.template !== ESuluPageTypes.TILE) {
      resetScrollbarToTop();
    }
  });

  /**
   * Init Klaro only once
   * */
  useEffect(() => {
    // we assign the Klaro module to the window, so that we can access it in JS
    // @ts-ignore
    window.klaro = Klaro;
    // @ts-ignore
    window.klaroConfig = klaroConfig;
    // @ts-ignore
    Klaro.setup(klaroConfig);
    // we set up Klaro with the config
    const klaroCookie = window.document.cookie.includes('klaro');
    // if klaroCookie is set, take no further action
    if (!klaroCookie) {
      // @ts-ignore
      Klaro.show();
    }
  }, []);

  return (
    <>
      {suluPageHandler(data)}
      <ToastContainer />
    </>
  );
};
