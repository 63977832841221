// eslint-disable-next-line import/no-extraneous-dependencies
import { toJS } from 'mobx';
import React, { Key } from 'react';
import { ZodFirstPartySchemaTypes } from 'zod/lib/types';

import BlockContainer from 'components/BlockContainer';
import { ThreeColumns, TwoColumns } from 'components/blocks';

import { ESuluLayoutBlockTypes } from 'types/enums/sulu/EBackendData';
import { ISuluLayoutBlock } from 'types/interfaces/sulu/ILayoutBlocks';
import ZSLBThreeColumns from 'types/interfaces/sulu/layoutBlocks/ZSLBThreeColumns';
import ZSLBTwoColumns from 'types/interfaces/sulu/layoutBlocks/ZSLBTwoColumns';

const parseBlockData = (
  blockData: ISuluLayoutBlock,
  zodType: ZodFirstPartySchemaTypes,
): boolean => {
  // MIND: blockData may have special types ("observableArray") from MobX.
  // The toJS method parses special MobX types to regular JS types.
  const blockDataJS = toJS(blockData);
  const zodParsed = zodType.safeParse(blockDataJS);
  if (!zodParsed.success) {
    localStorage.setItem(
      `layout-block-${blockData.type}-${Date.now()}`,
      JSON.stringify(zodParsed.error.issues),
    );
  }
  return zodParsed.success;
};

export default (blockData: ISuluLayoutBlock, key: Key): JSX.Element => {
  const { type } = blockData;
  let blockToRender = <h2>No component found!</h2>;

  switch (type) {
    case ESuluLayoutBlockTypes.TWO_COLUMNS:
      if (parseBlockData(blockData, ZSLBTwoColumns)) {
        blockToRender = <TwoColumns {...blockData} key={key} />;
      }
      break;
    case ESuluLayoutBlockTypes.THREE_COLUMNS:
      if (parseBlockData(blockData, ZSLBThreeColumns)) {
        blockToRender = <ThreeColumns {...blockData} key={key} />;
      }
      break;
    default: {
      return blockToRender;
    }
  }

  return (
    <BlockContainer key={key} outerClasses={type}>
      {blockToRender}
    </BlockContainer>
  );
};
