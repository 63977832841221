import { IImpressumResponse } from 'types/interfaces/sulu/IImpressum';

export default async (
  formData: FormData,
  impressumId?: string,
): Promise<IImpressumResponse> => {
  const response = await fetch(
    `/consultant/impressum${impressumId ? `/${impressumId}` : ''}`,
    {
      method: 'POST',
      body: formData,
    },
  );

  const jsonResponse = await response.json();
  return jsonResponse;
};
