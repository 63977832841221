import React, { FormEvent, useEffect, useId, useRef, useState } from 'react';
import {
  BtnBold,
  BtnBulletList,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnStyles,
  BtnUnderline,
  Editor,
  EditorProvider,
  Separator,
  Toolbar,
} from 'react-simple-wysiwyg';
import { useLocation } from 'react-use';

import ChevronDownIcon from 'assets/icons/chevron-down.svg';
import CloseIcon from 'assets/icons/close.svg';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import { Button } from 'components/static/Button';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';

import { EButtonType } from 'types/enums/EButton';
import { EConsultantNewArticleType } from 'types/enums/EConsultant';
import { ERoute } from 'types/enums/ERoute';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { EConsultantNewArticleResponseStatus } from 'types/enums/sulu/EConsultantApi';
import { EConsultantNewArticleFormData } from 'types/enums/sulu/EFormData';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { IConsultantNewArticleResponse } from 'types/interfaces/sulu/IConsultantApi';
import { ISPConsultantNewArticle } from 'types/interfaces/sulu/IPages';
import { IUser } from 'types/interfaces/sulu/IUser';

import getDefaultConsultantThumbnail from 'utils/getDefaultConsultantThumbnail';
import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import navigateTo from 'utils/navigateTo';
import notify from 'utils/notify';
import createNewConsultantArticle from 'utils/sulu/createNewConsultantArticle';
import getCsrfToken from 'utils/sulu/getCsrfToken';
import getProfile from 'utils/sulu/getProfile';

export default ({ content }: ISPConsultantNewArticle) => {
  const {
    page_headline_top: headline,
    page_headline_bottom: subline,
    checkbox_1_label: checkbox1Label,
    checkbox_2_label: checkbox2Label,
  } = content;

  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const articleTypeParam = searchParams.get(EUrlSearchParam.TYPE) ?? '';
  const consultantId = searchParams.get(EUrlSearchParam.CONSULTANT_ID) ?? '';

  const MAX_SIZE = {
    THUMBNAIL: 5242880, // 5MB
    PDF: 78643200, // 75MB
    PODCAST: 52428800, // 50MB
    VIDEO: 838860800, // 800MB
  };
  const MAX_NUMBER_OF_ARTICLE_TEXT_CHARS = 30000;
  const REGEX_ARTICLE_TEXT = /(<([^>]+)>)/gi;

  const defaultThumbnail = getDefaultConsultantThumbnail();

  const [csrfToken, setCsrfToken] = useState<string>('');
  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoggedInConsultant, setIsLoggedInConsultant] = useState<
    boolean | undefined
  >(undefined);
  const [authChecked, setAuthChecked] = useState<boolean>(false);

  /* FORM INPUTS */
  // general
  const [articleType, setArticleType] = useState<EConsultantNewArticleType>(
    articleTypeParam
      ? (articleTypeParam as EConsultantNewArticleType)
      : EConsultantNewArticleType.ARTICLE,
  );
  const [thumbnail, setThumbnail] = useState<File | null>(null);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>(defaultThumbnail);
  const thumbnailInputRef = useRef<HTMLInputElement | null>(null);
  const [title, setTitle] = useState<string>('');
  const [subtitle, setSubtitle] = useState<string>('');
  const [checkbox1, setCheckbox1] = useState<boolean>(false);
  const [checkbox2, setCheckbox2] = useState<boolean>(false);

  // article
  const [articleText, setArticleText] = useState<string>('');
  const [numberOfArticleTextChars, setNumberOfArticleTextChars] =
    useState<number>(0);
  const [articlePdfFile, setArticlePdfFile] = useState<File | null>(null);
  const articlePdfInputRef = useRef<HTMLInputElement | null>(null);

  // podcast
  const [podcastAudioFile, setPodcastAudioFile] = useState<File | null>(null);
  const podcastAudioInputRef = useRef<HTMLInputElement | null>(null);

  // video
  const [videoType, setVideoType] = useState<'file' | 'link'>('file');
  const [videoLink, setVideoLink] = useState<string>('');
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const videoFileInputRef = useRef<HTMLInputElement | null>(null);

  // input ids
  const articleTypeSelectId = useId();
  const thumbnailInputId = useId();
  const titleInputId = useId();
  const subtitleInputId = useId();
  const articlePdfFileInputId = useId();
  const checkbox1InputId = useId();
  const checkbox2InputId = useId();
  const podcastAudioFileInputId = useId();
  const videoTypeFileInputId = useId();
  const videoTypeLinkInputId = useId();
  const videoFileInputId = useId();

  // general login check
  const getNewToken = () => {
    getCsrfToken('/consultant/articles').then(token => setCsrfToken(token));
  };

  const checkAuth = () => {
    getProfile().then(profile => {
      setUser(profile);
      setAuthChecked(true);
    });
  };

  useEffect(() => {
    getNewToken();
    checkAuth();
    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      ESidebarType.CONSULTANT,
      [],
      consultantId,
    );
    setMenuItems(sidebarMenuItems);

    document.addEventListener('login:success', () => {
      getNewToken();
      checkAuth();
    });
  }, []);

  useEffect(() => {
    if (authChecked) {
      if (
        user &&
        user.consultantDetails !== undefined &&
        user.id.toString() === consultantId
      ) {
        setIsLoggedInConsultant(true);
      } else {
        setIsLoggedInConsultant(false);
      }
    }
  }, [user, authChecked]);

  useEffect(() => {
    if (isLoggedInConsultant !== undefined) {
      if (!isLoggedInConsultant) {
        navigateTo('', [{ key: EUrlSearchParam.NO_CONSULTANT, value: 'true' }]);
      }
    }
  }, [isLoggedInConsultant]);

  useEffect(() => {
    const articleTextWithoutHtml = articleText.replace(REGEX_ARTICLE_TEXT, '');
    setNumberOfArticleTextChars(articleTextWithoutHtml.length);
  }, [articleText]);

  const readThumbnailUrl = () => {
    const reader = new FileReader();

    reader.onload = event => {
      if (event.target && typeof event.target.result === 'string') {
        setThumbnailUrl(event.target.result);
        return;
      }

      setThumbnailUrl(defaultThumbnail);
    };
    if (thumbnail) {
      reader.readAsDataURL(thumbnail);
    } else {
      setThumbnailUrl(defaultThumbnail);
    }
  };

  useEffect(() => {
    readThumbnailUrl();
  }, [thumbnail]);

  const getTypeLabel = (label: string) => {
    let preLabel = '';

    switch (articleType) {
      case EConsultantNewArticleType.PODCAST:
        preLabel = 'Podcast';
        break;
      case EConsultantNewArticleType.VIDEO:
        preLabel = 'Video';
        break;
      case EConsultantNewArticleType.ARTICLE:
      default:
        preLabel = 'Beitrag';
    }

    return `${preLabel} ${label}`;
  };

  const getTileBgColor = () => {
    switch (articleType) {
      case EConsultantNewArticleType.PODCAST:
        return 'bg-consultant-tile-podcast/30';
      case EConsultantNewArticleType.VIDEO:
        return 'bg-consultant-tile-video/30';
      case EConsultantNewArticleType.ARTICLE:
      default:
        return 'bg-consultant-tile-article/30';
    }
  };

  const isSubmitDisabled = () => {
    const isDisabled = !(
      articleType &&
      title &&
      subtitle &&
      checkbox1 &&
      checkbox2
    );
    let result: boolean;

    switch (articleType) {
      case EConsultantNewArticleType.PODCAST:
        result =
          isDisabled ||
          !podcastAudioFile ||
          podcastAudioFile.size > MAX_SIZE.PODCAST;
        break;
      case EConsultantNewArticleType.VIDEO:
        result =
          isDisabled ||
          (videoType === 'file' &&
            (!videoFile || videoFile.size > MAX_SIZE.VIDEO)) ||
          (videoType === 'link' && !videoLink);
        break;
      case EConsultantNewArticleType.ARTICLE:
      default:
        result =
          isDisabled ||
          !articleText ||
          numberOfArticleTextChars > MAX_NUMBER_OF_ARTICLE_TEXT_CHARS;

        if (articlePdfFile) {
          result = result || articlePdfFile.size > MAX_SIZE.PDF;
        }
    }

    return result;
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    if (isSubmitDisabled()) {
      return;
    }

    setIsLoading(true);

    const formData = new FormData();

    formData.append(
      EConsultantNewArticleFormData.ARTICLE_TYPE,
      articleType.toString(),
    );
    formData.append(
      EConsultantNewArticleFormData.THUMBNAIL,
      thumbnailUrl || defaultThumbnail,
    );
    formData.append(EConsultantNewArticleFormData.TITLE, title);
    formData.append(EConsultantNewArticleFormData.SUBTITLE, subtitle);
    // Article
    if (articleType === EConsultantNewArticleType.ARTICLE) {
      formData.append(
        EConsultantNewArticleFormData.ARTICLE_CONTENT,
        articleText,
      );
      if (articlePdfFile) {
        formData.append(
          EConsultantNewArticleFormData.ARTICLE_FILE,
          articlePdfFile,
        );
      }
    }
    // Podcast
    if (articleType === EConsultantNewArticleType.PODCAST) {
      formData.append(
        EConsultantNewArticleFormData.ARTICLE_FILE,
        podcastAudioFile || '',
      );
    }
    // Video
    if (articleType === EConsultantNewArticleType.VIDEO) {
      if (videoType === 'file') {
        formData.append(
          EConsultantNewArticleFormData.ARTICLE_FILE,
          videoFile || '',
        );
      } else {
        formData.append(EConsultantNewArticleFormData.VIDEO_LINK, videoLink);
      }
    }

    formData.append(EConsultantNewArticleFormData.TOKEN, csrfToken);

    createNewConsultantArticle(formData).then(
      (res: IConsultantNewArticleResponse) => {
        const { status } = res;

        setIsLoading(false);

        if (status !== EConsultantNewArticleResponseStatus.SUCCESS) {
          notify(
            () => (
              <>
                <p className='text-lg'>Fehler</p>
                <p className='mt-1'>{status}</p>
              </>
            ),
            'error',
          );
          return;
        }

        navigateTo(ERoute.CONSULTANT_ARTICLES_OVERVIEW, [
          { key: EUrlSearchParam.CONSULTANT_ID, value: consultantId },
          { key: EUrlSearchParam.CONSULTANT_ARTICLE_CREATED, value: 'true' },
        ]);
      },
    );
  };

  return (
    <Layout menuItems={menuItems}>
      {!isLoggedInConsultant ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <BlockContainer>
          <>
            <h1>
              <span className='block text-3xl font-normal uppercase'>
                {headline || 'Meine'}
              </span>
              <span className='block'>{subline || 'Beiträge'}</span>
            </h1>
            <form
              className='form flex flex-col gap-6 mt-12'
              onSubmit={handleSubmit}
            >
              <label htmlFor={articleTypeSelectId} className='2xl:max-w-[50%]'>
                Beitrag Typ*
                <div className='relative'>
                  <select
                    id={articleTypeSelectId}
                    value={articleType}
                    onChange={e =>
                      setArticleType(
                        e.target.value as EConsultantNewArticleType,
                      )
                    }
                    required
                  >
                    <option value={EConsultantNewArticleType.ARTICLE}>
                      Text
                    </option>
                    <option value={EConsultantNewArticleType.PODCAST}>
                      Podcast
                    </option>
                    <option value={EConsultantNewArticleType.VIDEO}>
                      Video
                    </option>
                  </select>
                  <ChevronDownIcon className='absolute top-1/2 right-4 -translate-y-1/2 mt-1' />
                </div>
              </label>
              <div>
                <p className='font-semibold'>{getTypeLabel('Thumbnail')}</p>
                <div className='flex items-center gap-3'>
                  <div className='input-file-wrapper flex-1 2xl:max-w-[50%]'>
                    <div className='relative flex-1'>
                      <input
                        type='text'
                        value={thumbnail ? thumbnail.name : ''}
                        disabled
                      />
                      {thumbnail && (
                        <button
                          type='button'
                          className='absolute top-1/2 right-2.5 -translate-y-1/2 flex items-center justify-center w-4 h-4 rounded-full bg-gradient-to-b from-orange-gradient-from to-orange-gradient-to'
                          onClick={() => {
                            if (
                              thumbnailInputRef &&
                              thumbnailInputRef.current
                            ) {
                              thumbnailInputRef.current.value = '';
                              setThumbnail(null);
                            }
                          }}
                        >
                          <CloseIcon className='w-3 h-3 fill-white' />
                        </button>
                      )}
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label htmlFor={thumbnailInputId}>Browse</label>
                    <input
                      ref={thumbnailInputRef}
                      id={thumbnailInputId}
                      type='file'
                      accept='image/*'
                      onChange={e =>
                        setThumbnail(
                          e.target.files && e.target.files.length
                            ? e.target.files.item(0)
                            : null,
                        )
                      }
                      hidden
                    />
                  </div>
                  <p
                    className={`text-sm mt-2 ${
                      thumbnail && thumbnail.size > MAX_SIZE.THUMBNAIL
                        ? 'text-red-500'
                        : 'text-grey-dark'
                    }`}
                  >
                    Max. Größe: 5MB
                  </p>
                </div>
              </div>
              <label htmlFor={titleInputId} className='2xl:max-w-[75%]'>
                Titel*
                <input
                  id={titleInputId}
                  type='text'
                  maxLength={35}
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  required
                />
              </label>
              <label htmlFor={subtitleInputId} className='2xl:max-w-[75%]'>
                Untertitel*
                <input
                  id={subtitleInputId}
                  type='text'
                  maxLength={50}
                  value={subtitle}
                  onChange={e => setSubtitle(e.target.value)}
                  required
                />
              </label>
              {/* ---------- ARTICLE ----------*/}
              {articleType === EConsultantNewArticleType.ARTICLE && (
                <>
                  <div className='relative'>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label>Beitrag Text*</label>
                    <EditorProvider>
                      <Editor
                        value={articleText}
                        onChange={e => setArticleText(e.target.value)}
                      >
                        <Toolbar>
                          <BtnStyles />
                          <Separator />
                          <BtnBold />
                          <BtnItalic />
                          <BtnUnderline />
                          <Separator />
                          <BtnNumberedList />
                          <BtnBulletList />
                          <Separator />
                          <BtnLink />
                        </Toolbar>
                      </Editor>
                    </EditorProvider>
                    <p className='absolute left-4 bottom-4 text-grey-dark text-sm'>
                      Zeichen:{' '}
                      <span
                        className={
                          numberOfArticleTextChars >
                          MAX_NUMBER_OF_ARTICLE_TEXT_CHARS
                            ? 'text-red-500 font-bold'
                            : ''
                        }
                      >
                        {numberOfArticleTextChars}
                      </span>{' '}
                      / {MAX_NUMBER_OF_ARTICLE_TEXT_CHARS}
                    </p>
                  </div>
                  <div>
                    <p className='font-semibold'>Pdf-Datei hinzufügen</p>
                    <div className='flex items-center gap-3'>
                      <div className='input-file-wrapper flex-1 2xl:max-w-[50%]'>
                        <div className='relative flex-1'>
                          <input
                            type='text'
                            value={articlePdfFile ? articlePdfFile.name : ''}
                            disabled
                          />
                          {articlePdfFile && (
                            <button
                              type='button'
                              className='absolute top-1/2 right-2.5 -translate-y-1/2 flex items-center justify-center w-4 h-4 rounded-full bg-gradient-to-b from-orange-gradient-from to-orange-gradient-to'
                              onClick={() => {
                                if (
                                  articlePdfInputRef &&
                                  articlePdfInputRef.current
                                ) {
                                  articlePdfInputRef.current.value = '';
                                  setArticlePdfFile(null);
                                }
                              }}
                            >
                              <CloseIcon className='w-3 h-3 fill-white' />
                            </button>
                          )}
                        </div>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor={articlePdfFileInputId}>Browse</label>
                        <input
                          ref={articlePdfInputRef}
                          id={articlePdfFileInputId}
                          type='file'
                          accept='.pdf'
                          onChange={e =>
                            setArticlePdfFile(
                              e.target.files && e.target.files.length
                                ? e.target.files.item(0)
                                : null,
                            )
                          }
                          hidden
                        />
                      </div>
                      <p
                        className={`text-sm mt-2 ${
                          articlePdfFile && articlePdfFile.size > MAX_SIZE.PDF
                            ? 'text-red-500'
                            : 'text-grey-dark'
                        }`}
                      >
                        Max. Größe: 75MB
                      </p>
                    </div>
                  </div>
                </>
              )}
              {/* ---------- PODCAST ----------*/}
              {articleType === EConsultantNewArticleType.PODCAST && (
                <div>
                  <p className='font-semibold'>Audiodatei hinzufügen*</p>
                  <div className='flex items-center gap-3'>
                    <div className='input-file-wrapper flex-1 2xl:max-w-[50%]'>
                      <div className='relative flex-1'>
                        <input
                          type='text'
                          value={podcastAudioFile ? podcastAudioFile.name : ''}
                          disabled
                        />
                        {podcastAudioFile && (
                          <button
                            type='button'
                            className='absolute top-1/2 right-2.5 -translate-y-1/2 flex items-center justify-center w-4 h-4 rounded-full bg-gradient-to-b from-orange-gradient-from to-orange-gradient-to'
                            onClick={() => {
                              if (
                                podcastAudioInputRef &&
                                podcastAudioInputRef.current
                              ) {
                                podcastAudioInputRef.current.value = '';
                                setPodcastAudioFile(null);
                              }
                            }}
                          >
                            <CloseIcon className='w-3 h-3 fill-white' />
                          </button>
                        )}
                      </div>
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor={podcastAudioFileInputId}>Browse</label>
                      <input
                        ref={podcastAudioInputRef}
                        id={podcastAudioFileInputId}
                        type='file'
                        accept='.mp3'
                        onChange={e =>
                          setPodcastAudioFile(
                            e.target.files && e.target.files.length
                              ? e.target.files.item(0)
                              : null,
                          )
                        }
                        required
                        hidden
                      />
                    </div>
                    <p
                      className={`text-sm mt-2 ${
                        podcastAudioFile &&
                        podcastAudioFile.size > MAX_SIZE.PODCAST
                          ? 'text-red-500'
                          : 'text-grey-dark'
                      }`}
                    >
                      Max. Größe: 50MB
                    </p>
                  </div>
                </div>
              )}
              {/* ---------- VIDEO ----------*/}
              {articleType === EConsultantNewArticleType.VIDEO && (
                <div>
                  <p className='font-semibold'>Videodatei hinzufügen*</p>
                  <div className='flex items-center gap-5 mt-2'>
                    <label htmlFor={videoTypeFileInputId} className='radio'>
                      <input
                        id={videoTypeFileInputId}
                        type='radio'
                        checked={videoType === 'file'}
                        value='file'
                        onChange={() => setVideoType('file')}
                      />
                      Video Datei
                    </label>
                    <label htmlFor={videoTypeLinkInputId} className='radio'>
                      <input
                        id={videoTypeLinkInputId}
                        type='radio'
                        checked={videoType === 'link'}
                        value='link'
                        onChange={() => setVideoType('link')}
                      />
                      Externer Link
                    </label>
                  </div>
                  {videoType === 'file' && (
                    <div className='flex items-center gap-3'>
                      <div className='input-file-wrapper flex-1 2xl:max-w-[50%]'>
                        <div className='relative flex-1'>
                          <input
                            type='text'
                            value={videoFile ? videoFile.name : ''}
                            disabled
                          />
                          {videoFile && (
                            <button
                              type='button'
                              className='absolute top-1/2 right-2.5 -translate-y-1/2 flex items-center justify-center w-4 h-4 rounded-full bg-gradient-to-b from-orange-gradient-from to-orange-gradient-to'
                              onClick={() => {
                                if (
                                  videoFileInputRef &&
                                  videoFileInputRef.current
                                ) {
                                  videoFileInputRef.current.value = '';
                                  setVideoFile(null);
                                }
                              }}
                            >
                              <CloseIcon className='w-3 h-3 fill-white' />
                            </button>
                          )}
                        </div>
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label htmlFor={videoFileInputId}>Browse</label>
                        <input
                          ref={videoFileInputRef}
                          id={videoFileInputId}
                          type='file'
                          accept='video/*'
                          onChange={e =>
                            setVideoFile(
                              e.target.files && e.target.files.length
                                ? e.target.files.item(0)
                                : null,
                            )
                          }
                          required
                          hidden
                        />
                      </div>
                      <p
                        className={`text-sm mt-2 ${
                          videoFile && videoFile.size > MAX_SIZE.VIDEO
                            ? 'text-red-500'
                            : 'text-grey-dark'
                        }`}
                      >
                        Max. Größe: 800MB
                      </p>
                    </div>
                  )}
                  {videoType === 'link' && (
                    <input
                      type='text'
                      className='2xl:max-w-[50%]'
                      value={videoLink}
                      onChange={e => setVideoLink(e.target.value)}
                      required
                    />
                  )}
                </div>
              )}
              <div>
                <p className='font-semibold'>Vorschau</p>
                <div
                  className={`w-52 p-2.5 text-black rounded-xl mt-2 ${getTileBgColor()}`}
                >
                  <div className='relative pt-[100%] rounded-md shadow-md'>
                    <div
                      className='absolute inset-0 bg-center bg-no-repeat bg-cover rounded-md'
                      style={{
                        backgroundImage: `url(${thumbnailUrl})`,
                      }}
                    />
                  </div>
                  <div className='h-12 px-2 mt-2'>
                    <p className='font-bold line-clamp-2'>{title}</p>
                  </div>
                </div>
              </div>
              <label
                htmlFor={checkbox1InputId}
                className='checkbox 2xl:max-w-[75%]'
              >
                <input
                  id={checkbox1InputId}
                  type='checkbox'
                  checked={checkbox1}
                  onChange={e => setCheckbox1(e.target.checked)}
                  required
                />
                {checkbox1Label}*
              </label>
              <label
                htmlFor={checkbox2InputId}
                className='checkbox 2xl:max-w-[75%]'
              >
                <input
                  id={checkbox2InputId}
                  type='checkbox'
                  checked={checkbox2}
                  onChange={e => setCheckbox2(e.target.checked)}
                  required
                />
                {checkbox2Label}*
              </label>
              <p className='text-sm text-grey-dark'>* Pflichtfeld</p>
              {/* ---------- BUTTONS ----------*/}
              <div className='flex items-center justify-between gap-5 mt-12'>
                <Button
                  type='button'
                  label={getTypeLabel('löschen')}
                  buttonType={EButtonType.TERTIARY}
                  onClick={() =>
                    navigateTo(ERoute.CONSULTANT_ARTICLES_OVERVIEW, [
                      {
                        key: EUrlSearchParam.CONSULTANT_ID,
                        value: consultantId,
                      },
                    ])
                  }
                />
                <Button
                  type='submit'
                  label={getTypeLabel('posten')}
                  isLoading={isLoading}
                  disabled={isSubmitDisabled()}
                />
              </div>
            </form>
          </>
        </BlockContainer>
      )}
      <Footer />
    </Layout>
  );
};
