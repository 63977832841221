import React from 'react';

import { INavigation } from 'types/interfaces/sulu/INavigation';

const isActive = (activeLinkUrl: string, menuItem: INavigation): boolean => {
  if (activeLinkUrl) {
    return `/${menuItem.locale}${menuItem.url}` === activeLinkUrl;
  }

  return (
    window.location.pathname.startsWith(
      `/${menuItem.locale}${menuItem.url}/`,
    ) || window.location.pathname === `/${menuItem.locale}${menuItem.url}`
  );
};

export default (
  items: INavigation[],
  schemeColor: string,
  activeLinkUrl: string,
  hoverMenuItemId: string,
  setHoverMenuItemId: Function,
  handleClick?: Function,
) => {
  const getLinkStyles = (link: INavigation) => {
    if (isActive(activeLinkUrl, link)) {
      return { color: '#fff', backgroundColor: schemeColor, fontWeight: 500 };
    }

    return {
      backgroundColor: hoverMenuItemId === link.id ? schemeColor : '',
    };
  };

  return items.map(item => (
    <a
      key={item.id}
      href={`/${item.locale}${item.url}`}
      className='px-4 py-3 rounded-md hover:text-white'
      style={getLinkStyles(item)}
      onMouseOver={() => setHoverMenuItemId(item.id)}
      onFocus={() => setHoverMenuItemId(item.id)}
      onMouseOut={() => setHoverMenuItemId('')}
      onBlur={() => setHoverMenuItemId('')}
      onClick={() => {
        if (handleClick) {
          handleClick();
        }
      }}
    >
      {item.title}
    </a>
  ));
};
