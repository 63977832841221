import React, { useEffect, useState } from 'react';

import { ISCBWysiwyg } from 'types/interfaces/sulu/IContentBlocks';

import LinkPopup from './linkPopup';

const Wysiwyg = ({ content }: ISCBWysiwyg) => {
  const [parsedContent, setParsedContent] = useState<string>(content);

  /**
   * WORKAROUND
   * replace keyword [TAB] with an unstyled list to get
   * the same indent as <ul> but without a bullet point
   */
  const replaceTabWithList = () => {
    let replacedContent = parsedContent;
    replacedContent = replacedContent.replaceAll(
      '<p>[TAB]',
      '<ul class="list-none"><li>',
    );
    replacedContent = replacedContent.replaceAll('[/TAB]</p>', '</li></ul>');
    setParsedContent(replacedContent);
  };

  /**
   * WORKAROUND
   * get the content between the keywords [ID] and [/ID] and
   * use this as the value for the attribute id on the
   * html element in which the keywords are located
   */
  const createHtmlIds = () => {
    const ids = [...parsedContent.matchAll(/\[ID\](.*?)\[\/ID\]/g)].map(res =>
      res.length > 1 ? res[1] : '',
    );
    let replacedContent = parsedContent;
    let startIndexHtmlTag = 0;
    let endIndexHtmlTag = 0;
    let htmlTag = '';
    let htmlTagWithId = '';

    ids.forEach(id => {
      startIndexHtmlTag = replacedContent.lastIndexOf(
        '<',
        replacedContent.indexOf(id),
      );
      endIndexHtmlTag = replacedContent.lastIndexOf(
        '>',
        replacedContent.indexOf(id),
      );
      htmlTag = replacedContent.substring(startIndexHtmlTag, endIndexHtmlTag);
      htmlTagWithId = `${htmlTag} id="${id}">`;

      // remove [ID]<id>[/ID] from result content
      replacedContent = replacedContent.replaceAll(`[ID]${id}[/ID]`, '');
      replacedContent =
        replacedContent.substring(0, startIndexHtmlTag) +
        htmlTagWithId +
        replacedContent.substring(endIndexHtmlTag + 1);
    });

    setParsedContent(replacedContent);
  };

  /**
   * WORKAROUND
   * find links or text between [HOVER] and [/HOVER] and display
   * a small popup when hovering over the text or link
   */
  const addLinkPopup = () => {
    let replacedContent = parsedContent;

    replacedContent = replacedContent.replaceAll(
      /\[POPUP\](.*?)\[\/POPUP\]/g,
      innerContent => {
        // Check if the content contains a link
        const linkRegex = /<a\s+([^>]+)>(.*?)<\/a>/;
        const matchLink = innerContent.match(linkRegex);

        if (matchLink) {
          // If it's a link, extract the href attribute
          const linkAttributes = matchLink[1];
          const linkText = matchLink[2];
          const urlMatch = linkAttributes.match(/href="([^"]+)"/);
          const url = urlMatch ? urlMatch[1] : '';

          // Add the link with hover-popup functionality
          return `<a ${linkAttributes} class="hover-popup underline text-orange" data-popup="${url}" onclick="event.preventDefault();">${linkText}</a>`;
        }
        return innerContent;
      },
    );

    setParsedContent(replacedContent);
  };

  useEffect(() => {
    replaceTabWithList();
    createHtmlIds();
    addLinkPopup();
  }, [parsedContent]);

  return (
    <>
      {/* eslint-disable-next-line react/no-danger */}
      <LinkPopup />
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: parsedContent }} />
    </>
  );
};

export default Wysiwyg;
