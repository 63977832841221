import { ESuluNavigationContexts } from 'types/enums/sulu/EBackendData';
import { INavigation } from 'types/interfaces/sulu/INavigation';

import currentLanguage from './currentLanguage';

export default async (
  context: ESuluNavigationContexts,
): Promise<INavigation[]> => {
  const response = await fetch(
    `/${currentLanguage}/api/navigations/${context}?depth=10&flat=true`,
  );
  if (response.ok) {
    const jsonResponse = await response.json();
    // eslint-disable-next-line no-underscore-dangle
    return jsonResponse._embedded.items;
  }
  return [];
};
