import React, {
  ChangeEvent,
  ChangeEventHandler,
  FormEvent,
  useEffect,
  useId,
  useState,
} from 'react';
import { useLocation } from 'react-use';

import AddIcon from 'assets/icons/add.svg';
import GlobeIcon from 'assets/icons/globe.svg';
import InfoIcon from 'assets/icons/info.svg';
import UploadIcon from 'assets/icons/upload.svg';
import Logo from 'assets/images/logo.svg';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import { Button } from 'components/static/Button';
import ComboBox from 'components/static/ComboBox';
import Dialog from 'components/static/Dialog';
import Disclosure from 'components/static/Disclosure';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';

import { ComboBoxOption } from 'types/TComboBoxOption';
import { EButtonType } from 'types/enums/EButton';
import { ERoute } from 'types/enums/ERoute';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { EConsultantRegistrationFormData } from 'types/enums/sulu/EFormData';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { ISPConsultantRegistration } from 'types/interfaces/sulu/IPages';
import { IUser } from 'types/interfaces/sulu/IUser';

import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import navigateTo from 'utils/navigateTo';
import getCsrfToken from 'utils/sulu/getCsrfToken';
import getProfile from 'utils/sulu/getProfile';
import register from 'utils/sulu/register';
import updateConsultantProfile from 'utils/sulu/updateConsultantProfile';

export default ({ content }: ISPConsultantRegistration) => {
  const {
    introduction,
    checkbox_1_label: checkbox1Label,
    checkbox_2_label: checkbox2Label,
    countries,
    languages,
    categories_blocks: categoriesBlock,
  } = content;

  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const consultantId = searchParams.get(EUrlSearchParam.CONSULTANT_ID) ?? '';

  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoggedInConsultant, setIsLoggedInConsultant] = useState<
    boolean | undefined
  >(undefined);
  const [authChecked, setAuthChecked] = useState<boolean>(false);

  const checkAuth = () => {
    getProfile().then(profile => {
      setUser(profile);
      setAuthChecked(true);
    });
  };

  /**
   * ------------------- Page 1 -------------------
   * */
  const companyOptions: ComboBoxOption[] = [];
  const firstNameInputId = useId();
  const lastNameInputId = useId();
  const emailInputId = useId();
  const passwordInputId = useId();
  const passwordConfirmInputId = useId();
  const companyId = useId();
  const checkbox1 = useId();
  const checkbox2 = useId();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState('');
  const [company, setCompany] = useState('');
  const [companyOption, setCompanyOption] = useState<ComboBoxOption>(
    companyOptions[0],
  );
  const [stateCheckbox1, setStateCheckbox1] = useState(false);
  const [stateCheckbox2, setStateCheckbox2] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const isButtonDisabled = () => {
    if (isLoggedInConsultant) {
      return !(firstName && lastName && email && (company || companyOption));
    }
    return !(
      firstName &&
      lastName &&
      email &&
      password &&
      !passwordError &&
      passwordConfirm &&
      !passwordConfirmError &&
      (company || companyOption) &&
      stateCheckbox1 &&
      stateCheckbox2
    );
  };

  /**
   * ------------------- Page 2 -------------------
   * */
  const titleId = useId();
  const firstNameDisplayId = useId();
  const lastNameDisplayId = useId();
  const emailDisplayId = useId();
  const myOfferId = useId();
  const linkedInId = useId();
  const instaId = useId();
  const facebookId = useId();
  const countryDisclosureId = useId();
  const languageDisclosureId = useId();
  const categoryDisclosureId = useId();
  const focalPointsDisclosureId = useId();
  const fileUploadId = useId();
  const uploadedImageId = useId();
  const miscellaneousFocalPointId = useId();

  const [title, setTitle] = useState('');
  const [myOffer, setMyOffer] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [insta, setInsta] = useState('');
  const [facebook, setFacebook] = useState('');
  const [countryList] = useState<string[]>(
    countries.split(';').map(c => c.trim()),
  );
  const importantCountries = [
    'deutschland',
    'österreich',
    'schweiz',
    'spanien',
    'frankreich',
    'niederlande',
  ];
  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);
  const [languageList] = useState<string[]>(
    languages.split(';').map(l => l.trim()),
  );
  const importantLanguages = [
    'deutsch',
    'englisch',
    'französisch',
    'spanisch',
    'niederländisch',
  ];
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [languagesError, setLanguagesError] = useState('');
  const [categoryList] = useState<string[]>(categoriesBlock.map(c => c.title));
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [categoriesError, setCategoriesError] = useState('');
  const [focalPointList, setFocalPointList] = useState<string[]>([]);
  const [selectedFocalPoints, setSelectedFocalPoints] = useState<string[]>([]);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [file, setFile] = useState<File>();
  const [fileSrc, setFileSrc] = useState<string>('');
  const [miscellaneousFocalPoint, setMiscellaneousFocalPoint] = useState(false);
  const [miscellaneousFocalPointValue, setMiscellaneousFocalPointValue] =
    useState('');

  const handleCategoryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const category = e.target.value;

    let copyCategories = [...selectedCategories];

    if (isChecked) {
      copyCategories.push(category);
    } else {
      copyCategories = copyCategories.filter(c => c !== category);
    }

    setSelectedCategories(copyCategories);

    if (copyCategories.length > 5) {
      setCategoriesError('Bitte wählen Sie maximal 5 Kategorien aus.');
    } else {
      setCategoriesError('');
    }

    // generate array of focalpoints for each category as entry -> for example [[ca1_fp1, ca1_fp2], [ca2_fp1, ca2_fp2]]
    const copyFocalPoints = categoriesBlock
      .filter(categoryItem => copyCategories.includes(categoryItem.title))
      .map(categoryItem =>
        categoryItem.focalpoints
          .split(';')
          .map(focalpoint => `${categoryItem.title}_${focalpoint.trim()}`),
      );

    // flat out the array to get a single dimensional array -> for example [ca1_fp1, ca1_fp2, ca2_fp1, ca2_fp2]
    const singleFocalPointsArray = copyFocalPoints.flat();

    if (copyCategories.length === 0) {
      setFocalPointList([]);
    } else {
      setFocalPointList(singleFocalPointsArray);
    }
  };

  const handleCountryChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const country = e.target.value;

    let copyCountries = [...selectedCountries];

    if (isChecked) {
      copyCountries.push(country);
    } else {
      copyCountries = copyCountries.filter(c => c !== country);
    }

    setSelectedCountries(copyCountries);
  };

  const handleLanguageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const language = e.target.value;

    let copyLanguages = [...selectedLanguages];

    if (isChecked) {
      copyLanguages.push(language);
    } else {
      copyLanguages = copyLanguages.filter(l => l !== language);
    }

    setSelectedLanguages(copyLanguages);

    if (copyLanguages.length > 7) {
      setLanguagesError('Bitte wählen Sie maximal 7 Sprachen aus.');
    } else {
      setLanguagesError('');
    }
  };

  const handleFocalPointChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    const focalPoint = e.target.value;

    let copyFocalPoint = [...selectedFocalPoints];

    if (isChecked) {
      copyFocalPoint.push(focalPoint);
    } else {
      copyFocalPoint = copyFocalPoint.filter(c => c !== focalPoint);
    }

    setSelectedFocalPoints(copyFocalPoint);
  };

  const fillDisclosureOptions = (
    items: string[],
    handleChange: ChangeEventHandler<HTMLInputElement>,
    selectedList: string[] = [],
    importantOptionsList: string[] = [],
  ) => {
    const normalizedList = items.map(c => c.toLowerCase());
    const relevantOptionList = importantOptionsList
      .filter(e => (normalizedList.includes(e) ? e : null))
      .map(e => e.charAt(0).toUpperCase() + e.slice(1));

    const newItemsList = items.filter(
      e => !importantOptionsList.includes(e.toLowerCase()),
    );

    return (
      <div className='bg-grey-background'>
        <div className='pl-8 flex flex-col'>
          {relevantOptionList.map(importantOption => (
            <label htmlFor={importantOption} key={importantOption}>
              <div className='flex mb-1'>
                <input
                  id={importantOption}
                  type='checkbox'
                  value={importantOption}
                  checked={selectedList.includes(importantOption)}
                  onChange={handleChange}
                  className='grow-0 shrink-0'
                />
                <span className='font-normal'>{importantOption}</span>
              </div>
            </label>
          ))}
        </div>
        {relevantOptionList.length > 0 ? <hr className='mb-4 mt-3' /> : ''}
        <div className='pl-8 flex flex-col'>
          {newItemsList.map(item => (
            <label htmlFor={item} key={item}>
              <div className='flex mb-1'>
                <input
                  id={item}
                  type='checkbox'
                  value={item}
                  checked={selectedList.includes(item)}
                  onChange={handleChange}
                  className='grow-0 shrink-0'
                />
                <span className='font-normal'>{item}</span>
              </div>
            </label>
          ))}
        </div>
      </div>
    );
  };

  const fillFocalPointsDisclosureOptions = (
    items: string[],
    handleChange: ChangeEventHandler<HTMLInputElement>,
    selectedList: string[] = [],
  ) => {
    const flatout = items
      .map(entry => [
        {
          Key: entry,
          ValueCategory: entry.split('_')[0],
          ValueFocalPoint: entry.split('_')[1],
        },
      ])
      .flat();
    let previousCategoryValue = '';
    const loopObject = flatout.map(object => {
      if (previousCategoryValue !== object.ValueCategory) {
        previousCategoryValue = object.ValueCategory;

        return {
          Key: object.Key,
          ValueCategory: previousCategoryValue,
          ValueFocalPoint: object.ValueFocalPoint,
        };
      }

      return {
        Key: object.Key,
        ValueCategory: '',
        ValueFocalPoint: object.ValueFocalPoint,
      };
    });

    return (
      <div className='pl-8 flex flex-col'>
        {loopObject.map(item => (
          <div key={item.Key}>
            {item.ValueCategory && (
              <span className='inline-block mt-4 mb-2'>
                {item.ValueCategory}
              </span>
            )}
            <label htmlFor={item.Key}>
              <div className='flex mb-1'>
                <input
                  id={item.Key}
                  type='checkbox'
                  value={item.Key}
                  checked={selectedList.includes(item.Key)}
                  onChange={handleChange}
                  className='grow-0 shrink-0'
                />
                <span className='font-normal'>{item.ValueFocalPoint}</span>
              </div>
            </label>
          </div>
        ))}
      </div>
    );
  };

  const renderPicture = (e: ChangeEvent<HTMLInputElement>) => {
    const thisFile =
      e.target != null && e.target.files != null ? e.target.files[0] : null;
    const reader = new FileReader();

    reader.onload = function (event) {
      if (event.target != null && typeof event.target.result === 'string') {
        setFileSrc(event.target.result);
      }
    };
    if (thisFile != null) {
      reader.readAsDataURL(thisFile);
      setFile(thisFile);
    }
  };

  const isPage2ForwardButtonDisabled = () =>
    !(
      selectedCategories.length > 0 &&
      selectedCategories.length < 6 &&
      (selectedFocalPoints.length > 0 || miscellaneousFocalPoint) &&
      !languagesError
    );

  /**
   * ------------------- Page 3 -------------------
   * */
  const companyNameId = useId();
  const companyAddressId = useId();
  const companyPostalCodeId = useId();
  const companyCityId = useId();
  const companyStateId = useId();
  const companyPhoneNumberId = useId();
  const companyWebpageId = useId();
  const companyMailAddressId = useId();
  const aboutCompanyId = useId();
  const accessibilityYesId = useId();
  const accessibilityNoId = useId();
  const confirmationCheckId = useId();
  const federalStateId = useId();

  // const [successMessage, setSuccessMessage] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [companyPostalCode, setCompanyPostalCode] = useState('');
  const [companyCity, setCompanyCity] = useState('');
  const [companyState, setCompanyState] = useState('');
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
  const [companyWebpage, setCompanyWebpage] = useState('');
  const [companyMailAddress, setCompanyMailAddress] = useState('');
  const [aboutCompany, setAboutCompany] = useState('');
  const [accessibility, setAccessibility] = useState('');
  const [confirmationCheck, setConfirmation] = useState(false);
  const [federalState, setFederalState] = useState('');
  const [csrfToken, setCsrfToken] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isError, setIsError] = useState<boolean>(false);

  // const [errorMessage, setErrorMessage] = useState('');

  const isSubmitDisabled = () => {
    const commonConditions =
      companyAddress &&
      companyPostalCode &&
      companyCity &&
      companyState &&
      companyMailAddress &&
      companyPhoneNumber;
    return isLoggedInConsultant
      ? !commonConditions
      : !(commonConditions && confirmationCheck);
  };

  const [pageState, setPageState] = useState('step-1');

  const getNewToken = () => {
    getCsrfToken('/registration').then(token => setCsrfToken(token));
  };

  useEffect(() => {
    setLoading(true);
    getNewToken();
    checkAuth();
    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      ESidebarType.DEFAULT,
    );
    setMenuItems(sidebarMenuItems);
    document.addEventListener('login:success', () => {
      getNewToken();
      checkAuth();
    });
  }, []);

  useEffect(() => {
    if (authChecked) {
      if (
        user &&
        user.consultantDetails !== undefined &&
        user.id.toString() === consultantId
      ) {
        setIsLoggedInConsultant(true);

        /**
         * insert data for edit mode
         */
        setFirstName(user.firstName || '');
        setLastName(user.lastName || '');
        setEmail(user.email || '');
        if (user && user.consultantDetails) {
          setTitle(user.consultantDetails.title || '');
          setMyOffer(user.consultantDetails.myOffer || '');
          // TODO:
          // setLinkedIn(user.consultantDetails.LinkedIn || '');
          // setInsta(user.consultantDetails.insta || '');
          // setFacebook(user.consultantDetails.facebook || '');
          setSelectedCountries(user.consultantDetails.countries || []);
          setSelectedLanguages(user.consultantDetails.languages || []);
          setSelectedCategories(user.consultantDetails.categories || []);
          setSelectedFocalPoints(user.consultantDetails.focalPoints || []);
          // TODO:
          // setFileSrc(user.consultantDetails.fileScr || '');
          // setFile(user.consultantDetails.file || '');
        }
        if (user && user.consultantDetails && user.consultantDetails.company) {
          setCompany(user.consultantDetails.company?.companyName || '');
          setCompanyAddress(user.consultantDetails.company.street || '');
          setCompanyPostalCode(user.consultantDetails.company.plz || '');
          setCompanyCity(user.consultantDetails.company.ort || '');
          setCompanyState(user.consultantDetails.company.bundesland || '');
          setCompanyPhoneNumber(user.consultantDetails.company.telephone || '');
          setCompanyWebpage(user.consultantDetails.company.website || '');
          setCompanyMailAddress(user.consultantDetails.company.email || '');
          setAccessibility(
            typeof user.consultantDetails.company.barrierFree === 'boolean'
              ? user.consultantDetails.company.barrierFree.toString()
              : user.consultantDetails.company.barrierFree || '',
          );
          // TODO:
          // setOpeningHours(user.consultantDetails.company.about || '');
        }
      } else {
        setIsLoggedInConsultant(false);
      }
    }
  }, [user, authChecked]);

  useEffect(() => {
    if (isLoggedInConsultant !== undefined) {
      if (user && !!consultantId && !isLoggedInConsultant) {
        navigateTo(ERoute.CONSULTANT_PROFILE, [
          { key: EUrlSearchParam.CONSULTANT_ID, value: consultantId },
        ]);
      }
      setLoading(false);
    }
  }, [isLoggedInConsultant]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const formData = new FormData();

    // Page 1 stuff
    formData.append(EConsultantRegistrationFormData.USERNAME, email);
    formData.append(EConsultantRegistrationFormData.EMAIL, email);
    formData.append(EConsultantRegistrationFormData.PASSWORD, password);
    formData.append(EConsultantRegistrationFormData.FIRST_NAME, firstName);
    formData.append(EConsultantRegistrationFormData.LAST_NAME, lastName);
    formData.append(
      EConsultantRegistrationFormData.CONFIRMATION_TERMS,
      stateCheckbox1 ? 'true' : 'false',
    );
    formData.append(EConsultantRegistrationFormData.COMPANY_NAME, company);

    // Page 2 stuff
    formData.append(EConsultantRegistrationFormData.TITLE, title);

    formData.append(
      EConsultantRegistrationFormData.SOCIALMEDIA_LINKEDIN,
      linkedIn,
    );
    formData.append(EConsultantRegistrationFormData.SOCIALMEDIA_FB, facebook);
    formData.append(EConsultantRegistrationFormData.SOCIALMEDIA_INSTA, insta);
    formData.append(
      EConsultantRegistrationFormData.AVATAR,
      file != null ? file : '',
    );
    formData.append(EConsultantRegistrationFormData.MY_OFFER, myOffer);
    formData.append(
      EConsultantRegistrationFormData.CONSULTANT_CATEGORIES,
      JSON.stringify(selectedCategories),
    );
    formData.append(
      EConsultantRegistrationFormData.CONSULTANT_COUNTRIES,
      JSON.stringify(selectedCountries),
    );
    formData.append(
      EConsultantRegistrationFormData.CONSULTANT_LANGUAGES,
      JSON.stringify(selectedLanguages),
    );
    formData.append(
      EConsultantRegistrationFormData.CONSULTANT_FOCALPOINTS,
      JSON.stringify(selectedFocalPoints),
    );

    if (miscellaneousFocalPoint) {
      formData.append(
        EConsultantRegistrationFormData.CONSULTANT_FOCALPOINT_MISCELLANEOUS,
        miscellaneousFocalPointValue,
      );
    }

    // Page 3 stuff
    formData.append(
      EConsultantRegistrationFormData.COMPANY_STREET,
      companyAddress,
    );
    formData.append(
      EConsultantRegistrationFormData.COMPANY_ABOUT,
      aboutCompany,
    );
    formData.append(
      EConsultantRegistrationFormData.COMPANY_PLZ,
      companyPostalCode,
    );
    formData.append(EConsultantRegistrationFormData.COMPANY_CITY, companyCity);
    formData.append(
      EConsultantRegistrationFormData.COMPANY_COUNTRY,
      companyState,
    );
    formData.append(
      EConsultantRegistrationFormData.COMPANY_FEDERAL_STATE,
      federalState,
    );
    formData.append(
      EConsultantRegistrationFormData.COMPANY_TELEPHONE,
      companyPhoneNumber,
    );
    formData.append(
      EConsultantRegistrationFormData.COMPANY_EMAIL,
      companyMailAddress,
    );
    formData.append(
      EConsultantRegistrationFormData.COMPANY_WEBSITE,
      companyWebpage,
    );
    formData.append(
      EConsultantRegistrationFormData.COMPANY_ACCESSIBILITY,
      accessibility,
    );
    formData.append(
      EConsultantRegistrationFormData.COMPANY_CONFIRMATION_TERMS,
      confirmationCheck ? 'true' : 'false',
    );

    formData.append(EConsultantRegistrationFormData.IS_CONSULTANT, 'true');

    formData.append(EConsultantRegistrationFormData.TOKEN, csrfToken);

    if (isLoggedInConsultant) {
      formData.delete(EConsultantRegistrationFormData.PASSWORD);
      formData.delete(
        EConsultantRegistrationFormData.COMPANY_CONFIRMATION_TERMS,
      );
      formData.delete(EConsultantRegistrationFormData.IS_CONSULTANT);

      updateConsultantProfile(formData).then(res => {
        const { form_errors: errors, success } = res;

        setIsLoading(false);

        if (errors) {
          setIsError(true);
          setErrorMessage(
            'Beim Speichern ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
          );
          return;
        }
        if (success) {
          navigateTo(ERoute.CONSULTANT_PROFILE, [
            { key: EUrlSearchParam.CONSULTANT_ID, value: consultantId },
          ]);
        }
      });
    } else {
      register(formData).then(res => {
        const { form_errors: errors, success } = res;

        setIsLoading(false);

        if (
          res.form_errors &&
          res.form_errors &&
          Object.keys(res.form_errors).length
        ) {
          setIsError(true);
          setErrorMessage(
            Object.keys(res.form_errors)
              .map(key => {
                if (key === 'registration[email]') {
                  return 'Die E-Mail-Adresse ist bereits in Benutzung.';
                }
                if (key === 'registration[username]') {
                  return 'Der Benutzername ist bereits vergeben.';
                }
                return 'Beim Registrieren ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.';
              })
              .join(' '),
          );
          return;
        }
        if (errors && Object.keys(errors).length) {
          setIsError(true);
          setErrorMessage(
            'Beim Registrieren ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
          );
          return;
        }

        if (success) {
          navigateTo('', [
            { key: EUrlSearchParam.REGISTRATION_SUCCESSFUL, value: 'true' },
          ]);
        }
      });
    }
  };

  /**
   * Navigation functions
   */
  const toStep1 = () => {
    setPageState('step-1');
    window.scrollTo(0, 0);
  };

  const toStep2 = () => {
    setPageState('step-2');
    window.scrollTo(0, 0);
  };

  const toStep3 = () => {
    setPageState('step-3');
    window.scrollTo(0, 0);
  };

  return (
    <Layout menuItems={menuItems}>
      {loading ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <div className='2xl:max-w-[75%] mx-auto'>
          <BlockContainer>
            <form className='form' onSubmit={handleSubmit}>
              <div
                className={`step-1 ${pageState !== 'step-1' ? 'hidden' : ''}`}
              >
                <h2 className='text-orange'>
                  <GlobeIcon className='icon inline mr-2 mb-1' />
                  Willkommen bei Welt des Erbens
                </h2>
                <div className='mt-4 mb-12'>
                  <p>
                    {introduction}{' '}
                    <InfoIcon
                      className='icon inline ml-2 cursor-pointer'
                      onClick={() => setTooltipOpen(true)}
                    />
                  </p>
                  <Dialog
                    isOpen={tooltipOpen}
                    handleToggle={setTooltipOpen}
                    titleClassName='text-orange !text-2xl'
                    title='Überschrift'
                    content={
                      <ul>
                        {categoryList.map(item => (
                          <li className='mt-1' key={item}>
                            {item}
                          </li>
                        ))}
                      </ul>
                    }
                  />
                </div>
                <div className='md:flex block'>
                  <div className='flex flex-col gap-6 mb-14 flex-1 mr-4'>
                    <h3>Persönlich</h3>
                    <label htmlFor={firstNameInputId}>
                      Vorname *
                      <input
                        id={firstNameInputId}
                        type='text'
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                        required
                      />
                    </label>
                    <label htmlFor={lastNameInputId}>
                      Nachname *
                      <input
                        id={lastNameInputId}
                        type='text'
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                        required
                      />
                    </label>
                    <label htmlFor={emailInputId}>
                      E-Mail *
                      <input
                        id={emailInputId}
                        type='email'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                        placeholder='user@example.com'
                      />
                    </label>
                    {!isLoggedInConsultant && (
                      <label htmlFor={passwordInputId}>
                        Passwort *
                        <input
                          id={passwordInputId}
                          minLength={8}
                          type='password'
                          value={password}
                          className={`${passwordError ? 'error' : ''}`}
                          onChange={e => {
                            const inputPasswort = e.target.value;

                            if (inputPasswort.length < 8) {
                              e.target.setCustomValidity(
                                'Bitte geben Sie mindestens 8 Zeichen ein',
                              );
                              setPasswordError(
                                'Bitte geben Sie mindestens 8 Zeichen ein',
                              );
                            } else {
                              e.target.setCustomValidity('');
                              setPasswordError('');
                            }

                            setPassword(e.target.value);
                          }}
                          required
                        />
                        {passwordError && (
                          <p className='error'>{passwordError}</p>
                        )}
                      </label>
                    )}
                    {!isLoggedInConsultant && (
                      <label htmlFor={passwordConfirmInputId}>
                        Passwort wiederholen *
                        <input
                          id={passwordConfirmInputId}
                          minLength={8}
                          type='password'
                          className={`${
                            passwordConfirm && passwordConfirm !== password
                              ? 'error'
                              : ''
                          }`}
                          value={passwordConfirm}
                          onChange={e => {
                            const currentPasswordConfirm = e.target.value;

                            if (currentPasswordConfirm !== password) {
                              e.target.setCustomValidity(
                                'Die beiden Passwörter stimmen nicht überein',
                              );
                              setPasswordConfirmError(
                                'Die beiden Passwörter stimmen nicht überein',
                              );
                            } else {
                              e.target.setCustomValidity('');
                              setPasswordConfirmError('');
                            }

                            setPasswordConfirm(currentPasswordConfirm);
                          }}
                          required
                        />
                        {passwordConfirmError && (
                          <p className='error'>{passwordConfirmError}</p>
                        )}
                      </label>
                    )}
                  </div>
                  <div className='flex flex-col gap-6 mb-14 flex-1 md:ml-4'>
                    <h3>Unternehmen</h3>
                    <label htmlFor={companyId}>
                      Name *
                      {companyOptions && companyOptions.length !== 0 ? (
                        <ComboBox
                          options={companyOptions}
                          id={company}
                          selected={companyOption}
                          handleSelected={setCompanyOption}
                        />
                      ) : (
                        <input
                          id={companyId}
                          type='text'
                          value={company}
                          onChange={e => setCompany(e.target.value)}
                          required
                        />
                      )}
                    </label>
                  </div>
                </div>
                {!isLoggedInConsultant && (
                  <label htmlFor={checkbox1} className='checkbox flex mb-6'>
                    <input
                      id={checkbox1}
                      className='!mt-[2px]'
                      type='checkbox'
                      checked={stateCheckbox1}
                      onChange={e => setStateCheckbox1(e.target.checked)}
                      required
                    />
                    <p className='flex-1 pl-2'>{checkbox1Label}</p>
                  </label>
                )}
                {!isLoggedInConsultant && (
                  <label htmlFor={checkbox2} className='checkbox flex mb-16'>
                    <input
                      id={checkbox2}
                      className='!mt-[2px]'
                      type='checkbox'
                      checked={stateCheckbox2}
                      onChange={e => {
                        setStateCheckbox2(e.target.checked);
                      }}
                      required
                    />
                    <p className='flex-1 pl-2'>{checkbox2Label}</p>
                  </label>
                )}
                <div className='mb-14'>
                  <Button
                    type='button'
                    label='Weiter zum Profil'
                    disabled={isButtonDisabled()}
                    addClass='ml-auto'
                    onClick={toStep2}
                  />
                </div>
              </div>
              <div
                className={`step-2 ${pageState !== 'step-2' ? 'hidden' : ''}`}
              >
                <h2 className='mt-6 mb-2'>
                  <span className='uppercase'>Registrierung</span> <br />
                  <span className='font-bold text-5xl'>Profil</span>
                </h2>
                <p className='mb-14'>
                  <Button
                    type='button'
                    label='Anmeldedaten'
                    onClick={toStep1}
                    buttonType={EButtonType.SECONDARY}
                    className='inline-block'
                  />
                  <span className='mr-4 ml-4'>|</span>
                  <span className='text-orange'>Beraterprofil</span>
                  <span className='mr-4 ml-4'>|</span>
                  <Button
                    type='button'
                    label='Unternehmensprofil'
                    onClick={toStep3}
                    buttonType={EButtonType.SECONDARY}
                    className='inline-block'
                    disabled={isPage2ForwardButtonDisabled()}
                  />
                </p>
                <div className='md:flex block'>
                  <div className='flex flex-col gap-6 mb-14 flex-1 mr-4'>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div
                      className='relative bg-gradient-to-t from-[#B5B5B5] to-[#E4E3E5] xl:w-1/2 md:w-2/3 w-60 h-80 rounded-xl flex
                                      items-center cursor-pointer'
                      onClick={() => setDialogIsOpen(true)}
                    >
                      <img
                        className={`object-cover w-full h-full rounded-xl '
                                  ${fileSrc ? '' : 'hidden'}`}
                        id={uploadedImageId}
                        src={fileSrc}
                        alt=''
                      />
                      <div className={`m-auto ${fileSrc ? 'hidden' : ''}`}>
                        <Logo className='w-[75%] h-full m-auto fill-white' />
                      </div>
                      <div
                        className='absolute bottom-0 right-0 bg-gradient-to-b from-orange-gradient-from
                                        to-orange-gradient-to w-11 h-11 mr-2 mb-2 rounded-full text-white
                                        flex justify-center items-center'
                      >
                        <AddIcon className='w-9 h-9 fill-white m-auto' />
                      </div>
                      <Dialog
                        isOpen={dialogIsOpen}
                        handleToggle={setDialogIsOpen}
                        headline='Hinzufügen'
                        title='Profilbild'
                        confirmButton='Hinzufügen'
                        cancelButton='Löschen'
                        dialogPanelClassName='sm:min-w-[540px] min-w-[400px]'
                        handleCancelButton={() => {
                          setFileSrc('');
                          setFile(undefined);
                        }}
                        content={
                          <div className='flex flex-column justify-center items-center relative'>
                            <label
                              className='w-[300px] h-[340px] rounded-xl bg-gradient-to-t from-[#B5B5B5] to-[#E4E3E5]
                                            flex justify-center items-center'
                              htmlFor={fileUploadId}
                            >
                              <UploadIcon className='w-[100%] h-52 fill-white' />
                              <img
                                className={`object-cover w-[100%] h-[100%] rounded-xl '
                                  ${fileSrc ? '' : 'hidden'}`}
                                id={uploadedImageId}
                                src={fileSrc}
                                alt=''
                              />
                              <input
                                onChange={e => renderPicture(e)}
                                className='w-[300px] h-[340px] absolute opacity-0'
                                id={fileUploadId}
                                type='file'
                              />
                            </label>
                          </div>
                        }
                      />
                    </div>
                    <label htmlFor={myOfferId} className='mt-4'>
                      Mein Angebot
                      <textarea
                        id={myOfferId}
                        onChange={e => setMyOffer(e.target.value)}
                        className='w-full border rounded-xl mt-2 p-4 font-normal'
                        rows={10}
                        value={myOffer}
                        maxLength={1000}
                      />
                    </label>
                    <label htmlFor={countryDisclosureId}>
                      zu diesen Staaten berate ich <sup>1</sup>
                      <Disclosure
                        id={countryDisclosureId}
                        title='Staaten'
                        defaultOpen={false}
                      >
                        {fillDisclosureOptions(
                          countryList,
                          handleCountryChange,
                          selectedCountries,
                          importantCountries,
                        )}
                      </Disclosure>
                    </label>
                    <label htmlFor={languageDisclosureId}>
                      Meine Sprachen
                      <Disclosure
                        id={languageDisclosureId}
                        title='Sprachen'
                        defaultOpen={false}
                      >
                        {fillDisclosureOptions(
                          languageList,
                          handleLanguageChange,
                          selectedLanguages,
                          importantLanguages,
                        )}
                      </Disclosure>
                      {languagesError && (
                        <p className='error'>{languagesError}</p>
                      )}
                    </label>
                  </div>
                  <div className='flex flex-col gap-6 mb-14 flex-1 md:ml-4'>
                    <label htmlFor={titleId}>
                      Titel
                      <input
                        id={titleId}
                        type='text'
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                      />
                    </label>
                    <label htmlFor={firstNameDisplayId}>
                      Vorname *
                      <input
                        id={firstNameDisplayId}
                        type='text'
                        value={firstName}
                        onChange={e => setFirstName(e.target.value)}
                        required
                        readOnly
                      />
                    </label>
                    <label htmlFor={lastNameDisplayId}>
                      Nachname *
                      <input
                        id={lastNameDisplayId}
                        type='text'
                        value={lastName}
                        onChange={e => setLastName(e.target.value)}
                        required
                        readOnly
                      />
                    </label>
                    <label htmlFor={emailDisplayId}>
                      E-Mail *
                      <input
                        id={emailDisplayId}
                        type='email'
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        required
                        readOnly
                      />
                    </label>
                    <label htmlFor={linkedInId}>
                      LinkedIN
                      <input
                        id={linkedInId}
                        type='text'
                        value={linkedIn}
                        onChange={e => setLinkedIn(e.target.value)}
                        placeholder='linkedin.com/in/username'
                      />
                    </label>
                    <label htmlFor={facebookId}>
                      Facebook
                      <input
                        id={facebookId}
                        type='text'
                        value={facebook}
                        onChange={e => setFacebook(e.target.value)}
                        placeholder='facebook.com/username'
                      />
                    </label>
                    <label htmlFor={instaId}>
                      Insta
                      <input
                        id={instaId}
                        type='text'
                        value={insta}
                        onChange={e => setInsta(e.target.value)}
                        placeholder='instagram.com/username'
                      />
                    </label>
                    <label
                      htmlFor={categoryDisclosureId}
                      className='mt-[0.86rem]'
                    >
                      Wählen Sie bis zu 5 Kategorien *
                      <Disclosure
                        id={categoryDisclosureId}
                        title='Kategorien'
                        defaultOpen={false}
                      >
                        {fillDisclosureOptions(
                          categoryList,
                          handleCategoryChange,
                          selectedCategories,
                        )}
                      </Disclosure>
                      {categoriesError && (
                        <p className='error'>{categoriesError}</p>
                      )}
                    </label>
                    <label htmlFor={focalPointsDisclosureId}>
                      Meine Schwerpunkte *
                      <Disclosure
                        id={focalPointsDisclosureId}
                        title='Schwerpunkte'
                        defaultOpen={false}
                      >
                        {fillFocalPointsDisclosureOptions(
                          focalPointList,
                          handleFocalPointChange,
                          selectedFocalPoints,
                        )}
                        <label
                          htmlFor={miscellaneousFocalPointId}
                          className='pl-8 flex justify-center items-center'
                        >
                          <div className='w-1/2 mr-2'>
                            <input
                              id={miscellaneousFocalPointId}
                              className='!mt-[2px]'
                              type='checkbox'
                              checked={miscellaneousFocalPoint}
                              onChange={e => {
                                setMiscellaneousFocalPoint(e.target.checked);
                              }}
                            />
                            <span className='font-normal'>Sonstiges:</span>
                          </div>
                          <input
                            className='w-1/2'
                            type='text'
                            value={miscellaneousFocalPointValue}
                            onChange={e =>
                              setMiscellaneousFocalPointValue(e.target.value)
                            }
                            maxLength={40}
                            placeholder='Ihr Schwerpunkt'
                          />
                        </label>
                      </Disclosure>
                    </label>
                  </div>
                </div>
                <div className='mb-14 flex justify-between'>
                  <Button
                    type='button'
                    label='Zurück'
                    onClick={toStep1}
                    buttonType={EButtonType.SECONDARY}
                  />
                  <Button
                    type='button'
                    label='Weiter'
                    disabled={isPage2ForwardButtonDisabled()}
                    addClass='ml-auto'
                    onClick={toStep3}
                  />
                </div>
                <p>
                  <sup>1</sup> nach deren Recht ich die Erlaubnis habe, die von
                  mir angebotene Beratung zu leisten
                </p>
              </div>
              <div
                className={`step-3 ${pageState !== 'step-3' ? 'hidden' : ''}`}
              >
                <h2 className='mt-6 mb-2'>
                  <span className='uppercase'>Registrierung</span> <br />
                  <span className='font-bold text-5xl'>Profil</span>
                </h2>
                <p className='mb-14'>
                  <Button
                    type='button'
                    label='Anmeldedaten'
                    onClick={toStep1}
                    buttonType={EButtonType.SECONDARY}
                    className='inline-block'
                  />
                  <span className='mr-4 ml-4'>|</span>
                  <Button
                    type='button'
                    label='Beraterprofil'
                    onClick={toStep2}
                    buttonType={EButtonType.SECONDARY}
                    className='inline-block'
                  />
                  <span className='mr-4 ml-4'>|</span>
                  <span className='text-orange'>Unternehmensprofil</span>
                </p>
                <div className='md:flex block'>
                  <div className='flex flex-col gap-6 mb-14 flex-1 mr-4'>
                    <h3>Zum Unternehmen</h3>
                    <label htmlFor={companyNameId}>
                      Name des Unternehmens *
                      <input
                        id={companyNameId}
                        type='text'
                        value={company}
                        onChange={e => setCompany(e.target.value)}
                        required
                        readOnly
                      />
                    </label>
                    <label htmlFor={aboutCompanyId} className='mt-4'>
                      Über das Unternehmen
                      <textarea
                        id={aboutCompanyId}
                        onChange={e => setAboutCompany(e.target.value)}
                        className='w-full border rounded-xl mt-2 p-4 font-normal'
                        rows={10}
                        value={aboutCompany}
                        maxLength={1000}
                      />
                    </label>
                  </div>
                  <div className='flex flex-col gap-6 mb-14 flex-1 md:ml-4'>
                    <h3>Kontakt</h3>
                    <label htmlFor={companyAddressId}>
                      Straße und Hausnummer *
                      <input
                        id={companyAddressId}
                        type='text'
                        value={companyAddress}
                        onChange={e => setCompanyAddress(e.target.value)}
                        required
                      />
                    </label>
                    <div className='flex flex-1'>
                      <label
                        className='w-1/2 mr-2'
                        htmlFor={companyPostalCodeId}
                      >
                        PLZ *
                        <input
                          id={companyPostalCodeId}
                          type='text'
                          value={companyPostalCode}
                          onChange={e => setCompanyPostalCode(e.target.value)}
                          required
                        />
                      </label>
                      <label className='w-1/2 ml-2' htmlFor={companyCityId}>
                        Ort *
                        <input
                          id={companyCityId}
                          type='text'
                          value={companyCity}
                          onChange={e => setCompanyCity(e.target.value)}
                          required
                        />
                      </label>
                    </div>
                    <label htmlFor={federalStateId}>
                      Bundesland
                      <input
                        id={federalStateId}
                        type='text'
                        value={federalState}
                        onChange={e => setFederalState(e.target.value)}
                      />
                    </label>
                    <label htmlFor={companyStateId}>
                      Staat *
                      <input
                        id={companyStateId}
                        type='text'
                        value={companyState}
                        onChange={e => setCompanyState(e.target.value)}
                        required
                      />
                    </label>
                    <label htmlFor={companyPhoneNumberId}>
                      Telefonnummer *
                      <input
                        id={companyPhoneNumberId}
                        type='text'
                        value={companyPhoneNumber}
                        onChange={e => setCompanyPhoneNumber(e.target.value)}
                        required
                      />
                    </label>
                    <label htmlFor={companyWebpageId}>
                      Webseite
                      <input
                        id={companyWebpageId}
                        type='text'
                        value={companyWebpage}
                        onChange={e => setCompanyWebpage(e.target.value)}
                        placeholder='www.yourwebpage.com'
                      />
                    </label>
                    <label htmlFor={companyMailAddressId}>
                      E-Mail *
                      <input
                        id={companyMailAddressId}
                        type='text'
                        value={companyMailAddress}
                        onChange={e => setCompanyMailAddress(e.target.value)}
                        required
                        placeholder='yourcompany@example.com'
                      />
                    </label>
                    <h3 className='mt-8'>Weitere Informationen</h3>
                    <p className='font-bold'>Barrierefreier Zugang</p>
                    <div className='flex'>
                      <label
                        htmlFor={accessibilityYesId}
                        className='radio mr-4'
                      >
                        <input
                          id={accessibilityYesId}
                          type='radio'
                          checked={accessibility === 'true'}
                          value='true'
                          onChange={e => setAccessibility(e.target.value)}
                        />
                        Ja
                      </label>
                      <label htmlFor={accessibilityNoId} className='radio ml-4'>
                        <input
                          id={accessibilityNoId}
                          type='radio'
                          checked={accessibility === 'false'}
                          value='false'
                          onChange={e => setAccessibility(e.target.value)}
                        />
                        Nein
                      </label>
                    </div>
                    {!isLoggedInConsultant && (
                      <label
                        htmlFor={confirmationCheckId}
                        className='checkbox flex mb-16'
                      >
                        <input
                          id={confirmationCheckId}
                          className='!mt-[2px]'
                          type='checkbox'
                          checked={confirmationCheck}
                          onChange={e => {
                            setConfirmation(e.target.checked);
                          }}
                          required
                        />
                        <p className='flex-1 pl-2'>
                          Ich bestätige, in dem vorgenannten Staaten einen Sitz,
                          einen Berufsabschluss und die Erlaubnis zu haben, die
                          von mir angebotene Beratung zu leisten.
                        </p>
                      </label>
                    )}
                  </div>
                </div>
                <div className='mb-4 flex justify-between'>
                  <Button
                    type='button'
                    label='Zurück'
                    onClick={toStep2}
                    buttonType={EButtonType.SECONDARY}
                  />
                  <Button
                    type='submit'
                    label={isLoggedInConsultant ? 'Speichern' : 'Registrieren'}
                    isLoading={isLoading}
                    disabled={isSubmitDisabled()}
                    addClass='ml-auto'
                    onClick={toStep3}
                    isError={isError}
                  />
                </div>
                <div className='flex justify-end'>
                  {errorMessage && (
                    <p
                      className={`text-sm ml-4 mb-0 text-right ${
                        errorMessage ? 'text-red-500' : 'text-green-500'
                      }`}
                    >
                      {errorMessage}
                    </p>
                  )}
                </div>
                {isError || <p className='error'>{errorMessage}</p>}
              </div>
            </form>
          </BlockContainer>
        </div>
      )}
      <Footer />
    </Layout>
  );
};
