import React, { FormEvent, useEffect, useId, useState } from 'react';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import { Button } from 'components/static/Button';
import Layout from 'components/static/Layout';

import { EButtonType } from 'types/enums/EButton';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EDeleteAccountFormData } from 'types/enums/sulu/EFormData';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { ISPDeleteAccount } from 'types/interfaces/sulu/IPages';

import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import navigateTo from 'utils/navigateTo';
import deleteAccount from 'utils/sulu/deleteAccount';
import logout from 'utils/sulu/logout';

import './DeleteAccount.css';

export default ({ content }: ISPDeleteAccount) => {
  const { title, article } = content;
  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [deleteAccountCheckbox, setDeleteAccountCheckbox] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const deleteAccountCheckboxInputId = useId();
  const passwordConfirmInputId = useId();

  useEffect(() => {
    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      ESidebarType.PROFILE,
    );
    setMenuItems(sidebarMenuItems);
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setIsError(false);
    setIsLoading(true);
    setErrorMessage('');
    // Prevent the browser from reloading the page
    e.preventDefault();

    const formData = new FormData();
    formData.append(EDeleteAccountFormData.PASSWORD, passwordConfirmation);

    deleteAccount(formData).then(res => {
      const { status, success } = res;

      setIsLoading(false);

      if (success) {
        setIsSuccessful(true);
        // TODO update success message
        setSuccessMessage('Das Löschen Ihres Accounts war erfolgreich.');
        logout().then(successful => {
          if (successful) {
            navigateTo('');
          }
        });
        return;
      }

      setIsError(true);

      if (status === 401) {
        setErrorMessage('Das eingegebene Passwort ist falsch.');
      }

      if (status === 404) {
        setErrorMessage(
          'Ihr Benutzer wurde nicht gefunden oder Sie sind nicht angemeldet.',
        );
      }

      if (status === 500) {
        setErrorMessage(
          'Ihr Account konnte nicht gelöscht werden. Bitte versuchen Sie es erneut.',
        );
      }
    });
  };

  return (
    <Layout menuItems={menuItems} showLogoutButton>
      {/* render content */}
      <BlockContainer>
        <>
          <h3>{title}</h3>
          <div
            className='delete-account-content mt-8'
            dangerouslySetInnerHTML={{ __html: article }}
          />
          <form className='form flex flex-col mt-9' onSubmit={handleSubmit}>
            <label
              htmlFor={deleteAccountCheckboxInputId}
              className='checkbox place-self-start'
            >
              <input
                id={deleteAccountCheckboxInputId}
                type='checkbox'
                checked={deleteAccountCheckbox}
                onChange={e => setDeleteAccountCheckbox(e.target.checked)}
                required
              />
              Ich möchte meinen Account und alle damit verbundenen Daten
              löschen.
            </label>
            <label
              htmlFor={passwordConfirmInputId}
              className='place-self-start mt-5'
            >
              Bestätigen Sie bitte mit Ihrem Passwort
              <input
                id={passwordConfirmInputId}
                type='password'
                value={passwordConfirmation}
                onChange={e => setPasswordConfirmation(e.target.value)}
                required
              />
            </label>
            <div className='flex items-center justify-between mt-12'>
              <Button
                type='submit'
                label='Account löschen'
                isLoading={isLoading}
                isSuccessful={isSuccessful}
                isError={isError}
                disabled={!deleteAccountCheckbox || !passwordConfirmation}
              />
              {!isSuccessful && (
                <Button
                  label='Abbrechen'
                  onClick={() => {
                    // eslint-disable-next-line no-restricted-globals
                    history.back();
                  }}
                  buttonType={EButtonType.SECONDARY}
                />
              )}
            </div>
            {(errorMessage || successMessage) && (
              <p
                className={`text-sm mt-2 ${
                  errorMessage ? 'text-red-500' : 'text-green-500'
                }`}
              >
                {errorMessage || successMessage}
              </p>
            )}
          </form>
        </>
      </BlockContainer>
      <Footer />
    </Layout>
  );
};
