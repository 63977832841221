export enum ESuluDataTypes {
  PAGE = 'page',
  SNIPPET = 'snippet',
}

export enum ESuluSnippetTypes {
  HERO = 'hero',
  FOOTER = 'footer',
}

export enum ESuluPageTypes {
  ALL_CONTENTS = 'all_contents',
  CATEGORY = 'category',
  DEFAULT = 'default',
  DELETE_ACCOUNT = 'delete_account',
  HOMEPAGE = 'homepage',
  MY_LIBRARY = 'my_library',
  CONSULTANT_ARTICLES_OVERVIEW = 'consultant_articles_overview',
  MY_WORLD = 'my_world',
  PASSWORD_RESET = 'password_reset',
  SEARCH = 'search',
  SETTINGS = 'settings',
  TILE = 'tile',
  CONSULTANT_REGISTRATION = 'consultant_registration',
  CONSULTANT_PROFILE = 'consultant_profile',
  CONSULTANT_OVERVIEW = 'consultant_overview',
  CONSULTANT_WELCOME = 'consultant_welcome',
  CONSULTANT_CREATE_ARTICLE = 'consultant_create_article',
  CONSULTANT_NEW_ARTICLE = 'consultant_new_article',
  CONSULTANT_ARTICLE = 'consultant_article',
  CONSULTANT_DELETE_ACCOUNT = 'consultant_delete_account',
}

export enum ESuluContentBlockTypes {
  PAGE = 'page',
  LINK = 'link',
  SOCIAL_MEDIA_LINK = 'social_media_link',
  WYSIWYG = 'wysiwyg',
  IFRAME = 'iframe',
  IMAGE = 'image',
  MEDIA = 'media',
  TWO_COLUMNS = 'two_columns',
  THREE_COLUMNS = 'three_columns',
  TABLE_OF_CONTENTS = 'table_of_contents',
  TOC_ITEM = 'toc_item',
}

export enum ESuluLayoutBlockTypes {
  TWO_COLUMNS = 'two_columns',
  THREE_COLUMNS = 'three_columns',
}

export enum ESuluLayoutBlockVariant {
  FIFTY_FIFTY = '50_50',
  ONE_THIRD_TWO_THIRD = '33_66',
  TWO_THIRD_ONE_THIRD = '66_33',
}

export enum ESuluSettingsScheduleTypes {
  WEEKLY = 'weekly',
  FIXED = 'fixed',
}

export enum ESuluSettingsScheduleWeekDays {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export enum ESuluMediaTypes {
  IMAGE = 'image',
  VIDEO = 'video',
  AUDIO = 'audio',
  DOCUMENT = 'document',
}

export enum ESuluLanguages {
  'EN' = 'en',
  'DE' = 'de',
}

export enum ESuluNavigationContexts {
  INFORMATION = 'information',
  ENTERTAINMENT = 'entertainment',
  CONSULTING = 'consulting',
}

export enum ESuluContentBlockSocialMediaType {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
}

export enum ESuluContentTypeText {
  ARTICLE = 'Artikel',
  PODCAST = 'Podcast',
  VIDEO = 'Video',
}

export enum ESuluTOCItemLevel {
  LEVEL_0 = 'level_0',
  LEVEL_1 = 'level_1',
  LEVEL_2 = 'level_2',
}
