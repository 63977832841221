import { ReactNode } from 'react';
import { toast, ToastContentProps, TypeOptions } from 'react-toastify';

export default (
  content: string | ((props: ToastContentProps<unknown>) => ReactNode),
  type: TypeOptions = 'info',
) =>
  toast(content, {
    theme: 'colored',
    type,
  });
