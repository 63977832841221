import { z } from 'zod';

import {
  ESuluContentBlockSocialMediaType,
  ESuluContentBlockTypes,
} from 'types/enums/sulu/EBackendData';

import ZSCBBase from './ZSCBBase';

const ZSCBSocialMediaLink = ZSCBBase.extend({
  type: z.literal(ESuluContentBlockTypes.SOCIAL_MEDIA_LINK),
  social_media_type: z.nativeEnum(ESuluContentBlockSocialMediaType),
  url: z.string(),
  title: z.string().or(z.null()),
});

export default ZSCBSocialMediaLink;
