import { useEffect } from 'react';

const LinkPopup = () => {
  const handleClickPopup = async (event: MouseEvent) => {
    const target = event.target as HTMLElement;

    if (target.classList.contains('hover-popup')) {
      const url = target.getAttribute('data-popup');

      if (url) {
        const jsonUrl = `${url}.json`;

        // Check if the popup already exists
        let popup = document.querySelector('.popup-data') as HTMLElement | null;
        let overlay = document.querySelector(
          '.popup-overlay',
        ) as HTMLElement | null;

        // Create the overlay if it doesn't exist
        if (!overlay) {
          overlay = document.createElement('div');
          overlay.classList.add(
            'popup-overlay',
            'fixed',
            'top-0',
            'left-0',
            'w-full',
            'h-full',
            'bg-black/30',
            'z-9', // Ensure it appears below the popup
          );
          document.body.appendChild(overlay);
        }

        // Create the popup if it doesn't exist
        if (!popup) {
          popup = document.createElement('div');
          popup.classList.add(
            'popup-data',
            'fixed',
            'top-1/2',
            'left-1/2',
            'transform',
            '-translate-x-1/2',
            '-translate-y-1/2',
            'bg-white',
            'border',
            'border-gray-dark',
            'space-y-4',
            'rounded-xl',
            'shadow-lg',
            'py-5',
            'px-4',
            'z-10',
            'overflow-hidden', // Prevent scrollbars from affecting the outer edges
          );

          // Add close button (X button)
          const closeButton = document.createElement('button');
          closeButton.innerHTML = 'x'; // X symbol
          closeButton.classList.add(
            'absolute',
            'top-1',
            'right-5',
            'text-3xl',
            'bg-transparent',
            'border-none',
            'cursor-pointer',
            'hover:text-orange',
          );
          closeButton.onclick = () => {
            if (popup) popup.remove();
            if (overlay) overlay.remove();
          };
          popup.appendChild(closeButton);

          document.body.appendChild(popup);
        }

        // Ensure the popup content is added to the inner container only
        const contentDiv = document.querySelector(
          '.popup-content',
        ) as HTMLElement | null;

        if (!contentDiv) {
          // Create a new inner content div if it doesn't exist
          const newContentDiv = document.createElement('div');
          newContentDiv.classList.add('popup-content');
          newContentDiv.style.maxHeight = '50vh';
          newContentDiv.style.maxWidth = '70vw';
          newContentDiv.style.overflowY = 'auto';
          newContentDiv.style.overflowX = 'hidden';
          popup.appendChild(newContentDiv);

          // Add CSS styles for responsive design
          const style = document.createElement('style');
          style.textContent = `
            .popup-content {
              max-width: 70vw;
            }
            @media (max-width: 600px) {
              .popup-content {
                max-width: 90vw;
              }
            }
          `;
          document.head.appendChild(style);

          try {
            const response = await fetch(jsonUrl);
            if (response.ok) {
              const data = await response.json();
              const paragraphs = data.content?.article.split(
                /<h[1-6][^>]*>/,
              ) || ['Keine Artikelbeschreibung vorhanden.'];

              // Extract the last part of the URL for the heading
              const urlParts = url.split('/');
              const heading = urlParts[urlParts.length - 1];

              // Capitalize the first letter of the heading
              const formattedHeading =
                heading.charAt(0).toUpperCase() +
                heading.slice(1).toLowerCase();

              newContentDiv.innerHTML = `<h2>${formattedHeading}</h2><p>${paragraphs
                .slice(0, 2)
                .join('<br/>')}</p>`;

              // Add a link at the bottom of the popup to redirect to the URL
              const bottomLink = document.createElement('a');
              bottomLink.href = url;
              bottomLink.target = '_blank'; // Öffnet den Link in einem neuen Tab
              bottomLink.classList.add('text-orange', 'underline');
              bottomLink.innerText = 'Zum Artikel';

              const linkContainer = document.createElement('div');
              linkContainer.classList.add('text-right', 'mt-5', 'mr-5');
              linkContainer.appendChild(bottomLink);

              newContentDiv.appendChild(linkContainer);
            } else {
              newContentDiv.innerHTML = `<p>Error: Unable to fetch data.</p>`;
            }
          } catch (error) {
            newContentDiv.innerHTML = `<p>Error: Unable to fetch data.</p>`;
          }
        }

        // Handle closing the popup if clicked outside of it (on the overlay)
        const handleClickOutside = (outsideClickEvent: MouseEvent) => {
          if (
            popup &&
            overlay &&
            overlay.contains(outsideClickEvent.target as Node)
          ) {
            popup.remove();
            overlay.remove();
            document.removeEventListener('mousedown', handleClickOutside);
          }
        };
        document.addEventListener('mousedown', handleClickOutside);
      }
    }
  };

  useEffect(() => {
    // Listen for click events to open the popup
    document.body.addEventListener('click', handleClickPopup);

    return () => {
      document.body.removeEventListener('click', handleClickPopup);
    };
  }, []);

  return null;
};

export default LinkPopup;
