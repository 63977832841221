import React, { useEffect, useRef, useState } from 'react';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import CategoryHeader from 'components/static/CategoryHeader';
import Layout from 'components/static/Layout';
import NoDataText from 'components/static/NoDataText';
import Pagination from 'components/static/Pagination';

import useIsInViewport from 'hooks/useIsInViewport';

import { ESidebarType } from 'types/enums/ESidebarType';
import { ESuluNavigationContexts } from 'types/enums/sulu/EBackendData';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { ISPCategory } from 'types/interfaces/sulu/IPages';

import { TILES_PER_PAGE } from 'utils/constants';
import createTiles from 'utils/createTiles';
import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import scrollToElement from 'utils/scrollToElement';
import getNavigation from 'utils/sulu/getNavigation';

export default ({ content }: ISPCategory) => {
  const {
    title,
    page_color: bgColor,
    tile_background_image: image,
    tiles,
  } = content;
  const scrollToElementRef = useRef<HTMLHeadingElement>(null);
  const isScrollToElementRefInViewport = useIsInViewport(scrollToElementRef);
  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [numberOfArticles, setNumberOfArticles] = useState<number>(0);
  const [numberOfPodcasts, setNumberOfPodcasts] = useState<number>(0);
  const [numberOfVideos, setNumberOfVideos] = useState<number>(0);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const tilesPerPage = TILES_PER_PAGE;
  const indexOfLastTile = currentPage * tilesPerPage;
  const indexOfFirstTile = indexOfLastTile - tilesPerPage;
  const currentTiles = tiles.slice(indexOfFirstTile, indexOfLastTile);

  const countNumberOf = (type: 'isAudio' | 'isDocument' | 'isVideo') => {
    const count = tiles.filter(tile => {
      if (!tile.content.content) {
        if (type === 'isDocument') return true;
        return false;
      }
      return tile.content.content[type] === true;
    });
    return count.length;
  };

  useEffect(() => {
    setNumberOfArticles(countNumberOf('isDocument'));
    setNumberOfPodcasts(countNumberOf('isAudio'));
    setNumberOfVideos(countNumberOf('isVideo'));
  });

  useEffect(() => {
    // get menu items
    Promise.all([
      getNavigation(ESuluNavigationContexts.INFORMATION),
      getNavigation(ESuluNavigationContexts.ENTERTAINMENT),
      getNavigation(ESuluNavigationContexts.CONSULTING),
    ]).then(subMenuItems => {
      const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
        ESidebarType.TOPICS,
        subMenuItems.flat(),
      );
      setMenuItems(sidebarMenuItems);
    });
  }, []);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      scrollToElement(scrollToElementRef, isScrollToElementRefInViewport);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(tiles.length / tilesPerPage)) {
      setCurrentPage(currentPage + 1);
      scrollToElement(scrollToElementRef, isScrollToElementRefInViewport);
    }
  };

  return (
    <Layout menuItems={menuItems} schemeColor={bgColor}>
      <CategoryHeader
        backgroundColor={bgColor}
        imageUrl={image.url}
        title={title}
        numberOfArticles={numberOfArticles}
        numberOfPodcasts={numberOfPodcasts}
        numberOfVideos={numberOfVideos}
      />
      <BlockContainer>
        <>
          <h3 ref={scrollToElementRef} className='scroll-mt-16'>
            {title}
          </h3>
          <div className='flex items-center justify-center flex-wrap gap-5 mt-5'>
            {tiles.length === 0 && (
              <NoDataText>
                Keine Artikel, Podcasts oder Videos verfügbar
              </NoDataText>
            )}
            {tiles.length > 0 &&
              createTiles(currentTiles, false, bgColor, title.toLowerCase())}
          </div>
          {tiles.length > tilesPerPage && (
            <Pagination
              postsPerPage={tilesPerPage}
              totalPosts={tiles.length}
              currentPage={currentPage}
              previousPage={previousPage}
              nextPage={nextPage}
              className='mt-12'
            />
          )}
        </>
      </BlockContainer>
      <Footer />
    </Layout>
  );
};
