import React, { useEffect, useState } from 'react';

import { ISCBWysiwyg } from 'types/interfaces/sulu/IContentBlocks';

const Wysiwyg = ({ content }: ISCBWysiwyg) => {
  const [parsedContent, setParsedContent] = useState<string>(content);

  /**
   * WORKAROUND
   * replace keyword [TAB] with an unstyled list to get
   * the same indent as <ul> but without a bullet point
   * */
  const replaceTabWithList = () => {
    let replacedContent = parsedContent;
    replacedContent = replacedContent.replaceAll(
      '<p>[TAB]',
      '<ul class="list-none"><li>',
    );
    replacedContent = replacedContent.replaceAll('[/TAB]</p>', '</li></ul>');
    setParsedContent(replacedContent);
  };

  /**
   * WORKAROUND
   * get the content between the keywords [ID] and [/ID] and
   * use this as the value for the attribute id on the
   * html element in which the keywords are located
   * */
  const createHtmlIds = () => {
    const ids = [...parsedContent.matchAll(/\[ID\](.*?)\[\/ID\]/g)].map(res =>
      res.length > 1 ? res[1] : '',
    );
    let replacedContent = parsedContent;
    let startIndexHtmlTag = 0;
    let endIndexHtmlTag = 0;
    let htmlTag = '';
    let htmlTagWithId = '';

    ids.forEach(id => {
      startIndexHtmlTag = replacedContent.lastIndexOf(
        '<',
        replacedContent.indexOf(id),
      );
      endIndexHtmlTag = replacedContent.lastIndexOf(
        '>',
        replacedContent.indexOf(id),
      );
      htmlTag = replacedContent.substring(startIndexHtmlTag, endIndexHtmlTag);
      htmlTagWithId = `${htmlTag} id="${id}">`;

      // remove [ID]<id>[/ID] from result content
      replacedContent = replacedContent.replaceAll(`[ID]${id}[/ID]`, '');
      replacedContent =
        replacedContent.substring(0, startIndexHtmlTag) +
        htmlTagWithId +
        replacedContent.substring(endIndexHtmlTag + 1);
    });

    setParsedContent(replacedContent);
  };

  useEffect(() => {
    replaceTabWithList();
    createHtmlIds();
  });

  return (
    // eslint-disable-next-line react/no-danger
    <div dangerouslySetInnerHTML={{ __html: parsedContent }} />
  );
};

export default Wysiwyg;
