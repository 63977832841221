import React from 'react';

import { ISearch } from 'types/interfaces/sulu/ISearch';

import { DEFAULT_SCHEME_COLOR } from 'utils/constants';

export default ({ document }: ISearch) => {
  const { url, properties } = document;
  const {
    title,
    page_color: bgColor, // main category
    tile_background_image_json: tileBackgroundImageJson, // main category
    'main_category#page_color': mainCategoryBgColor, // detail content
    color: overrideMainCategoryBgColor, // detail content
    photo_json: photoJson, // detail content
    text: textTitle, // detail content
    text_hover: description, // detail content
  } = properties;
  const isMainCategory = !!tileBackgroundImageJson;

  const getBgImageUrl = (): string => {
    if (tileBackgroundImageJson) {
      const tileBackgroundImage = JSON.parse(tileBackgroundImageJson);
      return tileBackgroundImage.url;
    }

    if (photoJson) {
      const photo = JSON.parse(photoJson);
      return photo.url;
    }

    return '';
  };

  const detailContentBgColor =
    overrideMainCategoryBgColor || mainCategoryBgColor || DEFAULT_SCHEME_COLOR;
  const backgroundColor = isMainCategory
    ? bgColor || DEFAULT_SCHEME_COLOR
    : `${detailContentBgColor}50`;
  const bgImageUrl = getBgImageUrl();
  const bgImageStyle = {
    backgroundImage: `url(${bgImageUrl})`,
    backgroundColor: isMainCategory ? '#ffffff75' : detailContentBgColor,
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className='grid grid-cols-[3rem_minmax(0,_1fr)] md:grid-cols-[6rem_minmax(0,_1fr)] items-center gap-x-2.5 md:gap-x-5 p-2.5 mt-2 first:mt-0 rounded-xl cursor-pointer'
      style={{
        backgroundColor,
      }}
      onClick={() => {
        window.location.href = url;
      }}
    >
      <div
        className='relative w-12 h-12 md:w-24 md:h-24 bg-center bg-no-repeat bg-cover rounded-md'
        style={bgImageStyle}
      />
      <div>
        <p
          className={`text-sm md:text-base font-bold line-clamp-1 md:line-clamp-2${
            isMainCategory ? ' text-white' : ''
          }`}
        >
          {textTitle || title}
        </p>
        {description && (
          <p className='text-sm font-light truncate mt-1 md:mt-2'>
            {description}
          </p>
        )}
      </div>
    </div>
  );
};
