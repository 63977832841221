import React, { useEffect, useRef, useState } from 'react';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';
import NoDataText from 'components/static/NoDataText';
import Pagination from 'components/static/Pagination';

import useIsInViewport from 'hooks/useIsInViewport';

import { ESidebarType } from 'types/enums/ESidebarType';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { ISPContentTile, ISPMyLibrary } from 'types/interfaces/sulu/IPages';

import { TILES_PER_PAGE } from 'utils/constants';
import createTiles from 'utils/createTiles';
import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import scrollToElement from 'utils/scrollToElement';
import getPaidContent from 'utils/sulu/getPaidContent';

export default ({ content }: ISPMyLibrary) => {
  const { title } = content;
  const scrollToElementRef = useRef<HTMLHeadingElement>(null);
  const isScrollToElementRefInViewport = useIsInViewport(scrollToElementRef);
  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [tiles, setTiles] = useState<ISPContentTile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const tilesPerPage = TILES_PER_PAGE;
  const indexOfLastTile = currentPage * tilesPerPage;
  const indexOfFirstTile = indexOfLastTile - tilesPerPage;
  const currentTiles = tiles.slice(indexOfFirstTile, indexOfLastTile);

  useEffect(() => {
    setLoading(true);

    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      ESidebarType.PROFILE,
    );
    setMenuItems(sidebarMenuItems);
    getPaidContent().then(paidTiles => {
      setTiles(paidTiles);
      setLoading(false);
    });
  }, []);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      scrollToElement(scrollToElementRef, isScrollToElementRefInViewport);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(tiles.length / tilesPerPage)) {
      setCurrentPage(currentPage + 1);
      scrollToElement(scrollToElementRef, isScrollToElementRefInViewport);
    }
  };

  return (
    <Layout menuItems={menuItems} showLogoutButton>
      <BlockContainer>
        <>
          <h3 ref={scrollToElementRef} className='scroll-mt-16'>
            {title}
          </h3>
          {loading ? (
            <BlockContainer>
              <div className='flex justify-center mt-5'>
                <LoadingSpinner size='w-10 h-10' />
              </div>
            </BlockContainer>
          ) : (
            <>
              <div className='flex items-center justify-center flex-wrap gap-5 mt-5'>
                {tiles.length === 0 && (
                  <NoDataText>Keine Inhalte verfügbar</NoDataText>
                )}
                {tiles.length > 0 && createTiles(currentTiles, true)}
              </div>
              {tiles.length > tilesPerPage && (
                <Pagination
                  postsPerPage={tilesPerPage}
                  totalPosts={tiles.length}
                  currentPage={currentPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  className='mt-12'
                />
              )}
            </>
          )}
        </>
      </BlockContainer>
      <Footer />
    </Layout>
  );
};
