import React from 'react';

import BlockContainer from 'components/BlockContainer';
import Menu from 'components/primitives/Menu';
// import SearchConsultant from 'components/primitives/Search/SearchConsultant';
import Footer from 'components/snippets/Footer';
import Hero from 'components/snippets/Hero';

// import { ERoute } from 'types/enums/ERoute';
import { ISPMyWorld } from 'types/interfaces/sulu/IPages';

// import navigateTo from 'utils/navigateTo';

export default ({ content }: ISPMyWorld) => {
  const { title } = content;
  // const [filterId, setFilterId] = useState<number[]>([]);
  // const [searchTriggered, setSearchTriggered] = useState(false);

  // const navigate = navigateTo;

  /* TODO: remove for prod
     useEffect(() => {
       if (searchTriggered && filterId.length >= 0) {
         navigate(`${ERoute.CONSULTANT_OVERVIEW}`);
       }
     }, [searchTriggered]); */

  return (
    <>
      <Hero />
      <div className='flex items-center justify-center flex-wrap'>
        <div className='lg:w-8/12 md:w-full p-4'>
          {/* TODO: remove for prod
             <SearchConsultant
            filterId={setFilterId}
            setSearchTriggered={setSearchTriggered}
            hideAdvancedSearch
            resetSearch
          />
             */}
        </div>
      </div>
      <Menu />
      {/* render content */}
      <div className='bg-grey-background'>
        <BlockContainer>
          <div className='flex items-center justify-center flex-wrap gap-5'>
            my world here
            <br />
            {title}
          </div>
        </BlockContainer>
      </div>
      <Footer />
    </>
  );
};
