import { IConsultantResponse } from 'types/interfaces/sulu/IUser';

import { TILES_PER_PAGE } from 'utils/constants';

export default async (
  limit: number = TILES_PER_PAGE,
): Promise<IConsultantResponse[]> => {
  const response = await fetch(`/consultants?limit=${limit}`);

  if (response.ok) {
    try {
      const jsonResponse = await response.json();
      return jsonResponse.data;
    } catch (e) {
      return [];
    }
  }

  return [];
};
