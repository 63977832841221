import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Transition,
} from '@headlessui/react';
import clsx from 'clsx';
import React, { useState } from 'react';

import CheckIcon from 'assets/icons/check.svg';
import ChevronDownIcon from 'assets/icons/chevron-right.svg';

import { ComboBoxOption } from 'types/TComboBoxOption';

interface IComboBox {
  options: ComboBoxOption[];
  id: string;
  selected: ComboBoxOption;
  handleSelected: (value: ComboBoxOption) => void;
}

export default ({ options, id, selected, handleSelected }: IComboBox) => {
  const [query, setQuery] = useState('');

  const filteredPeople =
    query === ''
      ? options
      : options.filter(entry =>
          entry.label.toLowerCase().includes(query.toLowerCase()),
        );

  return (
    <div className='mx-auto h-10 w-auto'>
      <Combobox value={selected} onChange={handleSelected}>
        <div className='relative'>
          <ComboboxInput
            className={clsx(
              'w-full rounded-xl bg-white py-1.5 pr-8 pl-3 text-sm/6 text-black',
              'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25',
            )}
            displayValue={(entry: ComboBoxOption) => entry?.label}
            onChange={event => setQuery(event.target.value)}
            id={id}
          />
          <ComboboxButton className='group absolute inset-y-0 right-0 px-2.5'>
            <ChevronDownIcon className='icon size-4 fill-grey-dark group-data-[hover]:fill-grey mt-2' />
          </ComboboxButton>
        </div>
        <Transition
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
          afterLeave={() => setQuery('')}
        >
          <ComboboxOptions
            anchor='bottom'
            className='w-[var(--input-width)] rounded-xl border border-grey-dark bg-white p-1 mt-1 [--anchor-gap:var(--spacing-1)] empty:hidden'
          >
            {filteredPeople.map(entry => (
              <ComboboxOption
                key={entry.id}
                value={entry}
                className='group flex cursor-pointer items-center gap-1 rounded-xl py-1.5 px-3 select-none data-[focus]:bg-white/10'
              >
                <CheckIcon className='icon invisible group-data-[selected]:visible' />
                <div className='text-sm/6'>{entry.label}</div>
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Transition>
      </Combobox>
    </div>
  );
};
