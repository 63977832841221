import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-use';

import BlockContainer from 'components/BlockContainer';
import { IFrame, Media } from 'components/blocks';
import Footer from 'components/snippets/Footer';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';
import NoDataText from 'components/static/NoDataText';
import SimpleWysiwyg from 'components/static/SimpleWysiwyg';

import { ERoute } from 'types/enums/ERoute';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import {
  ESuluContentBlockTypes,
  ESuluMediaTypes,
} from 'types/enums/sulu/EBackendData';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { IConsultantArticleResponse } from 'types/interfaces/sulu/IConsultantApi';
import { IUser } from 'types/interfaces/sulu/IUser';

import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import currentLanguage from 'utils/sulu/currentLanguage';
import getConsultantArticle from 'utils/sulu/getConsultantArticle';
import getProfile from 'utils/sulu/getProfile';

export default () => {
  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const consultantId = searchParams.get(EUrlSearchParam.CONSULTANT_ID) ?? '';
  const articleId = searchParams.get(EUrlSearchParam.ARTICLE_ID) ?? '';

  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [article, setArticle] = useState<
    IConsultantArticleResponse | undefined
  >();
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoggedInConsultant, setIsLoggedInConsultant] =
    useState<boolean>(false);

  const checkAuth = () => {
    getProfile().then(profile => setUser(profile));
  };

  const updateMenuItems = () => {
    const sidebarType = isLoggedInConsultant
      ? ESidebarType.CONSULTANT
      : ESidebarType.DEFAULT;
    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      sidebarType,
      [],
      consultantId,
    );
    setMenuItems(sidebarMenuItems);
  };

  useEffect(() => {
    setLoading(true);
    updateMenuItems();
    checkAuth();
    document.addEventListener('login:success', checkAuth);
    getConsultantArticle(parseInt(consultantId, 10), articleId).then(
      (articleData: IConsultantArticleResponse) => {
        const newArticle = Object.keys(articleData).length
          ? {
              id: articleData.id,
              title: articleData.title,
              description: articleData.description,
              category: articleData.category,
              content: articleData.content,
              photo: articleData.photo,
              file: articleData.file,
              video_link: articleData.video_link,
            }
          : undefined;
        setArticle(newArticle);
        setLoading(false);
      },
    );
  }, []);

  useEffect(() => {
    if (
      user &&
      user.consultantDetails !== undefined &&
      user.id.toString() === consultantId
    ) {
      setIsLoggedInConsultant(true);
    } else {
      setIsLoggedInConsultant(false);
    }
  }, [user]);

  useEffect(() => {
    updateMenuItems();
  }, [isLoggedInConsultant]);

  return (
    <Layout menuItems={menuItems}>
      {loading ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <BlockContainer>
          {article ? (
            <div className='2xl:max-w-[75%] mx-auto'>
              <h2 className='text-orange font-bold'>{article.title}</h2>
              <p className='text-orange'>{article.description}</p>
              {user && (
                <p className='text-xs'>
                  Autor:{' '}
                  <a
                    href={`/${currentLanguage}/${ERoute.CONSULTANT_PROFILE}?${EUrlSearchParam.CONSULTANT_ID}=${consultantId}`}
                  >
                    {user.firstName} {user.lastName}
                  </a>
                </p>
              )}
              <div className='mt-6'>
                {!article.category ? (
                  <NoDataText>
                    Inhalt des Artikels konnte nicht geladen werden
                  </NoDataText>
                ) : (
                  <>
                    {article.category === 'article' && (
                      <SimpleWysiwyg content={article.content} />
                    )}
                    {article.category !== 'article' && (
                      <>
                        {article.video_link && (
                          <IFrame
                            type={ESuluContentBlockTypes.IFRAME}
                            settings={[]}
                            url={article.video_link}
                          />
                        )}
                        {!article.video_link && (
                          <Media
                            type={
                              article.category === 'video'
                                ? ESuluMediaTypes.VIDEO
                                : ESuluMediaTypes.AUDIO
                            }
                            url={article.file.url}
                            mimeType={article.file.mimeType}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          ) : (
            <NoDataText>Artikel konnte nicht geladen werden</NoDataText>
          )}
        </BlockContainer>
      )}
      <Footer />
    </Layout>
  );
};
