import React from 'react';

import ArrowRightIcon from 'assets/icons/arrow-right.svg';

import { INavigation } from 'types/interfaces/sulu/INavigation';

export default (links: INavigation[]) => {
  // eslint-disable-next-line react/destructuring-assignment
  if (!links.length) {
    return <p className='text-black/50 text-center'>Keine Einträge</p>;
  }

  // eslint-disable-next-line react/destructuring-assignment
  return links.map(link => (
    <a
      key={`${link.title}-${link.url}`}
      href={`${link.locale}${link.url}`}
      className='menu-bar-link flex items-center justify-between gap-4 py-2.5 hover:text-black hover:font-bold'
    >
      <span className='flex-1 truncate'>{link.title}</span>
      <span className='arrow-right'>
        <ArrowRightIcon className='icon' />
      </span>
    </a>
  ));
};
