import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-use';

import BlockContainer from 'components/BlockContainer';
import Footer from 'components/snippets/Footer';
import { Button } from 'components/static/Button';
import ImpressumForm from 'components/static/ImpressumForm';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';

import { EConsultantNewArticleType } from 'types/enums/EConsultant';
import { ERoute } from 'types/enums/ERoute';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { ISPConsultantCreateArticle } from 'types/interfaces/sulu/IPages';
import { IUser } from 'types/interfaces/sulu/IUser';

import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import navigateTo from 'utils/navigateTo';
import getProfile from 'utils/sulu/getProfile';

export default ({ content }: ISPConsultantCreateArticle) => {
  const {
    page_headline_top: headline,
    page_headline_bottom: subline,
    intro_text: introText,
    description,
  } = content;

  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const consultantId = searchParams.get(EUrlSearchParam.CONSULTANT_ID) ?? '';

  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [user, setUser] = useState<IUser | null>(null);
  const [showImpressumForm, setShowImpressumForm] = useState<boolean>(false);
  const [isLoggedInConsultant, setIsLoggedInConsultant] = useState<
    boolean | undefined
  >(undefined);
  const [authChecked, setAuthChecked] = useState<boolean>(false);

  const checkAuth = () => {
    getProfile().then(profile => {
      setUser(profile);
      setAuthChecked(true);
    });
  };

  useEffect(() => {
    checkAuth();

    const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
      ESidebarType.CONSULTANT,
      [],
      consultantId,
    );
    setMenuItems(sidebarMenuItems);

    document.addEventListener('login:success', checkAuth);
  }, []);

  useEffect(() => {
    if (authChecked) {
      if (
        user &&
        user.consultantDetails !== undefined &&
        user.id.toString() === consultantId
      ) {
        setIsLoggedInConsultant(true);
        setShowImpressumForm(
          !(user.impressum && Object.keys(user.impressum).length),
        );
      } else {
        setIsLoggedInConsultant(false);
        setShowImpressumForm(false);
      }
    }
  }, [user, authChecked]);

  useEffect(() => {
    if (isLoggedInConsultant !== undefined) {
      if (!isLoggedInConsultant) {
        navigateTo('', [{ key: EUrlSearchParam.NO_CONSULTANT, value: 'true' }]);
      }
    }
  }, [isLoggedInConsultant]);

  return (
    <Layout menuItems={menuItems}>
      {!isLoggedInConsultant ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <BlockContainer>
          <>
            <h1>
              <span className='block text-3xl font-normal uppercase'>
                {headline || 'Meine'}
              </span>
              <span className='block'>{subline || 'Beiträge'}</span>
            </h1>
            <div className='mt-12 2xl:max-w-[75%]'>
              {introText && (
                <h4 className='text-orange font-bold whitespace-pre-line'>
                  {introText}
                </h4>
              )}
              {description && (
                <p className='whitespace-pre-line'>{description}</p>
              )}
            </div>
            {showImpressumForm && (
              <div className='mt-8 xl:max-w-[50%]'>
                <ImpressumForm onSubmitDone={checkAuth} />
              </div>
            )}
            <div className='flex flex-col md:flex-row justify-end gap-5 mt-24'>
              <Button
                type='button'
                label='Artikel verfassen'
                onClick={() =>
                  navigateTo(ERoute.CONSULTANT_NEW_ARTICLE, [
                    { key: EUrlSearchParam.CONSULTANT_ID, value: consultantId },
                    {
                      key: EUrlSearchParam.TYPE,
                      value: EConsultantNewArticleType.ARTICLE,
                    },
                  ])
                }
                disabled={showImpressumForm}
              />
              <Button
                type='button'
                label='Podcast hinzufügen'
                onClick={() =>
                  navigateTo(ERoute.CONSULTANT_NEW_ARTICLE, [
                    { key: EUrlSearchParam.CONSULTANT_ID, value: consultantId },
                    {
                      key: EUrlSearchParam.TYPE,
                      value: EConsultantNewArticleType.PODCAST,
                    },
                  ])
                }
                disabled={showImpressumForm}
              />
              <Button
                type='button'
                label='Video hinzufügen'
                onClick={() =>
                  navigateTo(ERoute.CONSULTANT_NEW_ARTICLE, [
                    { key: EUrlSearchParam.CONSULTANT_ID, value: consultantId },
                    {
                      key: EUrlSearchParam.TYPE,
                      value: EConsultantNewArticleType.VIDEO,
                    },
                  ])
                }
                disabled={showImpressumForm}
              />
            </div>
          </>
        </BlockContainer>
      )}
      <Footer />
    </Layout>
  );
};
