import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-var-requires
import { useLocation } from 'react-use';

import BlockContainer from 'components/BlockContainer';
import SearchConsultant from 'components/primitives/Search/SearchConsultant';
import Footer from 'components/snippets/Footer';
import { IConsultantTile } from 'components/static/ConsultantTile';
import Layout from 'components/static/Layout';
import LoadingSpinner from 'components/static/LoadingSpinner';
import NoDataText from 'components/static/NoDataText';
import Pagination from 'components/static/Pagination';

import useIsInViewport from 'hooks/useIsInViewport';

import { ERoute } from 'types/enums/ERoute';
import { ESidebarType } from 'types/enums/ESidebarType';
import { EUrlSearchParam } from 'types/enums/EUrlSearchParam';
import { ESuluNavigationContexts } from 'types/enums/sulu/EBackendData';
import { ISidebarMenuItem } from 'types/interfaces/ISidebar';
import { IConsultantResponse } from 'types/interfaces/sulu/IUser';

import { TILES_PER_PAGE } from 'utils/constants';
import createConsultantTiles from 'utils/createConsultantTiles';
import getRandomSample from 'utils/getRandomSample';
import getSidebarMenuItems from 'utils/getSidebarMenuItems';
import scrollToElement from 'utils/scrollToElement';
import currentLanguage from 'utils/sulu/currentLanguage';
import getAllConsultants from 'utils/sulu/getAllConsultants';
import getNavigation from 'utils/sulu/getNavigation';

import { ISPConsultantOverview } from '../types/interfaces/sulu/IPages';

export default ({ content }: ISPConsultantOverview) => {
  const { title, page_title: pageTitle } = content;
  const { search: searchParamString } = useLocation();
  const searchParams = new URLSearchParams(searchParamString);
  const filterIdFromUrl = searchParams.get('filterId');
  const searchTriggeredFromUrl = searchParams.get('searchTriggered');

  const scrollToElementRef = useRef<HTMLHeadingElement>(null);
  const isScrollToElementRefInViewport = useIsInViewport(scrollToElementRef);
  const [menuItems, setMenuItems] = useState<ISidebarMenuItem[]>([]);
  const [tiles, setTiles] = useState<IConsultantTile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  // pagination
  const [currentPage, setCurrentPage] = useState(1);
  const tilesPerPage = TILES_PER_PAGE;
  const indexOfLastTile = currentPage * tilesPerPage;
  const indexOfFirstTile = indexOfLastTile - tilesPerPage;
  const currentTiles = tiles.slice(indexOfFirstTile, indexOfLastTile);
  const [filterId, setFilterId] = useState<number[]>([]);
  const [searchTriggered, setSearchTriggered] = useState(false);

  useEffect(() => {
    Promise.all([
      getNavigation(ESuluNavigationContexts.INFORMATION),
      getNavigation(ESuluNavigationContexts.ENTERTAINMENT),
      getNavigation(ESuluNavigationContexts.CONSULTING),
    ]).then(subMenuItems => {
      const sidebarMenuItems: ISidebarMenuItem[] = getSidebarMenuItems(
        ESidebarType.TOPICS,
        subMenuItems.flat(),
      );
      setMenuItems(sidebarMenuItems);
    });
  }, []);

  // useEffect, to set the URL parameters
  useEffect(() => {
    if (filterIdFromUrl) {
      const parsedFilterId = JSON.parse(filterIdFromUrl);
      if (Array.isArray(parsedFilterId)) {
        setFilterId(parsedFilterId);
      }
    }

    if (searchTriggeredFromUrl) {
      setSearchTriggered(searchTriggeredFromUrl === 'true');
    }
  }, [filterIdFromUrl, searchTriggeredFromUrl]);

  useEffect(() => {
    setLoading(true);

    getAllConsultants().then((consultants: IConsultantResponse[]) => {
      // Random sequence of advisors and limit search results
      const limitedConsultants = getRandomSample(consultants);
      const consultantTiles = limitedConsultants.map(consultant => ({
        id: consultant.id,
        name: consultant.name || '',
        url: `/${currentLanguage}/${ERoute.CONSULTANT_PROFILE}?${EUrlSearchParam.CONSULTANT_ID}=${consultant.id}`,
        profileImageUrl: consultant.avatar || '',
      }));
      setTiles(consultantTiles);
      setLoading(false);
    });
  }, []);

  const previousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
      scrollToElement(scrollToElementRef, isScrollToElementRefInViewport);
    }
  };

  const nextPage = () => {
    if (currentPage !== Math.ceil(tiles.length / tilesPerPage)) {
      setCurrentPage(currentPage + 1);
      scrollToElement(scrollToElementRef, isScrollToElementRefInViewport);
    }
  };

  return (
    <Layout menuItems={menuItems}>
      {loading ? (
        <BlockContainer>
          <div className='flex justify-center'>
            <LoadingSpinner size='w-10 h-10' />
          </div>
        </BlockContainer>
      ) : (
        <div className='bg-grey-background'>
          <BlockContainer>
            <>
              <div>
                <span className='uppercase text-3xl'>unsere</span>
                <h3 ref={scrollToElementRef} className='scroll-mt-16 mb-8'>
                  <span className='text-5xl'>Berater</span>
                </h3>
                <span className='hidden'>{pageTitle || title}</span>
              </div>

              <div className='2xl:mr-24'>
                <SearchConsultant
                  setFilterId={setFilterId}
                  setSearchTriggered={setSearchTriggered}
                  hideAdvancedSearch={false}
                />
                {searchTriggered && (
                  <div className='flex flex-col gap-5 mt-10'>
                    {filterId.length === 0 && (
                      <NoDataText>Keine passenden Berater gefunden</NoDataText>
                    )}
                    {filterId.length > 0 &&
                      createConsultantTiles(tiles, filterId)}
                  </div>
                )}
                {!searchTriggered && currentTiles.length >= 0 && (
                  <div className='flex flex-col gap-5 mt-10'>
                    {tiles.length === 0 && (
                      <NoDataText>Keine Berater verfügbar</NoDataText>
                    )}
                    {tiles.length > 0 &&
                      createConsultantTiles(currentTiles, filterId)}
                  </div>
                )}
              </div>
              {tiles.length > tilesPerPage && (
                <Pagination
                  postsPerPage={tilesPerPage}
                  totalPosts={tiles.length}
                  currentPage={currentPage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  className='mt-12'
                />
              )}
            </>
          </BlockContainer>
        </div>
      )}
      <Footer />
    </Layout>
  );
};
