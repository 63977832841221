import React from 'react';
import { useWindowSize } from 'react-use';

import { ETailwindBreakpoint } from 'types/enums/ETailwindBreakpoint';
import { ISidebar } from 'types/interfaces/ISidebar';

import getTailwindBreakpointAsNumber from 'utils/getTailwindBreakpointAsNumber';

import Navigation from './Navigation';
import NavigationMobile from './NavigationMobile';

export default ({
  menuItems,
  schemeColor,
  activeLinkUrl = '',
  showLogoutButton = false,
}: ISidebar) => {
  const { width } = useWindowSize();

  return width < getTailwindBreakpointAsNumber(ETailwindBreakpoint.lg) ? (
    <NavigationMobile
      menuItems={menuItems}
      schemeColor={schemeColor}
      activeLinkUrl={activeLinkUrl}
      showLogoutButton={showLogoutButton}
    />
  ) : (
    <Navigation
      menuItems={menuItems}
      schemeColor={schemeColor}
      activeLinkUrl={activeLinkUrl}
      showLogoutButton={showLogoutButton}
    />
  );
};
