import React, { useEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

import CloseIcon from 'assets/icons/close.svg';

import AuthTabs from 'components/static/AuthTabs';
import CloseButton from 'components/static/CloseButton';

import { EButtonSize, EButtonType } from 'types/enums/EButton';
import { ETailwindBreakpoint } from 'types/enums/ETailwindBreakpoint';
import { IButton } from 'types/interfaces/IButton';

import {
  getButtonSizeStyles,
  getButtonTypeStyles,
} from 'utils/getButtonStyles';
import getTailwindBreakpointAsNumber from 'utils/getTailwindBreakpointAsNumber';

import './Button.css';

interface IButtonProps
  extends IButton,
    React.ComponentPropsWithoutRef<'button'> {}

export default ({
  icon = undefined,
  label = undefined,
  showIconOnDesktop = false,
  buttonType = EButtonType.PRIMARY,
  buttonSize = EButtonSize.REGULAR,
  disabled = false,
  ...rest
}: IButtonProps) => {
  const { width } = useWindowSize();
  const [showIcon, setShowIcon] = useState<boolean>(showIconOnDesktop);
  const [showContainer, setShowContainer] = useState<boolean>(false);
  const labelIfContainerIsVisible = 'Schließen';

  useEffect(() => {
    if (!label) {
      setShowIcon(true);
    }
  }, []);

  return (
    <div className='relative'>
      <button
        type='button'
        className={`${getButtonTypeStyles({
          buttonType,
        })} ${getButtonSizeStyles({
          icon,
          label,
          buttonSize,
        })} button ${
          buttonSize === EButtonSize.SMALL ? 'button-small' : ''
        } flex items-center justify-center text-center rounded-full transition-all ease-in-out duration-300 ${
          disabled && 'pointer-events-none opacity-50'
        }`}
        disabled={disabled}
        onClick={() => {
          setShowContainer(!showContainer);
        }}
        {...rest}
      >
        {icon && (
          <span
            className={`${!showIcon ? 'lg:hidden' : ''} ${
              label ? 'lg:mr-2' : ''
            } ${
              buttonType === EButtonType.SECONDARY
                ? 'fill-orange'
                : 'fill-white'
            }`}
          >
            {showContainer ? <CloseIcon className='icon' /> : icon}
          </span>
        )}
        {label && (
          <span className={`${icon ? 'hidden lg:inline' : ''}`}>
            {showContainer ? labelIfContainerIsVisible : label}
          </span>
        )}
      </button>
      {/* mobile container */}
      {width < getTailwindBreakpointAsNumber(ETailwindBreakpoint.md) && (
        <div
          className={`fixed inset-0 z-10 bg-white overflow-y-auto transition-transform ease-in-out duration-300 ${
            showContainer ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className='relative pt-16'>
            <CloseButton
              className='absolute top-4 right-5'
              onClick={() => setShowContainer(false)}
            />
            <AuthTabs />
          </div>
        </div>
      )}
      {/* desktop container */}
      {width >= getTailwindBreakpointAsNumber(ETailwindBreakpoint.md) && (
        <div
          className={`absolute top-full right-0 w-96 pt-1 transition-all ease-in-out duration-300 ${
            showContainer ? 'visible opacity-100' : 'invisible opacity-0'
          }`}
        >
          <div className='bg-white rounded-xl shadow-lg'>
            <AuthTabs />
          </div>
        </div>
      )}
    </div>
  );
};
