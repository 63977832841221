import React from 'react';

import TileDetails from 'components/blocks/TileDetails';

import { ISPContentTile } from 'types/interfaces/sulu/IPages';

export default (
  tiles: ISPContentTile[],
  useVibrantColor: boolean = false,
  schemeColor: string = '',
  category: string = '',
) =>
  tiles.map(tile => (
    <TileDetails
      key={tile.id}
      {...tile}
      useVibrantColor={useVibrantColor}
      schemeColor={schemeColor}
      category={category}
    />
  ));
