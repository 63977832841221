import React, { useState } from 'react';

import TrashIcon from 'assets/icons/trashCan.svg';

import { ESuluContentTypeText } from 'types/enums/sulu/EBackendData';

import { EConsultantNewArticleType } from '../../../types/enums/EConsultant';

export interface IConsultantArticleTile {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  contentType: EConsultantNewArticleType;
  url: string;
}

export interface IConsultantArticleTileProps {
  isLoggedInConsultant: boolean;
  tile: IConsultantArticleTile;
}

const ConsultantArticleTile = ({
  isLoggedInConsultant,
  tile,
}: IConsultantArticleTileProps) => {
  const { title, description, imageUrl, contentType, url } = tile;

  const [hover, setHover] = useState(false);

  const getBackgroundColor = (filter: EConsultantNewArticleType) => {
    switch (filter) {
      case EConsultantNewArticleType.PODCAST:
        return 'bg-consultant-tile-podcast/30';
      case EConsultantNewArticleType.VIDEO:
        return 'bg-consultant-tile-video/30';
      case EConsultantNewArticleType.ARTICLE:
      default:
        return 'bg-consultant-tile-article/30';
    }
  };

  const getBillText = (billText: EConsultantNewArticleType) => {
    switch (billText) {
      case EConsultantNewArticleType.PODCAST:
        return ESuluContentTypeText.PODCAST;
      case EConsultantNewArticleType.VIDEO:
        return ESuluContentTypeText.VIDEO;
      case EConsultantNewArticleType.ARTICLE:
      default:
        return ESuluContentTypeText.ARTICLE;
    }
  };

  const bgImageStyle = {
    backgroundImage: `url(${imageUrl})`,
  };
  const backgroundColor = getBackgroundColor(contentType);
  const bill = getBillText(contentType);

  const handleClick = () => {
    window.open(url, '_self');
  };

  // TODO
  const handleTrashClick = () => {
    if (!isLoggedInConsultant) {
      return;
    }
    window.open(url, '_blank');
  };

  return (
    <div
      className={`w-52 p-2.5 rounded-xl cursor-pointer overflow-hidden ${backgroundColor}`}
      onClick={handleClick}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onBlur={() => setHover(false)}
      onKeyDown={e => e.key === 'Enter' && handleClick()}
      role='button'
      tabIndex={0}
    >
      <div className='relative pt-[100%] rounded-md shadow-md'>
        <div
          className={`absolute inset-0 rounded-md bg-center bg-no-repeat bg-cover transition-all
    ${hover ? 'brightness-50' : 'brightness-100'}`}
          style={bgImageStyle}
        />
        {contentType && (
          <div
            className={`flex text-black bg-white/90 px-2.5 py-0.5 rounded-full absolute top-2 left-2 transition-opacity ${
              hover ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <span className='text-xs'>{bill}</span>
          </div>
        )}
      </div>

      <div className='relative flex items-center h-12 px-2 mt-2'>
        <p
          className={`absolute left-2 right-2 inset-y-0 font-bold line-clamp-2 transition-all ${
            hover ? 'text-white' : ''
          }`}
          style={{
            transform: hover ? 'translateY(calc(-100% - 1rem))' : '',
          }}
        >
          {title}
        </p>
        <p
          className={`text-black text-sm line-clamp-2 transition-opacity ${
            hover ? 'opacity-100' : 'opacity-0'
          }`}
        >
          {description}
        </p>
      </div>
      {isLoggedInConsultant && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          className='flex items-center justify-center rounded-full ml-auto mt-2 w-8 h-8 bg-orange hover:bg-gradient-to-r hover:from-orange-gradient-from to-orange-gradient-to'
          onClick={handleTrashClick}
        >
          <TrashIcon />
        </div>
      )}
    </div>
  );
};

export default ConsultantArticleTile;
